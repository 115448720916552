import React from "react";
import { useNavigate } from "react-router-dom";
import "./PersonalDetails_AadharVerificationDetails.css";

const PersonalDetails_AadharVerificationDetails = ({ ProfileDetails }) => {
  return (
    <div className="PersonalDetails_AadharVerificationDetails">
      <div className="fewWordBoxHeader">
        <h6>Aadhar Verification Details</h6>
      </div>

      <div className="card PersonalDetails_card">
        <div className="DetailsSectionRow">
          <div className="Details_section">
            <ul className="Details_section_label">
              <li>Verification Status</li>
            </ul>
            <ul className="Details_section_colon">
              <li>:</li>
            </ul>

            {/* DesktopView verification */}
            <ul className="Details_section_values DesktopAadharVarification">
              <li>
                <span className="notAadhaarVerifiedLabel">{`Not Verified`}</span>
                <a
                  className="NeedToSelectValuePink aadharverifyPinkLink"
                  href="/aadhar"
                >
                  {`Verify Now`} &nbsp;&nbsp;
                  <i class="fa-solid fa-play"></i>
                </a>
              </li>
            </ul>

            {/* MobileView verification */}
            <ul className="Details_section_values MobileAadharVarification">
              <li>
                <span className="notAadhaarVerifiedLabel">{`Not Verified`}</span>
                <a
                  className="NeedToSelectValuePink aadharverifyPinkLink"
                  href="/mobileaadhar"
                >
                  {`Verify Now`} &nbsp;&nbsp;
                  <i class="fa-solid fa-play"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_AadharVerificationDetails;

{
  /* <li className="">
  <span className="notAadhaarVerifiedLabel">{`Not Verified`}</span>
  <a
    className="NeedToSelectValuePink aadharverifyPinkLink"
    href="/mobileaadhar"
  >
    {`Verify Now`} &nbsp;&nbsp;
    <i class="fa-solid fa-play"></i>
  </a>
</li>; */
}

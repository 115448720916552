import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "./FAQ.css";

const FAQ = () => {

  const navigate = useNavigate();

  const [tab, setTab] = useState("");

  const handleTab = (value) => {
    setTab(value);
  };


  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">FAQ</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>
          </div>
          <div className="policy">
            <div className="headerRow tearmsAndConditionsHeaderRow">
              <h4>Frequently Asked Questions</h4>
            </div>
          </div>

          <div className="FAQAccordian">
            <div
              className={`AccordianTab ${
                tab === "addPhoto" ? "ChangeTabColor" : "No"
              }`}
              onClick={() => {
                setTab(tab === "addPhoto" ? "" : "addPhoto");
              }}
            >
              <div className="AccordianButton">
                <span>1. How can I add a photo?</span>

                {tab === "addPhoto" ? (
                  <i class="fa-solid fa-minus"></i>
                ) : (
                  <i class="fa-solid fa-plus"></i>
                )}
              </div>

              {tab === "addPhoto" && (
                <p className="FAQTABPara">
                  Click here to upload photo.
                  <br /> You can also take a selfie or send your photos via
                  WhatsApp to +91 XXXXXXXXXX for instant upload.
                  <br />
                  Please note: It may take up to 2 hours for photo validation,
                  after which it will be visible to everyone.
                </p>
              )}
            </div>

            <div
              className={`AccordianTab ${
                tab === "contactPersonPhoto" && "ChangeTabColor"
              }`}
              onClick={() => {
                setTab(
                  tab === "contactPersonPhoto" ? "" : "contactPersonPhoto"
                );
              }}
            >
              <div className="AccordianButton">
                <span>
                  2. How can I set my photo visible only to those people whom I
                  have contacted/responded to me?
                </span>

                {tab === "contactPersonPhoto" ? (
                  <i class="fa-solid fa-minus"></i>
                ) : (
                  <i class="fa-solid fa-plus"></i>
                )}
              </div>
              {tab === "contactPersonPhoto" && (
                <p className="FAQTABPara">
                  Click here to upload photo.
                  <br /> You can also take a selfie or send your photos via
                  WhatsApp to +91 XXXXXXXXXX for instant upload.
                  <br />
                  Please note: It may take up to 2 hours for photo validation,
                  after which it will be visible to everyone.
                </p>
              )}
            </div>

            <div
              className={`AccordianTab ${
                tab === "preferPersonPhoto" && "ChangeTabColor"
              }`}
              onClick={() => {
                setTab(tab === "preferPersonPhoto" ? "" : "preferPersonPhoto");
              }}
            >
              <div className="AccordianButton">
                <span>
                  3. How can I make my photo visible only to members I prefer,
                  and not everyone?
                </span>

                {tab === "preferPersonPhoto" ? (
                  <i class="fa-solid fa-minus"></i>
                ) : (
                  <i class="fa-solid fa-plus"></i>
                )}
              </div>
              {tab === "preferPersonPhoto" && (
                <p className="FAQTABPara">
                  Click here to upload photo.
                  <br /> You can also take a selfie or send your photos via
                  WhatsApp to +91 XXXXXXXXXX for instant upload.
                  <br />
                  Please note: It may take up to 2 hours for photo validation,
                  after which it will be visible to everyone.
                </p>
              )}
            </div>

            <div
              className={`AccordianTab ${
                tab === "photoNotVisible" && "ChangeTabColor"
              }`}
              onClick={() => {
                setTab(tab === "photoNotVisible" ? "" : "photoNotVisible");
              }}
            >
              <div className="AccordianButton">
                <span>4. Why is my photo not visible on my profile?</span>

                {tab === "photoNotVisible" ? (
                  <i class="fa-solid fa-minus"></i>
                ) : (
                  <i class="fa-solid fa-plus"></i>
                )}
              </div>
              {tab === "photoNotVisible" && (
                <p className="FAQTABPara">
                  Click here to upload photo.
                  <br /> You can also take a selfie or send your photos via
                  WhatsApp to +91 XXXXXXXXXX for instant upload.
                  <br />
                  Please note: It may take up to 2 hours for photo validation,
                  after which it will be visible to everyone.
                </p>
              )}
            </div>

            <div
              className={`AccordianTab ${
                tab === "removePhoto" && "ChangeTabColor"
              }`}
              onClick={() => {
                setTab(tab === "removePhoto" ? "" : "removePhoto");
              }}
            >
              <div className="AccordianButton">
                <span>5. How do I remove a photo from my profile?</span>

                {tab === "removePhoto" ? (
                  <i class="fa-solid fa-minus"></i>
                ) : (
                  <i class="fa-solid fa-plus"></i>
                )}
              </div>
              {tab === "removePhoto" && (
                <p className="FAQTABPara">
                  Click here to upload photo.
                  <br /> You can also take a selfie or send your photos via
                  WhatsApp to +91 XXXXXXXXXX for instant upload.
                  <br />
                  Please note: It may take up to 2 hours for photo validation,
                  after which it will be visible to everyone.
                </p>
              )}
            </div>
          </div>

          <div className="row Testimonials-Footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;

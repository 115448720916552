import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Subscribe/SubscribePopUp.css";

const SubscribePopUp = ({ setSubscribePopUp, subscribe }) => {
  const Navigate = useNavigate();
  useEffect(() => {
    subscribe
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [subscribe]);
  return (
    <>
      <div className={`SubscribePopUp `}>
        <div className="subscribePopupContent">
          <div
            className="SubscribePopUp-Close"
            onClick={() => {
              document.getElementById("body").style.overflowY = "auto";
              setSubscribePopUp(false);
            }}
          >
            <i class="fa-solid fa-xmark"></i>
          </div>

          <div className="SubscribePopUp-Box">
            <div className="SubscribePopUp-Header">
              <p> Subscribe Now </p>
            </div>

            <div className="SubscribePopUp-Context">
              <p>
                You are one among the most viewed matches ! Subscribe now to
                access the chat feature and unlimited benefits!
              </p>
            </div>

            <div className="SubscribePopUp-Buttons">
              <div
                className="SubscribePopUp-Button-1"
                onClick={() => {
                  document.getElementById("body").style.overflowY = "auto";
                  setSubscribePopUp(false);
                }}
              >
                <button className="Buttons"> I will subscribe later </button>
                {/* <button className="Mobile-Buttons">Later </button> */}
              </div>
              <div className="SubscribePopUp-Button-2">
                <button
                  onClick={() => {
                    document.getElementById("body").style.overflowY = "auto";
                    Navigate("/subscribe");
                  }}
                >
                  {" "}
                  Subscribe Now{" "}
                </button>
                {/* <button
                  onClick={() => {
                    document.getElementById("body").style.overflowY = "auto";
                    Navigate("/subscribe");
                  }}
                  className="Mobile-Buttons" >
                  {" "}
                  Subscribe 
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribePopUp;

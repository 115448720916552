import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import withAuthBackground from "../withAuthBackground";
import AuthHeader from "../../../components/Auth/AuthHeader";
import MobileVerification from "../../../components/Auth/Mobile verification/MobileVerification";
import "./Forgotpassword.css";

const ForgotPassword = () => {
  let [counter,setCounter] = useState(59);

  useEffect(() => {
      const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1),1000);

      return () => {
          clearInterval(timer);
      }
  },[counter]);

  const navigate = useNavigate();

  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      {/* reset container */}
      <div className="row">
        <div className="forgotContainer">
          <div className="forgotHeader">Reset Password</div>
              <MobileVerification nextPage={"reset"}/>

          <div
            className="goBack"
            onClick={() => {
              navigate("/sendOTP");
            }}
          >
            <span className="material-symbols-outlined">chevron_left</span>
            Back
          </div>
          <div className="forgotFull" />
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(ForgotPassword, "login");

import React from "react";
import "./RadioInputField.css";

const RadioInputField = ({ input, handleChange, inputFields, className }) => {
  // console.log(input.value);
  return (
    <>
      {input.field.map((inp) => (
        <div className="partnerFieldRadio">
          <input
            type="radio"
            id={inp.id}
            name={inp.name}
            value={inp.value}
            onChange={handleChange}
            checked = { inp.value === inputFields[inp.name] }
            className= {className}
          />
            <label htmlFor={inp.id}>{inp.label}</label>
        </div>
      ))}
    </>
  );
};

export default RadioInputField;

import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import { TextArea } from "@progress/kendo-react-inputs";
import "../Contactus/Contactus.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import nayan from "../../assets/images/Home/nayan.jpg";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

const Contactus = () => {
  const navigate = useNavigate();

  const [textBox, setTextBox] = useState("");

  const schema = z.object({
    name: z.string().min(1, { message: "Please enter your name" }),
    mobile: z
      .string()
      .min(10, { message: "Invalid mobile number" })
      .max(10, { message: "Invalid mobile number" }),
    help: z.string().min(1, { message: "Please select the Help" }),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      mobile: "",
      help: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = async (formValues) => {
    let { name, mobile, help } = formValues;

    const user_id = window.sessionStorage.getItem("User_ID");

    const postData = await axios.post(
      `${BACKEND_PORT}/contactus/contactUsForm`,
      {
        user_id,
        userName: name,
        mobile: mobile,
        helpWithYou: help,
        howCanWeHelp: textBox,
      }
    );

    console.log(postData);

    reset();
    setTextBox("");
  };
  const handleChange = ({ target: { value, name } }) => {
    setTextBox(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Contact Us</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="profileDetails_pic_ID">
              <div className="profileImg">
                <img src={nayan} />
              </div>
              <div className="profileName">{`Cathy`}</div>
              <span className="profileID">{`JM123456`}</span>
            </div>

            <div className="Contact-Us">
              <div className="Contact-Us-Header">
                Contact Us
                <span>
                  We’d love to hear from you, please do let us know if you have
                  any query
                </span>
              </div>
              <div className="Contact-Us-Form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label>Your Name*</label>
                  <input type="text" name={"name"} {...register("name")} />
                  {errors.name && (
                    <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
                      {errors.name.message}
                    </label>
                  )}
                  <label>Your Mobile Number*</label>

                  <input
                    type="Number"
                    name={"mobile"}
                    {...register("mobile")}
                  />
                  {errors.mobile && (
                    <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
                      {errors.mobile.message}
                    </label>
                  )}
                  <label>What can we help you with?*</label>

                  <select id="help" name={"help"} {...register("help")}>
                    <option value="option1">
                      Select what can we help you with
                    </option>
                    <option value="option1">Option1</option>
                    <option value="option1">Option1</option>
                    <option value="option1">Option1</option>
                  </select>
                  {errors.help && (
                    <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
                      {errors.help.message}
                    </label>
                  )}
                  <label>How can we help?</label>
                  <TextArea
                    name="textBox"
                    className="Contact-Us-TextArea"
                    value={textBox}
                    onChange={handleChange}
                  ></TextArea>

                  <button> Submit </button>
                </form>
              </div>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./MobileSearchBox.css";

function MobileSearchBox() {
  const navigate = useNavigate();
  return (
    <>
      <div className="searchBoxTapBar">
        Search
        <div className="leftArrowIcon" onClick={()=>{navigate('/home')}}>
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      </div>

      <div className="SearchBoxContents">
        <label className="SearchOptionsLabel">Select search options</label>
        <div className="SearchBoxButtons">
          <button
            onClick={() => {
              navigate("/regularsearch");
            }}
          >
            <label>Regular Search</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/keywordsearch");
            }}
          >
            <label>Keyword Search</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matrimonyidsearch");
            }}
          >
            <label>Search by Matrimony ID</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default MobileSearchBox;

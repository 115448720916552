import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import nayan from "../../assets/images/Home/nayan.jpg";
import homeProfilePic1 from "../../assets/images/Home/homeProfilePic1.png";
import homeProfilePic2 from "../../assets/images/Home/homeProfilePic2.png";
import homeProfilePic3 from "../../assets/images/Home/homeProfilePic3.png";
import homeEdit from "../../assets/images/Home/homeEdit.png";
import homeEditPref from "../../assets/images/Home/homeEditPref.png";
import homeVerify from "../../assets/images/Home/homeVerify.png";
import homeSettings from "../../assets/images/Home/homeSettings.png";
import homeLogout from "../../assets/images/Home/homeLogout.png";
import HomeCardComponent from "../../components/Home/HomeCardComponent/HomeCardComponent";
import DiscoverMatchesCard from "../../components/Home/DiscoverMacthesCard/DiscoverMatchesCard";
import HomeAdvBox from "../../components/Home/HomeAdvBox/HomeAdvBox";
import homeCouple from "../../assets/images/Home/homeCouple.png";
import sidebarIcon from "../../assets/images/Sidebar/sidebarIcon.png";
import MobileNotificationIcon from "../../assets/images/Sidebar/NotificationBell Icon.png";
import GreenNotificationPointIcon from "../../assets/images/Sidebar/GreenNotificationPointIcon.png";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import LogoutPopup from "../../components/LogoutPopup/LogoutPopup";
import MobileSidebar from "../../components/Headers/MobileSidebar/MobileSidebar";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ProfilePicPlaceholder from "../../assets/images/Home/HomeProfilePlaceHolder.png";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

const Home = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  // Profile Picture
  const [profilePicture, setProfilePicture] = useState(nayan);

  // Logout popup
  const [logoutPopup, setLogoutPopup] = useState(false);

  // Mobile sidebar
  const [Sidebar, setSidebar] = useState(false);

  // for slider...
  const [slide, setSlide] = useState(0);

  const [NewProfileMembers, setProfileMembers] = useState([]);

  const profileMembers = [
    { profilePic: homeProfilePic1, name: "Thomas", age: "25 Yrs" },
    { profilePic: homeProfilePic2, name: "Thomas", age: "25 Yrs" },
    { profilePic: homeProfilePic3, name: "Thomas", age: "25 Yrs" },
  ];

  const user_id = window.sessionStorage.getItem("User_ID");

  useEffect(() => {
    (async () => {
      const getNewMatches = await axios.get(
        `${BACKEND_PORT}/matches/newMatches`,
        {
          user_id,
        }
      );

      setProfileMembers(getNewMatches.data.message);
      // console.log(getNewMatches.data.message);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const getTotalMatches = await axios.get(
        `${BACKEND_PORT}/matches/totalMatches/${user_id}`
      );

      // console.log(getTotalMatches);
    })();
  }, []);

  // console.log(NewProfileMembers);

  // home slider content
  const homeProfileSlides = [
    {
      text: "Photos are the first thing that everyone looks in a profile",
      link: "/addProfilePhotos",
      button: "Add photo",
    },
    {
      text: "Members look for professional details while looking for an ideal match",
      link: "/editProfilePage",
      button: "Add Details",
    },
    {
      text: "Get more responses by verifying your profile with ID proof",
      link: "/aadhar",
      button: "Verify now",
    },
    {
      text: "Add your partner preferences for better match results",
      link: "/partnerPreference",
      button: "Add Details",
    },
  ];

  useEffect(() => {
    Sidebar ? disableBodyScroll(document) : enableBodyScroll(document);
    Sidebar
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [Sidebar]);

  return (
    <>
      <div
        className={`container-fluid ${
          Sidebar ? "MobileHomePageContainer" : ""
        } HomePageContainer`}
      >
        <span className="HomeHeaderDivision">
          {/* home header */}
          <HomeHeader />
        </span>

        <span className="MobileSidebarDivision">
          <span
            className="sidebarIcon"
            onClick={() => {
              setSidebar(true);
            }}
          >
            <img src={sidebarIcon} alt="SidebarIcon" />
          </span>

          {Sidebar && (
            <div
              id={`${Sidebar ? "sidebarMenu" : "hideSidebar"}`}
              // style={{ width: "100%" }}
            >
              <MobileSidebar
                setSidebar={setSidebar}
                setLogoutPopup={setLogoutPopup}
              />
            </div>
          )}

          <span
            className="MobileNotificationIcon"
            onClick={() => {
              navigate("/notifications");
            }}
          >
            <img src={MobileNotificationIcon} alt="NotificationBell" />
            <img
              src={GreenNotificationPointIcon}
              alt="MobileNotificationPoint"
              className="MobileNotificationPoint"
            />
          </span>
        </span>

        <div className="row homeContainer">
          {/* profile box design */}
          <div className="homeProfileDetails">
            <div className="homeProfileContainer">
              <div className="homeProfile">
                <div className="homeProfileDetailsImage">
                  {!profilePicture ? (
                    <img src={profilePicture} />
                  ) : (
                    <img
                      src={ProfilePicPlaceholder}
                      className="placeHolderImg"
                    />
                  )}
                </div>
                <div className="homeProfileDetailsName">Hey ! Cathy</div>
                <div className="homeProfileCompletion">
                  <div className="ProfileCompletedLable">Profile Completed</div>
                  <div className="homeProfileCompletionStatus">
                    <div className="homeProfileCompletionStatusPink"></div>
                  </div>
                  <div>25%</div>
                </div>
                <div className="homeProfileCompletionButton">
                  <button
                    onClick={() => {
                      navigate("/editprofilepage");
                    }}
                  >
                    complete your profile
                    <span>
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className="homeProfileSubscibe">
                <div>Subscribe to chat with the matches</div>
                <button>Subscribe now</button>
              </div>
            </div>
            <div className="homeProfileOptions">
              <a href="/editProfilePage">
                <span>
                  <img src={homeEdit} />
                </span>
                Edit profile
              </a>
              <a href="/partnerPreference">
                <span>
                  <img src={homeEditPref} />
                </span>
                Edit preferences
              </a>
              <a href="/aadhar">
                <span>
                  <img src={homeVerify} />
                </span>
                Verify profile
              </a>
              <a href="/changeEmailId">
                <span>
                  <img src={homeSettings} />
                </span>
                Settings
              </a>
              <a
                href="#"
                onClick={() => {
                  setLogoutPopup(true);
                }}
              >
                <span>
                  <img src={homeLogout} />
                </span>
                Logout
              </a>
            </div>
          </div>

          <div className="MobileViewProfileVerify">
            <span>Your profile is pending verification</span>
            <button>Verify</button>
          </div>

          <hr className="MobileViewHrTag" />

          {/* profile completion box */}
          <div className="homeMatchesContainer">
            <div className="profileCompletionContainer">
              <div className="profileCompletionContainerHeader">
                <h4 className="profileCompletionContainerHeader1">
                  Your profile is only {"25%"} complete
                </h4>
                <div className="profileCompletionContainerHeader2">
                  Add the details below to complete your Profile
                </div>
              </div>
              <div className="profileCompletionContainerSlide">
                <button
                  className={`homeSlideArrowLeft ${
                    slide === 0 ? "homeArrowGrey" : ""
                  }`}
                  onClick={() => {
                    setSlide(slide === 0 ? 0 : slide - 1);
                  }}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </button>
                <div className="profileCompletionContainerSlideContentContainer">
                  <div className="profileCompletionContainerSlideContent">
                    <div>{homeProfileSlides[slide].text}</div>
                    <div>(+25% in profile completeness)</div>
                  </div>
                  <div
                    className="profileCompletionContainerSlideButton"
                    onClick={() => {
                      navigate(homeProfileSlides[slide].link);
                    }}
                  >
                    <button>{homeProfileSlides[slide].button}</button>
                  </div>
                </div>
                <button
                  className={`homeSlideArrowRight ${
                    slide >= homeProfileSlides.length - 1 ? "homeArrowGrey" : ""
                  }`}
                  onClick={() => {
                    setSlide(
                      slide >= homeProfileSlides.length - 1
                        ? homeProfileSlides.length - 1
                        : slide + 1
                    );
                  }}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </button>
              </div>
            </div>

            {/* new matches */}
            <div className="homeMatchesContainerBox">
              <HomeCardComponent
                profileMembers={NewProfileMembers}
                header1={"1500 New Matches"}
                header2={"Members who have joined in the last 10 Days"}
                link={"/matches/newMatches"}
                activity="true"
                // screenWidth= {screenWidth}
              />
            </div>

            {/* Total matches */}
            <div className="totalMatchesContainer homeMatchesContainerBox">
              <HomeCardComponent
                profileMembers={NewProfileMembers}
                header1={"2000 Total Matches"}
                header2={"Members who match your Partner preferences"}
                link={"/matches/totalMatches"}
                activity="true"
                // array data must be comming from the backend directly to
                // the matches component using useeffect, but for testing purpose
              />
            </div>

            {/*   */}
            <HomeAdvBox homeCouple={homeCouple} />

            {/* discover matches */}
            <DiscoverMatchesCard />

            {/* members viewed */}
            <div className="totalMatchesContainer homeMatchesContainerBox">
              <HomeCardComponent
                profileMembers={NewProfileMembers}
                header1={"25 Members viewed you"}
                header2={"Members who have viewed your profile"}
                link={"/matches/membersViewedYou"}
                activity="true"
              />
            </div>

            {/*  */}
            <HomeAdvBox />

            {logoutPopup && (
              <LogoutPopup
                logoutPopup={logoutPopup}
                setLogoutPopup={setLogoutPopup}
              />
            )}

            {/* mutual Matches */}
            <div className="totalMatchesContainer homeMatchesContainerBox">
              <HomeCardComponent
                profileMembers={NewProfileMembers}
                header1={"250 Mutual Matches"}
                header2={
                  "Members whose preferences match your profile and vice-versa"
                }
                link={"/matches/mutualMatches"}
                activity="true"
              />
            </div>

            {/* members looking for you*/}
            <div className="totalMatchesContainer homeMatchesContainerBox">
              <HomeCardComponent
                profileMembers={NewProfileMembers}
                header1={"5000 Members looking for you"}
                header2={"Members whose preferences matches your profile"}
                link={"/matches/membersLookingForYou"}
                activity="true"
              />
            </div>
          </div>
        </div>

        <div className="row homeFooter">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;

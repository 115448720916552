import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withVerificationBackground from "../withVerificationBackground";
import MobileVerification from "../../../components/Auth/Mobile verification/MobileVerification";
import "./MobileVerify.css";

const MobileAuth = () => {
  const [mobileVerified, setMobileVerified] = useState(false);

  const {
    state: { UserID, mobile },
  } = useLocation();
  // const forgotPassword = state?.forgotPassword || false;

  const navigate = useNavigate();

  return (
    <>
      <div className="mobileContainer">
        {mobileVerified ? (
          <>
            <div
              className="mobileVerifiedIcon"
              // onClick={() => {
              //   forgotPassword
              //     ? navigate("/login")
              //     : navigate("/emailVerification");
              // }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
            <div className="mobileVerifiedTHeader">Mobile Number Verified!</div>
            <div className="mobileVerifiedText">
              You have successfully verified your mobile number.
            </div>
          </>
        ) : (
          <>
            <div className="registeredheader">
              <p>You have successfully registered with Joy Matrimony !</p>
              <p>
                Your Matrimony ID is{" "}
                <span className="matrimonyID">{UserID}</span>
              </p>
              <hr className="MobileverifyHrTag" />
            </div>
            <div className="mobileHeader">Verify your Mobile Number</div>
            <MobileVerification
            modile={mobile}
              edit={true}
              nextPage={"emailVerification"}
              setMobileVerified={setMobileVerified}
            />
          </>
        )}
      </div>
    </>
  );
};
export default withVerificationBackground(MobileAuth);

import React from "react";

import "./AadharCard.css";

const AadharCard = ({ aadharContent }) => {
  return (
    <div className={`aadharVerificationBox ${aadharContent.classname}`}>
      {aadharContent.aadharImage && <div className="aadharStatusImage">
        <img src={aadharContent.aadharImage} />
      </div> }
      <div className="aadharVerificationBoxTitle">ID Verification</div>
      <div className="aadharVerificationBoxContent">
        {aadharContent.content}
      </div>
      <button>Aadhar</button>
    </div>
  );
};

export default AadharCard;

import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PartnerPreferenceBasic.css";

const PartnerPreferenceBasic = ({
  partnerPreferenceField,
  handleEdit,
  handleSave,
  partnerEditProcess,
  setPartnerEditProcess,
  handleChange,
}) => {
  // basic inputs
  const PartnerPreferenceBasicInputs = [
    {
      className: "partnerPreferenceSmallOption",
      inputType: "range",
      name: "age",
      field: [
        {
          name: "ageFrom",
          value: partnerPreferenceField.ageFrom,
          options: [
            { value: "0", disabled: true, selected: true },
            { value: "20", disabled: false },
            { value: "30", disabled: false },
            { value: "35", disabled: false },
          ],
        },
        {
          name: "ageTo",
          value: partnerPreferenceField.ageTo,
          options: [
            { value: "0", disabled: true, selected: true },
            { value: "20", disabled: false },
            { value: "30", disabled: false },
            { value: "35", disabled: false },
          ],
        },
      ],
    },
    {
      className: "partnerPreferenceMediumOption",
      inputType: "range",
      name: "height",
      field: [
        {
          value: partnerPreferenceField.heightFrom,
          name: "heightFrom",
          options: [
            { value: `130`, disabled: true, selected: true },
            { value: `150`, disabled: false },
            { value: `170`, disabled: false },
            { value: `190"`, disabled: false },
          ],
        },
        {
          value: partnerPreferenceField.heightTo,
          name: "heightTo",
          options: [
            { value: `130`, disabled: true, selected: true },
            { value: `150`, disabled: false },
            { value: `170`, disabled: false },
            { value: `190"`, disabled: false },
          ],
        },
      ],
    },
    {
      inputType: "radio",
      value: partnerPreferenceField.bodyType,
      field: [
        { name: "bodyType", label: "Slim", value: "Slim", idAndFor: "slim" },
        {
          name: "bodyType",
          label: "Athletic",
          value: "Athletic",
          idAndFor: "athletic",
        },
        {
          name: "bodyType",
          label: "Average",
          value: "Average",
          idAndFor: "Average",
        },
        { name: "bodyType", label: "Heavy", value: "Heavy", idAndFor: "Heavy" },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "maritalStatus",
          label: "Unmaried",
          value: "Unmaried",
          idAndFor: "slim",
        },
        {
          name: "maritalStatus",
          label: "widow/widower",
          value: "widow/widower",
          idAndFor: "athletic",
        },
        {
          name: "maritalStatus",
          label: "Divorced",
          value: "Divorced",
          idAndFor: "divorced",
        },
        {
          name: "maritalStatus",
          label: "Seperated",
          value: "Seperated",
          idAndFor: "seperated",
        },
        {
          name: "maritalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "doesn'tmatter",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "physicalStatus",
          label: "Normal",
          value: "Normal",
          idAndFor: "normal",
        },
        {
          name: "physicalStatus",
          label: "Physically challenged",
          value: "Physically challenged",
          idAndFor: "athletic",
        },
        {
          name: "physicalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "doesn'tmatter",
        },
      ],
    },
    {
      inputType: "select",
      name: "motherTounge",
      value: partnerPreferenceField.motherTounge,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "foodHabits",
      value: partnerPreferenceField.foodHabits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "smokingHabits",
      value: partnerPreferenceField.smokingHabits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "drinkingHabits",
      value: partnerPreferenceField.drinkingHabits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  // converting height in cms to feet and inches - From
  let heightInFeetFrom = "";
  let heightInInchesFrom = "";
  heightInFeetFrom = heightInInchesFrom =
    partnerPreferenceField.heightFrom * 0.0328;

  // converting height in cms to feet and inches - From
  let heightInFeetTo = "";
  let heightInInchesTo = "";
  heightInFeetTo = heightInInchesTo = partnerPreferenceField.heightTo * 0.0328;

  return (
    <div className="basicPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle">Basic Preferences</div>

        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("BasicDetails");
          }}
        >
          {partnerEditProcess === "BasicDetails" ? (
            <span
              className="PRcloseButton"
              onClick={() => {
                handleSave("cancel");
              }}
            >
              <i class="fa-solid fa-pen"></i> Close
            </span>
          ) : (
            <>
              <i class="fa-solid fa-pen partnerPreferenceMobileEdit"></i>
            </>
          )}
        </button>

        <button
          className="PartnerEditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("BasicDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {partnerEditProcess === "BasicDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>
      </div>

      <div
        className={`PartnerPreferenceDetailsSectionRow ${
          partnerEditProcess === "BasicDetails"
            ? "PartnerDetailsSectionRow_EditTime"
            : "DetailsSectionRow PartnerPreferenceDetailsSectionRow"
        }`}
      >
        <div className="partnerInputs">
          {/* basic details labels */}
          <ul
            className={` ${
              partnerEditProcess === "BasicDetails"
                ? "PartnerDetails_section_label PartnerDetails_section_label_EditTime"
                : "PartnerDetails_section_label"
            }`}
          >
            <li>Age</li>
            <li>Height</li>
            <li className="BodyTypeEditTime">Body Type</li>
            <li className="MaritalStatusEditTime">Marital Status</li>
            <li className="PhysicalStatusEditTime">Physical Status</li>
            <li className="MotherTongueEditTime">Mother Tongue</li>
            <li className="EatingEditTime">Eating Habits</li>
            <li className="SmokingEditTime">Smoking Habits</li>
            <li className="DrinkingEditTime">Drinking Habits</li>
          </ul>

          <ul
            className={` ${
              partnerEditProcess === "BasicDetails"
                ? "PartnerDetails_section_colon_EditTime"
                : "PartnerDetails_section_colon"
            }`}
          >
            <li>:</li>
            <li>:</li>
            <li className="BodyTypeEditTime">:</li>
            <li className="MaritalStatusEditTime">:</li>
            <li className="PhysicalStatusEditTime">:</li>
            <li className="MotherTongueEditTime">:</li>
            <li className="EatingEditTime">:</li>
            <li className="SmokingEditTime">:</li>
            <li className="DrinkingEditTime">:</li>
          </ul>

          {partnerEditProcess === "BasicDetails" ? (
            // basic details inputs
            <>
              <ul className="Details_section_values partnerDetails_section_values">
                {PartnerPreferenceBasicInputs.map((input) =>
                  input.inputType === "radio" ? (
                    <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                      <RadioInputField
                        input={input}
                        handleChange={handleChange}
                        inputFields={partnerPreferenceField}
                        className={`EditTimeRadioflexWrap`}
                      />
                    </div>
                  ) : input.inputType === "select" ? (
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : input.inputType === "range" ? (
                    <div className="partnerFieldRange partnerFieldAns">
                      <SelectInputField
                        input={input.field[0]}
                        className={input.className}
                        handleChange={handleChange}
                      />
                      <div> to </div>
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input.field[1]}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                      <span className="Details_section_values_rightLabel">
                        {input.name === "age" && <div>Years</div>}
                      </span>
                    </div>
                  ) : (
                    <div className="partnerFieldAns">
                      <InputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                      <span className="Details_section_values_rightLabel">
                        {input.FieldLabel}
                      </span>
                    </div>
                  )
                )}
              </ul>
            </>
          ) : (
            <>
              <ul className="partnerDetails_section_values">
                <li>
                  {(partnerPreferenceField.ageFrom === "" &&
                    partnerPreferenceField.ageTo === "") ||
                  (partnerPreferenceField.ageFrom === undefined &&
                    partnerPreferenceField.ageTo === undefined) ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    <>
                      <span className="from">
                        {partnerPreferenceField.ageFrom} yrs
                      </span>
                      <span className="PartnerPreferencetolabel">to</span>
                      <span className="to">
                        {partnerPreferenceField.ageTo} yrs
                      </span>
                    </>
                  )}
                </li>

                <li>
                  {(partnerPreferenceField.heightFrom === "" &&
                    partnerPreferenceField.heightTo === "") ||
                  (partnerPreferenceField.heightFrom === undefined &&
                    partnerPreferenceField.heightTo === undefined) ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    <>
                      <span className="fromHeight">
                        {`
                         
                          ${partnerPreferenceField.heightFrom} Cms
                        `}
                      </span>
                      <span className="PartnerPreferencetolabel">to</span>
                      <span className="fromHeight">
                        {`
                       
                          ${partnerPreferenceField.heightTo} Cms
                        `}
                      </span>
                    </>
                  )}
                </li>

                <li>
                  {partnerPreferenceField.bodyType === "" ||
                  partnerPreferenceField.bodyType === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.bodyType
                  )}
                </li>

                <li>
                  {partnerPreferenceField.maritalStatus === "" ||
                  partnerPreferenceField.maritalStatus === "Select" ||
                  partnerPreferenceField.maritalStatus === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.maritalStatus
                  )}
                </li>

                <li>
                  {partnerPreferenceField.physicalStatus === "" ||
                  partnerPreferenceField.physicalStatus === "Select" ||
                  partnerPreferenceField.physicalStatus === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.physicalStatus
                  )}
                </li>

                <li>
                  {partnerPreferenceField.motherTounge === "" ||
                  partnerPreferenceField.motherTounge === "Select" ||
                  partnerPreferenceField.motherTounge === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.motherTounge
                  )}
                </li>

                <li>
                  {partnerPreferenceField.foodHabits === "" ||
                  partnerPreferenceField.foodHabits === "Select" ||
                  partnerPreferenceField.foodHabits === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.foodHabits
                  )}
                </li>
                <li>
                  {partnerPreferenceField.smokingHabits === "" ||
                  partnerPreferenceField.smokingHabits === "Select" ||
                  partnerPreferenceField.smokingHabits === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.smokingHabits
                  )}
                </li>
                <li>
                  {partnerPreferenceField.drinkingHabits === "" ||
                  partnerPreferenceField.drinkingHabits === "Select" ||
                  partnerPreferenceField.drinkingHabits === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("BasicDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.drinkingHabits
                  )}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerPreferenceBasic;

// <div className="partnerInputlabel">
//         <div className="partnerFieldQue">Age</div>
//         <div className="partnerFieldQue">Height</div>
//         <div className="partnerFieldQue">Body Type</div>
//         <div className="partnerFieldQue">Marital Status</div>
//         <div className="partnerFieldQue">Physical Status</div>
//         <div className="partnerFieldQue">Mother Toungue</div>
//         <div className="partnerFieldQue">Food Habits</div>
//         <div className="partnerFieldQue">Drinking Habits</div>
//         <div className="partnerFieldQue">Smoking Habits</div>
//       </div>
//       <div className="partnerInputcolon">
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//         <div className="partnerFieldQue">:</div>
//       </div>
//       <div className="partnerInputFields">
//         {basicInputs.map((input) =>
//           input.inputType === "range" ? (
//             <div className="partnerFieldRange">
//               <div className="partnerFieldAns">
//                 <SelectInputField
//                   input={input.from}
//                   className={input.className}
//                 />
//               </div>
//               <div>to</div>
//               <div className="partnerFieldAns">
//                 <SelectInputField
//                   input={input.to}
//                   className={input.className}
//                 />
//               </div>
//               {input.from.name === "age" && <div>Years</div>}
//             </div>
//           ) : input.inputType === "radio" ? (
//             <div className="partnerFieldAns partnerPreferenceRadioFields">
//               <RadioInputField input={input} inputFields={basicInputs} />
//             </div>
//           ) : (
//             <div className="partnerFieldAns">
//               <SelectInputField input={input} className={input.className} />
//             </div>
//           )
//         )}
//       </div>

import React from "react";
import jmTitle from "../../../assets/images/Landing/JMLogo.png";
import "./landingheader.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Login from "../../../container/Auth/Login/Login";

function LandingHeader({ isLogin }) {
  const navigate = useNavigate();

  return (
    <div className="landingHeader">
      {/* landing image title */}
      <Link to={`/`}>
        <div className="landingTitle">
          <img src={jmTitle} />
        </div>
      </Link>
      {!isLogin && (
        <>
          {/* login button */}
          <div className="landingLogin">
            <span>Already a member?</span>
            <button
              onClick={() => {
                navigate("/login");
              }}
            >
              LOGIN
            </button>
            {/* Already a member? <Link to="/login">Login</Link> */}
          </div>
        </>
      )}
    </div>
  );
}

export default LandingHeader;

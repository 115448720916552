import React, { useEffect, useState } from "react";

import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import PartnerPreferenceReligious from "../../components/PartnerPreference/PartnerPreferenceReligious/PartnerPreferenceReligious";
import PartnerPreferenceLocation from "../../components/PartnerPreference/PartnerPreferenceLocation/PartnerPreferenceLocation.js";
import PartnerPreferenceProfessional from "../../components/PartnerPreference/PartnerPreferenceProfessional/PartnerPreferenceProfessional";
import PartnerPreferenceAbout from "../../components/PartnerPreference/PartnerPreferenceAbout/PartnerPreferenceAbout";
import Footer from "../../components/Footer/Footer";
import "./PartnerPreference.css";
import { useNavigate } from "react-router-dom";
import PartnerPreferenceBasic from "./PartnerPreferenceBasic/PartnerPreferenceBasic";
import { BACKEND_PORT } from "../../App";
import axios from "axios";

const PartnerPreference = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");
  const User_ID = window.sessionStorage.getItem("User_ID");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      let {
        data: {
          message: { partner_preferences },
        },
      } = await axios.get(`${BACKEND_PORT}/partnerPreference/${User_ID}`);

      setPartnerPreferenceField({
        ...partnerPreferenceField,
        ageFrom: partner_preferences?.age_from,
        ageTo: partner_preferences?.age_to,
        heightFrom: partner_preferences?.height_from,
        heightTo: partner_preferences?.height_to,
        bodyType: partner_preferences?.body_type,
        maritalStatus: partner_preferences?.marital_status,
        physicalStatus: partner_preferences?.physical_status,
        motherTounge: partner_preferences?.mother_tongue,
        foodHabits: partner_preferences?.food_habits,
        drinkingHabits: partner_preferences?.drinking_habits,
        smokingHabits: partner_preferences?.smoking_habits,
        denomination: partner_preferences?.denomination,
        division: partner_preferences?.division,
        subCaste: partner_preferences?.sub_caste,
        education: partner_preferences?.education,
        employed: partner_preferences?.employeed_in,
        occupation: partner_preferences?.occupation,
        annualIncomeFrom: partner_preferences?.annual_income_from,
        annualIncometo: partner_preferences?.annual_income_to,
        country: partner_preferences?.country,
        state: partner_preferences?.state,
        city: partner_preferences?.city,
        about: partner_preferences?.about_your_partner,
      });
    };

    getData();
  }, []);

  const [partnerPreferenceField, setPartnerPreferenceField] = useState({
    ageFrom: "",
    ageTo: "",
    heightFrom: "",
    heightTo: "",
    bodyType: "",
    maritalStatus: "",
    physicalStatus: "",
    motherTounge: "",
    foodHabits: "",
    drinkingHabits: "",
    smokingHabits: "",
    denomination: "",
    division: "",
    subCaste: "",
    education: "",
    employed: "",
    occupation: "",
    annualIncomeFrom: "",
    annualIncometo: "",
    country: "",
    state: "",
    city: "",
    about: "",
  });

  const [partnerEditProcess, setPartnerEditProcess] = useState("");

  // Cancel Button Functionalities
  const [PartnerIndDetails, setPartnerIndDetails] = useState([]);

  // popup on clicking the skip button
  const [partnerPreferencePopup, setPartnerPreferencePopup] = useState(false);

  // skip count
  const [skipCount, setSkipCount] = useState(0);

  // handle skip
  const handleSkip = () => {
    document.getElementById("pr");
    setPartnerPreferencePopup(true);
    setSkipCount((prevSkip) => prevSkip + 1);
  };

  const handleEdit = (id) => {
    setPartnerEditProcess(id === partnerEditProcess ? "" : id);
    setPartnerIndDetails({ ...partnerPreferenceField });
  };

  const handleSave = async (type) => {
    if (type === "save") {
      setPartnerEditProcess("");

      let postData = await axios.post(
        `${BACKEND_PORT}/partnerPreference/${User_ID}`,
        {
          age_from: partnerPreferenceField.ageFrom,
          age_to: partnerPreferenceField.ageTo,
          height_from: partnerPreferenceField.heightFrom,
          height_to: partnerPreferenceField.heightTo,
          body_type: partnerPreferenceField.bodyType,
          marital_status: partnerPreferenceField.maritalStatus,
          physical_status: partnerPreferenceField.physicalStatus,
          mother_tongue: partnerPreferenceField.motherTounge,
          food_habits: partnerPreferenceField.foodHabits,
          smoking_habits: partnerPreferenceField.smokingHabits,
          drinking_habits: partnerPreferenceField.drinkingHabits,
          about_your_partner: partnerPreferenceField.about,
          denomination: partnerPreferenceField.denomination,
          division: partnerPreferenceField.division,
          sub_caste: partnerPreferenceField.subCaste,
          education: partnerPreferenceField.education,
          employeed_in: partnerPreferenceField.employed,
          occupation: partnerPreferenceField.occupation,
          annual_income_from: partnerPreferenceField.annualIncomeFrom,
          annual_income_to: partnerPreferenceField.annualIncometo,
          country: partnerPreferenceField.country,
          state: partnerPreferenceField.state,
          city: partnerPreferenceField.city,
        }
      );

      console.log(postData);
    } else if (type === "cancel") {
      setPartnerPreferenceField({ ...PartnerIndDetails });
      setPartnerEditProcess("");
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setPartnerPreferenceField({ ...partnerPreferenceField, [name]: value });
  };

  return (
    <>
      {/* popup for skip button */}
      {partnerPreferencePopup && (
        <div className={` partnerPreferencePopupContainer`}>
          <div className="partnerPreferencePopupTitle">Are You Sure?</div>
          <div className="partnerPreferencePopupContents">
            <div className="partnerPreferencePopupContent1">
              If you skip this step now, you will only be able to see the
              matches based on the general preferences that we have set
            </div>
            <div className="partnerPreferencePopupContent">
              To find suitable matches, please set your own partner preference I
              will do this later I will set my partner preferences
            </div>
          </div>
          <div className="partnerPreferencePopupButons">
            <button onClick={()=> {navigate("/home")}}>Yes later</button>
            <button
              onClick={() => {
                setPartnerPreferencePopup(false);
              }}
            >
              Set now
            </button>
          </div>
        </div>
      )}

      <div
        className="container-fluid backGroundAntique partnerPreferencePage "
        id="skipId"
      >
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="searchBoxTapBar">
              <span className="PageHeading">Partner preferences</span>
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </span>
        </div>

        <div className="col-lg-12">
          {partnerPreferencePopup && (
            // if popup, then the background show be black
            <div className="partnerBackGroundBlack"></div>
          )}

          <div className="row">
            <div className="partnerPreferenceTitleContainer">
              <div className="partnerPreferenceTitle">Partner preferences</div>
            </div>
          </div>

          <div className="row partnerPreference">
            <div className="partnerPreferenceContainer">
              {/* components of partner preference field */}

              {/* basic preferences */}
              <PartnerPreferenceBasic
                partnerPreferenceField={partnerPreferenceField}
                partnerEditProcess={partnerEditProcess}
                handleChange={handleChange}
                handleEdit={handleEdit}
                setPartnerEditProcess={setPartnerEditProcess}
                handleSave={handleSave}
              />

              {/*religious preference */}
              <PartnerPreferenceReligious
                partnerPreferenceField={partnerPreferenceField}
                partnerEditProcess={partnerEditProcess}
                handleChange={handleChange}
                handleEdit={handleEdit}
                setPartnerEditProcess={setPartnerEditProcess}
                handleSave={handleSave}
              />

              {/* professional preference */}
              <PartnerPreferenceProfessional
                partnerPreferenceField={partnerPreferenceField}
                partnerEditProcess={partnerEditProcess}
                handleChange={handleChange}
                handleEdit={handleEdit}
                setPartnerEditProcess={setPartnerEditProcess}
                handleSave={handleSave}
              />

              {/* location preference */}
              <PartnerPreferenceLocation
                partnerPreferenceField={partnerPreferenceField}
                partnerEditProcess={partnerEditProcess}
                handleChange={handleChange}
                handleEdit={handleEdit}
                setPartnerEditProcess={setPartnerEditProcess}
                handleSave={handleSave}
              />

              {/* about preference */}
              <PartnerPreferenceAbout
                partnerPreferenceField={partnerPreferenceField}
                partnerEditProcess={partnerEditProcess}
                handleChange={handleChange}
                handleEdit={handleEdit}
                setPartnerEditProcess={setPartnerEditProcess}
                handleSave={handleSave}
              />

              <div className="partnerButton">
                <button
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>

                <a
                  href="#skipId"
                  onClick={handleSkip}
                  className={skipCount > 0 && "skipInvisible"}
                >
                  Skip
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="row homeFooter">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PartnerPreference;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../NotificationsPage/Notifications.css";
import IMG from "../../assets/images/Home/homeProfilePic2.png";

const Notifications = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const [showAll, setShowAll] = useState(false);

  const [Notifications, setNotifications] = useState([
    {
      Image: IMG,
      NotificationTag: "Thomas Vijayaraj has viewed your profile",
      ViewProfile: "View Profile",
      Request: false,
    },
    {
      Image: IMG,
      NotificationTag: "Thomas Vijayaraj has shown interest in your profile",
      ViewProfile: "View Profile",
      Request: false,
    },
    {
      Image: IMG,
      NotificationTag:
        "Thomas Vijayaraj has requested you to add photo. Would you like to add it now?",
      ViewProfile: "View Profile",
      Request: true,
      button: "Add photo",
      link: "/addprofilephotos",
    },
    {
      Image: IMG,
      NotificationTag:
        "Thomas Vijayaraj has requested you to add your family details. Would you like to add it now?",
      ViewProfile: "View Profile",
      // UploadPhoto:"Add Photo",
      button: "Add detail",
      Request: true,
      link: "/editProfilePage",
    },
    {
      Image: IMG,
      NotificationTag: "Thomas Vijayaraj has shown interest in your profile",
      ViewProfile: "View Profile",
      Request: false,
    },
    {
      Image: IMG,
      NotificationTag: "Thomas Vijayaraj has viewed your profile",
      ViewProfile: "View Profile",
      Request: false,
    },
  ]);

  const handleMarkAllRead = () => {
    setNotifications([]);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileHomeHeader">
              <div className="searchBoxTapBar">
                Notifications
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="Notification">
              <div
                className="mobile_MarkAllReadLink"
                onClick={handleMarkAllRead}
              >
                Mark all as Read
              </div>
              {!showAll
                ? Notifications.slice(0, 5).map((Input) => (
                    <>
                      <div className="Notification-Card">
                        <div>
                          <img src={IMG} />{" "}
                        </div>
                        <div className="Notification-context">
                          <div>
                            <span className="NTProfileName">
                              Thomas Vijayaraj
                            </span>{" "}
                            has viewed your profile
                          </div>
                          {Input.Request ? (
                            <div className="Notification-Buttons">
                              <button
                                onClick={() => {
                                  navigate(Input.link);
                                }}
                              >
                                {Input.button}
                              </button>
                              <button> No </button>
                            </div>
                          ) : (
                            <div
                              className="Notification-View"
                              onClick={() => {
                                navigate("/individual", {
                                  state: {
                                    data: { profilepic: IMG },
                                    profilepic: "",
                                  },
                                });
                              }}
                            >
                              View profile
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="Notification-Time"> 6 hours ago </div>
                    </>
                  ))
                : Notifications.map((Input) => (
                    <>
                      <div className="Notification-Card">
                        <div>
                          <img src={IMG} />{" "}
                        </div>
                        <div className="Notification-context">
                          <div>
                            <span className="NTProfileName">
                              Thomas Vijayaraj
                            </span>{" "}
                            has viewed your profile
                          </div>
                          {Input.Request ? (
                            <div className="Notification-Buttons">
                              <button
                                onClick={() => {
                                  navigate(Input.link);
                                }}
                              >
                                {Input.button}
                              </button>
                              <button> No </button>
                            </div>
                          ) : (
                            <div
                              className="Notification-View"
                              onClick={() => {
                                navigate("/individual", {
                                  state: {
                                    data: { profilepic: IMG },
                                    profilepic: "",
                                  },
                                });
                              }}
                            >
                              View profile
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="Notification-Time"> 6 hours ago </div>
                    </>
                  ))}
            </div>
            <div
              className={`${
                !showAll ? "NTBoxViewAllLink" : "hideNTBoxViewAllLink"
              }`}
            >
              <div
                onClick={() => {
                  setShowAll(true);
                }}
              >
                View all Notifications
              </div>
            </div>

            <div className="row notificationBoxFooterRow">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;

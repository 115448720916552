import React from "react";
import { useNavigate } from "react-router-dom";
import nayan from "../../assets/images/Home/nayan.jpg";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import ProfileSettingsTab from "../../components/ProfileSettingsTab/ProfileSettingsTab";
import "./EmailChanged.css";
import BG from "../../assets/images/SettingImages/ChangeEmailBG.png";

function EmailChanged() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="col-lg-12">
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="searchBoxTapBar">
              <span className="PageHeading">Email</span>
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/settingsBox");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </span>

          <div className="profileDetails_pic_ID">
            <div className="profileImg">
              <img src={nayan} />
            </div>
            <div className="profileName">{`Cathy`}</div>
            <span className="profileID">{`JM123456`}</span>
          </div>

          <div className="MobileChangeEmailContents">
            <label className="SettingsBoxLabel">Profile Settings</label>
            <h6>Change your Email ID</h6>
            <p>Your Email ID has been changed successfully.</p>
            <input type={"submit"} value={"Back"} className="BackButton" onClick={() => {navigate("/settingsBox");}} />
            <img
              src={BG}
              alt="ChangeEmailBGImage"
              className="ChangeEmailBGImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailChanged;

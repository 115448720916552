import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PersonalDetails_BasicDetails.css";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";

const PersonalDetails_BasicDetails = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
}) => {
  // basic details inputs
  const PersonalDetails_basicInputs = [
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "ProfileCreatedFor",
      value: ProfileDetails.ProfileCreatedFor,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Myself", disabled: false },
        { value: "Son", disabled: false },
        { value: "Daughter", disabled: false },
        { value: "Friend", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "text",
      name: "Name",
      value: ProfileDetails.Name,
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "text",
      name: "Age",
      FieldLabel: "Yrs",
      value: ProfileDetails.Age,
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "text",
      name: "Height",
      FieldLabel: "Cms",
      value: ProfileDetails.Height,
    },
    {
      inputType: "radio",
      name: "BodyType",
      value: ProfileDetails.field,
      field: [
        {
          name: "BodyType",
          label: "Slim",
          value: "Slim",
          id: "slim",
          for: "slim",
        },
        {
          name: "BodyType",
          label: "Athletic",
          value: "Athletic",
          id: "athletic",
          for: "athletic",
        },
        {
          name: "BodyType",
          label: "Average",
          value: "Average",
          id: "Average",
          for: "Average",
        },
        {
          name: "BodyType",
          label: "Heavy",
          value: "Heavy",
          id: "Heavy",
          for: "Heavy",
        },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "MotherTongue",
      value: ProfileDetails.MotherTongue,
      options: [
        { value: "Select", disabled: false },
        { value: "Tamil", disabled: false },
        { value: "Hindi", disabled: false },
        { value: "Telugu", disabled: false },
        { value: "Malayalam", disabled: false },
        { value: "English", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "MaritalStatus",
      value: ProfileDetails.MaritalStatus,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Single", disabled: false },
        { value: "Married", disabled: false },
        { value: "Divorcee", disabled: false },
        { value: "Widowed", disabled: false },
        { value: "Separated", disabled: false },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "EatingHabits",
          label: "Non-Vegeterian",
          value: "Non-Vegeterian",
          id: "NonVegeterian",
          for: "NonVegeterian",
        },
        {
          name: "EatingHabits",
          label: "Vegeterian",
          value: "Vegeterian",
          id: "Vegeterian",
          for: "Vegeterian",
        },
        {
          name: "EatingHabits",
          label: "Eggetarian",
          value: "Eggetarian",
          id: "Eggetarian",
          for: "Eggetarian",
        },
        {
          name: "EatingHabits",
          label: "Not Specified",
          value: "Not Specified",
          id: "Not Specified",
          for: "Not Specified",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "SmokingHabits",
          label: "Never",
          value: "Never",
          idAndFor: "Never",
        },
        {
          name: "SmokingHabits",
          label: "Smokes Occasionally",
          value: "Smokes Occasionally",
          idAndFor: "Smokes Occasionally",
        },
        {
          name: "SmokingHabits",
          label: "Smokes Regularly",
          value: "Smokes Regularly",
          idAndFor: "Smokes Regularly",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "DrinkingHabits",
          label: "Never",
          value: "Never",
          idAndFor: "Never",
        },
        {
          name: "DrinkingHabits",
          label: "Drinks Occasionally",
          value: "Drinks Occasionally",
          idAndFor: "Drinks Occasionally",
        },
        {
          name: "DrinkingHabits",
          label: "Drinks Regularly",
          value: "Drinks Regularly",
          idAndFor: "Drinks Regularly",
        },
      ],
    },
  ];

  // converting height in cms to feet and inches
  let heightInFeet = "";
  let heightInInches = "";
  heightInFeet = heightInInches = ProfileDetails.Height * 0.0328;

  return (
    <div
      className={` ${
        editProcess === "BasicDetails"
          ? "EditTimePersonalDetails_card"
          : "PersonalDetails_BasicDetails"
      }`}
    >
      <div className="fewWordBoxHeader">
        <h6>Basic Details</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("BasicDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {editProcess === "BasicDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>
      </div>

      <div className={`card PersonalDetails_card`}>
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("BasicDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {editProcess === "BasicDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>

        <div
          className={`${
            editProcess === "BasicDetails"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          <div className="Details_section">
            {/* basic details labels */}
            <ul
              className={`Details_section_label ${
                editProcess === "BasicDetails"
                  ? "Details_section_label_EditTime"
                  : ""
              }`}
            >
              <li>Profile created for</li>
              <li>Name</li>
              <li>Age</li>
              <li>Height</li>
              <li className="BodyTypeEditTime">Body type</li>
              <li>Mother Tongue</li>
              <li>Marital Status</li>
              <li className="EatingEditTime">Eating Habits</li>
              <li className="SmokingEditTime">Smoking Habits</li>
              <li className="DrinkingEditTime">Drinking Habits</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "BasicDetails"
                  ? "Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li className="BodyTypeEditTime">:</li>
              <li>:</li>
              <li>:</li>
              <li className="EatingEditTime">:</li>
              <li className="SmokingEditTime">:</li>
              <li className="DrinkingEditTime">:</li>
            </ul>
            {editProcess === "BasicDetails" ? (
              // basic details inputs
              <>
                <ul className="Details_section_values">
                  {PersonalDetails_basicInputs.map((input) =>
                    input.inputType === "radio" ? (
                      <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                        <RadioInputField
                          input={input}
                          handleChange={handleChange}
                          inputFields={ProfileDetails}
                          className={`EditTimeRadioflexWrap`}
                        />
                      </div>
                    ) : input.inputType === "select" ? (
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    )
                  )}
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails?.ProfileCreatedFor === "" ||
                    ProfileDetails?.ProfileCreatedFor === "Select" ||
                    ProfileDetails?.ProfileCreatedFor === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Creating profile for`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails?.ProfileCreatedFor
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Name === "" ||
                    ProfileDetails.Name === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Name`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Name
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Age === "" ||
                    ProfileDetails.Age === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Age`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Age
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Height === "" ||
                    ProfileDetails.Height === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Height`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      <>
                        {`
                          ${heightInFeet.toString().split(".")[0]} Ft 
                          ${Math.ceil(heightInInches)} In / 
                          ${ProfileDetails.Height} Cms
                        `}
                      </>
                    )}
                  </li>
                  <li>
                    {ProfileDetails.BodyType === "" ||
                    ProfileDetails.BodyType === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Body type`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.BodyType
                    )}
                  </li>
                  <li>
                    {ProfileDetails.MotherTongue === "" ||
                    ProfileDetails.MotherTongue === "Select" ||
                    ProfileDetails.MotherTongue === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Mother Tongue`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.MotherTongue
                    )}
                  </li>
                  <li>
                    {ProfileDetails.MaritalStatus === "" ||
                    ProfileDetails.MaritalStatus === "Select" ||
                    ProfileDetails.MaritalStatus === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Marital Status`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.MaritalStatus
                    )}
                  </li>
                  <li>
                    {ProfileDetails.EatingHabits === "" ||
                    ProfileDetails.EatingHabits === "Select" ||
                    ProfileDetails.EatingHabits === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Eating Habits`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.EatingHabits
                    )}
                  </li>
                  <li>
                    {ProfileDetails.SmokingHabits === "" ||
                    ProfileDetails.SmokingHabits === "Select" ||
                    ProfileDetails.SmokingHabits === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Smoking Habits`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.SmokingHabits
                    )}
                  </li>
                  <li>
                    {ProfileDetails.DrinkingHabits === "" ||
                    ProfileDetails.DrinkingHabits === "Select" ||
                    ProfileDetails.DrinkingHabits === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BasicDetails");
                        }}
                      >
                        {`Add Drinking Habits`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.DrinkingHabits
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "BasicDetails" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_BasicDetails;

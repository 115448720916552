import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutPopup = ({ logoutPopup, setLogoutPopup }) => {
  const navigate = useNavigate();

  // Logout Popup content
  //   const [logoutPopup, setLogoutPopup] = useState(false);

  useEffect(() => {
    logoutPopup
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [logoutPopup]);

  return (
    <div className={`${logoutPopup === true ? "HomeLogoutPopupOn" : ""}`}>
      <>
        <div className="logoutPopupContainer">
          <p className="LogoutHeader">Log out of Joy Matrimony?</p>
          <p className="LogoutSubHeader">
            You can always log back in at any time.
          </p>
          <div className="LogoutPopupButtons">
            <button
              className="cancelButton"
              onClick={() => {
                setLogoutPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="logoutButton"
              onClick={() => {
                navigate("/");
                window.sessionStorage.removeItem("token");
                window.sessionStorage.removeItem("User_ID");
              }}
            >
              Log out
            </button>
          </div>
        </div>
      </>

      {/* {!logoutPopup && <div className=''></div>} */}
    </div>
  );
};

export default LogoutPopup;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SearchesHeader.css";

const SearchesHeader = () => {
  const [searchHeader, setSearchheader] = useState("");

  const searchHeaderArray = [
    { header: "Regular Search", link: "/regularSearch" },
    { header: "Keyword Search", link: "/keywordsearch" },
    { header: "By Matrimonial ID", link: "/matrimonyidsearch" },
  ];
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchheader(pathname);
  }, [pathname]);

  //   console.log(pathname.slice(1));
  return (
    <>
      <div className="SearchesHeaderContainer">
        {searchHeaderArray.map(({ header, link }) => (
          <div
            className={`searchesHeader ${
              searchHeader === link ? "searchesHeaderPink" : ""
            }`}
            key={header}
            id={header}
            onClick={() => {
              navigate(link);
            }}
          >
            {header}
          </div>
        ))}
      </div>
      <div className="SearchesHeaderContainerLine"></div>
    </>
  );
};

export default SearchesHeader;

import React, { useState } from "react";
import nayan from "../../../../../assets/images/Home/nayan.jpg";
import ProfilePicPlaceholder from "../../../../../assets/images/Home/HomeProfilePlaceHolder.png";
import MobileNotificationIcon from "../../../../../assets/images/Sidebar/NotificationBell Icon.png";
import GreenNotificationPointIcon from "../../../../../assets/images/Sidebar/GreenNotificationPointIcon.png";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../../../../Headers/Home header/HomeHeader";
import "./MobileFilterBox.css";
import Footer from "../../../../Footer/Footer";
import Filter from "../../../Filter/Filter";

function MobileFilterBox() {
  const [profilePicture, setProfilePicture] = useState(nayan);

  const navigate = useNavigate();

  const initialValues = {
    withPhoto: "",
    withHoroscope: "",
    verifiedProfile: "",
    ageFrom: "",
    ageTo: "",
    heightFrom: "",
    heightTo: "",
    motherToungue: "",
    maritalStatus: "",
    denomination: "",
    division: "",
    star: "",
    profession: "",
    employed: "",
    annualIncomeFrom: "",
    annualIncomeTo: "",
    location: "",
    bodyType: "",
    physicalStatus: "",
    eatingHabits: "",
    smokingHabits: "",
    drinkingHabits: "",
    familyValue: "",
    familyStatus: "",
    familyType: "",
  };

  const [filterInputs, setFilterInputs] = useState(initialValues);

  const handleChange = ({ target: { value, name } }) => {
    setFilterInputs({ ...filterInputs, [name]: value });
  };

 

  // if the filter id matches with the drop down id then render
  // the component
  const [filterId, setFilterId] = useState([]);

  const handleApply = () => {
     console.log(filterInputs);
  };

  const handleClear = (e) => {
    setFilterInputs(initialValues)
    
  };

  console.log(filterInputs);

  return (
    <div className="FilterBoxContainer">
      <div className="row">
        <span className="DesktopContactUsHomeHeader">
          <HomeHeader />
        </span>

        <span className="MobileContactUsHomeHeader">
          <div className="PageNotFoundTapBar">
            <span className="PageHeading">Testimonials</span>

            <div
              className="leftArrowIcon"
              onClick={() => {
                navigate("/mobileMatchesBox");
              }}
            >
              <i class="fa-solid fa-arrow-left"></i>
            </div>

            <div className="PageNotFoundProfilePics">
              {profilePicture ? (
                <img src={profilePicture} />
              ) : (
                <img src={ProfilePicPlaceholder} className="placeHolderImg" />
              )}
            </div>
            <span className="pageNotFoundProfileDetails">Hey ! Cathy</span>

            <span
              className="MobileNotificationIcon"
              onClick={() => {
                navigate("/notifications");
              }}
            >
              <img src={MobileNotificationIcon} alt="NotificationBell" />
              <img
                src={GreenNotificationPointIcon}
                alt="MobileNotificationPoint"
                className="MobileNotificationPoint"
              />
            </span>
          </div>
        </span>
      </div>

      <div className="MobieFilterBoxContentSection">
        <div className="FiltersBoxHeaderBox">Filters</div>

        {/* Filters Component */}
        <Filter
          handleApply={handleApply}
          handleClear={handleClear}
          handleChange={handleChange}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterId={filterId}
          setFilterId={setFilterId}
        />
      </div>

      <div className="FilterControlButtons">
        <button className="ClearButton" onClick={handleClear}>
          Clear All
        </button>
        <button className="ApplyButton" onClick={handleApply}>
          Apply
        </button>
      </div>

      {/* footer */}
      <div className="row">
        <Footer />
      </div>
    </div>
  );
}

export default MobileFilterBox;

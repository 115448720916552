import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Terms and Conditions</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>
          </div>
          <div className="policy">
            <div className="headerRow tearmsAndConditionsHeaderRow">
              <h4>Terms and Conditions</h4>
            </div>
          </div>

          <div className="row ConditionsRow">
            <div>
              <p>Welcome to JoyMatrimony.com (herein referred as JM).</p>
              <p>
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and rules there under
                pertaining to electronic records as applicable and amended. This
                electronic record is generated by a computer system and does not
                require any physical or digital signatures.
              </p>
            </div>

            <p>
              This document is published in accordance with the provisions of
              Rule 3 (1) of the Information Technology (Intermediaries
              Guidelines and Digital Media Ethics Code) Rules, 2021 (hereinafter
              referred to as Rules) that require publishing the rules and
              regulations, privacy policy and the terms and conditions for
              access or usage of www.Christian.com (the "Website") / "WAP"
              (Wireless Application Protocol)/ Mobile App "APP"
            </p>

            <p>
              PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY
              BEFORE USING THE WEBSITE/WAP/APP. ACCESSING, BROWSING OR OTHERWISE
              USING THE WEBSITE/WAP/APP IMPLIES YOUR AGREEMENT TO BE BOUND BY
              ALL THESE TERMS AND CONDITIONS ("Agreement"). If you do not want
              to be bound by the Terms and Conditions, you must not use the
              Website of CM WAP/APP or the CM (Christian Matrimony) CM WAP/APP
              Services. The Terms and Conditions also include the applicable
              policies which are incorporated herein by way of reference and as
              amended from time to time (the "Terms and conditions").
            </p>

            <p>
              In these Terms, references to "CM member" shall mean the end user
              accessing the Website/CM services, its contents or using the CM
              Services offered. "CM member" also includes persons such as
              father, mother, brother, sister, relative or a friend
              ("Registrant") of the prospective bride/ bridegroom. The terms
              "You" and "User" shall be interchangeably used for term "CM
              member". "CM Services" means a collective reference to the
              Community Matrimony service provided by Matrimony.com Limited, CIN
              : L63090TN2001PLC047432, a company under the provisions of
              companies act, 2013 and having its registered office at No.94, TVH
              Beliciaa Towers, Tower - 2, 5th Floor, MRC Nagar, Chennai, Tamil
              Nadu - 600 028('MCL/Company'). The terms "We/Our/us" shall be used
              interchangeably for term "MCL/Company". Scope
            </p>

            <p>
              You understand and acknowledge that Christian Matrimony(CM) act as
              an "Intermediary" as defined under clause (1) sub-clause (w) of
              Section 2 of the Information Technology Act, 2000.CM is a brand
              owned by Matrimony.Com Limited (MCL) which owns, retains and has
              complete rights in CM and the CM Website / WAP/ App/CM Service.
            </p>

            <p>
              CM acts as a platform to enable any user to themselves register on
              it (by filling the mandatory fields and optional fields, (if any)
              to voluntarily search for profile(s) from the database of CM's
              already registered users, for seeking prospective lawful
              matrimonial alliances for themselves. Our retail store may also be
              able to assist you to create your profile, however, you must have
              a valid/operational mobile phone number and an email id. The
              profiles in the database of CM are classified broadly on the basis
              of language and region for the ease and convenience of its member
              / customer. CM Members are provided with free/paid access for
              searching profiles from the database of CM, as per the partner
              preference set by you (on the Website/Applications - (App) and you
              can shortlist the profiles in which you are interested.
            </p>

            <p>1. Eligibility</p>

            <p>
              A) CM Membership and rights of admission is reserved solely for :
            </p>

            <p>I. Indian Nationals & Citizens.</p>

            <p>II. Persons of Indian Origin (PIO).</p>

            <p>III. Non Resident Indians (NRI).</p>

            <p>IV. Persons of Indian Descent or Indian Heritage</p>

            <p>V. Persons who intend to marry persons of Indian Origin</p>

            <p>
              B) Further in capacity as CM member you confirm that you are :
            </p>

            <p>
              I. 18 years or above (if you are a woman) or 21 years or above (if
              you are a man);
            </p>

            <p>
              II. If you have applied for Divorce, you may register on our
              website/App by stating "Awaiting Divorce".
            </p>

            <p>
              III. If you are a resident of any other Country, you are legally
              competent to marry as per the local rules applicable to your
              country and you shall comply with the Indian laws for marrying a
              person of Indian Origin
            </p>

            <p>
              C. In case you are a Registrant of the prospective bride/
              bridegroom and has created profile in CM Website/WAP/App on behalf
              of them or is accessing the CM Website/App on behalf of them
              implies that you have taken their consent for their profile
              creation in CM and for accessing the CM Website/WAP/App.
            </p>

            <p>2. Registration</p>

            <p>
              A. We expect that you would complete the online registration
              process with fairness and honesty in furnishing true, accurate,
              current, complete information and with providing recent photos of
              you which will help you to meet your parameters .We expect you to
              read the relevant column before keying in the details or selecting
              the option available or uploading the photo. You are requested not
              to key in details of the profile in field other than the
              applicable field (including mentioning id's of other
              platforms/websites/App or repeating your details in another
              fields, after filling them once in the relevant fields or others
              photographs. In order to serve you better if We require additional
              details you agree to provide it. You may also give a missed call
              to any of our retail outlets phone number for us to call you back
              and assist you in getting your profile registered on our
              website/App. Further you may fill in your email id and phone
              number in any of our registration campaigns appearing in various
              websites as part of our advertisements, basis which you may
              receive call from our Customer Service Center and assist you in
              getting your profile registered on our website/App. We may also
              reproduce your profile information in other matrimonial websites
              owned by MCL including community based websites to provide better
              matching Profiles to you.
            </p>

            <p>
              B. If at any point of time We CM come to know or is so informed by
              third party or has reasons to believe that any information
              provided by you for registration (including photos) or otherwise
              is found to be untrue, inaccurate, or incomplete, We / then CM
              shall have the full right to suspend or terminate (without any
              notice) your CM membership and forfeit any amount paid by you
              towards CM membership fee and refuse to provide CM service to you
              thereafter.
            </p>

            <p>
              C. CM also reserves the right to block the registration of your
              profile on Website/App, if any, in the case of your contact
              details/links being entered in irrelevant fields or if there are
              errors in any data entered by the CM members in their profile.
            </p>

            <p>
              D. Registration of duplicate profiles of the same person is not
              allowed in CM Website/WAP/APP. We CM shall have full right to
              suspend or terminate (without any notice) such duplicate profile.
            </p>

            <p>
              E. You acknowledge and confirm that your registration with CM and
              the usage of CM services is with the bonafide intention of
              marriage and not otherwise. CM Membership is restricted strictly
              to the registered CM individual member only. Organizations,
              companies, businesses and/or individuals carrying on similar or
              competitive business cannot become Members of CM and nor use the
              CM Service or CM members data for any commercial purpose, and We
              CM reserve our its right to initiate appropriate legal action for
              breach of these obligation.
            </p>

            <p>3. Account Security</p>

            <p>
              You are responsible for safeguarding the confidentiality of your
              CM login credentials such as your user id, password, OTP, etc.,
              and for restricting access to your computer/mobile to prevent
              unauthorized access to your account. We, as a Company do not ask
              for Password and you are cautioned not to share your password to
              any persons.. You agree to accept responsibility for all
              activities that occur under your account.
            </p>

            <p>4. Role and Responsibility of CM</p>

            <p>
              A. CM reproduces your details once you register on our website /
              WAP/ App on "as is" and "as available" basis and also share your
              profile with other registered CM members within website(s) of MCL.
            </p>

            <p>
              B. Our CM obligation is only to provide a CM interface to its
              registered members to search their prospect themselves without any
              assistance.
            </p>

            <p>
              C. The profile search conducted by any CM member and the matches
              shown thereof are automatically generated by CM, and based on the
              partner preference set by you. In the event of CM member changing
              their partner preference on the Website/App, then the automated
              system generated prospect results of the Website/App may also
              undergo corresponding change.
            </p>

            <p>
              D. CM does not prohibit any CM member from sending interest to
              your profile or communicating to you based on their partner
              preference. But you are at the liberty to deny their interest or
              proceed further if you are interested.
            </p>

            <p>
              E. We cannot guarantee or assume responsibility for any specific
              results from the use of the data available from the CM service or
              from other matrimonial websites owned by MCL including community
              based websites.
            </p>

            <p>
              F. CM shall safeguard sensitive user information using security
              standards, authentication mechanisms, access controls and
              encryption techniques.
            </p>

            <p>
              G. CM cannot guarantee the complete protection of user data while
              it is in transit, or prevent any tampering of the data by a third
              party with malicious intent before the data reaches the CM servers
            </p>

            <p>
              H. CM does not authenticate/endorse any information of any profile
              and hence you as a user need to verify the credentials and
              information provided by other users.
            </p>

            <p>5. Role and Responsibility of CM Member</p>

            <p>
              A. You shall safeguard your profile information by creating a
              strong password during profile creation with combination of
              alphabets, both upper and lower case and numbers.
            </p>

            <p>F. CM Members are advised to refrain from:</p>

            <p>
              i. Entering into any financial transactions with prospects. CM
              Members shall not seek financial help or provide financial help
              from / to the other CM Members.
            </p>

            <p>
              ii. Using abusive language when they communicate with the other CM
              Members
            </p>

            <p>iii. being discriminative or using racial comments etc.</p>

            <p>
              iv. Sharing of confidential and personal data with each other but
              not limited to sharing of bank details, etc.
            </p>

            <p>
              v. Entering into physical relationship with any prospect before
              marriage.
            </p>

            <p>vi. violating any law for the time being in force.</p>

            <p>
              vii. From mentioning details of other matrimonial services while
              sending personalized messages
            </p>

            <p>
              G. CM members are expected to visit URL
              https://www.christianmatrimony.com/safematrimony/ for
              understanding the safety measures on partner search and awareness
              towards online safety.
            </p>

            <p>
              H. CM Members are expected to be cautious of prospects who ask for
              favours / sexual favors, sexual favors money etc or call from
              multiple phone numbers, only interact over phone, doesn't come
              forward for face to face meeting (physically or through video
              calls) and don't involve family and friends in match making.
              Beware of suspended profiles status before you finalize an
              alliance with the prospect.
            </p>

            <p>
              I. The CM members agrees that for getting effective search results
              of prospects on Website/App you will regularly log in to the
              profile maintained in the Website/App and send expression of
              interest which is an automated messages to prospects as a free CM
              member, and in the event you have availed paid CM package, you can
              send personalized messages to prospects to show your expression of
              interest/replies. CM member also may review the expression of
              interest sent to you / read the messages sent to you by prospects
              and may respond suitably.
            </p>

            <p>
              J. You also agree that while accessing / using the Website/WAP
              App, you will be guided by the terms and conditions of the
              Website/WAP /App including but not limited to the use of feature
              of website/WAP App like chat instantly with prospect, viewing
              horoscope of prospects, enhancing privacy settings
              (photo/horoscope/phone number) or for viewing social and
              professional profile of members on their Facebook, LinkedIn, etc.
            </p>

            <p>
              K. If the CM Member fails to update in CM website/App, any change
              in the particulars furnished in their profile, then the CM
              membership may be suspended by us at our sole discretion.
            </p>

            <p>
              L. CM Member shall, in case of finalization of his/her/their
              Registrant's marriage, delete their profile by themselves or
              intimate us for removal/deletion of their profile.
            </p>

            <p>
              M. CM Members agree to use secure devices, software and networks
              in a private place for accessing the CM services.
            </p>

            <p>
              L. CM Member shall, in case of finalization of his/her/their
              Registrant's marriage, delete their profile by themselves or
              intimate us for removal/deletion of their profile.
            </p>

            <p>
              M. CM Members agree to use secure devices, software and networks
              in a private place for accessing the CM services
            </p>

            <p>
              N. CM member shall not use any kind of Bots like web robots,
              Chatbot or any other automated processes/programs/scripts to use,
              communicate or respond in CM Website/App.
            </p>

            <p>
              O. CM Members shall not probe, scan or test the vulnerabilities of
              the CM Website/App or any networks neither connected to the
              Website/App nor breach the security measures instituted by CM
            </p>

            <p>
              P. You shall also not make any payment / transfer any amount to
              account of any MCL employees. All payments shall be made only to
              the account of MCL.
            </p>

            <p>
              Q. CM members are expected to approach MCL to resolve their
              complaints and grievances and shall not air their
              grievance/complaints in social media.
            </p>

            <p>6. Customer Care / Customer Service</p>

            <p>
              In the event you come across any concerns in our service, then
              please be free to contact us:
            </p>

            <p>
              Email ID : helpdesk@communitymatrimony.com
              <br /> Contact No : +91-8144998877
              <br /> Address :<br /> Matrimony.com Limited
              <br /> No.94, TVH Beliciaa Towers, Tower - 2,
              <br /> 5th Floor, MRC Nagar, Chennai,
              <br /> Tamil Nadu - 600 028
            </p>

            <p>7. Medium of Communication to CM Members</p>

            <p>
              a. CM member hereby consents for receiving communication including
              promotional communications through any medium of communications
              such as electronic mails, calls, SMS or through Whatsapp messages
              from MCL or other portals owned by MCL or their alliance partners.
            </p>

            <p>
              b. CM by its automatic system sends to its member (s) profile (s)
              of the prospect by way of images/ documents/ messages / links. CM
              member confirms that the mobile number submitted to it for
              verification or alternative number if any provided, is not
              registered with the Do Not Disturb / National Customer Preference
              Register and they shall not initiate any complaint. CM Member
              further confirms that even if CM Member is registered with the
              telecom service provider under the category Do Not Disturb /
              National Customer Preference Register the calls from CM either to
              the verified mobile number or alternative number if any provided
              shall not be treated as promotional calls.
            </p>

            <p>
              C. You are also entitled to set up your communications frequency
              from us / CM, like - only on weekends or fortnightly etc. You may
              also opt for not getting any call from us / CM by ticking the
              appropriate box in the Website/WAP/App, provided that, you agree
              to receive calls from Relationship Manager of Assisted Matrimony/
              Elite Matrimony for contacting you as a shortlisted profile for
              their registered members.
            </p>

            <p>8. Confidentiality</p>

            <p>
              Any feedback you provide to us CM shall be deemed to be
              non-confidential. We CM shall be free to use such
              feedback/information on an unrestricted basis. Further, by
              submitting the feedback, you represent and warrant that
            </p>

            <p>
              (i). your feedback does not contain confidential or proprietary
              information of yourself or third parties;
            </p>

            <p>
              (ii). CM member is not under any obligation of confidentiality,
              express or implied, with respect to the feedback;
            </p>

            <p>
              (iii). you are not entitled to any compensation or reimbursement
              of any kind from us CM for the feedback under any circumstances.
            </p>

            <p>9. Privacy of Membership</p>

            <p>
              To protect your privacy and understand our practices as amended
              from time to time, please read and follow our Privacy Policy which
              also governs your visit to CM and other websites owned by MCL, the
              personal information / data provided to us by you during the
              course of usage of CM will be treated as confidential and in
              accordance with the Privacy policy and applicable laws and
              regulations. If you object to your information being transferred
              or used, please do not use the website.
            </p>

            <p>10. Grievance Officer</p>

            <p>
              In the event you come across any violation by another user
              including but not limited to having content in the web site that
              is obscene, menacing, grossly offensive, harming minors,
              infringing copyright, patents, etc., or another user is
              impersonating etc. you may then please be free to provide your
              concerns in writing or email us with digital signature to:
            </p>

            <p>Email: grievanceofficer@matrimony.com,</p>

            <p>Grievance Officer : Mr. Khader Mohiadeen.</p>

            <p>
              Contact Address :<br /> M/s. Matrimony.com Limited,
              <br /> No.94, TVH Beliciaa Towers, Tower - 2,
              <br /> 5th Floor, MRC Nagar, Chennai,
              <br /> Tamil Nadu - 600028
            </p>

            <p>
              The Grievance office shall be available between 10 am till 6 pm
              Indian Standard Time from Monday to Saturday excluding Sunday and
              public holidays in India. The Grievance officer is appointed as
              per Rule 11 of the Rules. Any and all complaints to the Grievance
              Officer shall be in writing
            </p>

            <p>
              The Grievance Officer shall handle complaints in relation to the
              following violation by the User of computer resource as the User
              is not entitled to host, display, upload, modify, publish,
              transmit, store, update or share any information on the
              Website/App that -
            </p>

            <p>
              A. belongs to another person and to which the user does not have
              any right;
            </p>

            <p>
              B. is obscene, pornographic, paedophilic, invasive of another’s
              privacy including bodily privacy, insulting or harassing on the
              basis of gender, racially or ethnically objectionable, relating or
              encouraging money laundering or gambling, or promoting enmity
              between different groups on the grounds of religion or caste with
              the intent to incite violence;
            </p>

            <p>C. is harmful to child;</p>

            <p>
              D. infringes any patent, trademark, copyright or other proprietary
              rights;
            </p>

            <p>
              E. deceives or misleads the addressee about the origin of the
              message or knowingly and intentionally communicates any
              misinformation or information which is patently false and untrue
              or misleading in nature;
            </p>

            <p>F. impersonates another person;</p>

            <p>
              G. threatens the unity, integrity, defence, security or
              sovereignty of India, friendly relations with foreign States, or
              public order, or causes incitement to the commission of any
              cognisable offence, or prevents investigation of any offence, or
              is insulting other nation;
            </p>

            <p>
              H. contains software virus or any other computer code, file or
              program designed to interrupt, destroy or limit the functionality
              of any computer resource; (ix) violates any law for the time being
              in force;"
            </p>

            <p>11. Disputes between Members</p>

            <p>
              A. CM Members are solely responsible for the communications
              (through any medium) with prospect or vice versa. We CM expressly
              disclaim any responsibility or liability for any monetary
              transaction(s) or exchange(s) or interaction(s) or passing of
              information(s) etc. between any CM members inter se via e-mail,
              chat, interaction, Whatsapp or any other medium of communication
              between CM members either using website /WAP/App or otherwise.
            </p>

            <p>
              B. CM has no obligation, to monitor any such disputes arising
              between the CM members, and We CM shall not be party to any such
              dispute/litigation etc.
            </p>

            <p>
              C. We are CM is not a broker or the agent of any CM member, and CM
              does not partake in the exchange of any kind of discussion between
              the CM members and prospects or the results of their discussion.
            </p>

            <p>12. Content Right</p>

            <p>
              I. You agree that all content of CM belong to MCL excluding your
              or third party content including advertisement on the
              Website/WAP/App for which We have / We has requisite license/right
              in terms hereof to display the same on our Website/WAP/ App.
            </p>

            <p>
              II. You acknowledge that you alone are responsible and liable for
              the content (mandatory or optional content you provided on our
              website) and information including the profile details, photograph
              and other content(s) keyed in the relevant field of the
              Website/WAP App at the time of the creation of the CM profile or
              subsequently modified by you and also for all the post and
              communication (including personalized messages sent by CM paid
              members) with other member(s). You hereby represent and warrant
              that you own all rights, title and interest in your
              content/information or have the necessary licenses, rights,
              consents, and permissions to provide the said content/information.
              However, by submitting the content/information to CM, you hereby
              grant us CM a worldwide, non-exclusive, royalty-free,
              sub-licensable and transferable license to use, reproduce,
              distribute, prepare derivative works of, display, publish,
              communicate to the public, law enforcement agencies, courts, and
              Our / The CM's business and services, including without limitation
              for promoting, communicating to the public and redistributing part
              or all of the content/information (and derivative works thereof)
              in any media formats and through any media channels.
            </p>

            <p>
              III. While CM does not accept any obligation to monitor the
              content/information in your profile, if the content/information is
              found not to be in compliance with these Terms and conditions, CM
              may delete the content/information and / or terminate or suspend
              your registration or (without a refund of any subscription paid by
              you in terms of clause 2 (b) of these terms and conditions). You
              agree that if the situation warrants, We CM may at our / its sole
              discretion put restriction on any CM member to other member's
              communications.
            </p>

            <p>
              IV. MCL reserves the right to proceed/initiate appropriate steps
              under the prevailing law against infringement by violators of the
              its proprietary rights or for illegally copying, modifying,
              publishing, transmitting, distributing, performing, displaying, or
              selling of any such proprietary information including using it for
              commercial purpose in any manner whatsoever.
            </p>

            <p>13. Third Party website</p>

            <p>
              A). CM member understand and agree that We may allow third parties
              to put up advertisements on the CM application/Website and We do
              not control the contents of such third party advertisement on the
              CM application/Website. Third party websites to which links are
              given on Website/App are not governed by these Terms and
              conditions and all such third party websites are governed by their
              own terms and conditions for use and privacy policy, and in case
              of conflict the terms and conditions of the third party website
              shall prevail. The Company does not in any manner whatsoever
              authenticate, endorse, verify or certify these third party
              websites or any contents therein. Please note that a user shall
              click on links to third party websites at his / her sole risk and
              responsibility.
            </p>

            <p>
              B). The CM members agrees and understands that CM may also allow
              third parties to provide CM members services on the website/App
              like horoscope matching etc., and We may also collect the payment
              for such third party services to you, and CM in this regard, We
              can have revenue sharing arrangement with such third party,
              however in no event shall We are CM be liable to you for such
              third party services.
            </p>

            <p>
              C). CM reserve the right to add/delete/alter/modify/suspend any or
              all the CM Services at any time as it may deem fit, without
              notice. The CM members are expected to see the change on the
              Website/WAP/ App
            </p>

            <p>14. Limitation of liability</p>

            <p>
              A. MCL / CM or its Office bearers shall under no circumstances be
              liable or responsible to the CM member or his/her authorized
              Representative or Registrant / Guardian or any third party for any
              direct, indirect, special, exemplary, incidental, or consequential
              damages of any character including, without limitation, damages
              resulting from the use of our Website/WAP/ App/Third Party
              Website/ CM services.
            </p>

            <p>
              B. CM inform you that the exchange of profile(s) through or by us
              CM should not in any way be construed as a matrimonial offer
              and/or recommendation and / or advice or guarantee given to the CM
              member, from/ or by CM
            </p>

            <p>
              C. Notwithstanding anything to the contrary contained herein, Our
              / CM's liability to you for any cause whatsoever, and regardless
              of the form of the action, will at all times be limited to the
              amount paid, if any, by you to CM, for any specific CM paid
              package, and no further
            </p>

            <p>
              D. MCL / CM will not be liable in case of any wrong/improper match
              made due to unavailability of profiles from CM MCL's owned other
              websites.
            </p>

            <p>
              E. MCL / CM or its office bearers shall under no circumstances be
              liable, if any, for the CM member entering into financial
              transaction with any other CM Member or any third party.
            </p>

            <p>
              F. MCL / CM or its office bearers shall under no circumstances be
              liable, if any, for any CM members not responding/reciprocating
              when you approach them for matrimonial alliance.
            </p>

            <p>15. Class Action Suits</p>

            <p>
              You acknowledge and confirm that you will not bring or participate
              in any class action or other class proceeding in connection with
              any dispute with us / CM. Further neither you nor MCL / CM agrees
              to class arbitration.
            </p>

            <p>16. General</p>

            <p>
              In case the event you file a false complaint against another
              prospect on our Website /App and consequently we have
              suspended/deleted that prospects profile based on your complaint,
              then we reserve our right to initiate appropriate legal
              (Civil/Criminal) action against you and claim any and all costs
              expenses from you, for such
              irresponsible/misrepresentation/illegal/unlawful action. We also
              reserve our right to suspend your profile and forfeit any and all
              amounts paid by you for the CM services as per clause 2 (b) of
              these terms and conditions.
            </p>

            <p>
              17. Notwithstanding anything contained herein, CM reserve the
              absolute right to delete, in any manner as it deems fit, any
              content of any profile listing placed on CM website/App (once such
              instance come to CM notice) in order to ensure, that proper
              consent has been obtained by you , prima facie accuracy and the
              prevailing laws in force for the time being, especially those
              relating to providing any obscene, libelous, blasphemous,
              slanderous, defamatory or invasive of another person's (deceased
              or alive) right of privacy or publicity, or that may reasonably be
              deemed to be harmful, vulgar, pornographic, abusive, harassing,
              threatening, hateful, objectionable with respect to race,
              religion, creed, nationality, gender or otherwise unfit for
              reproduction; or suggests or encourages unfair or illegal
              /indecent, unlawful activity. CM also reserves the right to block/
              delete / suspend the profile which might be offensive, illegal or
              that might violate the rights, harm or threaten the safety of our
              office bearers/employees (including undue communication with any
              employee) and/or other registered prospects or using our CM
              website/App as a means of communication while sending messages to
              other CM members regarding their profile identity in other
              matrimonial websites.
            </p>

            <p>
              18. Once your paid membership expires, you cannot avail the
              unexpired balance phone call count/ unexpired SMS. Similarly, you
              cannot access the already viewed CM member(s) contact information
              unless you renew your account within 30 days.
            </p>

            <p>
              19. However on renewal, the unexpired phone call / SMS shall be
              carried forward to your account from the last day of expiry
            </p>

            <p>
              20. In case of conflict between the terms and condition of
              Website/WAP App and terms and conditions of any other website
              including other websites of MCL, the terms and condition of CM
              Website/App shall prevail for the service provided through this
              Website/App.
            </p>

            <p>21. Disclaimer</p>

            <p>
              A. Your access to and use of the CM Services or any content is at
              your own risk. YOU UNDERSTAND AND AGREE THAT THE CM SERVICES ARE
              PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT
              LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, CM
              DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. CM does not
              warrant that the Website/WAP/App, its servers, or e-mail sent from
              CM are free of viruses or other harmful components. We are CM wil
              not liable for any damages of any kind arising from the use of
              this CM Website/App, including, but not limited to direct,
              indirect, incidental, punitive, and consequential damages.
            </p>

            <p>
              B. We do CM does not give any implied or explicit guarantee or
              warranty of marriage or alliance by you choosing to register on
              our Website/App and using CM services (both paid and free).
            </p>

            <p>
              C. Notwithstanding anything contrary contained anywhere, under no
              circumstances, CM shall be held responsible or liable whatsoever
              or howsoever, arising out of, relating to or connected with:
            </p>

            <p>
              i. any act or omission done by CM /MCL/payment gateway/alliance
              partner etc.;
            </p>

            <p>
              ii. any untrue or incorrect information submitted by any CM
              Member/you or on your behalf;
            </p>

            <p>
              iii. any decision taken by you or on your behalf or any
              consequences thereof, based on any information provided by any
              other user (including suspension/deletion of the profile from CM);
            </p>

            <p>
              iv. any unauthorized or illegal act done by any third party
              relating to or connected with any information submitted by you or
              on your behalf;
            </p>

            <p>v. any cybercrime attempted or committed by anyone and</p>

            <p>vi. any incident of force-majeure or 'act of god'.</p>

            <p>
              vii. Any issue already stated in these terms and conditions
              including limitation of liability clause of these terms and
              conditions.
            </p>

            <p>
              viii. Using/availing of third party services namely horoscope
              matching services etc. and for paying them through the Website.
            </p>

            <p>
              ix. CM shall not be liable for the outcome of during any
              interaction in a meeting, call, sms, chat, email or social media
              posts at any point of time.
            </p>

            <p>
              x. Any issues relating to any technical malfunction of any
              telecommunication network, software, hardware failures, network
              congestion, denial of service, failure due to spamming or any
              combination of the above.
            </p>

            <p>
              D. You expressly agree that your use of this Website/App is at
              your sole risk.
            </p>

            <p>
              E. We are not liable to you for any damage or alteration to your
              equipment including but not limited to computer equipment,
              hand-held device or mobile telephones as a result of the
              installation or use of the app. We are not liable to you for any
              damage or alteration to your equipment including but not limited
              to computer equipment, hand-held device or mobile telephones as a
              result of the installation or use of the App.
            </p>

            <p>22. Indemnity</p>

            <p>
              By using our CM services you agree to defend, indemnify, and hold
              harmless MCL/CM , its subsidiaries, affiliates, Directors,
              officers, agents, and other partners and employees, fully
              indemnified and harmless from any loss, damage, liability, claim,
              or demand, including reasonable attorney's fees, made by any
              person through improper use of the service provided by us CM. This
              defence and indemnification obligation will survive in perpetuity.
            </p>

            <p>23. Termination</p>

            <p>
              CM in good faith reserve our right to terminate your CM membership
              or suspend/delete your profile at our sole discretion without any
              notice to you and take any action as mentioned in clause 2 (b) of
              the Terms and Conditions, in case you
            </p>

            <p>
              I. seek physical / financial favors from our other registered
              members or
            </p>

            <p>
              II. have provided incorrect information on our website/WAP/ App or
            </p>

            <p>
              III. have committed any fraudulent/ unlawful/ illegal activities
              through the use of our website/WAP/ App or
            </p>

            <p>IV. have violated this Terms and conditions or</p>

            <p>
              V. have misappropriated the name, likeness, email address, contact
              no or other personally identifiable information of another CM
              member created a profile on our website /App by impersonation/
              fake/ bogus/ false/ misrepresentation/ without consent of the
              person who's profile is being registered or use only part
              information including using photo of third parties without the
              permission of such third parties or act with other
              members/employees in indecent/improper manner,
            </p>

            <p>
              B. If We receive any complaint against you and consequently we
              have suspended/deleted your profile in good faith, then we shall
              not be liable to you in any manner whatsoever, including for any
              loss, costs, expenses, or consequence, if any
            </p>

            <p>
              C. Unless otherwise provided in these Terms and Conditions, CM
              membership may be deactivated by you without assigning any reasons
              by . However all amount paid till date by you to CM shall be
              forfeited.
            </p>

            <p>
              D. Upon termination / suspension of CM membership, your right to
              use or access the CM Website/App services will cease immediately.
              All provisions of this terms and conditions that by their nature
              should survive termination shall survive termination, including,
              without limitation, disclaimers, and limitations of liability.
              Termination of your access to and use of the CM Services shall not
              relieve you of any obligations arising or accruing prior to such
              termination.
            </p>

            <p>24. Jurisdiction and Applicable Law</p>

            <p>
              A. The registration of CM membership and all CM services are
              deemed to have been entered into within the territorial
              Jurisdiction of Chennai, India.
            </p>

            <p>
              B. The CM Members unconditionally agree that all such disputes and
              / or differences if any shall be governed by the Laws of India and
              submitting to the exclusive jurisdiction of appropriate court of
              law in Chennai, Tamil Nadu, India
            </p>

            <p>25. Mode of Payment</p>

            <p>
              A. Payment made by cash / cheque / Net Banking / Debit / Credit
              Card/ Paypal/ RTGS/ NEFT/ would be in accordance with the
              prevailing RBI guidelines. Activation of paid CM service shall be
              subject to realization of the said payment. In case of dishonor of
              Cheque/demand draft; you shall be liable to pay applicable bank's
              cheque/DD bouncing charges. Further CM service to your account
              will be deactivated / suspended until you settle the value of the
              payment and applicable bank charges in cash.
            </p>

            <p>
              B. CM uses third party payment gateways for collection payment
              through credit/debit card or other payment instruments.
            </p>

            <p>
              C. You agree to provide us with your Permanent Account Number /
              Form 60/61 of the Income Tax Act, 1961 and rules thereunder in the
              event you choose any paid package of MCL, the value of such paid
              package being Rs. 2,00,000/- [Rupees Two Lakh Only] and above from
              MCL.
            </p>

            <p>26. Refund and Assignment</p>

            <p>
              The payments made by any CM member to MCL by way of membership /
              renewal fee / auto renewal are treated as non-refundable.
            </p>

            <p>
              CM member shall not assign or transfer your CM membership to any
              other person or entity, and such an act shall be treated as
              violation of terms and conditions and CM shall retain their right
              under clause 2 (b) of this terms and conditions.
            </p>

            <p>
              Payment once made for CM services cannot be assigned to any
              person/party or adjusted towards any other product or packages
              provided by the Company.
            </p>

            <p>27. Renewal</p>

            <p>
              A. We automatically renew all paid Memberships before the expiry
              of the term of the paid Membership. You agree that your account
              will be subject to this automatic renewal feature.
            </p>

            <p>
              B. When you first subscribe for your paid Membership, you
              acknowledge that when your paid Membership expires it will
              automatically renew for the duration and at the last paid price.
            </p>

            <p>
              C. The maximum tenure of any packages will be 12 (Twelve) month.
              Every CM packages post validity period (varies from package to
              package), shall be renewed.
            </p>

            <p>
              D. If you do not want your paid membership to automatically renew,
              you must cancel your paid / automatic membership. In case you have
              to mail for cancellation, mail to cancellation.cbs@matrimony.com
            </p>

            <p>
              28. Additional terms and conditions applicable to Non- Resident
              Indian customers.
            </p>

            <p>A. Jurisdiction</p>

            <p>The laws of India shall be applicable.</p>

            <p>B. Arbitration of Disputes.</p>

            <p>
              The Parties shall discuss among themselves and resolve any
              disputes informally. We are interested in attempting to resolve
              any Disputes by amicable and informal means, and we encourage you
              to contact us before resorting to arbitration. If the dispute is
              not resolved within 30 ( thirty ) days from the date of such
              dispute , then such dispute may only be resolved through an
              individual arbitration conducted in accordance with the provisions
              of The Arbitration and conciliation Act, 1996 as amended, by sole
              arbitrator. The seat of Arbitration shall be Chennai, Tamil Nadu,
              India. We shall appoint the sole arbitrator. The arbitration
              proceedings shall be conducted in the English language
            </p>

            <p>
              C. However, disputes relating to infringement of our intellectual
              property rights, which we may elect to have resolved by means
              other than arbitration
            </p>

            <p>
              D. In case of Renewal, Customer is charged the current price that
              prevails and renewal is done only for 3 months duration
              irrespective of the past package.
            </p>

            <p>E. Enforcement</p>

            <p>
              Any proceeding to enforce this arbitration agreement may be
              brought in Courts of Chennai, Tamil Nadu India.
            </p>

            <p>29. Assisted Service Guarantee</p>

            <p>
              Assisted Service Guarantee is applicable only for the users: Who
              have availed the Assisted Service for the first time
            </p>

            <p>
              Who have more than 100 matching profiles based on the partner
              preference set by them at the time of the Assisted Welcome Call
            </p>

            <p>
              Who have not narrowed down the partner preference, once the
              Assisted service starts
            </p>

            <p>30. Membership Packages</p>

            <p>
              A.We have various premium membership packages called Gold,
              Diamond, Platinum, Till U Marry and Assisted.
            </p>

            <p>
              B.You shall opt for any of our premium membership packages varying
              between three months to one year with differing price to access
              various premium features.
            </p>

            <p>
              C.Each premium package has certain limitations on count of Phone
              number view except for Diamond and Platinum package (3 & 6
              months). The Diamond and Platinum package (3 & 6 months) has
              unlimited phone number view but within the Fair Usage Policy
              limit. Viewing an accepted member's mobile number will not deduct
              the mobile number count (For applicable domains)
            </p>

            <p>
              D.The members of Diamond and Platinum package (3 & 6 months) can
              receive Personalised Messages (PMs) from Free Members. Only if PP
              of Platinum member matches with the Personal Interest of the free
              member profile this will be allowed (For applicable domains).
            </p>

            <p>
              E.The Fair Usage Policy (FUP) limit on Phone view count and Count
              on Personal message is as follows:
            </p>

            <p>
              Fair Usage Metrics Fair Usage Metrics Personal Message Count Phone
              View Count
            </p>

            <p>
              Day Zero Paid Limit 50 20
              <br />
              Daily Limit 100 30
              <br />
              Weekly Limit 150 50
              <br />
              Monthly Limit 500 100
              <br />
              Total Limit 4000 750
            </p>

            <p>
              F.To know the complete details on various Membership package and
              its benefits, please visit 'Payment Options' page
            </p>
          </div>

          <div className="row Testimonials-Footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;

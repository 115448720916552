import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import RadioInputField from "../../PartnerPreference/InputFields/RadioInputField";
import InputField from "../../PartnerPreference/InputFields/InputField";

const RegularSearchBasicDetails = ({ RegularSearchField, handleChange }) => {
 
  const RegularSearchBasicInputs = [
    {
      // className: "partnerPreferenceMediumOption selectFielBox",
      inputType: "range",
      name: "age",
      field: [
        {
          name: "ageFrom",
          value: RegularSearchField.ageFrom,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "30", disabled: false },
            { value: "35", disabled: false },
            { value: "40", disabled: false },
          ],
        },
        {
          name: "ageTo",
          value: RegularSearchField.ageTo,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "30", disabled: false },
            { value: "35", disabled: false },
            { value: "40", disabled: false },
          ],
        },
      ],
    },
    {
      // className: "partnerPreferenceMediumOption selectFielBox",
      inputType: "range",
      name: "height",
      field: [
        {
          value: RegularSearchField.heightFrom,
          name: "heightFrom",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: `5'5"`, disabled: false },
            { value: `6'2"`, disabled: false },
            { value: `6'5"`, disabled: false },
          ],
        },
        {
          value: RegularSearchField.heightTo,
          name: "heightTo",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: `5'5"`, disabled: false },
            { value: `6'2"`, disabled: false },
            { value: `6'5"`, disabled: false },
          ],
        },
      ],
    },
    {
      inputType: "radio",
      value: RegularSearchField.bodyType,
      field: [
        { name: "bodyType", label: "Slim", value: "Slim", idAndFor: "slim" },
        {
          name: "bodyType",
          label: "Athletic",
          value: "Athletic",
          idAndFor: "athletic",
        },
        {
          name: "bodyType",
          label: "Average",
          value: "Average",
          idAndFor: "Average",
        },
        {
          name: "bodyType",
          label: "Heavy",
          value: "Heavy",
          idAndFor: "Heavy",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "maritalStatus",
          label: "Unmaried",
          value: "Unmaried",
          idAndFor: "slim",
        },
        {
          name: "maritalStatus",
          label: "widow/widower",
          value: "widow/widower",
          idAndFor: "athletic",
        },
        {
          name: "maritalStatus",
          label: "Divorced",
          value: "Divorced",
          idAndFor: "divorced",
        },
        {
          name: "maritalStatus",
          label: "Seperated",
          value: "Seperated",
          idAndFor: "seperated",
        },
        {
          name: "maritalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "doesn'tmatter",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "physicalStatus",
          label: "Normal",
          value: "Normal",
          idAndFor: "normal",
        },
        {
          name: "physicalStatus",
          label: "Physically challenged",
          value: "Physically challenged",
          idAndFor: "athletic",
        },
        {
          name: "physicalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "doesn'tmatter",
        },
      ],
    },
    {
      inputType: "select",
      name: "motherTounge",
      value: RegularSearchField.motherTounge,
      // className: "partnerPreferenceMediumOption selectFielBox",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "foodHabits",
      value: RegularSearchField.foodHabits,
      // className: "partnerPreferenceMediumOption selectFielBox",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "drinkingHabbits",
      value: RegularSearchField.drinkingHabbits,
      // className: "partnerPreferenceMediumOption selectFielBox",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "smokingHabits",
      value: RegularSearchField.smokingHabits,
      // className: "partnerPreferenceMediumOption selectFielBox",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="regularSearchIndividualContainer">
      <div className="regularSearchHead">Basic Details</div>
      <div className="regularSearchContainerIndividualInputs">
        <div className="regularSearchLabels">
          <div className="regularSearchLabel">Age</div>
          <div className="regularSearchLabel">Height</div>
          <div className="regularSearchLabel">Body Type</div>
          <div className="regularSearchLabel">Marital Status</div>
          <div className="regularSearchLabel">Physical Status</div>
          <div className="regularSearchLabel">Mother Toungue</div>
          <div className="regularSearchLabel">Food Habits</div>
          <div className="regularSearchLabel">Drinking Habits</div>
          <div className="regularSearchLabel">Smoking habits</div>
        </div>
        <div className="regularSearchInputs">
          {RegularSearchBasicInputs.map((input) =>
            input.inputType === "radio" ? (
              <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                <RadioInputField
                  input={input}
                  handleChange={handleChange}
                  inputFields={RegularSearchField}
                  className={`EditTimeRadioflexWrap`}
                />
              </div>
            ) : input.inputType === "select" ? (
              <div className="partnerFieldAns">
                <SelectInputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
              </div>
            ) : input.inputType === "range" ? (
              <div className="partnerFieldRange partnerFieldAns">
                <SelectInputField
                  input={input.field[0]}
                  className={input.className}
                  handleChange={handleChange}
                />
                <div> - </div>
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input.field[1]}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
                <span className="Details_section_values_rightLabel">
                  {input.name === "age" && <div>Years</div>}
                </span>
              </div>
            ) : (
              <div className="partnerFieldAns">
                <InputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
                <span className="Details_section_values_rightLabel">
                  {input.FieldLabel}
                </span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularSearchBasicDetails;

import React from "react";
import { useNavigate } from "react-router-dom";

import "./HomeSearchBox.css";

const HomeSearchBox = ({ setHeaderBox }) => {
  const matchesArray = [
    {
      match: "Regular Search",
      link: "/regularSearch",
      // header1: "2000 Total Matches",
      // header2: "Members who match your Partner preferences",
    },
    {
      match: "Keyword Search",
      link: "/keywordsearch",
    },
    {
      match: "Search by Matrimony ID",
      link: "/matrimonyidsearch",
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="homeSearchContainer">
      <div className="homeSearches">
        {matchesArray.map((match, idx) => (
          <div
            className="homeSearch"
            onClick={() => {
              navigate(match.link);
              setHeaderBox("");
            }}
            key={idx}
          >
            {match.match}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSearchBox;

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import HomeHeader from '../../components/Headers/Home header/HomeHeader'
import ProfileSettingsTab from '../../components/ProfileSettingsTab/ProfileSettingsTab'
import "../ShortlistedProfiles/ShortlistedProfiles.css"
import ProfilePic from "../../assets/images/Home/homeProfilePic2.png"
import shareGrey from "../../assets/images/Matches/shareGrey.png";
import shareBlue from "../../assets/images/Matches/shareBlue.png";
import chatIcon from "../../assets/images/Matches/Chat_Icon.png";
import verified from "../../assets/images/Matches/Verified_User.png";
import pending from "../../assets/images/Matches/Pending_verification.png";
import yetToVerify from "../../assets/images/Matches/yetToVerify.png";
import Filter from "../../components/Macthes/Filter/Filter";
import ShareInput from "../../components/Macthes/ShareInput/ShareInput";
import Activity from "../../components/Macthes/Activity/Activity";
import SubscribePopUp from "../../components/Individual/Subscribe/SubscribePopUp";
import InterestPopUp from "../../components/Individual/ShowInterest/InterestPopUp"

const ShortlistedProfiles = () => {
     // share profile pop up
  const [shareProfile, setShareprofile] = useState(false);

  // subscribe pop up
  const [subscribe, setSubscribe] = useState(false);

  // interest pop up
  const [interest, setInterest] = useState(false);

  // when clicking on the Shortlisted page the page goes to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // getting headers from the home page
  const {
    state: {  header2}, 
  } = useLocation();

  // navigate
  const navigate = useNavigate();

  // Shortlisted details
  const [ShortlistedData, setShortlistedData] = useState([
    {
      profilepic: ProfilePic,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: ProfilePic,
      isverified: "Aadhar verification pending",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: ProfilePic,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: ProfilePic,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    
  ]);
  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Shortlisted</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="ShortlistedProfiles">
              <div className="Profile-Setting-Tab-Shortlisted col-lg-3">
                <ProfileSettingsTab />
              </div>
              {/* background grey when popup */}
              <div
                className={`${shareProfile && "ShortlistBlack"} ${
                  subscribe && "ShortlistBlack"
                } ${interest && "ShortlistBlack"}`}
              />

              {/* subscribe pop up */}
              {subscribe && (
                <div className="ShortlistedSubscribe">
                  <SubscribePopUp
                    setSubscribePopUp={setSubscribe}
                    classname={"ShortlistedSubscribe"}
                  />
                </div>
              )}

              {/* interest popup */}
              {interest && (
                <div className="ShortlistedSubscribe">
                  <InterestPopUp setInterestPopUp={setInterest} />
                </div>
              )}

              <div className="Shortlisted-Profiles">
                {/* share profile popup */}
                {shareProfile && (
                  <div className="shareProfileContainer">
                    <div>
                      <div
                        className="ShortlistedShareClose"
                        onClick={() => {
                          setShareprofile(false);
                        }}
                      >
                        {" "}
                        <i class="fa-solid fa-xmark"></i>
                      </div>
                      <div className="ShortlistedShareContainerBox">
                        <div className="ShortlistedShareHeader">
                          <div className="ShortlistedShareBlue">
                            <img src={shareBlue} />
                          </div>
                          <div>Share This Profile!</div>
                        </div>
                        <div className="ShortlistedShareContent">
                          <form>
                            <ShareInput
                              buttonName={"Copy ID"}
                              classname="shareInputMedium"
                            />
                            <div>or</div>
                            <ShareInput
                              buttonName={"Copy Link"}
                              classname="shareInputLarge"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <p className="HistoryPageHeading">
                  Profiles shortlisted by you
                </p>

                {/* matches pages */}
                <div className="col-lg-12">
                  <div className="ShortlistedContainerHeaders">
                    {/* <div className="ShortlistedContainerHeader1">{header1}</div> */}
                    {/* <div className="ShortlistedContainerHeader2">{header2}</div> */}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row ShortlistedesContainer">
                    {ShortlistedData.map((data, idx) => (
                      <div className=" HistoryCardContainerBox">
                        <div className="HistoryCardIdContainer">
                          <div>
                            <span className="matchId">JM123456</span> | Profile
                            created by Friend
                          </div>
                          <div
                            className="HistoryShareIcon"
                            onClick={() => {
                              setShareprofile(true);
                            }}
                          >
                            <img src={shareGrey} />
                          </div>
                        </div>
                        <div
                          className="HistoryCardContainer"
                          key={idx.toString()}
                        >
                          <div className="HistoryCardPictureContainer">
                            <div className="UserVerificationStatus">
                              {data.isverified === "Verified" ? (
                                <div className="UserVerificationStatus_verified">
                                  <i class="fa-solid fa-circle-check"></i>{" "}
                                  {`Verified user`}
                                </div>
                              ) : data.isverified ===
                                "Aadhar verification pending" ? (
                                <div className="UserVerificationStatus_pending">
                                  <i class="fa-solid fa-circle-check"></i>{" "}
                                  {`Verified user`}
                                </div>
                              ) : data.isverified === "Yet to verify" ? (
                                <div className="UserVerificationStatus_yet">
                                  <i class="fa-solid fa-circle-check"></i>{" "}
                                  {`Verified user`}
                                </div>
                              ) : (
                                <div>
                                  <i class="fa-solid fa-circle-check"></i>{" "}
                                  {`Verified user`}
                                </div>
                              )}
                            </div>
                            <div className="HistoryCardPicture">
                              <img src={data.profilepic} />
                            </div>
                            <div
                              onClick={() => {
                                navigate("/individual", {
                                  state: {
                                    data: { profilepic: data.profilepic },
                                    profilepic: "",
                                  },
                                });
                              }}
                            >
                              View profile
                            </div>
                          </div>

                          <div className="HistoryCardDetailsContainer">
                            <div className="HistoryCardVerifiedContainer">
                              {data.isverified === "Verified" ? (
                                <span className="HistoryCardIsVerified HistoryCardVerified">
                                  <div className="HistoryVerifiedIcon">
                                    <img src={verified} />
                                  </div>
                                  <span>{data.isverified}</span>
                                </span>
                              ) : data.isverified ===
                                "Aadhar verification pending" ? (
                                <span className="HistoryCardIsVerified HistoryCardPending">
                                  <div className="HistoryVerifiedIcon">
                                    <img src={pending} />
                                  </div>
                                  <span>{data.isverified}</span>
                                </span>
                              ) : data.isverified === "Yet to verify" ? (
                                <span className="HistoryCardIsVerified HistoryCardYetToVerify">
                                  <div className="HistoryVerifiedIcon">
                                    <img src={yetToVerify} />
                                  </div>
                                  <span>{data.isverified}</span>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="HistoryCardName">{data.name}</div>
                            <div className="HistoryCardLocation HistoryCardGrey">
                              {data.location}
                            </div>
                            <div className="HistoryCardDetails">
                              <div className="HistoryCardDetailsInfoQue">
                                <div className="HistoryCardGrey HistoryCardDet">
                                  Age, Height
                                </div>
                                <div className="HistoryCardGrey HistoryCardDet">
                                  Denomination
                                </div>
                                <div className="HistoryCardGrey HistoryCardDet">
                                  Division
                                </div>
                                <div className="HistoryCardGrey HistoryCardDet">
                                  Education
                                </div>
                                <div className="HistoryCardGrey HistoryCardDet">
                                  Occupation
                                </div>
                              </div>
                              <div className="HistoryCardDetailsInfoAns">
                                <div className="HistoryCardBlack HistoryCardDet">
                                  {data.ageHeight}
                                </div>
                                <div className="HistoryCardBlack HistoryCardDet">
                                  {data.denomination}
                                </div>
                                <div className="HistoryCardBlack HistoryCardDet">
                                  {data.division}
                                </div>
                                <div className="HistoryCardBlack HistoryCardDet">
                                  {data.education}
                                </div>
                                <div className="HistoryCardBlack HistoryCardDet">
                                  {data.Occupation}
                                </div>
                              </div>
                            </div>
                            <hr className="HistoryCardHr" />
                            <div className="HistoryCardButtons">
                              <button
                                onClick={() => {
                                  setSubscribe(true);
                                }}
                              >
                                <div>
                                  <div className="HistoryChatIcon">
                                    <img src={chatIcon} />
                                  </div>
                                  <div>Chat</div>
                                </div>
                              </button>
                              {data.shortListed ? (
                                <button
                                  onClick={() => {
                                    const History = [...ShortlistedData];
                                    History[idx] = {
                                      ...History[idx],
                                      shortListed: false,
                                    };
                                    setShortlistedData(History);
                                  }}
                                >
                                  <i class="fa-solid fa-check"></i>Shortlisted
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    const history = [...ShortlistedData];
                                    history[idx] = {
                                      ...history[idx],
                                      shortListed: true,
                                    };
                                    setShortlistedData(history);
                                  }}
                                >
                                  <i className="fa-regular fa-star"></i>
                                  Shortlist
                                </button>
                              )}

                              <button
                                onClick={() => {
                                  setInterest(true);
                                }}
                              >
                                <i className="fa-solid fa-user-group"></i>Show
                                Interest
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShortlistedProfiles
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import withVerificationBackground from "../withVerificationBackground";
import "./EditMobile.css";

const EditMobile = ({}) => {
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleChangeEditMobileNumber = ({ target: { value } }) => {
    setError("");
    if (!/^[0-9]*$/.test(value)) {
      setError("Invalid Mobile No.");
    } else if (value.length !== 10) {
      setError("Must include ten numbers");
    }

    setMobile(value);
  };

  const handleSubmitEditMobileNumber = (e) => {
    try {
      e.preventDefault();
      if (!error.length && mobile.length) {
        navigate("/mobileVerification");
      } else if (!mobile.length) {
        setError("Must include ten numbers");
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
    <>
      <div className="editMobileContainer">
        <div
          className="mobileVerifiedIcon"
          onClick={() => {             
              navigate("/emailVerification");
          }}
        >
          <i class="fa-solid fa-xmark"></i>
        </div>
        <div className="editMobileHeader">Edit Mobile Number</div>
        <div className="editMobileText">
          Please change your mobile number and verify it
        </div>
        <div className="editMobileInp">
          <form onSubmit={handleSubmitEditMobileNumber}>
            <input className="editDefaultFeild" placeholder="+91" disabled />
            <input
              className="editMobileInpFeild"
              placeholder="Enter Mobile No."
              value={mobile}
              onChange={handleChangeEditMobileNumber}
            />
            <button>Update</button>
            <span className="editMobileErr">{error}</span>
          </form>
        </div>
      </div>
    </>
  );
};

export default withVerificationBackground(EditMobile);

import React from "react";
import { useNavigate } from "react-router-dom";
import nayan from "../../assets/images/Home/nayan.jpg";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import ProfileSettingsTab from "../../components/ProfileSettingsTab/ProfileSettingsTab";
import "./VerifyNewEmailID.css";
import BG from "../../assets/images/SettingImages/ChangeEmailBG.png";
import { useEffect } from "react";

function VerifyNewEmailID() {
  const navigate = useNavigate();

  useEffect(() => {
    alert("Email Sent");
  },[]);
  return (
    <div className="container-fluid">
      <div className="col-lg-12">
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="searchBoxTapBar">
              <span className="PageHeading">Email</span>
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/settingsBox");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </span>

          <div className="profileDetails_pic_ID">
            <div className="profileImg">
              <img src={nayan} />
            </div>
            <div className="profileName">{`Cathy`}</div>
            <span className="profileID">{`JM123456`}</span>
          </div>

          <div className="MobileChangeEmailContents">
            <label className="SettingsBoxLabel">Profile Settings</label>
            <h6>Verify your new Email ID</h6>
            <p>
              An Email with an activation link has been sent to your new Email
              address. Kindly activate that to update your new change.
            </p>
            <div className="ResendEmilLink">
              <p>Haven’t received the email?</p>
              <h5>Resend Email</h5>
            </div>
            <img
              src={BG}
              alt="ChangeEmailBGImage"
              className="ChangeEmailBGImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyNewEmailID;

import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";

const RegularSearchLocation = ({ RegularSearchField, handleChange }) => {
  const RegularSearchBasicInputs = [
    {
      inputType: "select",
      name: "country",
      value: RegularSearchField.country,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "state",
      value: RegularSearchField.state,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Preferred State/States", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },

    {
      inputType: "select",
      name: "city",
      value: RegularSearchField.city,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Preferred City/Cities", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="regularSearchIndividualContainer">
      <div className="regularSearchHead">Location Details</div>
      <div className="DesktopregularSearchContainerIndividualInputs">
        <div className="regularSearchLabels">
          <div className="regularSearchLabel">Country</div>
          <div className="regularSearchLabel">State</div>
          <div className="regularSearchLabel">City</div>
        </div>
        <div className="regularSearchInputs">
          {RegularSearchBasicInputs.map((input) =>
            input.inputType === "select" ? (
              <div className="partnerFieldAns">
                <SelectInputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularSearchLocation;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import withProfileBackground from "../withProfileBackground";
import { TextArea } from "@progress/kendo-react-inputs";
import "./ProfileRegister5.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";

const ProfileRegister5 = () => {
  const navigate = useNavigate();

  const [about, setAbout] = useState("");
  const max = 500;

  const handleChange = ({target:{value}}) => {
    setAbout(value);
  };

  let User_ID = sessionStorage.getItem("User_ID");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      let postData = await axios.put(
        `${BACKEND_PORT}/userDetails/BasicInformation/${User_ID}`,
        { about }
      );

      console.log(postData);
      
      navigate("/addprofilephotos");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <label className="PR_Card_Headings">
          Tell us about your basic details
        </label>
      </div>

      <div className="PR_Tab5_input_form">
        <label className="PR_Tab5_input_form_Toplabel">
          Write a brief about the person to find someone with lot of
          similarities
        </label>
        <br />
        <TextArea
          maxLength={max}
          onChange={handleChange}
          value={about}
          className="PR_Tab5_form_inputBox"
          height="500px"
        ></TextArea>
        <br />
        <label className="PR_Tab5_input_form_Bottomlabel">
          Characters Remaining :{" "}
          <span className="Char_Remaining_count_span">
            {max - about.length}
          </span>
        </label>
      </div>

      <div className="PR_Tab3_buttons">
        <input
          type={"button"}
          className="PR_Tab3_button1 PR_Form_Input_Fields"
          value={"Previous"}
          onClick={() => {
            navigate("/profileregister/EducationDetails");
          }}
        />

        <input
          type={"submit"}
          className="PR_Tab3_button2 PR_Form_Input_Fields"
          value={"Finish"}
          // onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default withProfileBackground(ProfileRegister5, "PR5");

import React from "react";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../../components/Auth/AuthHeader";
import withAuthBackground from "../withAuthBackground";
import "./AccountCreationSuccess.css";

const Resetpassword = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      <div className="row">
        <div className="successFormContiner">
          <div className="SuccessHeader">
            Your account has been created successfully !
          </div>
          <div className="successButton">
            <button
              onClick={() => {
                navigate("/login");
              }}
            >
              Login to your account
            </button>
          </div>

          <div className="resetFull"></div>
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(Resetpassword, "register");

import React from "react";
import { useNavigate } from "react-router-dom";

import homeProfilePic2 from "../../../../assets/images/Home/homeProfilePic2.png";
import "./HomeNotificationbox.css";

const HomeNotificationBox = () => {
  const navigate=useNavigate();
  const notificationsArray = [
    {
        image: homeProfilePic2,
      content: "Thomas Vijayaraj viewed your profile",
      time: "6 hours ago",
    },
    {
        image: homeProfilePic2,
      content: "Thomas Vijayaraj showed interest in your profile",
      time: "6 hours ago",
    },
  ];
  return (
    <div className="homeNotificationContainer">
      <div className="homeNotificationTitle">
        <div>Notifications</div>
        <div onClick={() => {navigate("/notifications")}}>View all</div>
      </div>
      <div className="homeNotifications">
        {notificationsArray.map((notify,idx) => (
          <div className="homeNotification" key={idx} >
            <div className="homeNotificationImage">
              <img src={notify.image} />
              </div>
            <div className="homeNotificationContent">
              <div>{notify.content}</div>
              <div>{notify.time}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeNotificationBox;

import React from "react";
import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";

const FilterFamilyDetails = ({ filterId, setFilterId, filterInputs }) => {
  const familyInputs = [
    {
      label: "Family Value",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "traditional", label: "Traditional" },
        {
          name: "employed",
          value: "moderate",
          label: "Moderate",
        },
        {
          name: "employed",
          value: "liberal",
          label: "Liberal",
        },
        {
          name: "employed",
          value: "orthodox",
          label: "Orthodox",
        },
      ],
    },
    {
      label: "Family Status",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "rich", label: "Rich" },
        {
          name: "employed",
          value: "middle",
          label: "Middle Class",
        },
        {
          name: "employed",
          value: "upperMiddle",
          label: "Upper Middle Class",
        },
        {
          name: "employed",
          value: "affluent",
          label: "Affluent",
        },
      ],
    },
    {
      label: "Family type",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "nuclear", label: "Nuclear" },
        { name: "employed", value: "joint", label: "Joint" },
      ],
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("familyDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Family Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("familyDetails")
              ? setFilterId(filterId.filter((e) => e !== "familyDetails"))
              : setFilterId([...filterId, "familyDetails"]);
          }}
        >
          {filterId.includes("familyDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("familyDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {familyInputs.map((input) => (
            <div className="dropDownOption">
              <div className="dropDownComponentTitle">{input.label}</div>
              <div className={`ProfileTypeCheckBoxsList`}>
                {input.checkInputs.map((inp) => (
                  <CheckBoxInputField input={inp} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterFamilyDetails;

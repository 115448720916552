import React from "react";
import LandingHeader from "../../components/Headers/Landing header/LandingHeader";
import "./withProfileBackground.css";

const withProfileBackground = (WrappedComponent, Page) => {
  return class extends React.Component {
    render() {
      return (
        <div className="container-fluid PR_container">
          <div className="col-lg-12">
            <div className="row">
              <LandingHeader isLogin={true} />
            </div>

            <div className="PR_Background1"></div>
            <div className="row PR_background">
              {/*  */}
              <div className="card PR_card">
                <div className="PRTabs">
                  <div className="PR_Page_Tabs_column">
                    <div
                      to="#"
                      className={`row PR_Page_Tabs ${
                        Page === "PR1" ? "active" : ""
                      }`}
                    >
                      01
                    </div>
                    <div
                      to="#"
                      className={`row PR_Page_Tabs ${
                        Page === "PR2" ? "active" : ""
                      }`}
                    >
                      02
                    </div>
                    <div
                      to="#"
                      className={`row PR_Page_Tabs ${
                        Page === "PR3" ? "active" : ""
                      }`}
                    >
                      03
                    </div>
                    <div
                      to="#"
                      className={`row PR_Page_Tabs ${
                        Page === "PR4" ? "active" : ""
                      }`}
                    >
                      04
                    </div>
                    <div
                      to="#"
                      className={`row PR_Page_Tabs ${
                        Page === "PR5" ? "active" : ""
                      }`}
                    >
                      05
                    </div>
                  </div>
                  
                  <div className="PR_card_tab1_form">
                    <WrappedComponent />
                  </div>

                  <div className="Profile_register_page_card_img"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
};

export default withProfileBackground;

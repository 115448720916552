import React, { useEffect } from "react";
import "../Block/BlockPopUp.css";

const BlockPopUp = ({ block, setBlock }) => {
  useEffect(() => {
    block
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [block]);
  return (
    <>
      <div className="BlockPopUp">
        <div className="BlockPopupContent">
        <div
          className="BlockPopUp-Close"
          onClick={() => {
            document.getElementById("body").style.overflowY = "auto";
            setBlock(false);
          }}
        >
          <i class="fa-solid fa-xmark"></i>
        </div>

        <div className="BlockPopUp-Box">
          <div className="BlockPopUp-Header">
            <p> Are you sure your want to block this Profile !!</p>
          </div>

          <div className="BlockPopUp-Context">
            <p> You will no longer be connected (if you were before)</p>
            <p>
              {" "}
              You will also no longer see any suggestions to interact with each
              other.
            </p>
          </div>

          <div className="BlockPopUp-Buttons">
            <div className="BlockPopUp-Button-1">
              <button
                onClick={() => {
                  document.getElementById("body").style.overflowY = "auto";
                  setBlock(false);
                }}
              >
                {" "}
                Go back{" "}
              </button>
            </div>
            <div className="BlockPopUp-Button-2">
              <button> Block</button>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    </>
  );
};

export default BlockPopUp;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import withVerificationBackground from "../withVerificationBackground";
import "./EditEmail.css";
import axios from "axios";

const EditEmail = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  
  const {
    state: { UserID },
  } = useLocation();

  const navigate = useNavigate();

  const handleChange = ({ target: { value } }) => {
    setError("");
    if (!value.length) {
      setError("Enter Email ID");
    } else if (!value.includes("@") || !value.includes(".com")) {
      setError("Invalid Email ID");
    }
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (email.length && !error.length) {
        // let resendEmail = await axios.post(``)
        navigate("/emailVerification", {
          state: {
            UserID: UserID,
            email: email
          }
        });
      } else if (!email.length) {
        setError("Enter Email ID");
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <div className="editEmailContainer">
        <div className="registeredheader">
          <p>You have successfully registered with Joy Matrimony !</p>
          {/* <p>
            Your Matrimony ID is <span className="matrimonyID">{UserID}</span>
          </p> */}
          <hr className="MobileverifyHrTag" />
        </div>
        <div className="editEmaileHeader">
          Didn’t receive an email or want to change?
        </div>
        <div className="editEmailtext">
          It may take a few minutes for the email to reach your inbox or may end
          up in your spam folder. Still nothing? Re.enter your email and try
          again.
        </div>
        <div className="editEmailInp">
          <form onSubmit={handleSubmit}>
            <input
              className="editEmailInpFeild"
              value={email}
              onChange={handleChange}
              placeholder="Enter Email ID"
            />
            <button>Send new verification Link</button>
            <span className="editEmailErr">{error}</span>
          </form>
        </div>
        <div className="emailContactLink">
          Still not seeing it?{" "}
          <span
            onClick={() => {
              navigate("/contactUs");
            }}
          >
            {" "}
            Contact Support
          </span>
        </div>
      </div>
    </>
  );
};

export default withVerificationBackground(EditEmail);

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import withAuthBackground from "../withAuthBackground";
import AuthHeader from "../../../components/Auth/AuthHeader";
import FormInputs from "../../../components/Auth/Form input/FormInputs";
import "./PasswordChange.css";
import { BACKEND_PORT } from "../../../App";
import axios from "axios";

const Resetpassword = () => {
  // form feild's state
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
    errors: {
      password: "",
      confirmPassword: "",
    },
  });

  const [error, setError] = useState("");

  // state to handle password eye icon
  const [passwordEye, setPasswordEye] = useState({
    password: true,
    confirmPassword: true,
  });

  // password checkings
  const [passwordChecking, setPasswordChecking] = useState({
    case1: false,
    case2: false,
    case3: false,
    case4: false,
  });

  // pop up if the password is changed successfully
  const [isPassswordChanged, setIsPasswordChanged] = useState(false);

  const navigate = useNavigate();

  // reset form inputs
  const resetInputs = [
    {
      name: "password",
      type: passwordEye.password ? "password" : "text",
      placeholder: "Password",
      classname: "regPassword",
      value: credentials.password,
    },
    {
      name: "confirmPassword",
      type: passwordEye.confirmPassword ? "password" : "text",
      placeholder: "Confrim Password",
      classname: "regPassword",
      value: credentials.confirmPassword,
    },
  ];

  // handling password type
  const handlePassword = (name) => {
    name === "password"
      ? setPasswordEye({ ...passwordEye, password: !passwordEye.password })
      : setPasswordEye({
          ...passwordEye,
          confirmPassword: !passwordEye.confirmPassword,
        });
  };

  // form validations and form inputs
  const handleChange = ({ target: { name, value } }) => {
    const errors = credentials.errors;

    switch (name) {
      case "password": {
        value.length < 8
          ? setPasswordChecking((prevState) => ({ ...prevState, case1: false }))
          : setPasswordChecking((prevState) => ({ ...prevState, case1: true }));

        /(?=.*?[A-Z])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case2: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case2: false,
            }));

        /(?=.*?[0-9])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case3: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case3: false,
            }));

        /(?=.*?[#$&@])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case4: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case4: false,
            }));
      }

      case "confirmPassword": {
        errors.confirmPassword =
          value !== credentials.password ? "Password mismatch" : setError("");
        break;
      }
    }

    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      passwordChecking.case1 &&
      passwordChecking.case2 &&
      passwordChecking.case3 &&
      passwordChecking.case4
    ) {
      if (!credentials.errors.confirmPassword) {
        const {password, confirmPassword} = credentials;

        let Resetpassword = axios.put(`${BACKEND_PORT}/forgotPassword/reset`, {
          password, confirmPassword
        });

        console.log(Resetpassword);
        setIsPasswordChanged(true)
      } else {
        setError(credentials.errors.confirmPassword);
      }
    }
  };

  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      <div className="row">
        <div className="resetFormContiner">
          {isPassswordChanged ? (
            <>
              <div className="passwrdSuccessFull">
                Your password has been reset successfully !
              </div>
              <div
                className="loginButton"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <button>Login to your account</button>
              </div>
              <div className="resetFull"></div>
            </>
          ) : (
            <>
              <div className="resetHeader">Reset Password</div>
              <div className="resetErrors">{error}</div>
              <form className="resetForm" onSubmit={handleSubmit}>
                {resetInputs.map((inp) => (
                  <FormInputs
                    key={inp.label}
                    name={inp.name}
                    type={inp.type}
                    value={inp.value}
                    placeholder={inp.placeholder}
                    handleChange={handleChange}
                    setPasswordEye={setPasswordEye}
                    passwordEye={passwordEye}
                    classname={inp.classname}
                    handlePassword={handlePassword}
                    passwordChecking={passwordChecking}
                  />
                ))}

                <div className="resetButton">
                  <button type="submit">Submit</button>
                </div>
              </form>
              <div className="resetFull"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(Resetpassword, "reset");

import React from "react";

const InputField = ({ input, className, handleChange }) => {
  return (
    <input
      type="text"
      name={input.name}
      value={input.value}
      onChange={handleChange}
      className={className}
    />
  );
};

export default InputField;

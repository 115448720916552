import React from "react";
import "./Missing_Details_BlueCard.css";
import right_rose from "../../../assets/images/EditProfilePage/right_rose.png";
import left_rose from "../../../assets/images/EditProfilePage/left_rose.png";
import { useState, useEffect } from "react";

const Missing_Details_BlueCard = ({
  missingCardDet,
  handleSave,
  handleChange,
  ProfileDetails,
}) => {
  const [count, setCount] = useState(0);
  const [filterCards, setFilterCards] = useState([]);

  useEffect(() => {
    let keySet = new Set(missingCardDet.map((obj) => obj.name));

    let missingCards_MatchingArray = [
      {
        name: "Education",
        title: "Share Cathy’s Academic details for better match search",
        placeholder: "Enter the maximum Educational Qualification",
        value: ProfileDetails.Education,
      },
      {
        name: "Occupation",
        title: "Share Cathy’s professional details",
        placeholder: "Enter the professional details",
        value: ProfileDetails.Education,
      },
      {
        name: "FathersOccupation",
        title: "Share Cathy’s Father’s Occupation",
        placeholder: "Enter here",
        value: ProfileDetails.FathersOccupation,
      },
      {
        name: "MothersOccupation",
        title: "Share Cathy’s Mother’s Occupation",
        placeholder: "Enter here",
        value: ProfileDetails.MothersOccupation,
      },
      {
        title: "Share Cathy’s Sibling Details",
        name: "NoOfBrothers",
        placeholder: "Select",
        subTitle: "No of Brothers",
        value: ProfileDetails.NoOfBrothers,
      },
      {
        title: "Share Cathy’s Sibling Details",
        name: "BrothersMarried",
        placeholder: "Select",
        subTitle: "No of Brothers Married",
        value: ProfileDetails.BrothersMarried,
      },
      {
        title: "Share Cathy’s Sibling Details",
        name: "NoOfSisters",
        placeholder: "Select",
        subTitle: "No of Sisters",
        value: ProfileDetails.NoOfSisters,
      },
      {
        title: "Share Cathy’s Sibling Details",
        name: "SistersMarried",
        placeholder: "Select",
        subTitle: "No of Sisters Married",
        value: ProfileDetails.SistersMarried,
      },
    ].filter((obj) => keySet.has(obj.name));
    setFilterCards(missingCards_MatchingArray);
  }, []);

  console.log(filterCards);

  const handleClick = (e) => {
    e.preventDefault();
    if (count === filterCards.length - 1) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
    // handleSave("cancel");
  };

  console.log(ProfileDetails.FathersOccupation)

  return (
    <div className="Missing_Details_BlueCard">
      <div className="card_rose_pics">
        <img
          src={left_rose}
          alt="card_rose_left_pic"
          className="card_rose_left_pic"
        />
        <img
          src={right_rose}
          alt="card_rose_right_pic"
          className="card_rose_right_pic"
        />
      </div>
      <form>
        <div className="Missing_Details_contents">
          <p>{filterCards[count]?.title}</p>
          {filterCards[count]?.title === "Share Cathy’s Sibling Details" && (
            <span className="SubTitlePara">
              {filterCards[count].subTitle}
            </span>
          )}
          <input
            type={"text"}
            placeholder={filterCards[count]?.placeholder}
            className="textbox"
            name={filterCards[count]?.name}
            value={filterCards[count]?.value}
            onChange={handleChange}
          />
          <div className="buttons">
            <button className="skip_button" onClick={handleClick}>
              Skip
            </button>
            <button
              className="submit_button"
              onClick={() => {
                handleSave("save");
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Missing_Details_BlueCard;

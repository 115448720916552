import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PersonalDetails_BridesLocation.css";

const PersonalDetails_BridesLocation = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
  setProfileDetails,
}) => {
  const PersonalDetails_BridesLocation = [
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Country",
      value: ProfileDetails.Country,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Afghanistan ", disabled: false },
        { value: "Canada ", disabled: false },
        { value: "Denmark ", disabled: false },
        { value: "India  ", disabled: false },
        { value: "Indonesia  ", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "State",
      value: ProfileDetails.State,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Andhra Pradesh", disabled: false },
        { value: "Assam", disabled: false },
        { value: "Tamilnadu", disabled: false },
        { value: "Kerala  ", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "City",
      value: ProfileDetails.City,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Coimbatore ", disabled: false },
        { value: "Chennai", disabled: false },
        { value: "Madurai ", disabled: false },
        { value: "Kanchipuram", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Citizenship",
      value: ProfileDetails.Citizenship,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Indian ", disabled: false },
        { value: "Gill ", disabled: false },
        { value: "Khokhar ", disabled: false },
        { value: "Others  ", disabled: false },
      ],
    },
  ];

  return (
    <div
      className={`${
        editProcess === "BridesLocation"
          ? "EditTimePersonalDetails_BridesLocation_Card"
          : "PersonalDetails_BridesLocation"
      } `}
    >
      <div className="fewWordBoxHeader">
        <h6>Bride’s Location</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("BridesLocation");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "BridesLocation" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
      </div>
      <div className="card PersonalDetails_card">
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("BridesLocation");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "BridesLocation" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
        <div
          className={`${
            editProcess === "BridesLocation"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          <div className="Details_section">
            <ul
              className={`Details_section_label ${
                editProcess === "BridesLocation"
                  ? "Details_section_label_EditTime"
                  : ""
              }`}
            >
              <li>Country</li>
              <li>State</li>
              <li>City</li>
              <li>Citizenship</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "BridesLocation"
                  ? "Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
            </ul>

            {editProcess === "BridesLocation" ? (
              <>
                <ul className="Details_section_values">
                  {PersonalDetails_BridesLocation.map((input) =>
                    input.inputType === "radio" ? (
                      <div className="partnerFieldAns partnerPreferenceRadioFields">
                        <RadioInputField
                          input={input}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "select" ? (
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "text" ? (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    ) : (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    )
                  )}
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails.Country === "" ||
                    ProfileDetails.Country === undefined ||
                    ProfileDetails.Country === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BridesLocation");
                        }}
                      >
                        {`Add Country`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Country
                    )}
                  </li>
                  <li>
                    {ProfileDetails.State === "" ||
                    ProfileDetails.State === undefined ||
                    ProfileDetails.State === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BridesLocation");
                        }}
                      >
                        {`Add State`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.State
                    )}
                  </li>
                  <li>
                    {ProfileDetails.City === "" ||
                    ProfileDetails.City === undefined ||
                    ProfileDetails.City === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BridesLocation");
                        }}
                      >
                        {`Add City`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.City
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Citizenship === "" ||
                    ProfileDetails.Citizenship === undefined ||
                    ProfileDetails.Citizenship === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("BridesLocation");
                        }}
                      >
                        {`Add Citizenship`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Citizenship
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "BridesLocation" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_BridesLocation;

import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import RadioInputField from "../../PartnerPreference/InputFields/RadioInputField";
import InputField from "../../PartnerPreference/InputFields/InputField";

const RegularSearchReligiousDetails = ({RegularSearchField, handleChange}) => {
  // basic inputs
  // partnerPreferenceMediumOption selectFielBox
  const RegularSearchReligiousInputs = [
    {
      inputType: "select",
      name: "denomination",
      value: RegularSearchField.denomination,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "division",
      value: RegularSearchField.division,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "subCaste",
      value: RegularSearchField.subCaste,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="regularSearchIndividualContainer">
      <div className="regularSearchHead">Religious Details</div>
      <div className="regularSearchContainerIndividualInputs">
        <div className="regularSearchLabels">
          <div className="DenominationEditTime">Denomination</div>
          <div className="DivisionEditTime">Division</div>
          <div className="subCasteEditTime">Sub caste</div>
        </div>
        <div className="regularSearchInputs">
          {RegularSearchReligiousInputs.map((input) =>
            input.inputType === "radio" ? (
              <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                <RadioInputField
                  input={input}
                  handleChange={handleChange}
                  inputFields={RegularSearchField}
                  className={`EditTimeRadioflexWrap`}
                />
              </div>
            ) : input.inputType === "select" ? (
              <div className="partnerFieldAns">
                <SelectInputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
              </div>
            ) : input.inputType === "range" ? (
              <div className="partnerFieldRange partnerFieldAns">
                <SelectInputField
                  input={input.field[0]}
                  className={input.className}
                  handleChange={handleChange}
                />
                <div> - </div>
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input.field[1]}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
                <span className="Details_section_values_rightLabel">
                  {input.name === "age" && <div>Years</div>}
                </span>
              </div>
            ) : (
              <div className="partnerFieldAns">
                <InputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
                <span className="Details_section_values_rightLabel">
                  {input.FieldLabel}
                </span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularSearchReligiousDetails;

import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PersonalDetails_ReligionInformation.css";

const PersonalDetails_ReligionInformation = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
}) => {
  const PersonalDetails_ReligionInformation = [
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Denomination",
      value: ProfileDetails.Denomination,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Protestant ", disabled: false },
        { value: "Catholic ", disabled: false },
        { value: "Orthodox ", disabled: false },
        { value: "Others  ", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Division",
      value: ProfileDetails.Division,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Protestant ", disabled: false },
        { value: "Catholic ", disabled: false },
        { value: "Orthodox ", disabled: false },
        { value: "Others  ", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Subcaste",
      value: ProfileDetails.Subcaste,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Sahotra ", disabled: false },
        { value: "Gill ", disabled: false },
        { value: "Khokhar ", disabled: false },
        { value: "Others  ", disabled: false },
      ],
    },
  ];

  return (
    <div
    className={`${
        editProcess === "ReligionInformation"
          ? "EditTimePersonalDetails_ReligionInformation_Card"
          : "PersonalDetails_ReligionInformation"
      }`}
      id="ReligionInformation"
    >
      <div className="fewWordBoxHeader">
        <h6>Religion Information</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("ReligionInformation");
          }}
        >
          {editProcess === "ReligionInformation" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>
              <i class="fa-solid fa-pen"></i>
              Edit
            </>
          )}
        </button>
      </div>

      <div className="card PersonalDetails_card">
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("ReligionInformation");
          }}
        >
          {editProcess === "ReligionInformation" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>
              <i class="fa-solid fa-pen"></i>
              Edit
            </>
          )}
        </button>
        <div
          className={`${
            editProcess === "ReligionInformation"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          <div className="Details_section">
            <ul
              className={`Details_section_label ${
                editProcess === "ReligionInformation"
                  ? "Details_section_label_EditTime"
                  : ""
              }`}
            >
              <li>Denomination</li>
              <li>Division</li>
              <li>Sub caste</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "ReligionInformation"
                  ? "Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li>:</li>
              <li>:</li>
              <li>:</li>
            </ul>

            {editProcess === "ReligionInformation" ? (
              <>
                <ul className="Details_section_values">
                  {PersonalDetails_ReligionInformation.map((input) =>
                    input.inputType === "radio" ? (
                      <div className="partnerFieldAns partnerPreferenceRadioFields">
                        <RadioInputField
                          input={input}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "select" ? (
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "text" ? (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    ) : (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    )
                  )}
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails.Denomination === "" || ProfileDetails.Denomination === undefined ||
                    ProfileDetails.Denomination === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ReligionInformation");
                        }}
                      >
                        {`Add Denomination`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Denomination
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Division === "" || ProfileDetails.Division === undefined ||
                    ProfileDetails.Division === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ReligionInformation");
                        }}
                      >
                        {`Add Division`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Division
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Subcaste === "" || ProfileDetails.Subcaste === undefined ||
                    ProfileDetails.Subcaste === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ReligionInformation");
                        }}
                      >
                        {`Add Subcaste`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Subcaste
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "ReligionInformation" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_ReligionInformation;

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import withProfileBackground from "../withProfileBackground";
import "./ProfileRegister4.css";
import { BACKEND_PORT } from "../../../App";

const ProfileRegister4 = () => {
  const navigate = useNavigate();

  // Common Error validation
  const [error, setError] = useState("");

  // Form Field's state
  const [PrInputs, setPrInputs] = useState({
    education: "",
    employeed_in: "",
    occupation: "",
    annual_income: "",
    work_location: "",
    errors: {
      education: "",
      employeed_in: "",
      occupation: "",
      annual_income: "",
      work_location: "",
    },
    touched: {
      education: false,
      employeed_in: false,
      occupation: false,
      annual_income: false,
      work_location: false,
    },
  });

  // Handle validations and Form input
  const handleChange = ({ target: { name, value } }) => {
    const error = PrInputs.errors;
    setError("");
    switch (name) {
      case "education": {
        error.education = !value ? "Please select the highest education" : "";
        break;
      }
      case "employeed_in": {
        error.employeed_in = !value ? "Please select the above field" : "";
        break;
      }
      case "occupation": {
        error.occupation = !value ? "Please select the occupation" : "";
        break;
      }
      case "annual_income": {
        error.annual_income = !value ? "Please select the annual income" : "";
        break;
      }
      case "work_location": {
        error.work_location = !value ? "Please select the work location" : "";
        break;
      }
      default:
        break;
    }
    setPrInputs({ ...PrInputs, [name]: value }, error);
  };

  // Handle input blur validations
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...PrInputs.touched, [name]: true };
    setPrInputs({ ...PrInputs, touched });
  };

  // Backend validations and Error handling
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = PrInputs.errors;

      // eslint-disable-next-line array-callback-return
      Object.values(PrInputs).map((inp, idx) => {
        if (
          (idx <= 2 && inp === "") ||
          (idx <= 2 && inp === PRTab4SelectInput[idx].options[0].value)
        ) {
          errors[PRTab4SelectInput[idx].name] = ` ${
            PRTab4SelectInput[idx].name === "education"
              ? "Please select the highest education"
              : PRTab4SelectInput[idx].name === "employeed_in"
              ? "Please select the above feild"
              : PRTab4SelectInput[idx].name === "occupation"
              ? "Please select the occupation"
              : ""
          }`;

          setPrInputs({ ...PrInputs, errors });
        }
      });

      const errorCount = Object.values(PrInputs.errors).filter(
        (err) => err !== ""
      ).length;

      const notTouched = Object.values(PrInputs.touched).filter((en) => !en);

      if (!error.length && !notTouched.length && errorCount === 0) {
        const {
          education,
          employeed_in,
          occupation,
          work_location,
          annual_income,
        } = PrInputs;

        let User_ID = sessionStorage.getItem("User_ID");

        let postData = await axios.put(
          `${BACKEND_PORT}/userDetails/EducationDetails/${User_ID}`,
          {
            education,
            employeed_in,
            occupation,
            work_location,
            annual_income,
          }
        );

        console.log(postData);

        navigate("/profileregister/BasicInformation");

      } else if (
        !PrInputs.annual_income.length &&
        !PrInputs.work_location.length
      ) {
        errors.annual_income = "Please select the annual income";
        errors.work_location = "Please select the work location";
      } else if (!PrInputs.annual_income.length) {
        errors.annual_income = "Please select the annual income";
      } else if (!PrInputs.work_location.length) {
        errors.work_location = "Please select the work location";
      }
      setPrInputs({ ...PrInputs, errors });
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(PrInputs.touched);

  const [PRTab4SelectInput, setPRTab3SelectInput] = useState([
    {
      name: "education",
      options: [
        { value: "Highest Education", disabled: true, selected: true },
        { value: "Single", disabled: false },
        { value: "Married", disabled: false },
        { value: "Divorce", disabled: false },
        { value: "Widow", disabled: false },
      ],
      error: "",
    },
    {
      name: "employeed_in",
      options: [
        { value: "Employed in", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      error: "",
    },
    {
      name: "occupation",
      options: [
        { value: "occupation", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      error: "",
    },
  ]);
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <label className="PR_Card_Headings">
          Tell us about your basic details
        </label>
      </div>

      <label className="All_input_Field_error">{error}</label>

      <div className="PR_Input_fields_Tab2">
        {PRTab4SelectInput.map((input) => {
          return (
            <>
              <select
                className={`PR_FROM_Tabs_selectBox PR_Form_Tab4_Input_Fields
                ${
                  PrInputs[input.name] === input.options[0].value ||
                  PrInputs[input.name] === ""
                    ? "PR_FORM_Tab1_Select_option_Grey"
                    : "PR_FORM_Tab1_Select_option_Black"
                }`}
                name={input.name}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {input.options.map((inp) => {
                  return (
                    <option
                      value={inp.value}
                      disabled={inp.disabled}
                      selected={inp.selected}
                    >
                      {inp.value}
                    </option>
                  );
                })}
              </select>
              <label className="PR_Page_Tab4_inputs_validation_Label PR_Page_Tab4_inputs_validation_Label4">
                {PrInputs.errors[input.name]}
              </label>
            </>
          );
        })}

        <input
          type={"number"}
          className="form-control PR_Form_Tab4_Input_Fields"
          placeholder="Annual income"
          name="annual_income"
          min={0}
          value={PrInputs.annual_income}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label className="PR_Page_Tab4_inputs_validation_Label">
          {PrInputs.errors.annual_income}
        </label>

        <input
          type={"text"}
          className="form-control PR_Form_Tab4_Input_Fields"
          placeholder="Work Location"
          name="work_location"
          value={PrInputs.work_location}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label className="PR_Page_Tab4_inputs_validation_Label">
          {PrInputs.errors.work_location}
        </label>

        <div className="PR_Tab3_buttons">
          <input
            type={"button"}
            className="PR_Tab3_button1 PR_Form_Input_Fields"
            value={"Previous"}
            onClick={() => {
              navigate("/profileregister/PersonalDetails");
            }}
          />

          <input
            type={"submit"}
            className="PR_Tab3_button2 PR_Form_Input_Fields"
            value={"Next"}
          />
        </div>
      </div>
    </form>
  );
};

export default withProfileBackground(ProfileRegister4, "PR4");

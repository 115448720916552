import React from 'react';
import { useNavigate } from 'react-router-dom';

import metaVideo from "../../assets/videos/footer/metaVideo.mp4";
import "./Footer.css";

const Footer = () => {
    const Navigate=useNavigate()
    return (
      <div className="footer">
        <div className="footerHead">
          <div className="footerHead1">JOY CHRISTIAN MATRIMONY</div>
          <div className="footerHead2">
            Adorable Matching...Affordable Pricing...
          </div>
        </div>

        <div className="col-lg-12 footerBody">
          <div className="row footer-row">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <video className="metaVideo" controls autoPlay muted>
                <source type={metaVideo} />
              </video>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 subHeaderFooter">
              <div className="footerSubHeader"> Help & Support</div>
              <div className="footerLink"> 24*7 Live chat</div>
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/contactus");
                }}
              >
                Contact Us
              </div>
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/FAQ");
                }}
              >
                FAQs
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 subHeaderFooter">
              <div className="footerSubHeader">Quick Links</div>
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/testimonials");
                }}
              >
                Testmonials
              </div>
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/privacyPolicy");
                }}
              >
                Privacy Policy
              </div>
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/termsAndConditions");
                }}
              >
                Terms & Conditions
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 footerNews">
              <div className="footerSubHeader">Newsletter</div>
              <div className="footerLink">
                Be the first to know about exciting offers and much more.
              </div>
              <input
                className="landingSubMail"
                placeholder="Enter your email ID"
              />
              <button className="landingSubButton">Subscribe</button>
            </div>

            <div className="footerLinks_mobileView">
              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/termsAndConditions");
                }}
              >
                Terms & Conditions
              </div>

              <div
                className="footerLink"
                onClick={() => {
                  Navigate("/privacyPolicy");
                }}
              >
                Privacy Policy
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./Activity.css";

const Activity = () => {
  const [activityID, setActivityID] = useState(false);

  const activityHeader = [
    { header: "Shortlisted By You", count: "5", id: "shortlistedByYou",link:"/matches/shortlistedByYou" },
    { header: "Who Viewed You", count: "4", id: "viewedYou",link:"/matches/viewedYou" },
    { header: "Who Shortlisted You", count: "7", id: "shortlistedYou",link:"/matches/shortlistedYou" },
    { header: "Blocked Profiles", count: "2", id: "blockedProfiles",link:"/matches/blockedProfiles" },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();

  //   to highlight the select header, get the pathname from the location
  useEffect(() => {
    // window.location.reload();
    setActivityID(pathname.slice(9));
  }, [pathname]);

  return (
    <div className="matchesFilterBoxContainer">
      <div className="matchesFilterTitle">Activity</div>
      <form action="">
        <div className="activityHeaders">
          {activityHeader.map(({ header, count, id, link }) => {
            return (
              <>
                <div
                  className={`activityHeader ${
                    activityID === id && "activityPink"
                  }`}
                  id={id}
                  key={id}
                  onClick={() => {navigate(link,{state:{header1:header,activity:true}})}}
                >
                  <div>{header}</div>
                  <div>({count})</div>
                </div>
              </>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default Activity;

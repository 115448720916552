import React from "react";
import { useNavigate } from "react-router-dom";

import discoverMathes from "../../../assets/images/Home/discoverMatches.png";
import homeProfilePic1 from "../../../assets/images/Home/homeProfilePic1.png";
import homeProfilePic2 from "../../../assets/images/Home/homeProfilePic2.png";
import homeProfilePic3 from "../../../assets/images/Home/homeProfilePic3.png";
import Rectangle1 from "../../../assets/images/Home/Rectangle1.png";
import Rectangle2 from "../../../assets/images/Home/Rectangle2.png";
import Rectangle3 from "../../../assets/images/Home/Rectangle3.png";
import "./DiscoverMatchesCard.css";

const DiscoverMatchesCard = () => {
  const discoverMatches = [
    { cardImg: Rectangle1, matchType: "City", matchesNumber: "950" },
    { cardImg: Rectangle2, matchType: "Profession", matchesNumber: "432" },
    { cardImg: Rectangle3, matchType: "Education", matchesNumber: "653" },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div className="homeMatchesContainerBox">
        <div>
          <h4 className="homeMatchesHeader1">Discover Matches</h4>
          <div className="homeMatchesHeader2">
            Explore profiles from different categories that matches your
            preferences
          </div>
          <div className="homeMatchesProfile">
            <div className="col-lg-12">
              <div className="row">
                <div className="MobileDiscoverMatches">
                  {discoverMatches.map((match) => {
                    return (
                      <div className="MobileDiscoverMatchCards">
                        <img
                          src={match.cardImg}
                          onClick={() => {
                            navigate("/matches/discoverMatches", {
                              state: {
                                header1: "Discover Matches",
                                header2:
                                  "Explore profiles from different categories that matches your preferences",
                              },
                            });
                          }}
                        />
                        <div className="MobileDiscoverMatchCardContent">
                          <h5>{match.matchType}</h5>
                          <p>{match.matchesNumber} matches</p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="discoverMatches">
                  {discoverMatches.map((match) => (
                    <>
                      <div
                        className="col-lg-4 discoverMatchesImage"
                        key={match.matchesNumber}
                      >
                        <div className="discoverMatchesImage">
                          <img src={discoverMathes} />
                        </div>
                        <div className="discoverMatchesContents">
                          <div className="dicoverMatchesTitle discoverMatchesContent">
                            {match.matchType}
                          </div>
                          <div className="dicoverMatchesCount discoverMatchesContent">
                            {match.matchesNumber} Matches
                          </div>
                          <div className="discoverMatchesCardImages">
                            <img src={homeProfilePic1} />
                            <img src={homeProfilePic2} />
                            <img src={homeProfilePic3} />
                          </div>
                          <div
                            className="discoverMatchesSeeAll"
                            onClick={() => {
                              navigate("/matches/discoverMatches", {
                                state: {
                                  header1: "Discover Matches",
                                  header2:
                                    "Explore profiles from different categories that matches your preferences",
                                },
                              });
                            }}
                          >
                            See All
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverMatchesCard;

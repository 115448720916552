import React from "react";
import { useNavigate } from "react-router-dom";

const AuthHeader = ({form}) => {
  const navigate = useNavigate();

  return (
    <>
      {/* container header */}
      <div className="loginHeader">
        <div
          className="loginPageLink"
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </div>
        <div
          className="RegPageLink"
          onClick={() => {
            navigate("/register");
          }}
        >
          Register
        </div>
        <div className="authBorder">
          <div className={`${form === "login" ? "authBorderLoginPink" : "authBorderRegPink"}`}></div>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;

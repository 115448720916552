import React from "react";
import LandingPage from "../landing/LandingPage";
import authPageImage from '../../assets/images/Auth/authImg.png'

const withAuthBackground = (WrappedComponent, formName) => {
  return class extends React.Component {
    render() {
      return (
        <>
          {/* Desktop LoginPage */}
          <div className="auth container-fluid">
            <LandingPage isBlack={true} />
            <div className={`${formName}Container col-lg-12`}>
              <div className="row loginRow">
                {/* image conatiner */}
                <div className="authImageContainer col-lg-4"></div>
                <div className="authContentConainer col-lg-8">
                  <WrappedComponent />
                </div>
              </div>
            </div>
          </div>

          {/* MobileScreen LoginPage */}
          <div className="MobileAuth">
            <LandingPage isBlack={true} />
            <div
              className={` ${formName}MobileContainer sendCodeMobileContainer`}
            >
              <div className="authImageContainer">
                <img
                  src={authPageImage}
                  alt="authPageImage"
                  className="authPageImage"
                />
              </div>
              <div className="authContentConainer">
                <WrappedComponent />
              </div>
            </div>
          </div>
        </>
      );
    }
  };
};

export default withAuthBackground;


{/* <div className={`${formName}MobileContainer`}>
  <div className="MobileloginRow">
    <div className="authImageContainer"></div>
    <div className="authContentConainer">
      <WrappedComponent />
    </div>
  </div>
</div> */}
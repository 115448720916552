import React from "react";
import { useState } from "react";
import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import "./FilterReligiousDetails.css";

const FilterReligiousDetails = ({ filterId, setFilterId, filterInputs, handleChange }) => {
  const [showAll, setShowAll] = useState(false);

  const [checkSubscribe, setCheckSubscribe] = useState(false);

  // religious input fields
  const religiousInputs = [
    {
      label: "Denomination",
      inputType: "option",
      name: "denomination",
      value: filterInputs.denomination,
      className:
        "partnerPreferenceMediumOption selectFielBox MobilePartnerPreferenceMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      label: "Division",
      inputType: "option",
      name: "division",
      value: filterInputs.division,
      className:
        "partnerPreferenceMediumOption selectFielBox MobilePartnerPreferenceMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      label: "Star",
      inputType: "checkBox",
      checkInputs: [
        { name: "Star", value: "Ashwini", label: "Ashwini" },
        { name: "Star", value: "Bharani", label: "Bharani" },
        { name: "Star", value: "Krittika", label: "Krittika" },
        { name: "Star", value: "Rohini", label: "Rohini" },
        { name: "Star", value: "Ashwini", label: "Ashwini" },
        { name: "Star", value: "Bharani", label: "Bharani" },
        { name: "Star", value: "Krittika", label: "Krittika" },
        { name: "Star", value: "Rohini", label: "Rohini" },
      ],
    },
  ];

  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("religiousDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Religious Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("religiousDetails")
              ? setFilterId(filterId.filter((e) => e !== "religiousDetails"))
              : setFilterId([...filterId, "religiousDetails"]);
          }}
        >
          {filterId.includes("religiousDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("religiousDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {religiousInputs.map((input) =>
            input.inputType === "option" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className="partnerFieldRange partnerFieldRangeFlex">
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : input.inputType === "checkBox" ? (
              <>
                {!checkSubscribe && (
                  <div className="SubsAccessInfo">
                    Subscribe to access the field below
                  </div>
                )}

                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  {!showAll ? (
                    <div
                      className={`${
                        checkSubscribe
                          ? "StarCheckBoxsList"
                          : "unSubscribeCheckList"
                      }`}
                    >
                      {input.checkInputs.slice(0, 3).map((inp) => (
                        <CheckBoxInputField
                          input={inp}
                          handleChange={handleChange}
                        />
                      ))}
                      <div
                        className="ShowAllBtn"
                        onClick={() => {
                          setShowAll(true);
                        }}
                      >
                        Show All
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${
                        checkSubscribe
                          ? "StarCheckBoxsList"
                          : "unSubscribeCheckList"
                      }`}
                    >
                      {input.checkInputs.map((inp) => (
                        <CheckBoxInputField input={inp} />
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : (
              ""
            )
          )}
        </div>
      )}
    </div>
  );
};

export default FilterReligiousDetails;

import React from "react";
import "../../../assets/images/Sidebar/profileCardBG.png";
import "./MobileSidebar.css";
import candidate from "../../../assets/images/Home/nayan.jpg";
import { useNavigate } from "react-router-dom";

function MobileSidebar({ setSidebar, setLogoutPopup }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="SBprofileSection">
        <div className="SBprofilePhoto">
          <img src={candidate} />
        </div>
        <div className="SBprofileDetails">
          <span className="welomeHead">Welcome</span>
          <span className="SBname">{"Cathy"}</span>
          <span className="SBid">{"JM123456"}</span>
        </div>
      </div>
      <div className="SBLinkContainer">
        {/* Home Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/home");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-house"></i>
          </div>
          <div className="SBLinkName">My Home</div>
        </div>

        {/* Search Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/mobileSearchBox");
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div className="SBLinkName">Search</div>
        </div>

        {/* Matches Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/mobileMatchesBox");
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-user-group"></i>
          </div>
          <div className="SBLinkName">Matches</div>
        </div>

        {/* AddSubscription Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/subscribe");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-crown"></i>
          </div>
          <div className="SBLinkName">Add Subscription</div>
        </div>

        {/* EditProfile Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/editprofilepage");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-file-pen"></i>
          </div>
          <div className="SBLinkName">Edit profile</div>
        </div>

        {/* EditPreference Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/partnerPreference");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-user-pen"></i>
          </div>
          <div className="SBLinkName">Edit preference</div>
        </div>

        {/* VerifyProfile Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/aadhar");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-regular fa-circle-check"></i>
          </div>
          <div className="SBLinkName">Verify profile</div>
        </div>

        {/* Settings Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/settingsBox");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-gear"></i>
          </div>
          <div className="SBLinkName">Settings</div>
        </div>

        {/* Testimonials Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/testimonials");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-people-pulling"></i>
          </div>
          <div className="SBLinkName">Testimonials</div>
        </div>

        {/* Help & Support Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            navigate("/helpandsupport");
            setSidebar(false);
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-circle-info"></i>
          </div>
          <div className="SBLinkName">Help & Support</div>
        </div>

        {/* Help & Support Link */}
        <div
          className="SBLinksSection"
          onClick={() => {
            setSidebar(false);
            setLogoutPopup(true);
            window.sessionStorage.removeItem("token")
            window.sessionStorage.removeItem("User_ID");
          }}
        >
          <div className="SBLinkIcon">
            <i class="fa-solid fa-right-from-bracket"></i>
          </div>
          <div className="SBLinkName">Logout</div>
        </div>
      </div>
    </>
  );
}

export default MobileSidebar;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LandingHeader from "../../components/Headers/Landing header/LandingHeader";
import aboutImg from "../../assets/images/Landing/aboutImg.png";
import ring from "../../assets/images/Landing/ring.png";
import rose from "../../assets/images/Landing/rose.png";
import successImg from "../../assets/images/Landing/successImg.png";
import Footer from "../../components/Footer/Footer";
import "./landing.css";

const LandingPage = ({ isBlack }) => {
  // if login page make the background black
  const [black, setBlack] = useState();

  // register feilds
  const [reginputs, setReginputs] = useState({
    matrimonial: "Myself",
    name: "",
    mobile: "",
    errors: {
      matrimonial: "",
      name: "",
      mobile: "",
    },
    touched: {
      matrimonial: false,
      name: false,
      mobile: false,
    },
  });

  // to which testimonial to display
  const [testimonialCard, setTestimonialCard] = useState(0);

  const navigate = useNavigate();

  // when auth pages the landing page has black background
  useEffect(() => {
    // based on the value give the background
    isBlack ? setBlack(true) : setBlack(false);

    // Remove Token and userId from Session storage
    window.sessionStorage.removeItem("User_ID");
    window.sessionStorage.removeItem("token");
  }, []);

  // testimonials slides array
  const testimonials = [
    {
      img: successImg,
      name: "Kelly and Sebastin",
      testimony:
        "Just fell in love when I came to know about how caring and nice human being he is, Thanks to Joymatrimony",
    },
    {
      img: successImg,
      name: "Sarah and Abraham",
      testimony:
        "Just fell in love when I came to know about how caring and nice human being he is, Thanks to Joymatrimony",
    },
    {
      img: successImg,
      name: "John and Jasmine",
      testimony:
        "We met in joymatrimony.com and connected via call. Within few months we realized that the search is ended here.",
    },
    {
      img: successImg,
      name: "Esther and David",
      testimony:
        "Just fell in love when I came to know about how caring and nice human being he is, Thanks to Joymatrimony",
    },
  ];

  // to check if all the inputs are touched are not
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...reginputs.touched, [name]: true };
    setReginputs({ ...reginputs, touched });
  };

  // input feild's input
  const handleChange = ({ target: { name, value } }) => {
    // console.log(name, value);
    const errors = reginputs.errors;
    errors.matrimonial = "";

    switch (name) {
      case "name": {
        if (!value.length) {
          errors.name = "Enter Name";
        } else if (value.length < 4) {
          errors.name = "Must contain atleast four characters";
        } else {
          errors.name = "";
        }
        break;
      }

      case "mobile": {
        if (!/^[0-9]*$/.test(value)) {
          errors.mobile = "Invalid Mobile No.";
        } else if (value.length != 10) {
          errors.mobile = "Must include ten numbers";
        } else {
          errors.mobile = "";
        }
        break;
      }
    }
    setReginputs({ ...reginputs, [name]: value }, errors);
  };

  // form submission
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const errors = Object.values(reginputs.errors).filter(
        (err) => err !== ""
      );
      const notTouched = Object.values(reginputs.touched).filter((e) => !e);
      // console.log(notTouched);
      const error = reginputs.errors;
      if (!errors.length && notTouched.length <= 1) {
        navigate("/register", {
          state: { name: reginputs.name, mobile: reginputs.mobile },
        });

        console.log(reginputs);
      } else if (!reginputs.name.length) {
        // const errors = reginputs.errors;
        error.name = "Please Enter Name";
        // setReginputs({ ...reginputs }, errors);
      } else if (!reginputs.mobile.length) {
        // const errors = reginputs.errors;
        error.mobile = "Please Enter Mobile No.";
        // setReginputs({ ...reginputs }, errors);
      }
      setReginputs({ ...reginputs }, error);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container-fluid landingPageContainer">
      <div className="col-lg-12">
        {/* landing header */}
        <div className="row">
          <LandingHeader />
        </div>

        <div className={` ${black ? "landingBlack1" : ""}`}></div>
        {/*  hero section*/}
        <div className="row heroRow">
          {/* Desktop Landinghero */}

          <div className="landingHero">
            {/* hero black layer effect */}
            <div className="black1">
              {/* hero text  */}
              <div className="landingHeroWelc">
                <div className="landingHeroWelcText">
                  We bring people together
                </div>
                <div>love unites them</div>
                <button
                  className="landingHeroButton"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Search Profiles
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Landinghero */}

          <div className="mobileLandingHero">
            {/* hero black layer effect */}
            <div className="black1">
              {/* hero text  */}
              <div className="landingHeroWelc">
                <div className="landingHeroWelcText">
                  We bring people together
                </div>
                <div>love unites them</div>
                <button
                  className="landingHeroButton"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Search Profiles
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* landing register form */}
        <div className="landReg">
          <form onSubmit={handleSubmit}>
            <div className="row landReg-Row">
              <div className="col-lg-3 col-md-3">
                <span className="landingRegHeading">
                  Matrimonial profile for
                </span>
                <select
                  className="landDropDown"
                  value={reginputs.matrimonial}
                  onChange={handleChange}
                  name="matrimonial"
                >
                  {/* <option disabled selected>
                    Select option for
                  </option> */}
                  <option value={"Myself"}>Myself</option>
                  <option value={"Son"}>Son</option>
                  <option value={"Daughter"}>Daughter</option>
                  <option value={"Brother"}>Brother</option>
                </select>
                <div className="landRegInpErr">
                  {reginputs.errors.matrimonial}
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <span className="landingRegHeading">Name</span>
                <input
                  className="landingRegInp"
                  name="name"
                  value={reginputs.name}
                  placeholder="Enter Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                <div className="landRegInpErr">{reginputs.errors.name}</div>
              </div>
              <div className="col-lg-3 col-md-3">
                <span className="landingRegHeading">Mobile Number</span>
                <input
                  className="landingRegInp"
                  name="mobile"
                  value={reginputs.mobile}
                  placeholder="Enter Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                <div className="landRegInpErr">{reginputs.errors.mobile}</div>
              </div>
              <div className="col-lg-3 col-md-3">
                <button className="landingRegButton">REGISTER FREE</button>
              </div>
            </div>
          </form>
        </div>

        {/* landing about us */}
        <div className="row">
          <div className="landingAbout">
            <div className="col-lg-12 heading">Why Choose Us</div>

            <div className="landingPageParaContent">
              <div className="textContent">
                <p className="landingAboutBold">
                  Most Trusted Matrimony service, Joy Matrimony has helped Lakhs
                  of
                  <br /> people like you, find perfect matches from across
                  different cultures.
                </p>

                <div className="withRingParas">
                  <div className="aboutPoints">
                    <span className="ring">
                      <img src={ring} />
                    </span>
                    <p className="aboutP">
                      No.1 & most trusted matrimony service for christians
                    </p>
                  </div>

                  <div className="aboutPoints">
                    <span className="ring">
                      <img src={ring} />
                    </span>
                    <p className="aboutP">100% verified mobile numbers</p>
                  </div>

                  <div className="aboutPoints">
                    <span className="ring">
                      <img src={ring} />
                    </span>
                    <p className="aboutP">
                      Our largest number of profiles increases your chances of
                      meeting the right person
                    </p>
                  </div>

                  <div className="aboutPoints">
                    <span className="ring">
                      <img src={ring} />
                    </span>
                    <p className="aboutP">
                      No.1 & most trusted matrimony service for christians
                    </p>
                  </div>

                  <div className="aboutPoints">
                    <span className="ring">
                      <img src={ring} />
                    </span>
                    <p className="aboutP">
                      Trusted service for more than 22 years
                    </p>
                  </div>
                </div>
              </div>

              <div className="aboutImg">
                <img src={aboutImg} />
              </div>
            </div>
          </div>
        </div>

        {/* your story div */}
        <div className="row">
          <div className="landingStory col-lg-12">
            <div className="row">
              <div className="landingStoryImg col-lg-3">
                <img src={rose} />
              </div>
              <div className="landingStoryText col-lg-9">
                Your Story is waiting to happen
              </div>
            </div>
          </div>
        </div>

        {/* success story */}
        <div className="row">
          <div className="successStory">
            <div className="successStoryHead">Success Stories</div>

            <div
              className={`arrow ${setBlack ? "arrowBlack" : ""} ${
                testimonialCard === 0 && "landingTestimonialArrowRight"
              }  ${
                testimonialCard === testimonials.length - 2 &&
                "landingTestimonialArrowLeft"
              }`}
            >
              {/* {console.log(testimonialCard, testimonials.length - 1)} */}
              <button
                className="material-symbols-outlined arrowLeft"
                onClick={() => {
                  setTestimonialCard(
                    testimonialCard === 0 ? 0 : testimonialCard - 1
                  );
                }}
              >
                chevron_left
              </button>
              <button
                className="material-symbols-outlined arrowRight"
                onClick={() => {
                  setTestimonialCard(
                    testimonialCard === testimonials.length - 2
                      ? testimonials.length - 2
                      : testimonialCard + 1
                  );
                }}
              >
                chevron_right
              </button>
            </div>

            <div className={`slides `}>
              <div
                className={`card slide1 col-lg-3 ${
                  setBlack ? "cardBlack" : ""
                }`}
              >
                <div className="successImg">
                  <img src={successImg} className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title successImgTitle">
                    {testimonials[testimonialCard].name}
                  </h5>
                  <p className="card-text successContent">
                    Just fell in love when I came to know about how caring and
                    nice human being he is, Thanks to Joymatrimony
                  </p>
                </div>
              </div>

              <div className={`card col-lg-3 ${setBlack ? "cardBlack" : ""}`}>
                <div className="successImg">
                  <img src={successImg} className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title successImgTitle">
                    {testimonials[testimonialCard + 1].name}
                  </h5>
                  <p className="card-text successContent">
                    We met in joymatrimony.com and connected via call. Within
                    few months we realized that the search is ended here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="row">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AuthHeader from "../../../components/Auth/AuthHeader";
import withAuthBackground from "../withAuthBackground";
import FormInputs from "../../../components/Auth/Form input/FormInputs";
import googleIcon from "../../../assets/images/Auth/googleIcon.png";
import "./register.css";
import { useEffect } from "react";
import axios from "axios";

// Backend Integration
import { BACKEND_PORT } from "../../../App";
import { useDispatch } from "react-redux";
import { addRegisterDetails } from "../../../slices/registerSlice";

const Register = () => {

  // Redux Operations
  const dispatch = useDispatch();

  //input feild's use state
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    errors: {
      name: "",
      email: "",
      mobile: "",
      confirmPassword: "",
    },
    touched: {
      name: false,
      email: false,
      mobile: false,
      password: false,
      confirmPassword: false,
    },
  });

  //  password eye icon
  const [passwordEye, setPasswordEye] = useState({
    password: true,
    confirmPassword: true,
  });

  // password checkings
  const [passwordChecking, setPasswordChecking] = useState({
    case1: false,
    case2: false,
    case3: false,
    case4: false,
  });

  // common error validation
  const [emailExist, setEmailExist] = useState("");

  const [error, setError] = useState("");

  const navigate = useNavigate();

  // landing page reg inp values
  const { state } = useLocation();

  const name = state?.name ?? "";
  const mobile = state?.mobile ?? "";

  useEffect(() => {
    if (name || mobile) {
      const touched = { ...credentials.touched, name: true, mobile: true };
      setCredentials({ ...credentials, name, mobile, touched });
    }
  }, [name, mobile]);

  // input feilds
  const registerInputs = [
    {
      name: "name",
      type: "text",
      placeholder: "Name",
      value: credentials.name,
      error: credentials.errors.name,
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email",
      value: credentials.email,
      error: credentials.errors.email,
    },
    {
      name: "mobile",
      type: "text",
      placeholder: "Mobile Number",
      value: credentials.mobile,
      error: credentials.errors.mobile,
    },
    {
      name: "password",
      type: passwordEye.password ? "password" : "text",
      placeholder: "Password",
      classname: "regPassword",
      value: credentials.password,
      error: credentials.errors.password,
    },
    {
      name: "confirmPassword",
      type: passwordEye.confirmPassword ? "password" : "text",
      placeholder: "Confirm Password",
      classname: "regPassword",
      value: credentials.confirmPassword,
      error: credentials.errors.confirmPassword,
    },
  ];

  // handling eye icon
  const handlePassword = (name) => {
    name === "password"
      ? setPasswordEye({ ...passwordEye, password: !passwordEye.password })
      : setPasswordEye({
          ...passwordEye,
          confirmPassword: !passwordEye.confirmPassword,
        });
  };

  // input blur validations
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...credentials.touched, [name]: true };
    setCredentials({ ...credentials, touched });
  };

  // handling validations and input to the form feilds
  const handleChange = ({ target: { name, value } }) => {
    const errors = credentials.errors;
    setError("");

    switch (name) {
      case "name": {
        if (!value.length) {
          errors.name = "Enter Name";
        } else if (value.length < 4) {
          errors.name = "Name  must be atleast 4 characters";
        } else {
          errors.name = "";
        }
        break;
      }

      case "email": {
        if (!value.length) {
          errors.email = "Enter Email ID";
        } else if (value.includes("@") && value.includes(".com")) {
          errors.email = "";
        } else {
          errors.email = "Enter a valid Email ID";
        }
        break;
      }

      case "password": {
        value.length < 8
          ? setPasswordChecking((prevState) => ({ ...prevState, case1: false }))
          : setPasswordChecking((prevState) => ({ ...prevState, case1: true }));

        /(?=.*?[A-Z])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case2: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case2: false,
            }));

        /(?=.*?[0-9])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case3: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case3: false,
            }));

        /(?=.*?[#$&@])/.test(value)
          ? setPasswordChecking((prevState) => ({ ...prevState, case4: true }))
          : setPasswordChecking((prevState) => ({
              ...prevState,
              case4: false,
            }));
        break;
      }

      case "mobile": {
        if (!/^[0-9]*$/.test(value)) {
          errors.mobile = "Invalid Mobile No.";
        } else if (value.length !== 10) {
          // console.log(value.length);
          errors.mobile = "Must include ten numbers";
        } else {
          errors.mobile = "";
        }
        break;
      }

      case "confirmPassword": {
        errors.confirmPassword =
          value !== credentials.password ? "Password mismatch" : "";
        break;
      }
    }

    setCredentials({ ...credentials, [name]: value }, errors);
  };

  // backend validations
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setEmailExist("");

      // checking validation (frontend)
      const errors = Object.values(credentials.errors).filter(
        (err) => err !== ""
      );
      const notTouched = Object.values(credentials.touched).filter((e) => !e);
      const passwordValidations = Object.values(passwordChecking).filter(
        (e) => !e
      );
      // console.log;
      if (!errors.length && !notTouched.length && !passwordValidations.length) {
        const { name, email, password, mobile } = credentials;

        dispatch(addRegisterDetails({name, email, password, mobile}))

        // const regData = await axios.post(`${BACKEND_PORT}/register`, {
        //   name,
        //   email,
        //   password,
        //   mobile,
        // });
        // console.log(regData);

        // navigate("/emailVerification", {
        //   state: {
        //     UserID: regData.data.user_Id,
        //     email: email,
        //   },
        // });
        
        navigate("/mobileVerification", {
          state: {
            UserID: null,
            mobile: mobile,
          },
        });

        // navigate("/mobileVerification", { state: { forgotPassword: false } });
      } else if (notTouched.length || !credentials.password.length) {
        setError("All the input fields are mandatory");
      } else if (passwordValidations.length) {
        return "";
      }
    } catch (err) {
      console.log(err);
      // alert(err.response.data.message)
      setEmailExist(err.response.data.message);
    }
  };

  return (
    <>
      {/* container header */}
      <AuthHeader form={"register"} />

      <div className="row">
        <div className="regFormContiner">
          {error && <div className="registerErr">{error}</div>}
          {emailExist && (
            <div className="registerErr">{"User email already exist"}</div>
          )}
          <form className="registerForm" onSubmit={handleSubmit}>
            {registerInputs.map((inp) => (
              <FormInputs
                key={inp.label}
                name={inp.name}
                type={inp.type}
                value={inp.value}
                placeholder={inp.placeholder}
                handleChange={handleChange}
                classname={inp.classname}
                passwordEye={passwordEye}
                handlePassword={handlePassword}
                passwordChecking={passwordChecking}
                error={inp.error}
                handleBlur={handleBlur}
              />
            ))}

            <div className="loginButton">
              <button>Create Account</button>
            </div>
          </form>

          <div
            className="authLink"
            onClick={() => {
              navigate("/login");
            }}
          >
            Don't have an account? <span>Login</span>
          </div>
          {/* login hr */}
          <div className="loginHr">
            <div>Or</div>
          </div>

          {/* google login */}
          <div className="googleloginReg">
            <div className="googleloginText">Register using</div>
            <div className="googleIconReg">
              <img src={googleIcon} />
            </div>
          </div>
        </div>

        {/* google icon */}

        <div className="registerFull"></div>
      </div>
    </>
  );
};

export default withAuthBackground(Register, "register");

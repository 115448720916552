import React from "react";

import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";

const FilterPersonalDetails = ({ filterId, setFilterId, filterInputs }) => {
  const personalInputs = [
    {
      label: "Physical Status",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "normal", label: "Normal" },
        {
          name: "employed",
          value: "physicallyChalanged",
          label: "Physically Challenged",
        },
      ],
    },
    {
      label: "Body type",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "slim", label: "Slim" },
        { name: "employed", value: "average", label: "Average" },
        { name: "employed", value: "atletic", label: "Athletic" },
        { name: "employed", value: "heavy", label: "Heavy" },
      ],
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("personalDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Personal Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("personalDetails")
              ? setFilterId(filterId.filter((e) => e !== "personalDetails"))
              : setFilterId([...filterId, "personalDetails"]);
          }}
        >
          {filterId.includes("personalDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("personalDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {personalInputs.map((input) => (
            <div className="dropDownOption">
              <div className="dropDownComponentTitle">{input.label}</div>
                <div className={`ProfileTypeCheckBoxsList`}>
              {input.checkInputs.map((inp) => (
                <CheckBoxInputField input={inp} />
              ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterPersonalDetails;

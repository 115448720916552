import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import jmTitle from "../../../assets/images/Landing/JMLogo.png";
import nayan from "../../../assets/images/Home/nayan.jpg";
import HomeNotificationBox from "./HomeNotificationBox/HomeNotificationBox";
import HomeProfileBox from "./HomeProfileBox/HomeProfileBox";
import HomeMatchesBox from "./HomeMatchesBox/HomeMatchesBox";
import HomeSearchBox from "./HomeSearchBox/HomeSearchBox";
import "./HomeHeader.css";
import LogoutPopup from "../../LogoutPopup/LogoutPopup";
import ProfilePicPlaceholder from '../../../assets/images/Home/HomeProfilePlaceHolder.png'

const HomeHeader = () => {

  const navigate = useNavigate();

  // Profile Picture
  const [profilePicture, setProfilePicture] = useState(nayan)

  // Logout popup
  const [logoutPopup, setLogoutPopup] = useState(false)

  const [headerBox, setHeaderBox] = useState("");

  const location = useLocation();

  const matchPath = location.pathname.includes("matches");
  
  const searchPath =
    location.pathname.includes("keywordsearch") ||
    location.pathname.includes("matrimonyidsearch");

  return (
    <div className="col-lg-12">
      <div className="row homeHeaderContainer">
        <div className="homeHeader">
          <div className="homeHeaderTitle">
            <img src={jmTitle} className="homeHeaderTitleImage" />
          </div>
          <div className="homeHeaderNav">
            <NavLink to="/home">MY HOME</NavLink>

            <div
              href="#"
              className={`homeHeaderNotify ${searchPath && "headerSearchPink"}`}
              onClick={() => {
                setHeaderBox(headerBox === "searchBox" ? "" : "searchBox");
              }}
            >
              SEARCH
            </div>

            <div
              href="#"
              className={`homeHeaderNotify ${matchPath && "headerMatchPink"}`}
              onClick={() => {
                setHeaderBox(headerBox === "matchesBox" ? "" : "matchesBox");
              }}
            >
              MATCHES
            </div>

            <NavLink to="/subscribe">SUBSCRIBE</NavLink>

          </div>
          <div className="homeHeaderProfile">
            <div
              className="homeHeaderNotify notificationButton"
              onClick={() => {
                setHeaderBox(
                  headerBox === "notificationBox" ? "" : "notificationBox"
                );
              }}
            >
              <i className="fa-regular fa-bell"></i>
              <i className="fa-solid fa-circle"></i>
            </div>
            <div className="homeHeaderProfileDisplay">
              <div className="SidebarProfileDetails">
                <span className="welcomeTag">Welcome</span>
                <span className="profileName">{"Cathy"}</span>
                <span className="profileID">{"JM123456"}</span>
              </div>
              <div className="headerProfilePic">
                {profilePicture ? (
                  <img src={profilePicture} />
                ) : (
                  <img src={ProfilePicPlaceholder} />
                )}
              </div>
              <div
                className="AngleDownArrowButton"
                onClick={() => {
                  setHeaderBox(headerBox === "profileBox" ? "" : "profileBox");
                }}
              >
                <i className="fa-solid fa-angle-down"></i>
              </div>
            </div>

            {logoutPopup && (
              <LogoutPopup
                logoutPopup={logoutPopup}
                setLogoutPopup={setLogoutPopup}
              />
            )}

            {headerBox === "matchesBox" && (
              <HomeMatchesBox setHeaderBox={setHeaderBox} />
            )}
            {headerBox === "searchBox" && (
              <HomeSearchBox setHeaderBox={setHeaderBox} />
            )}
            {headerBox === "notificationBox" && <HomeNotificationBox />}
            {headerBox === "profileBox" && (
              <HomeProfileBox
                logoutPopup={logoutPopup}
                setHeaderBox={setHeaderBox}
                setLogoutPopup={setLogoutPopup}
              />
            )}
          </div>
        </div>
        <div className="homeHeaderBorder"></div>
      </div>
    </div>
  );
};

export default HomeHeader;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withProfileBackground from "../withProfileBackground";
import "./ProfileRegister1.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";

function ProfileRegister() {
  const navigate = useNavigate();

  const params = useParams();

  const ref = useRef();

  // Password Hiding process
  const [showPassword, setShowPassword] = useState(false);

  // Common Error validation
  const [error, setError] = useState("");

  const [datePicker, setDatePicker] = useState(false);

  // const [dateValue, setDateValue] = useState(dayjs("2014-08-18T21:11:54"));

  // let datePickValue = dateValue.$d

  const [openDropDown, setOpenDropDown] = useState(false);

  const mother_tongueData = [
    "Tamil",
    "Hindi",
    "Telugu",
    "Malayalam",
    "konkani",
    "kannada",
    "English",
    "Matani",
    "Tamil",
    "Hindi",
    "Telugu",
    "Malayalam",
    "konkani",
    "kannada",
    "English",
    "Matani",
    "Tamil",
    "Hindi",
    "Telugu",
    "Malayalam",
    "konkani",
    "kannada",
    "English",
    "Matani",
    "Tamil",
    "Hindi",
    "Telugu",
    "Malayalam",
    "konkani",
    "kannada",
    "English",
    "Matani",
    "Tamil",
    "Hindi",
    "Telugu",
    "Malayalam",
    "konkani",
    "kannada",
    "English",
    "Matani",
  ];

  // Form Field's state
  const [PrInputs, setPrInputs] = useState({
    mother_tongue: "",
    date_of_birth: "",
    email: "",
    password: "",
    errors: {
      mother_tongue: "",
      date_of_birth: "",
      email: "",
      password: "",
    },
    touched: {
      mother_tongue: false,
      date_of_birth: false,
      email: false,
      password: false,
    },
  });

  // Handle validations and Form input
  const handleChange = ({ target: { name, value } }) => {
    // error validations
    const error = PrInputs.errors;
    setError("");
    switch (name) {
      case "mother_tongue": {
        error.mother_tongue = !value ? "Please select the mother tongue" : "";
        break;
      }
      case "date_of_birth": {
        error.date_of_birth = !value ? "Please enter the date of birth" : "";
        break;
      }
      case "email": {
        error.email = !value ? "Please enter the Email ID" : "";
        break;
      }
      case "password": {
        error.password = !value ? "Please enter the password" : "";
        break;
      }
      default:
        break;
    }

    // console.log(name, value);
    // to update the values in the form
    setPrInputs({ ...PrInputs, [name]: value }, error);
  };

  // Handle input blur validations
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...PrInputs.touched, [name]: true };
    setPrInputs({ ...PrInputs, touched });
    if (name === "date_of_birth") {
      ref.current.type = "text";
      // setDatePicker(false);
    }
  };

  // Backend validations and Error handling
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const errors = PrInputs.errors;
      if (
        PrInputs.mother_tongue === "What is you Mother Tongue" ||
        PrInputs.mother_tongue === ""
      ) {
        errors.mother_tongue = "Please enter a valid option";
        setPrInputs({ ...PrInputs, errors });
      } else {
        const errorCount = Object.values(PrInputs.errors).filter(
          (err) => err !== ""
        ).length;
        // console.log(errorCount);

        const notTouched = Object.values(PrInputs.touched).filter((en) => !en);

        if (!error.length && !notTouched.length && errorCount === 0) {
          const { mother_tongue, date_of_birth, email, password } = PrInputs;
          let postData = await axios.put(
            `${BACKEND_PORT}/userDetails/basicDetails`,
            {
              mother_tongue,
              date_of_birth,
              email,
              password,
            }
          );
          console.log(postData);
          navigate("/profileregister/FamilyDetails");
        } else {
          setError("All the input fields are mandatory");
        }
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <label className="PR_Card_Headings_Tab1">
          Tell us about your basic details
        </label>
      </div>
      <label className="All_input_Field_error">{error}</label>
     <input
          type="text"
          placeholder=" What is you Mother Tongue"
          className={`PR_FROM_Tabs_selectBox PR_Form_Tab1_Input_Fields ${
            PrInputs.mother_tongue === "What is you Mother Tongue" ||
            PrInputs.mother_tongue === ""
              ? "PR_FORM_Tab1_Select_option_Grey"
              : "PR_FORM_Tab1_Select_option_Black"
          }`}
          name="mother_tongue"
          onChange={handleChange}
          onBlur={handleBlur}
          value={PrInputs.mother_tongue}
          autoComplete="off"
          onClick={() => {
            setOpenDropDown(true);
          }}
        />

        <div className="dropDownMenuBar">
          {openDropDown
            ? PrInputs.mother_tongue.length > 0
              ? mother_tongueData
                  .filter((item) => {
                    const searchItem = PrInputs.mother_tongue.toLowerCase();
                    const mother_tongueData = item.toLowerCase();

                    return (
                      searchItem &&
                      mother_tongueData.includes(searchItem) &&
                      mother_tongueData !== searchItem
                    );
                  })
                  .map((item) => (
                    <div
                      onClick={() => {
                        setPrInputs({ ...PrInputs, mother_tongue: item });
                        console.log(PrInputs.mother_tongue);
                      }}
                      className="dropDownMenuItems"
                    >
                      {item}
                    </div>
                  ))
              : mother_tongueData.slice(0, 10).map((item) => (
                  <div
                    onClick={() => {
                      setPrInputs({ ...PrInputs, mother_tongue: item });

                      setOpenDropDown(false);
                    }}
                    className="dropDownMenuItems"
                  >
                    {item}
                  </div>
                ))
            : ""}
        </div>

        <label className="PR_Page_inputs_validation_Label">
          {PrInputs.errors.mother_tongue}
        </label>

        <input
          type="text"
          ref={ref}
          class="form-control PR_Form_Tab1_Input_Fields"
          name="date_of_birth"
          onChange={handleChange}
          onBlur={handleBlur}
          value={PrInputs.date_of_birth}
          placeholder={`Enter your Date of Birth`}
          onFocus={() => (ref.current.type = "date")}
        />
        {/* {!datePicker ? (
          <input
            type="text"
            ref={ref}
            class="form-control PR_Form_Tab1_Input_Fields"
            name="date_of_birth"
            onChange={handleChange}
            onBlur={handleBlur}
            value={PrInputs.date_of_birth}
            placeholder={`Enter your Date of Birth`}
            // onFocus={() => (ref.current.type = "date")}
            onFocus={() => {
              setDatePicker(true);
            }}
          />
        ) : (
          <DatePicker
            // placeholder="Enter your Date of Birth"
            style={{ width: 200 }}
          />
        )} */}

        {/* {console.log(PrInputs.date_of_birth)} */}

        <label className="PR_Page_inputs_validation_Label">
          {PrInputs.errors.date_of_birth}
        </label>

        <input
          type={"email"}
          className="form-control PR_Form_Tab1_Input_Fields"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={PrInputs.email}
          placeholder="Enter your Email ID"
        />
        <label className="PR_Page_inputs_validation_Label">
          {PrInputs.errors.email}
        </label>

        <div className="PR_Card_Tab1_passwordBox">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control PR_Form_Tab1_Input_Fields"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={PrInputs.password}
            placeholder="Enter your password"
          />
          <div
            className="PR_eye_icon"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <span className="material-symbols-outlined">visibility</span>
            ) : (
              <span className="material-symbols-outlined">visibility_off</span>
            )}
          </div>
        </div>
        <label className="PR_Page_inputs_validation_Label">
          {PrInputs.errors.password}
        </label>

        <button
          type={"submit"}
          className=" PR-pink-button PR_Form_Tab1_Input_Fields"
          value={"Continue"}
        >
          Continue
        </button>
    </form>
  );
}

export default withProfileBackground(ProfileRegister, "PR1");

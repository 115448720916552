import React from "react";

import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";

const FilterLifeStyle = ({ filterId, setFilterId, filterInputs }) => {
  const lifeStyleInputs = [
    {
      label: "Eating Habits",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "vegetarian", label: "Vegetarian" },
        {
          name: "employed",
          value: "nonVegeterian",
          label: "Non-vegeterian",
        },
        {
          name: "employed",
          value: "eggetarian",
          label: "Eggetarian",
        },
        {
          name: "employed",
          value: "notSpecified",
          label: "Not Specified",
        },
      ],
    },
    {
      label: "Smoking Habits",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "never", label: "Never" },
        {
          name: "employed",
          value: "smokesOccasionaly",
          label: "Smokes occasionally",
        },
        {
          name: "employed",
          value: "smokeRegularly",
          label: "Smokes Regularly",
        },
      ],
    },
    {
      label: "Drinking Habits",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "never", label: "Never" },
        {
          name: "employed",
          value: "smokesOccasionaly",
          label: "Drinks occasionally",
        },
        {
          name: "employed",
          value: "smokeRegularly",
          label: "Drinks Regularly",
        },
      ],
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("lifeStyle")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Lifestyle Habits</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("lifeStyle")
              ? setFilterId(filterId.filter((e) => e !== "lifeStyle"))
              : setFilterId([...filterId, "lifeStyle"]);
          }}
        >
          {filterId.includes("lifeStyle") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("lifeStyle") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {lifeStyleInputs.map((input) => (
            <div className="dropDownOption">
              <div className="dropDownComponentTitle">{input.label}</div>
              <div className={`ProfileTypeCheckBoxsList`}>
              {input.checkInputs.map((inp) => (
                <CheckBoxInputField input={inp} />
              ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterLifeStyle;

import React from 'react'
import { useNavigate } from 'react-router-dom';
import MobileVerification from '../../../components/Auth/Mobile verification/MobileVerification';
import withVerificationBackground from '../withVerificationBackground';
import "./MobileNoIncorrect.css";

const MobileNoIncorrect = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="mobileContainer ">
        <>
          <div className="registeredheader">
            <p>You have successfully registered with Joy Matrimony !</p>
            <p>
              Your Matrimony ID is <span className="matrimonyID">JM123456</span>
            </p>
            <hr className="MobileverifyHrTag" />
          </div>
          <div className="mobileHeader">Your mobile number is incorrect.</div>
          <div className='tryAgainButton'>
            <button>Try Again</button>
          </div>
          <div className="VerifySkipdiv">
            <button
              className="VerifySkipButton"
              onClick={() => {
                navigate("/emailVerification");
              }}
            >{`Skip >`}</button>
          </div>
        </>
      </div>
    </>
  );
};
export default withVerificationBackground(MobileNoIncorrect); 
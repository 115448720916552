import React from "react";
import "./SelectInputField.css";

const SelectInputField = ({ input, className, handleChange }) => {
  return (
    <select
      name={input.name}
      className={`${className} partnerPreferenceBigOption boxSelectField`}
      value={input.value}
      onChange={handleChange}
    >
      {input.options.map((inp) => (
        <option
          key={inp.value}
          value={inp.value}
        >
          {inp.value}
        </option>
      ))}
    </select>
  );
};

export default SelectInputField;

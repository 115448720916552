import React from "react";
import { useNavigate } from "react-router-dom";
import candidate from "../../assets/images/Home/nayan.jpg";

function HelpAndSupport() {
  const navigate = useNavigate();
  return (
    <>
      <div className="searchBoxTapBar">
        Help & Support
        <div
          className="leftArrowIcon"
          onClick={() => {
            navigate("/home");
          }}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      </div>

      <div className="SearchBoxContents MatchesBoxContents">
        <div className="MatchesBoxCandidateProfile">
          <div className="MBprofilePhoto">
            <img src={candidate} />
          </div>
          <p className="candidateName">{"Cathy"}</p>
          <p className="candidateId">{"JM123456"}</p>
        </div>
        <label className="MatchesBoxLabel">Help And Support</label>
        <div className="SearchBoxButtons">
          <button
            onClick={() => {
            //   navigate("/home");
            }}
          >
            <label>24x7 Live chat</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
                navigate("/contactus");
            }}
          >
            <label>Contact Us</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/customersupport");
            }}
          >
            <label>Customer Support</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/faq");
            }}
          >
            <label>FAQs</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

        </div>
      </div>
    </>
  );
}

export default HelpAndSupport;

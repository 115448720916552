import React from "react";

import SelectInputField from "../InputFields/SelectInputField";

const PartnerPreferenceProfessional = ({
  partnerPreferenceField,
  handleEdit,
  handleSave,
  partnerEditProcess,
  setPartnerEditProcess,
  handleChange,
}) => {
  // professionalDetails inputs
  const PartnerPreferenceProfessionalInputs = [
    {
      inputType: "select",
      name: "education",
      value: partnerPreferenceField.education,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      // error: PrInputs.errors.Denomination,
    },
    {
      inputType: "select",
      name: "employed",
      value: partnerPreferenceField.employed,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      // error: PrInputs.errors.Division,
    },
    {
      inputType: "select",
      name: "occupation",
      value: partnerPreferenceField.occupation,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      error: "",
    },
    {
      className: "partnerPreferenceSmallOption",
      inputType: "range",
      name: "annualIncome",
      field: [
        {
          value: partnerPreferenceField.annualIncomeFrom,
          name: "annualIncomeFrom",
          options: [
            { value: "2L", disabled: true, selected: true },
            { value: "5L", disabled: false },
            { value: "10L", disabled: false },
            { value: "10 Above", disabled: false },
          ],
        },
        {
          value: partnerPreferenceField.annualIncometo,
          name: "annualIncometo",
          options: [
            { value: "2L", disabled: true, selected: true },
            { value: "5L", disabled: false },
            { value: "10L", disabled: false },
            { value: "10 Above", disabled: false },
          ],
        },
      ],
    },
  ];
  return (
    <div className="religiousPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="ReligiousPartnerFieldTitle">Professional Details</div>
      </div>

      <button
        className="EditPage_EditButton mobileViewEditButton"
        onClick={() => {
          handleEdit("professionalDetails");
        }}
      >
        {partnerEditProcess === "professionalDetails" ? (
          <span
            onClick={() => {
              handleSave("cancel");
            }}
          >
            <i class="fa-solid fa-pen"></i> Close
          </span>
        ) : (
          <>
            <i class="fa-solid fa-pen partnerPreferenceMobileEdit"></i>
          </>
        )}
      </button>

      <button
        className="PartnerEditPage_EditButton DesktopViewEditButton"
        onClick={() => {
          handleEdit("professionalDetails");
        }}
      >
        <i class="fa-solid fa-pen"></i>

        {partnerEditProcess === "professionalDetails" ? (
          <span
            onClick={() => {
              handleSave("cancel");
            }}
          >
            Close
          </span>
        ) : (
          <>Edit</>
        )}
      </button>

      <div
        className={`PartnerPreferenceDetailsSectionRow ${
          partnerEditProcess === "professionalDetails"
            ? "DetailsSectionRow_EditTime"
            : "DetailsSectionRow"
        }`}
      >
        <div className="partnerInputs">
          {/* basic details labels */}
          <ul
            className={` ${
              partnerEditProcess === "professionalDetails"
                ? "PartnerDetails_section_label PartnerDetails_section_label_EditTime"
                : "PartnerDetails_section_label"
            }`}
          >
            <li>Education</li>
            <li>Employed in</li>
            <li>Occupation</li>
            <li>Annual Income</li>
          </ul>

          <ul
            className={`PartnerDetails_section_colon ${
              partnerEditProcess === "professionalDetails"
                ? "PartnerDetails_section_colon_EditTime"
                : ""
            }`}
          >
            <li>:</li>
            <li>:</li>
            <li>:</li>
            <li>:</li>
          </ul>

          {partnerEditProcess === "professionalDetails" ? (
            // basic details inputs
            <>
              <ul className="Details_section_values partnerDetails_section_values">
                {PartnerPreferenceProfessionalInputs.map((input) =>
                  input.inputType === "select" ? (
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : input.inputType === "range" ? (
                    <div className="partnerFieldRange partnerFieldAns">
                      {/* {console.log(input.field[0])} */}
                      <SelectInputField
                        input={input.field[0]}
                        className={input.className}
                        handleChange={handleChange}
                      />
                      <div> to </div>
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input.field[1]}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                      <span className="Details_section_values_rightLabel">
                        {input.name === "annualIncome" && <div>Lacs</div>}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </ul>
            </>
          ) : (
            <>
              <ul className="partnerDetails_section_values">
                <li>
                  {partnerPreferenceField.education === "" ||
                  partnerPreferenceField.education === "Select" ||
                  partnerPreferenceField.education === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("professionalDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.education
                  )}
                </li>

                <li>
                  {partnerPreferenceField.employed === "" ||
                  partnerPreferenceField.employed === "Select" ||
                  partnerPreferenceField.employed === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("professionalDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.employed
                  )}
                </li>

                <li>
                  {partnerPreferenceField.occupation === "" ||
                  partnerPreferenceField.occupation === "Select" ||
                  partnerPreferenceField.occupation === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("professionalDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.occupation
                  )}
                </li>

                <li>
                  {(partnerPreferenceField.annualIncomeFrom === "" &&
                    partnerPreferenceField.annualIncometo === "") ||
                  (partnerPreferenceField.annualIncomeFrom === undefined &&
                    partnerPreferenceField.annualIncometo === undefined) ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("professionalDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    <>
                      <span className="from">
                        {partnerPreferenceField.annualIncomeFrom}
                      </span>
                      <span className="PartnerPreferencetolabel">to</span>
                      <span>{partnerPreferenceField.annualIncometo}</span>
                    </>
                  )}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerPreferenceProfessional;

import React, { useEffect, useState } from "react";
import withProfileBackground from "../withProfileBackground";
import { useNavigate } from "react-router-dom";
import "../../ProfileRegister/ProfileRegister2/ProfileRegister2.css";
import "./ProfileRegister3.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const ProfileRegister3 = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [showChildrens, setShowChildrens] = useState(false);

  const navigate = useNavigate("");

  const schema = z.object({
    marital_status: z
      .string()
      .min(1, { message: "Please select the Marital Status" }),
    no_of_children: z.optional(z.string()),
    height: z.string().min(1, { message: "Please Enter your height" }),
    religious_values: z
      .string()
      .min(1, { message: "Please select the religious values" }),
    disability: z.string().min(1, { message: "Please select the above field" }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      marital_status: "",
      no_of_children: "",
      height: "",
      religious_values: "",
      disability: "",
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (watch().marital_status === "Single") {
      setShowChildrens(false);
    } else if (watch().marital_status === "") {
      setShowChildrens(false);
    } else {
      setShowChildrens(true);
    }
  }, [watch().marital_status]);

  let User_ID = sessionStorage.getItem("User_ID");

  const onSubmit = async (formValues) => {
    let {
      marital_status,
      no_of_children,
      height,
      religious_values,
      disability,
    } = formValues;

    no_of_children = parseInt(no_of_children);
    height = parseInt(height);

    let postData = await axios.put(
      `${BACKEND_PORT}/userDetails/PersonalDetails/${User_ID}`,
      {
        marital_status,
        no_of_children,
        height,
        religious_values,
        disability,
      }
    );

    console.log(postData);
    navigate("/profileregister/EducationDetails");
    reset();
  };

  // const handleChange = (e) => {
  // console.log(e.target.value);
  //   if (e.target.value === "Single") {
  //     setShowChildrens(false);
  //   } else {
  //     setShowChildrens(true);
  //   }
  // };

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  return (
    <>
      <div className="row">
        <label className="PR_Card_Headings">
          Tell us about your basic details
        </label>
      </div>

      <label className="All_input_Field_error"></label>

      <div className="row PR_Input_fields_Tab2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <select
            className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
            ${
              isFocused
                ? "PR_FORM_Tab1_Select_option_Grey"
                : "PR_FORM_Tab1_Select_option_Black"
            }
            `}
            name={"marital_status"}
            {...register("marital_status")}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <option value={""} disabled>
              Marital Status
            </option>
            <option value={"Single"}>Single</option>
            <option value={"Married"}>Married</option>
            <option value={"Divorcee"}>Divorcee</option>
            <option value={"Widowed"}>Widowed</option>
            <option value={"Separated"}>Separated</option>
          </select>

          {errors.marital_status && (
            <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
              {errors.marital_status.message}
            </label>
          )}

          {!showChildrens ? (
            <></>
          ) : (
            <>
              <input
                type={"number"}
                className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
            ${
              isFocused
                ? "PR_FORM_Tab1_Select_option_Grey"
                : "PR_FORM_Tab1_Select_option_Black"
            }
              `}
                {...register("no_of_children")}
                name={"no_of_children"}
                min={1}
                max={10}
                autoComplete="off"
                placeholder={"No of children"}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </>
          )}

          <input
            type={"text"}
            className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
            ${
              isFocused
                ? "PR_FORM_Tab1_Select_option_Grey"
                : "PR_FORM_Tab1_Select_option_Black"
            }
            `}
            name={"height"}
            {...register("height")}
            autoComplete="off"
            placeholder={"Height"}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          {errors.height && (
            <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
              {errors.height.message}
            </label>
          )}

          <select
            className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
            ${
              isFocused
                ? "PR_FORM_Tab1_Select_option_Grey"
                : "PR_FORM_Tab1_Select_option_Black"
            }
            `}
            name={"religious_values"}
            {...register("religious_values")}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <option value={""} disabled>
              Religious Values
            </option>
            <option value={"Generosity "}>Generosity </option>
            <option value={"Courage"}>Courage</option>
            <option value={"Love"}>Love</option>
          </select>

          {errors.religious_values && (
            <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
              {errors.religious_values.message}
            </label>
          )}

          <select
            className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
            ${
              isFocused
                ? "PR_FORM_Tab1_Select_option_Grey"
                : "PR_FORM_Tab1_Select_option_Black"
            }
            `}
            name={"disability"}
            {...register("disability")}
          >
            <option value={""} disabled>
              Disability
            </option>
            <option value={"intellectual "}>intellectual </option>
            <option value={"physical"}>physical</option>
            <option value={"sensory"}>sensory</option>
            <option value={"mental illness"}>mental illness</option>
          </select>

          {errors.disability && (
            <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
              {errors.disability.message}
            </label>
          )}

          <div className="PR_Tab3_buttons">
            <input
              type={"button"}
              className="PR_Tab3_button1 PR_Form_Input_Fields"
              value={"Previous"}
              onClick={() => {
                navigate("/profileregister/FamilyDetails");
              }}
            />

            <input
              type={"submit"}
              className="PR_Tab3_button2 PR_Form_Input_Fields"
              value={"Next"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default withProfileBackground(ProfileRegister3, "PR3");

// /* eslint-disable no-unused-vars */
// import React, { useRef, useState } from "react";
// import withProfileBackground from "../withProfileBackground";
// import "../../ProfileRegister/ProfileRegister2/ProfileRegister2.css";
// import { useNavigate } from "react-router-dom";
// import "./ProfileRegister3.css";
// import axios from "axios";

// const ProfileRegister3 = () => {
//   const navigate = useNavigate();

//   // const ref = useRef();

//   // Common Error validation
//   const [error, setError] = useState("");

// // Form Field's state
// const [PrInputs, setPrInputs] = useState({
//   marital_status: "",
//   no_of_children: "",
//   height: "",
//   religious_values: "",
//   disability: "",
//   errors: {
//     marital_status: "",
//     no_of_children: "",
//     height: "",
//     religious_values: "",
//     disability: "",
//   },
//   touched: {
//     marital_status: false,
//     // no_of_children: false,
//     height: false,
//     religious_values: false,
//     disability: false,
//   },
// });
//   // console.log(PrInputs.touched);

// // Handle validations and Form input
// const handleChange = ({ target: { name, value } }) => {
//   const error = PrInputs.errors;
//   setError("");
//   switch (name) {
//     case "marital_status": {
//       error.marital_status = !value ? "Please select the Marital Status" : "";
//       break;
//     }
//     case "no_of_children": {
//       if (!value) {
//         error.no_of_children = !value
//           ? "Please select the no of children"
//           : "";
//       } else if (value >= 0 && value <= 5) {
//         error.no_of_children = "";
//       } else {
//         error.no_of_children = "";
//       }
//       break;
//     }
//     case "height": {
//       error.height = !value ? "Please select the height" : "";
//       break;
//     }
//     case "religious_values": {
//       error.religious_values = !value
//         ? "Please select the religious values"
//         : "";
//       break;
//     }
//     case "disability": {
//       error.disability = !value ? "Please select the above field" : "";
//       break;
//     }
//     default:
//       break;
//   }
//   console.log(name, value);
//   setPrInputs({ ...PrInputs, [name]: value });
// };

//   // Handle input blur validations
// const handleBlur = ({ target: { name } }) => {
//   const touched = { ...PrInputs.touched, [name]: true };
//   setPrInputs({ ...PrInputs, touched });
// };

//   // Backend validations and Error handling
// const handleSubmit = async (e) => {
//   try {
//     e.preventDefault();
//     const errors = PrInputs.errors;

//     // eslint-disable-next-line array-callback-return
//     Object.values(PrInputs).map((inp, idx) => {
//       if (
//         (idx <= 4 &&
//           inp === "" &&
//           PRTab3SelectInput[idx].name !== "no_of_children") ||
//         (idx <= 4 &&
//           inp === PRTab3SelectInput[idx].options[0].value &&
//           PRTab3SelectInput[idx].name !== "no_of_children")
//       ) {
//         errors[PRTab3SelectInput[idx].name] = ` ${
//           PRTab3SelectInput[idx].name === "marital_status"
//             ? "Please select the Marital status"
//             : PRTab3SelectInput[idx].name === "height"
//             ? "Please select the height"
//             : PRTab3SelectInput[idx].name === "religious_values"
//             ? "Please select the religious values"
//             : PRTab3SelectInput[idx].name === "disability"
//             ? "Please select the above field"
//             : ""
//         }`;

//         setPrInputs({ ...PrInputs, errors });
//         console.log(PrInputs, errors);
//       }
//     });

//     const errorCount = Object.values(PrInputs.errors).filter(
//       (err) => err !== ""
//     ).length;

//     const notTouched = Object.values(PrInputs.touched).filter((en) => !en);

//     if (!errorCount.length && !notTouched.length && errorCount === 0) {
//       const {
//         marital_status,
//         height,
//         religious_values,
//         disability,
//         no_of_children,
//       } = PrInputs;
//       const postData = await axios.post(
//         `http://192.168.1.15:8000/userDetails/PersonalDetails/JM1675135656`,
//         {
//           marital_status,
//           height,
//           religious_values,
//           disability,
//           no_of_children,
//         }
//       );
//       alert(postData);
//       navigate("/profileregister/EducationDetails");
//     }
//   } catch (error) {
//     console.log(error);
//     alert("something went wrong");
//   }
// };

// const [PRTab3SelectInput, setPRTab3SelectInput] = useState([
//   {
//     name: "marital_status",
//     options: [
//       { value: "Marital Status", disabled: true, selected: true },
//       { value: "Single", disabled: false },
//       { value: "Married", disabled: false },
//       { value: "Divorcee", disabled: false },
//       { value: "Widowed", disabled: false },
//       { value: "Separated", disabled: false },
//     ],
//     error: PrInputs.errors.marital_status,
//   },
//   {
//     type: "text",
//     name: "no_of_children",
//     placeholder: "No of children",
//     value: PrInputs.no_of_children,
//     error: PrInputs.errors.no_of_children,
//   },
//   {
//     name: "height",
//     type: "text",
//     placeholder: "Height",
//     value: PrInputs.height,
//     error: PrInputs.errors.height,
//   },
//   {
//     name: "religious_values",
//     options: [
//       {
//         value: "Religious values",
//         disabled: true,
//         selected: true,
//       },
//       { value: "Tamil", disabled: false },
//       { value: "English", disabled: false },
//       { value: "Hindi", disabled: false },
//     ],
//     error: PrInputs.errors.religious_values,
//   },
//   {
//     name: "disability",
//     options: [
//       { value: "Any disability", disabled: true, selected: true },
//       { value: "Tamil", disabled: false },
//       { value: "English", disabled: false },
//       { value: "Hindi", disabled: false },
//     ],
//     error: PrInputs.errors.disability,
//   },
// ]);

//   console.log(PrInputs.height);
//   // console.log(PRTab3SelectInput);
//   return (
//     <>
//       <div className="row">
//         <label className="PR_Card_Headings">
//           Tell us about your basic details
//         </label>
//       </div>

//       <label className="All_input_Field_error">{error}</label>

//       <div className="row PR_Input_fields_Tab2">
//         <form onSubmit={handleSubmit}>
//           {PRTab3SelectInput.map((input) => {
//             return (
//               <>
//                 <>
//                   {PrInputs.marital_status !== "" &&
//                   input.type === "text" &&
//                   PrInputs.marital_status !== "Single" ? (
//                     <>
//                       <input
//                         type={input.type}
//                         className="form-control PR_Form_Tab1_Input_Fields PR_Form_Tab3_no_of_children"
//                         name={input.name}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         value={PrInputs.no_of_children}
//                         placeholder={input.placeholder}
//                       />
//                     </>
//                   ) : (
//                     <>
//                       {input.type !== "number" && (
//                         <>
//                 <select
//                   className={`PR_FROM_Tabs_selectBox PR_Form_Tab3_Input_Fields
// ${
//   PrInputs[input.name] === input.options[0].value ||
//   PrInputs[input.name] === ""
//     ? "PR_FORM_Tab1_Select_option_Grey"
//     : "PR_FORM_Tab1_Select_option_Black"
// }`}
//                   name={input.name}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   // value={input.options[0].value}
//                 >
//                   {input.options.map((inp) => {
//                     return (
//                       <option
//                         value={inp.value}
//                         // disabled={inp.disabled}
//                         selected={inp.selected}
//                       >
//                         {inp.value}
//                       </option>
//                     );
//                   })}
//                 </select>
//                           <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
//                             {PrInputs.errors[input.name]}
//                           </label>
//                         </>
//                       )}
//                     </>
//                   )}
//                 </>
//               </>
//             );
//           })}

// <div className="PR_Tab3_buttons">
//   <input
//     type={"button"}
//     className="PR_Tab3_button1 PR_Form_Input_Fields"
//     value={"Previous"}
//     onClick={() => {
//       navigate("/profileregister/FamilyDetails");
//     }}
//   />

//   <input
//     type={"submit"}
//     className="PR_Tab3_button2 PR_Form_Input_Fields"
//     value={"Next"}
//   />
// </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default withProfileBackground(ProfileRegister3, "PR3");

// const PRTab3SelectInput = [
//   {
//     name: "marital_status",
//     type: "select",
//     options: [
//       { value: "Marital Status", disabled: true, selected: true },
//       { value: "Single", disabled: false },
//       { value: "Married", disabled: false },
//       { value: "Divorcee", disabled: false },
//       { value: "Widowed", disabled: false },
//       { value: "Separated", disabled: false },
//     ],
//     error: PrInputs.errors.marital_status,
//   },
//   {
//     type: "text",
//     name: "no_of_children",
//     placeholder: "No of children",
//     value: PrInputs.no_of_children,
//     error: PrInputs.errors.no_of_children,
//   },
//   {
//     name: "height",
//     type: "text",
//     placeholder: "Height",
//     value: PrInputs.height,
//     error: PrInputs.errors.height,
//   },
//   {
//     name: "religious_values",
//     type: "select",
//     options: [
//       {
//         value: "Religious values",
//         disabled: true,
//         selected: true,
//       },
//       { value: "Tamil", disabled: false },
//       { value: "English", disabled: false },
//       { value: "Hindi", disabled: false },
//     ],
//     error: PrInputs.errors.religious_values,
//   },
//   {
//     name: "disability",
//     type: "select",
//     options: [
//       { value: "Any disability", disabled: true, selected: true },
//       { value: "Tamil", disabled: false },
//       { value: "English", disabled: false },
//       { value: "Hindi", disabled: false },
//     ],
//     error: PrInputs.errors.disability,
//   },
// ];

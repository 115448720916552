import React from "react";
import LandingHeader from "../../components/Headers/Landing header/LandingHeader";
import "./withVerificationBackground.css"

const withVerificationBackground = (WrappedComponent, formName) => {
  return class extends React.Component {
    render() {
      return (
        <>
          <div className="container-fluid verifyBackgroundContainer">
            <div className="col-lg-12">
              <div className="row">
                <LandingHeader isLogin={true} />
              </div>

              <div className="row verifyBackground">
                <div className="card verifyCard">
                  <WrappedComponent />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
};

export default withVerificationBackground;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import HomeHeader from "../../../components/Headers/Home header/HomeHeader";
import SearchesHeader from "../../../components/Searches/SearchesHeader/SearchesHeader";
import "../MatrimonyIDSearch/MatrimonyIDSearch.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";

const MatrimonyIDSearch = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(()=> {
    if(!token){
      navigate("/login");
    }
  },[])

  const [IDSearch, setIDSearch] = useState("");

  const [IDError, setIDError] = useState("");

  const handleChange = ({ target: { value } }) => {
    setIDSearch(value);
    setIDError("");
  };

  const handleSearch = async () => {
    if (IDSearch === "") {
      setIDError("Please Enter the ID");
    } else {
      try {
        const user_id = IDSearch;

        const PostData = await axios.post(
          `${BACKEND_PORT}/search/matrimonyId`,
          { user_id }
        );

        if(PostData.data.status){
          navigate("/individual", {
            state: {
              profilepic: PostData?.data?.user?.profilePictures[0],
              data: PostData?.data?.user
            },
          });
        }
      } catch (error) {
        console.log(error.response.data.message);
        setIDError(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Search</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/mobileSearchBox");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="MatrimonyIDSearchContainer">
              <span className="DesktopSearchHeader">
                <SearchesHeader />
              </span>

              <span className="SearchingPageTopHeading">
                <p>Search by Matrimony ID</p>
              </span>

              <div className="matrimonialIDSearch">
                <div>Search by ID</div>
                <div className="KeywordSearch-Input">
                  <input
                    type="text"
                    name="SearchingWord"
                    value={IDSearch}
                    onChange={handleChange}
                    className="matrimonyIdInputBox"
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>

                <button onClick={handleSearch}>Search</button>
              </div>
              {(!IDSearch || IDError) && (
                <label className="IDSearchError">{IDError}</label>
              )}
            </div>
            <div className="row searchFooter">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatrimonyIDSearch;

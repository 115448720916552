/* eslint-disable no-unused-vars */
import { TextArea } from "@progress/kendo-react-inputs";
import React from "react";
import "./PersonalDetails_FewWords.css";

const PersonalDetails_FewWords = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
}) => {

  return (
    <div className={"PersonalDetails_FewWords"}>
      <div className="fewWordBoxHeader">
        <h6>Few words about {`her`}</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("FewWords");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {editProcess === "FewWords" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>
      </div>
      
      <div
        className={` ${
          editProcess === "FewWords"
            ? "EditTime_PersonalDetails_card"
            : "card PersonalDetails_card"
        }`}
      >
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("FewWords");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {editProcess === "FewWords" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>

        <div
          className={`${
            editProcess === "FewWords"
              ? "PersonalDetails_FewWords_EditTime"
              : "PersonalDetails_FewWords_PTag"
          }`}
        >
          {editProcess === "FewWords" ? (
            <>
              <div className="partnerFieldAns">
                <TextArea
                  maxLength={200}
                  name={"FewWordsAboutMe"}
                  onChange={handleChange}
                  value={ProfileDetails.FewWordsAboutMe}
                  className="PR_Tab5_form_inputBox FedwWordsInputBox"
                ></TextArea>
              </div>
            </>
          ) : (
            <>
              <p>{ProfileDetails.FewWordsAboutMe}</p>
            </>
          )}
        </div>

        {editProcess === "FewWords" ? (
          <div className="Details_section_update_buttons">
            <button
              className="save"
              onClick={() => {
                handleSave("save");
              }}
            >
              Save
            </button>
            <button
              className="cancel"
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PersonalDetails_FewWords;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import HomeHeader from "../../../components/Headers/Home header/HomeHeader";
import SearchesHeader from "../../../components/Searches/SearchesHeader/SearchesHeader";
import "../KeywordSearch/KeywordSearch.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";

const KeywordSearch = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(()=> {
    if(!token){
      navigate("/login");
    }
  },[])

  const [keywordSearch, setKeywordSearch] = useState("");

  const [KeywordError, setKeywordError] = useState("");

  const handleChange = ({ target: { value } }) => {
    setKeywordSearch(value);
    setKeywordError("");
  };

  const handleSearch = async () => {
    if (keywordSearch === "") {
      setKeywordError("Please Enter the Keyword");
    } else {
      try {
        const keyword = keywordSearch;
        const getProfile = await axios.post(`${BACKEND_PORT}/search/keyword`, {
          keyword,
        });

        console.log(getProfile);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Search</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/mobileSearchBox");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="keywordSearchContainer">
              <span className="DesktopSearchHeader">
                <SearchesHeader />
              </span>

              <span className="SearchingPageTopHeading">
                <p>Keyword Search</p>
              </span>

              <div className="keywordSearchContainerHead">
                If you're looking for very specific results, try this Keyword
                search option
              </div>
              <div className="KeywordSearch-mid">
                <div className="KeywordSearch-tag">Keyword Search</div>

                <div className="KeywordSearch-Input">
                  <input
                    type="text"
                    name="SearchingWord"
                    autoComplete="off"
                    value={keywordSearch}
                    onChange={handleChange}
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                  {!keywordSearch && (
                    <label className="KeywordSearchError">{KeywordError}</label>
                  )}
                </div>

                <div className="KeywordSearch-ExamplesMid">
                  <p>Example : Catholic, Software Engineer, Slim, Chennai</p>
                </div>

                <div className="KeywordSearch-Button">
                  <button onClick={handleSearch}>Search</button>
                </div>
              </div>
              <div className="KeywordSearch-Examples">
                <p>
                  Example 1 : Catholic, Software engineer, Doctor, Slim, Chennai
                </p>
                <p>Example 2 : Doctor, Slim, Chennai</p>
              </div>

              <div className="keyword-bottom-mobile-hrTag">
                <hr />
              </div>

              <div className="KeywordSearch-Footer">
                <p>
                  Due to privacy reasons name search facility is not available
                  in keyword search.
                </p>
              </div>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordSearch;

{
  /* <div className="KeywordSearch">
<div className="KeywordSearch-Header">
  <SearchesHeader />
</div>
<div className="KeywordSearch-Context">
  <p>
    If you're looking for very specific results, try this Keyword
    search option
  </p>
</div>
<div className="KeywordSearch-mid">
  <div className="KeywordSearch-tag">
    <p> Keyword Search</p>
  </div>

  <div className="KeywordSearch-Input">
    <input type="text" />
  </div>
  <div className="KeywordSearch-Button">
    <button> Search</button>
  </div>
</div>
<div className="KeywordSearch-Examples">
  <p> Example 1 : Catholic, Software engineer, Chennai</p>
  <p>Example 2 : Doctor, Slim, Chennai</p>
</div>

<div className="KeywordSearch-Footer">
  <p>
    {" "}
    Due to privacy reasons name search facility is not available
    in keyword search.
  </p>
</div>
</div> */
}

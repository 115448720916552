import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import ProfileSettingsTab from "../../components/ProfileSettingsTab/ProfileSettingsTab";
import nayan from "../../assets/images/Home/nayan.jpg";
import { useNavigate } from "react-router-dom";
import "../PhotoPrivacy/PhotoPrivacy.css";

const PhotoPrivacy = () => {
  const data = [
    {
      name: "Request",
      id: "",
      value: "Show to verified profiles only",
      label: "Show to verified profiles only",
    },
    {
      name: "Request",
      id: "",
      value: "Show to subscribed members only",
      label: "Show to subscribed members only",
    },
    {
      name: "Request",
      id: "",
      value: "Show my photo only upon request",
      label: "Show my photo only upon request",
    },
    {
      name: "Request",
      id: "",
      value: "Show to everyone",
      label: "Show to everyone",
    },
  ];

  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Contact Us</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/settingsBox");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="profileDetails_pic_ID">
              <div className="profileImg">
                <img src={nayan} />
              </div>
              <div className="profileName">{`Cathy`}</div>
              <span className="profileID">{`JM123456`}</span>
            </div>

            <div className="PhotoPrivacy">
              <div className="Profile-Setting-Tab">
                <ProfileSettingsTab />
              </div>

              <div className="PhotoPrivacy-Inputs">
                <div className="PhotoPrivacy-Header">
                  Photo Privacy
                  <span> Control who can see your Photo</span>
                </div>

                {data.map((input) => (
                  <div className="PhotoPrivacy-Radio">
                    <input
                      type="radio"
                      id={input.id}
                      name={input.name}
                      value={input.value}
                    />
                    <label> {input.label}</label>
                  </div>
                ))}
              </div>
              <button onClick={() => {navigate("/settingsBox");}} className="PhotoPrivacyBackButton">Back</button>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoPrivacy;

import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import RadioInputField from "../../PartnerPreference/InputFields/RadioInputField";
import InputField from "../../PartnerPreference/InputFields/InputField";

const RegularSearchProfessionalDetails = ({
  RegularSearchField,
  handleChange,
}) => {
  // professional inputs
  const RegularSearchProfessionalInputs = [
    {
      inputType: "select",
      name: "education",
      value: RegularSearchField.education,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "employed",
      value: RegularSearchField.employed,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "occupation",
      value: RegularSearchField.occupation,
      // className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      // className: "partnerPreferenceSmallOption",
      inputType: "range",
      name: "annualIncome",
      field: [
        {
          name: "annualIncomeFrom",
          value: RegularSearchField.annualIncomeFrom,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          name: "annualIncometo",
          value: RegularSearchField.annualIncometo,
          options: [
            { value: "31", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
  ];
  return (
    <div className="regularSearchIndividualContainer">
      <div className="regularSearchHead">Professional Details</div>
      <div className="regularSearchContainerIndividualInputs">
        <div className="regularSearchLabels">
          <div className="MotherTongueEditTime">Education</div>
          <div className="EatingEditTime">Employed</div>
          <div className="SmokingEditTime">Occupation</div>
          <div className="DrinkingEditTime">Annual Income</div>
        </div>
        <div className="regularSearchInputs">
          {RegularSearchProfessionalInputs.map((input) =>
            input.inputType === "radio" ? (
              <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                <RadioInputField
                  input={input}
                  handleChange={handleChange}
                  inputFields={RegularSearchField}
                  className={`EditTimeRadioflexWrap`}
                />
              </div>
            ) : input.inputType === "select" ? (
              <div className="partnerFieldAns">
                <SelectInputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
              </div>
            ) : input.inputType === "range" ? (
              <div className="partnerFieldRange partnerFieldAns">
                <SelectInputField
                  input={input.field[0]}
                  className={input.className}
                  handleChange={handleChange}
                />
                <div> - </div>
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input.field[1]}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
                <span className="Details_section_values_rightLabel">
                  {input.name === "age" && <div>Years</div>}
                </span>
              </div>
            ) : (
              <div className="partnerFieldAns">
                <InputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
                <span className="Details_section_values_rightLabel">
                  {input.FieldLabel}
                </span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RegularSearchProfessionalDetails;

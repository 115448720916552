import React from "react";
import LandingHeader from "../../../components/Headers/Landing header/LandingHeader";
import "./withBGAddprofilePhotos.css";
import MsgBar_img from "../../../assets/images/ProfileRegister/AddProfilePhotos_MsgBar_img.png";

const withBGAddprofilePhotos = (AddPR_WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <div className="container-fluid AddPR_Photos_container">
          <div className="col-lg-12">
            <div className="row">
              <LandingHeader isLogin={true} />
            </div>
            <div className="row AddPR_Photos_cards">
              <div className="row Msg_bar">
                <img src={MsgBar_img} alt="MsgBar_img" className="MsgBar_img" />
                <p className="MsgBar_Text">
                  We Always value your Privacy! You can always control who will
                  be able to see your photos
                </p>
              </div>
              <div className="row WrappedCompnent_card">
                <AddPR_WrappedComponent />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
};

export default withBGAddprofilePhotos;

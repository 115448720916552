import React from "react";

import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";

const FilterProfileType = ({
  filterId,
  setFilterId,
  filterInputs,
  handleChange,
}) => {
  // profile type input fields
  const profileTypeInputs = [
    { name: "withPhoto", value: "withPhoto", label: "with photo" },
    { name: "withHoroscope", value: "withHoroscope", label: "with horoscope" },
    { name: "verifiedProfile", value: "verifiedProfile", label: "Verified profile" },
  ];

  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("profileType")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Profile Type</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("profileType")
              ? setFilterId(filterId.filter((e) => e !== "profileType"))
              : setFilterId([...filterId, "profileType"]);
          }}
        >
          {filterId.includes("profileType") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("profileType") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          <div className="dropDownComponentTitle">Show matches with</div>
          <div className="ProfileTypeCheckBoxsList">
            {profileTypeInputs.map((input) => (
              <CheckBoxInputField
                input={input}
                handleChange={handleChange}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterProfileType;

import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_PORT } from "../../../App";
import withProfileBackground from "../withProfileBackground";
import "./ProfileRegister2.css";

const ProfileRegister2 = () => {
  const navigate = useNavigate();
  const ref = useRef();

  // Common Error validation
  const [error, setError] = useState("");

  // Form Field's state
  const [PrInputs, setPrInputs] = useState({
    denomination: "",
    division: "",
    sub_caste: "",
    marry_another_community: "",
    family_status: "",
    family_type: "",
    errors: {
      denomination: "",
      division: "",
      sub_caste: "",
      marry_another_community: "",
      family_status: "",
      family_type: "",
    },
    touched: {
      denomination: false,
      division: false,
      sub_caste: false,
      marry_another_community: false,
      family_status: false,
      family_type: false,
    },
  });

  // Handle validations and Form input
  const handleChange = ({ target: { name, value } }) => {
    const error = PrInputs.errors;
    setError("");
    switch (name) {
      case "denomination": {
        error.denomination = !value ? "Please select the denomination" : "";
        break;
      }
      case "division": {
        error.division = !value ? "Please select the division" : "";
        break;
      }
      case "sub_caste": {
        error.sub_caste = !value ? "" : "";
        break;
      }
      case "marry_another_community": {
        error.marry_another_community = !value
          ? "Please answer the above field"
          : "";
        break;
      }
      case "family_status": {
        error.family_status = !value ? "Please select the family status" : "";
        break;
      }
      case "family_type": {
        error.family_type = !value ? "Please select the family type" : "";
        break;
      }
      default:
        break;
    }
    setPrInputs({ ...PrInputs, [name]: value }, error);
  };

  // Handle input blur validations
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...PrInputs.touched, [name]: true };
    setPrInputs({ ...PrInputs, touched });
  };

  // Backend validations and Error handling
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = PrInputs.errors;

      // eslint-disable-next-line array-callback-return
      Object.values(PrInputs).map((inp, idx) => {
        if (
          (idx <= 5 &&
            inp === "" &&
            PRTab2SelectInput[idx].name !== "sub_caste") ||
          (idx <= 5 &&
            inp === PRTab2SelectInput[idx].options[0].value &&
            PRTab2SelectInput[idx].name !== "sub_caste")
        ) {
          errors[PRTab2SelectInput[idx].name] = ` ${
            PRTab2SelectInput[idx].name === "marry_another_community"
              ? "Please answer the above field"
              : PRTab2SelectInput[idx].name === "family_status"
              ? "Please select the family status"
              : PRTab2SelectInput[idx].name === "family_type"
              ? "Please select the family type"
              : "Please select the " + PRTab2SelectInput[idx].name.toLowerCase()
          }`;

          setPrInputs({ ...PrInputs, errors });
        }
      });

      const errorCount = Object.values(PrInputs.errors).filter(
        (err) => err !== ""
      ).length;

      const notTouched = Object.values(PrInputs.touched).filter((en) => !en);

      if (!error.length && notTouched.length <= 1 && errorCount === 0) {
        const {
          denomination,
          division,
          sub_caste,
          family_status,
          marry_another_community,
          family_type,
        } = PrInputs;

        let User_ID = sessionStorage.getItem("User_ID")

        let postData = await axios.put(
          `${BACKEND_PORT}/userDetails/FamilyDetails/${User_ID}`,
          {
            denomination,
            division,
            sub_caste,
            marry_another_community,
            family_status,
            family_type,
          }
        );
        console.log(postData);
        navigate("/profileregister/PersonalDetails");
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
  };

  const [PRTab2SelectInput, setPRTab2SelectInput] = useState([
    {
      name: "denomination",
      options: [
        { value: "denomination", disabled: true, selected: true },
        { value: "Catholic", disabled: false },
        { value: "Chruch of south(CSI)", disabled: false },
        { value: "Born Again", disabled: false },
        { value: "Pentecostal", disabled: false },
        { value: "Latin Catholic", disabled: false },
        { value: "Protestant", disabled: false },
        { value: "Orthodox", disabled: false },
      ],
      error: PrInputs.errors.denomination,
    },
    {
      name: "division",
      options: [
        { value: "division", disabled: true, selected: true },
        { value: "Adi Dravidar", disabled: false },
        { value: "Anglo Indian", disabled: false },
        { value: "Chettiar", disabled: false },
        { value: "Garo", disabled: false },
        { value: "Gounder", disabled: false },
        { value: "Goan", disabled: false },
        { value: "Kamma", disabled: false },
        { value: "Kapu", disabled: false },
        { value: "Khasi", disabled: false },
        { value: "Knanaya", disabled: false },
        { value: "Kuki", disabled: false },
        { value: "Madiga", disabled: false },
        { value: "Mahar", disabled: false },
        { value: "Mala", disabled: false },
        { value: "Mangalorean", disabled: false },
        { value: "Matang", disabled: false },
        { value: "Mizo", disabled: false },
        { value: "Mudaliar", disabled: false },
        { value: "Mukkuvar", disabled: false },
        { value: "Nadar", disabled: false },
        { value: "Naga", disabled: false },
        { value: "Naidu", disabled: false },
        { value: "Oroan / Kurukh", disabled: false },
        { value: "Padmashali", disabled: false },
        { value: "Pallar / Devendrakula Vellalar", disabled: false },
        { value: "Paravar / Bharathar / Fernando", disabled: false },
        { value: "Parkavakulam / Udayar", disabled: false },
        { value: "Pillai", disabled: false },
        { value: "Pulayar / Cherumar", disabled: false },
        { value: "Rajaka / Vannar", disabled: false },
        { value: "Reddy", disabled: false },
        { value: "Sambavar", disabled: false },
        { value: "Scheduled Caste", disabled: false },
        { value: "Scheduled Tribe", disabled: false },
        { value: "Setti Balija", disabled: false },
        { value: "Thevar / Mukkulathor", disabled: false },
        { value: "Vaniya Chettiar", disabled: false },
        { value: "Vanniya Kula Kshatriyar", disabled: false },
        { value: "Vellalar", disabled: false },
        { value: "Vishwakarma", disabled: false },
        { value: "Yadavar", disabled: false },
        { value: "Intercaste", disabled: false },
        { value: "Others", disabled: false },
        { value: "Don't know division", disabled: false },
        { value: "Don't wish to specify", disabled: false },
      ],
      error: PrInputs.errors.division,
    },
    {
      name: "sub_caste",
      options: [
        { value: "sub_caste (Optional)", disabled: true, selected: true },
        { value: "Latin Catholic", disabled: false },
        { value: "Pulaya", disabled: false },
        { value: "Roman Catholic", disabled: false },
        { value: "Syrian", disabled: false },
      ],
      error: "",
    },
    {
      name: "marry_another_community",
      options: [
        {
          value: "Willing to marry another community?",
          disabled: true,
          selected: true,
        },
        { value: "Yes", disabled: false },
        { value: "No", disabled: false },
      ],
      error: PrInputs.errors.marry_another_community,
    },
    {
      name: "family_status",
      options: [
        { value: "Family status", disabled: true, selected: true },

        { value: "Upper-Middle Class", disabled: false },
        { value: "Middle Class", disabled: false },
        { value: "Rich", disabled: false },
        { value: "Affluent", disabled: false },
      ],
      error: PrInputs.errors.family_status,
    },
    {
      name: "family_type",
      options: [
        { value: "Family type", disabled: true, selected: true },

        { value: "Nuclear Family", disabled: false },
        { value: "Single Parent", disabled: false },
        { value: "Extended Family", disabled: false },
        { value: "Childless Family", disabled: false },
        { value: "Stepfamily", disabled: false },
        { value: "Grandparent Family", disabled: false },
      ],
      error: PrInputs.errors.family_type,
    },
  ]);

  return (
    <>
      <div className="row">
        <label className="PR_Card_Headings">
          Tell us about your basic details
        </label>
      </div>

      <label className="All_input_Field_error">{error}</label>

      <div className="row PR_Input_fields_Tab2">
        <form onSubmit={handleSubmit}>
          {PRTab2SelectInput.map((input, idx) => {
            return (
              <>
                <select
                  className={`PR_FROM_Tabs_selectBox PR_Form_Tab2_Input_Fields ${
                    PrInputs[input.name] === input.options[0].value ||
                    PrInputs[input.name] === ""
                      ? "PR_FORM_Tab1_Select_option_Grey"
                      : "PR_FORM_Tab1_Select_option_Black"
                  }`}
                  name={input.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={input.options[0].value}
                >
                  {input.options.map((inp) => {
                    return (
                      <option
                        value={inp.value}
                        disabled={inp.disabled}
                        selected={inp.selected}
                      >
                        {inp.value}
                      </option>
                    );
                  })}
                </select>
                <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label2">
                  {PrInputs.errors[input.name]}
                </label>
              </>
            );
          })}

          <div className="PR_Tab2_buttons">
            <input
              type={"button"}
              className="PR_Tab2_button1 PR_Form_Input_Fields"
              value={"Previous"}
              onClick={() => {
                navigate("/profileregister/BasicDetails");
              }}
            />

            <input
              type={"submit"}
              className="PR_Tab2_button2 PR_Form_Input_Fields"
              value={"Next"}
              // onClick={() => {
              //   navigate("/profileregister/PersonalDetails");
              // }}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default withProfileBackground(ProfileRegister2, "PR2");

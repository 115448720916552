import React from "react";

import edit from "../../../../assets/images/Home/homeEdit.png";
import settings from "../../../../assets/images/Home/homeSettings.png";
import logout from "../../../../assets/images/Home/homeLogout.png";
import "./HomeProfileBox.css";

const HomeProfileBox = ({ logoutPopup, setLogoutPopup, setHeaderBox }) => {
  const homeHeaderOptions = [
    { image: edit, option: "Edit Profile", ref: "/editProfilePage" },
    { image: settings, option: "Settings", ref: "/changeEmailId" },
  ];
  return (
    <div className="homeHeaderProfileContainer">
      <div className="homeHeaderProfileName">Cathy</div>
      <div className="homeHeaderprofileAccountDetails">
        <div>
          Your Account ID : <span> JM123456 </span>
        </div>
        <div>
          Account type : <span>Free</span>
        </div>
      </div>
      <div className="homeHeaderProfileOptions">
        {homeHeaderOptions.map((options, idx) => (
          <div className="homeHeaderProfileOption" key={idx}>
            <div className="homeHeaderProfileOptionImage">
              <img src={options.image} />
            </div>
            <a href={options.ref} onClick={options.onclick}>
              {options.option}
            </a>
          </div>
        ))}

        <div className="homeHeaderProfileOption">
          <div className="homeHeaderProfileOptionImage">
            <img src={logout} />
          </div>
          <a
            // href={"#"}
            onClick={() => {
              setLogoutPopup(true);
              setHeaderBox("");
            }}
          >
            Log out
          </a>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default HomeProfileBox;

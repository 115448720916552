import React, { useEffect } from "react";
import "./RequestPhoto.css";

const RequestPhoto = ({ requestPhoto, setrequestPhoto }) => {
  useEffect(() => {
    requestPhoto
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [requestPhoto]);
  // console.log(requestPhoto)
  return (
    <div className="requestPhotoPopup">
      <div className="requestPopupContent">
        <div
          className="InterestPopUp-Close"
          onClick={() => {
            document.getElementById("body").style.overflowY = "auto";
            setrequestPhoto(false);
          }}
        >
          <i class="fa-solid fa-xmark"></i>
        </div>
        <div className="InterestPopUp-Box">
          <div className="InterestPopUp-Context">
            <p>
              You will be able to see the photo once your request to view the
              photo has been accepted by the match.
            </p>
          </div>

          <div className="InterestPopUp-Buttons">
            <div className="InterestPopUp-Button-2">
              <button
                onClick={() => {
                  document.getElementById("body").style.overflowY = "auto";
                  setrequestPhoto(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPhoto;

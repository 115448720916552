import React, { useEffect, useState } from "react";
import HomeHeader from "../../../components/Headers/Home header/HomeHeader";
import SearchesHeader from "../../../components/Searches/SearchesHeader/SearchesHeader";
import RegularSearchBasicDetails from "../../../components/Searches/RegularSearch/RegularSearchBasicDetails";
import RegularSearchReligiousDetails from "../../../components/Searches/RegularSearch/RegularSearchReligiousDetails";
import RegularSearchProfessionalDetails from "../../../components/Searches/RegularSearch/RegularSearchProfessionalDetails";
import RegularSearchLocation from "../../../components/Searches/RegularSearch/RegularSearchLocation";
import ReligiousSearchHoroscopeDetails from "../../../components/Searches/RegularSearch/ReligiousSearchHoroscopeDetails";
import Footer from "../../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import RegularSearchBasic from "./RegularSearchBasic/RegularSearchBasic";
import "./RegularSearch.css";
import RegularSearchReligion from "./RegularSearchReligion/RegularSearchReligion";
import RegularSearchProfessional from "./RegularSearchProfessional/RegularSearchProfessional";
import RegularSearchHoroscope from "./RegularSearchHoroscope/RegularSearchHoroscope";
import RegularSearchLocationPreference from "./RegularSearchLocationPreference/RegularSearchLocationPreference";
import { BACKEND_PORT } from "../../../App";
import axios from "axios";

const RegularSearch = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(()=> {
    if(!token){
      navigate("/login");
    }
  },[])

  const [RegularSearchField, setRegularSearchField] = useState({
    ageFrom: "",
    ageTo: "",
    heightFrom: "",
    heightTo: "",
    bodyType: "",
    maritalStatus: "",
    physicalStatus: "",
    motherTounge: "",
    foodHabits: "",
    drinkingHabbits: "",
    smokingHabits: "",
    denomination: "",
    division: "",
    subCaste: "",
    education: "",
    employed: "",
    occupation: "",
    annualIncomeFrom: "",
    annualIncometo: "",
    star: "",
    raasi: "",
    country: "",
    state: "",
    city: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setRegularSearchField({ ...RegularSearchField, [name]: value });
    // console.log(RegularSearchField);
  };

  const handleSave = async () => {
    try {
      const postData = await axios.post(`${BACKEND_PORT}/search/regular`, {
        age_from: RegularSearchField.ageFrom,
        age_to: RegularSearchField.ageTo,
        height_from: RegularSearchField.heightFrom,
        height_to: RegularSearchField.heightTo,
        annual_income_from: RegularSearchField.annualIncomeFrom,
        annual_income_to: RegularSearchField.annualIncometo,
        body_type: RegularSearchField.bodyType,
        mother_tongue: RegularSearchField.motherTounge,
        physical_status: RegularSearchField.physicalStatus,
        food_habits: RegularSearchField.foodHabits,
        sub_caste: RegularSearchField.subCaste,
        education: RegularSearchField.education,
        marital_status: RegularSearchField.maritalStatus,
        
        eating_habits: RegularSearchField.foodHabits,
        smoking_habits: RegularSearchField.smokingHabits,
        drinking_habits: RegularSearchField.drinkingHabbits,
        // disability:RegularSearchField.disability,
        denomination: RegularSearchField.denomination,
        division: RegularSearchField.division,
        employeed_in: RegularSearchField.employed,
        occupation: RegularSearchField.occupation,
        work_location: RegularSearchField.work_location,
        star: RegularSearchField.star,
        raasi: RegularSearchField.raasi,
        country: RegularSearchField.country,
        state: RegularSearchField.star,
        city: RegularSearchField.city,
      });
      console.log(postData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="col-lg-12">
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="searchBoxTapBar">
              <span className="PageHeading">Search</span>
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/mobileSearchBox");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </span>

          <div className="regularSearchContainer DesktopRegularSearchContainer">
            <SearchesHeader />

            {/* basic details search */}
            <RegularSearchBasicDetails
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* religious details search */}
            <RegularSearchReligiousDetails
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* professional details search */}
            <RegularSearchProfessionalDetails
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* Horoscope Details search */}
            <ReligiousSearchHoroscopeDetails
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* location details search */}
            <RegularSearchLocation
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            <div className="regularSearchButton">
              <button onClick={handleSave}>Search</button>
            </div>
          </div>

          <div className="MobileRegularSearchContainer">
            <span className="SearchingPageTopHeading">
              <p>Regular Search</p>
            </span>

            {/* basic preferences */}
            <RegularSearchBasic
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* Religion preferences */}
            <RegularSearchReligion
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* Professional Details */}
            <RegularSearchProfessional
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* Horoscope Details */}
            <RegularSearchHoroscope
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            {/* Location preferences */}
            <RegularSearchLocationPreference
              RegularSearchField={RegularSearchField}
              handleChange={handleChange}
            />

            <div className="RegularSearchButton">
              <button onClick={handleSave}>Search</button>
            </div>
          </div>

          {/* footer */}
          <div className="row">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularSearch;

import React from "react";
import InputField from "../../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../../components/PartnerPreference/InputFields/SelectInputField";
import "./RegularSearchLocationPreference.css";

function RegularSearchLocationPreference({ RegularSearchField, handleChange }) {
  const RegularSearchBasicInputs = [
    {
      inputType: "select",
      name: "country",
      value: RegularSearchField.country,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "state",
      value: RegularSearchField.state,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Preferred State/States", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },

    {
      inputType: "select",
      name: "city",
      value: RegularSearchField.city,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Preferred City/Cities", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="RSbasicPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle">Professional Details</div>
      </div>

      <div className={`RSDetailsSectionRow_EditTime`}>
        <div className="partnerInputs">
          <ul className={`RSDetails_section_label_EditTime`}>
            <li className="MotherTongueEditTime">Country</li>
            <li className="EatingEditTime">State</li>
            <li className="EatingEditTime">City</li>
          </ul>
          <ul className={`PartnerDetails_section_colon_EditTime`}>
            <li className="MotherTongueEditTime">:</li>
            <li className="EatingEditTime">:</li>
            <li className="EatingEditTime">:</li>
          </ul>
          <ul className="Details_section_values partnerDetails_section_values">
            {RegularSearchBasicInputs.map((input) =>
              input.inputType === "select" ? (
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RegularSearchLocationPreference;

import React from "react";
import DateInputField from "../../../components/PartnerPreference/InputFields/DateInputField";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import TimeInputField from "../../../components/PartnerPreference/InputFields/TimeInputField";
import "./PersonalDetails_HoroscopeDetails.css";

const PersonalDetails_HoroscopeDetails = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
  setProfileDetails,
}) => {
  const PersonalDetails_HoroscopeDetails = [
    {
      className: "PersonalDetailsTextBox",
      inputType: "text",
      name: "PlaceofBirth",
      value: ProfileDetails.PlaceofBirth,
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "date",
      name: "DateOfBirth",
      value: ProfileDetails.DateOfBirth,
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "time",
      name: "TimeOfBirth",
      value: ProfileDetails.TimeOfBirth,
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Star",
      value: ProfileDetails.Star,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Ashwini", disabled: false },
        { value: "Bharani", disabled: false },
        { value: "Krittika", disabled: false },
        { value: "Rohini", disabled: false },
        { value: "Mrigashirsha", disabled: false },
        { value: "Aardraa", disabled: false },
        { value: "Punarvasu", disabled: false },
        { value: "Pushya", disabled: false },
        { value: "Ashlesha", disabled: false },
        { value: "Maagha", disabled: false },
        { value: "Poorva Phalkuni", disabled: false },
        { value: "Utra Phalkuni", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Raasi",
      value: ProfileDetails.Raasi,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Aries", disabled: false },
        { value: "Taurus", disabled: false },
        { value: "Gemini", disabled: false },
        { value: "Cancer", disabled: false },
        { value: "Leo", disabled: false },
        { value: "Virgo", disabled: false },
        { value: "Libra", disabled: false },
        { value: "Scorpio", disabled: false },
        { value: "Sagittarius", disabled: false },
        { value: "Capricorn", disabled: false },
        { value: "Aquarius", disabled: false },
        { value: "Pisces", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsTextBox",
      inputType: "file",
      name: "HoroscopeCopy",
      value: ProfileDetails.HoroscopeCopy,
    },
  ];

  const onSelectFile = (e) => {
    setProfileDetails({
      ...ProfileDetails,
      HoroscopeCopy: e.target.files[0].name,
    });
  };

  return (
    <div
      className={`${
        editProcess === "HoroscopeDetails"
          ? "EditTimePersonalDetails_HoroscopeDetails_Card"
          : "PersonalDetails_HoroscopeDetails"
      } `}
      id="HoroscopeDetails"
    >
      <div className="fewWordBoxHeader">
        <h6>Horoscope Details</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("HoroscopeDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "HoroscopeDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
      </div>

      <div className="card PersonalDetails_card">
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("HoroscopeDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "HoroscopeDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
        <div
          className={`${
            editProcess === "HoroscopeDetails"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          <div className="Details_section">
            <ul
              className={`Details_section_label ${
                editProcess === "HoroscopeDetails"
                  ? "Details_section_label_EditTime"
                  : ""
              }`}
            >
              <li>Place of Birth</li>
              <li>Date of Birth</li>
              <li>Time of Birth</li>
              <li>Star</li>
              <li>Raasi</li>
              <li>Horoscope Copy</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "HoroscopeDetails"
                  ? "Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
            </ul>

            {editProcess === "HoroscopeDetails" ? (
              <>
                <ul className="Details_section_values">
                  {PersonalDetails_HoroscopeDetails.map((input) =>
                    input.inputType === "radio" ? (
                      <div className="partnerFieldAns partnerPreferenceRadioFields">
                        <RadioInputField
                          input={input}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "select" ? (
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : input.inputType === "text" ? (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    ) : input.inputType === "time" ? (
                      <div className="partnerFieldAns">
                        <input
                          type={input.inputType}
                          name={input.name}
                          value={input.value}
                          onChange={handleChange}
                          className={input.className}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    ) : input.inputType === "date" ? (
                      <div className="partnerFieldAns EditPrPageDateField">
                        <input
                          type={input.inputType}
                          name={input.name}
                          value={input.value}
                          onChange={handleChange}
                          className={input.className}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    ) : input.inputType === "file" ? (
                      <>
                        {!ProfileDetails.HoroscopeCopy ? (
                          <label className={`Details_section_BlueButton`}>
                            Browse
                            <input
                              type={"file"}
                              accept={"image/*"}
                              onChange={onSelectFile}
                              style={{ display: "none" }}
                            />
                          </label>
                        ) : (
                          <span className="HoroscopeCopyFileName">
                            <span>{ProfileDetails.HoroscopeCopy}</span>
                            <span
                              className="x"
                              onClick={() => {
                                setProfileDetails({
                                  ...ProfileDetails,
                                  HoroscopeCopy: "",
                                });
                              }}
                            >
                              X
                            </span>
                          </span>
                        )}
                      </>
                    ) : (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    )
                  )}
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails.PlaceofBirth === "" || ProfileDetails.PlaceofBirth === undefined ||
                    ProfileDetails.PlaceofBirth === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("HoroscopeDetails");
                        }}
                      >
                        {`Add Place of Birth`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.PlaceofBirth
                    )}
                  </li>
                  <li>
                    {ProfileDetails.DateOfBirth === "" || ProfileDetails.DateOfBirth === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("HoroscopeDetails");
                        }}
                      >
                        {`Add Date of Birth`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.DateOfBirth
                    )}
                  </li>
                  <li>
                    {ProfileDetails.TimeOfBirth === "" || ProfileDetails.TimeOfBirth === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("HoroscopeDetails");
                        }}
                      >
                        {`Add Time Of Birth`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.TimeOfBirth
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Star === "" || ProfileDetails.Star === undefined ||
                    ProfileDetails.Star === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("HoroscopeDetails");
                        }}
                      >
                        {`Add Star`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Star
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Raasi === "" || ProfileDetails.Raasi === undefined ||
                    ProfileDetails.Raasi === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("HoroscopeDetails");
                        }}
                      >
                        {`Add Raasi`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Raasi
                    )}
                  </li>
                  <li>
                    {!ProfileDetails.HoroscopeCopy || ProfileDetails.HoroscopeCopy === undefined ? (
                      <label className={`Details_section_BlueButton`}>
                        Browse
                        <input
                          type={"file"}
                          accept={"image/*"}
                          onChange={onSelectFile}
                          style={{ display: "none" }}
                        />
                      </label>
                    ) : (
                      <span className="HoroscopeCopyFileName">
                        <span>{ProfileDetails.HoroscopeCopy}</span>
                        <span
                          className="x"
                          onClick={() => {
                            setProfileDetails({
                              ...ProfileDetails,
                              HoroscopeCopy: "",
                            });
                          }}
                        >
                          X
                        </span>
                      </span>
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "HoroscopeDetails" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_HoroscopeDetails;

{
  /* <ul className="Details_section_values">
              <li>{`Not Specified`}</li>
              <li>{`Not Specified`}</li>
              <li>{`Not Specified`}</li>
              <li>{`Thiruvonam`}</li>
              <li>{`Capricorn`}</li>
              <li>
                {!HoroscopeCopy.length ? (
                  <label className={`Details_section_BlueButton`}>
                    Browse
                    <input
                      type={"file"}
                      accept={"image/*"}
                      onChange={onSelectFile}
                      style={{ display: "none" }}
                    />
                  </label>
                ) : (
                  <span className="HoroscopeCopyFileName">
                    <span>{HoroscopeCopy}</span>
                    <span
                      className="x"
                      onClick={() => {
                        setHoroscopeCopy("");
                      }}
                    >
                      X
                    </span>
                  </span>
                )}
              </li>
            </ul> */
}

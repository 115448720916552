import React, { useState, useEffect, useRef } from "react";
import "./FormInputs.css";

const FormInputs = ({
  name,
  type,
  placeholder,
  value,
  handleChange,
  handlePassword,
  handleBlur,
  classname,
  passwordEye,
  passwordChecking,
  error,
}) => {
  // when clicking on the placeholder the input is not getting focused
  // because of which when click on the placeholder we can focus on the input instead
  const [foc, setFoc] = useState(false);
  const focus = useRef();

  useEffect(() => {
    if (foc) {
      focus.current.focus();
    }
  }, [foc]);

  // when clickin on the place holder the input gets focused but the prob is that we have to click twice
  // for second time to get focus after the initial focus this might cause a prob (since double click)
  // sol we have to set focus to false after setting it to true it works only when using handle Foc.
  const handleFoc = () => {
    setFoc((prev) => !prev);
    setTimeout(() => {
      setFoc((prev) => !prev);
    });
  };

  return (
    <>
      <div className="formContainer">
        <input
          type={type}
          name={name}
          value={value}
          ref={focus}
          onChange={handleChange}
          onBlur={handleBlur}
          className={`${foc ? "foc" : ""} ${
            value.length ? "animatePlaceholder" : ""
          } ${classname} MobileScreenWidth`}
          autoComplete="off"
        />
        <span className="loginPlaceholder" onClick={handleFoc}>
          {placeholder}
        </span>
        {error && (
          <span className="inpErr">
            {/* <span className="material-symbols-outlined">error</span> */}
            {error}
          </span>
        )}
        {classname === "loginPasswordEye" && (
          <>
            <span
              className="material-symbols-outlined logineyeicon"
              onClick={handlePassword}
            >
              {passwordEye ? "visibility_off" : "visibility"}
            </span>
          </>
        )}
        {classname === "regPassword" && name === "password" && (
          <>
            <span
              className="material-symbols-outlined eyeicon"
              onClick={() => {
                handlePassword("password");
              }}
            >
              {passwordEye.password ? "visibility_off" : "visibility"}
            </span>

            <span className="passwordChecking">
              <div className="passwordCheckingHead">Password must contain:</div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case1
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case1 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast 8 characters
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case2
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case2 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast one uppercase letter
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case3
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case3 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast one number
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case4
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case4 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                at least one special character(#$&@)
              </div>
            </span>

            <span className="MobilepasswordChecking">
              <div className="passwordCheckingHead">Password must contain:</div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case1
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case1 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast 8 characters
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case2
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case2 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast one uppercase letter
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case3
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case3 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                atleast one number
              </div>
              <div
                className={`passwordCheckingBody ${
                  passwordChecking.case4
                    ? "passwordCheckingGreen"
                    : "passwordCheckingRed"
                }`}
              >
                <span className="passCheckIcon">
                  {passwordChecking.case4 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa-solid fa-xmark"></i>
                  )}
                </span>
                at least one special character(#$&@)
              </div>
            </span>

            
          </>
        )}
        {name === "confirmPassword" && (
          <span
            className="material-symbols-outlined eyeicon"
            onClick={() => {
              handlePassword("confirmpassword");
            }}
          >
            {passwordEye.confirmPassword ? "visibility_off" : "visibility"}
          </span>
        )}
      </div>
    </>
  );
};

export default FormInputs;

import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../ChangeEmailId/ChangeEmailId.css";
import BG from "../../assets/images/SettingImages/ChangeEmailBG.png";
import nayan from "../../assets/images/Home/nayan.jpg";
import ProfileSettingsTab from "../../components/ProfileSettingsTab/ProfileSettingsTab";
import InputField from "../../components/PartnerPreference/InputFields/InputField";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

const ChangeEmailId = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const [password, SetPassword] = useState(false);
  const [InvalidPassword, setInvalidPassword] = useState(false);
  const PrInputs = {
    email: "",
    password: "",
  };

  const [formValues, setSetValues] = useState(PrInputs);
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });

  const handleChange = ({ target: { name, value } }) => {
    const error = formErrors;

    switch (name) {
      case "email": {
        if (!value.length) {
          error.email = "Please enter the Email";
          break;
        } else if (value.includes("@") && value.includes(".com")) {
          error.email = "";
        } else {
          error.email = "Invalid Email ID";
          break;
        }
      }
      case "password":
        error.password = !value ? "Please enter the password" : "";
        break;

      default:
        break;
    }

    setSetValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, error });
  };

  const handleEmail = (e) => {
    if (formValues.email.length && !formErrors.email.length) {
      SetPassword(true);
    } else {
      setFormErrors({ ...formErrors, email: "Enter Email ID" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user_id = window.sessionStorage.getItem("User_ID");

    if (formValues.password.length && !formErrors.password.length) {
      try {
        const postData = await axios.put(`${BACKEND_PORT}/changeEmail`, {
          user_id,
          email: formValues.email,
          password: formValues.password,
        });

        if (postData.data === "Check mail") {
          navigate("/emailVerification", {
            state: {
              UserID: user_id,
              email: formValues.email,
            },
          });
        }

        // setInvalidPassword(true);
        // navigate("/emailVerification", {
        //   state: {
        //     UserID: user_id,
        //     email: formValues.email,
        //   },
        // });
      } catch (error) {
        setFormErrors({ ...formErrors, email: error.response.data.message });
        if (error) {
          formValues.email = "";
          formValues.password = "";
          SetPassword(false);
        }
      }
    } else {
      setFormErrors({ ...formErrors, password: "Invalid Inputs" });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Email</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/settingsBox");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="profileDetails_pic_ID">
              <div className="profileImg">
                <img src={nayan} />
              </div>
              <div className="profileName">{`Cathy`}</div>
              <span className="profileID">{`JM123456`}</span>
            </div>

            <div className="ChangeEmail DesktopChangeEmailContents">
              <div className="ChangeEmail-Head">
                <div className="ChangeEmail-Filter-Box">
                  <ProfileSettingsTab />
                </div>

                {!InvalidPassword ? (
                  <form onSubmit={handleSubmit}>
                    {!password ? (
                      <>
                        <div className="ChangeEmail-Message-Box">
                          Change your Email ID
                          <div className="ChangeEmail-Input">
                            <input
                              type="email"
                              placeholder="Enter your new Email ID"
                              name="email"
                              value={formValues.email}
                              onChange={handleChange}
                              autoComplete={"off"}
                            />
                            <button onClick={handleEmail}>Save</button>
                          </div>
                          <label className="CheckEmail_errors">
                            {formErrors.email}
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="ChangeEmail-Message-Box">
                          Enter your current Password
                          <div className="ChangeEmail-Input">
                            <input
                              type="password"
                              placeholder="Enter your current Password"
                              name="password"
                              value={formValues.password}
                              onChange={handleChange}
                              autoComplete={"off"}
                            />
                            <input
                              type={"submit"}
                              value="Go"
                              className="PasswordButton"
                            />
                          </div>
                          <label className="CheckEmail_errors">
                            {formErrors.password}
                          </label>
                        </div>
                      </>
                    )}
                  </form>
                ) : (
                  <>
                    <div className="ChangeEmail-Message-Box">
                      Change your Email ID
                      <div className="ChangeEmail-Input invalidPassword_inputs">
                        <label className="CheckEmail_errors">
                          {"Invalid password"}
                        </label>
                        <button
                          onClick={() => {
                            setInvalidPassword(false);
                          }}
                          className="invalidPassword_label"
                        >
                          Try again
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="ChangeEmail-Background">
                <img src={BG} />
              </div>
            </div>

            <div className="MobileChangeEmailContents">
              <label className="SettingsBoxLabel">Profile Settings</label>
              <h6>Change your Email ID</h6>
              <form onSubmit={handleSubmit} className="ChangeEmailIDFormInput">
                <input
                  type={"email"}
                  placeholder={"Enter your new Email ID"}
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  autoComplete={"off"}
                />
                <input
                  type={"password"}
                  placeholder={"Enter your current Password"}
                  name="password"
                  value={formValues.password}
                  onChange={handleChange}
                  autoComplete={"off"}
                />

                <label className="CheckEmail_errors">
                  {formErrors.email}
                  {formErrors.email && formErrors.password && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  {formErrors.password}
                </label>

                <input type={"submit"} value={"Go"} className="GoButton" />
              </form>
              <img
                src={BG}
                alt="ChangeEmailBGImage"
                className="ChangeEmailBGImage"
              />
            </div>

            <div className="row MobileChangeEmailFooterRow">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmailId;

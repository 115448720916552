import React from "react";
import { useNavigate } from "react-router-dom";
import candidate from "../../assets/images/Home/nayan.jpg";

function SettingsBox() {
  const navigate = useNavigate();
  return (
    <>
      <div className="searchBoxTapBar">
        Settings
        <div
          className="leftArrowIcon"
          onClick={() => {
            navigate("/home");
          }}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      </div>

      <div className="SearchBoxContents MatchesBoxContents">
        <div className="MatchesBoxCandidateProfile">
          <div className="MBprofilePhoto">
            <img src={candidate} />
          </div>
          <p className="candidateName">{"Cathy"}</p>
          <p className="candidateId">{"JM123456"}</p>
        </div>
        <label className="MatchesBoxLabel">Profile Settings</label>
        <div className="SearchBoxButtons">
          <button
            onClick={() => {
              navigate("/changeEmailId");
            }}
          >
            <label>Change Email ID</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              //   navigate("/");
            }}
          >
            <label>Change Mobile No</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/history", {
                state: {
                  header1: "History",
                  header2: "History",
                },
              });
            }}
          >
            <label>History</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/customersupport");
            }}
          >
            <label>Customer Support</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/ShortlistedProfiles", {
                state: {
                  header1: "Shortlisted profiles",
                  header2: "",
                },
              });
            }}
          >
            <label>Shortlisted Profiles</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/photoPrivacy", {
                state: {
                  header1: "Photo Privacy",
                  header2: "",
                },
              });
            }}
          >
            <label>Photo Privacy</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default SettingsBox;


import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../Individualpages/IndividualPage.css";
import SubscribePopUp from "../../components/Individual/Subscribe/SubscribePopUp";
import InterestPopUp from "../../components/Individual/ShowInterest/InterestPopUp";
import BlockPopUp from "../../components/Individual/Block/BlockPopUp";
import FIMG from "../../assets/images/Home/nayan.jpg";
import MIMG from "../../assets/images/Home/homeProfilePic2.png";
import NA from "../../assets/images/Matches/NA.png";
import RING from "../../assets/images/Landing/ring.png";
import Share from "../../assets/images/Matches/shareWhite.png";
import ChatIcon from "../../assets/images/Matches/Chat_Icon.png";
import Verify from "../../assets/images/Matches/Verified_User.png";
import RequestPhoto from "../../components/Individual/RequestPhoto/RequestPhoto";
import nayan from "../../assets/images/Home/nayan.jpg";
import ProfilePicPlaceholder from "../../assets/images/Home/HomeProfilePlaceHolder.png";
import MobileNotificationIcon from "../../assets/images/Sidebar/NotificationBell Icon.png";
import userProfilePicture from "../../assets/images/Individual/userProfilePicture.png";
import GreenNotificationPointIcon from "../../assets/images/Sidebar/GreenNotificationPointIcon.png";
import informationIcon from "../../assets/images/Individual/information-button.png";
import { BACKEND_PORT } from "../../App";
import axios from "axios";

const IndividualPage = () => {
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({});
  const [profilePic, setProfilePic] = useState();

  // request photo pop up
  const [requestPhoto, setrequestPhoto] = useState(false);

  const Navigate = useNavigate();
  const {
    state: { profilepic, data, user_id },
  } = useLocation();

  useEffect(() => {
    (async () => {
      const getData = await axios.get(
        `${BACKEND_PORT}/profiles/view/${user_id}`
      );

      setProfileData(getData.data.getUsersbyId);
      setProfilePic(getData.data.getUsersbyId.profilePictures[0]);
    })();
  }, []);

  console.log(profileData);

  // matches data sent from the matches page
  const [matchData, setMatchData] = useState(data);

  // Shortlist checkbox
  const [shortList, SetshortList] = useState(false);

  // share profile pop up
  const [subscribePopUp, setSubscribePopUp] = useState(false);
  const [interestPopUp, setInterestPopUp] = useState(false);
  const [blockPopUp, setBlockPopUp] = useState(false);

  // when clicking on the matches page the page goes to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log(matchData);
  return (
    <>
      <div className="container-fluid matchesContainerPage">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="PageNotFoundTapBar">
                <span className="PageHeading">Testimonials</span>

                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/mobileMatchesBox");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>

                <div className="PageNotFoundProfilePics">
                  {profileData?.profilePictures ? (
                    <>
                      <img src={profileData?.profilePictures[0]} />
                    </>
                  ) : (
                    <img
                      src={ProfilePicPlaceholder}
                      className="placeHolderImg"
                    />
                  )}
                </div>
                <span className="pageNotFoundProfileDetails">Hey ! Cathy</span>

                <span
                  className="MobileNotificationIcon"
                  onClick={() => {
                    navigate("/notifications");
                  }}
                >
                  <img src={MobileNotificationIcon} alt="NotificationBell" />
                  <img
                    src={GreenNotificationPointIcon}
                    alt="MobileNotificationPoint"
                    className="MobileNotificationPoint"
                  />
                </span>
              </div>
            </span>
          </div>

          {/* background grey when popup */}
          <div
            // share profile

            className={`
               ${subscribePopUp && "matchesBlack"} ${
              interestPopUp && "matchesBlack"
            }
              ${requestPhoto && "matchesBlack"}
              ${blockPopUp && "matchesBlack"}`}
          />

          <div className="Individualpage ">
            {subscribePopUp && (
              <SubscribePopUp
                subscribe={subscribePopUp}
                setSubscribePopUp={setSubscribePopUp}
              />
            )}
            {interestPopUp && (
              <InterestPopUp
                interest={interestPopUp}
                setInterestPopUp={setInterestPopUp}
              />
            )}
            {blockPopUp && (
              <BlockPopUp block={blockPopUp} setBlock={setBlockPopUp} />
            )}

            {/* request photo popup */}
            {requestPhoto && (
              <div className="matchesSubscribe">
                {/* {console.log(requestPhoto)} */}
                <RequestPhoto
                  setrequestPhoto={setrequestPhoto}
                  requestPhoto={requestPhoto}
                />
              </div>
            )}

            <div className="Individaulpage-Head-Section">
              <div className="Individaulpage-Head-Section-Left">
                <div className="Individaulpage-Head-Section-Image">
                  <div className="Individaulpage-Head-Section-Main-Image">
                    {/* <img src={MIMG}/> */}
                    {matchData.profilepic ===
                      "The user has not uploaded the profile photo" ||
                    matchData.profilepic === "View photo request sent" ? (
                      <>
                        <div
                          className="macthesNoProfilePicIndiviudual"
                          onClick={() => {
                            setMatchData((matchesData) => {
                              return {
                                ...matchesData,
                                profilepic:
                                  matchesData.profilepic ===
                                  "The user has not uploaded the profile photo"
                                    ? "View photo request sent"
                                    : "The user has not uploaded the profile photo",
                              };
                            });
                          }}
                        >
                          <div>{matchData.profilepic}</div>
                          {matchData.profilepic ===
                            "The user has not uploaded the profile photo" && (
                            <button
                              className="macthesNoProfilePicRequest"
                              onClick={() => {
                                setrequestPhoto(true);
                              }}
                            >
                              Request Now
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={profilePic} />
                      </>
                    )}
                  </div>
                  <div className="Individaulpage-Head-Section-Small-Image">
                    {profileData?.profilePictures?.slice(0, 3)?.map((item) => {
                      return <img src={item} />;
                    })}
                  </div>
                </div>
                <div className="Individaulpage-Head-Section-Matches">
                  <p> Match Score</p>
                  <span>
                    <span> 12</span>/18
                  </span>
                </div>
              </div>

              <div className="Individaulpage-Head-Section-Right">
                <div className="Individaulpage-Head-Section-Right-Heading">
                  <div className="Individaulpage-Head-Section-Right-Name">
                    <div className="Individaulpage-Head-Section-Right-Status">
                      <p> Thomas Vijayaraj</p>{" "}
                      <b>
                        <i class="fa-solid fa-circle"></i> Online
                      </b>
                    </div>

                    <span>
                      {" "}
                      JM123456 <b>| Profile created by Friend</b>
                    </span>

                    <div className="Individaulpage-Head-Section-Right-Verification">
                      <img src={Verify} /> <span>Verified</span>
                    </div>
                  </div>

                  <div className="Buttons">
                    <div className="Chatnow">
                      <button
                        onClick={() => {
                          setSubscribePopUp(true);
                        }}
                      >
                        <img src={ChatIcon} /> Chat now
                      </button>
                    </div>

                    <div className="Share">
                      <button>
                        <img src={Share} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="Caterogy">
                  <div className="Column-1">
                    <div>
                      <img src={RING} /> <span>25 Years, 171 cms</span>
                    </div>
                    <div>
                      <img src={RING} /> <span>BE, Sofware Engineer</span>
                    </div>
                    <div>
                      <img src={RING} /> <span>Roman catholic</span>
                    </div>
                  </div>
                  <div className="Column-2">
                    <div>
                      <img src={RING} /> <span>Chennai, Tamil Nadu</span>
                    </div>
                    <div>
                      <img src={RING} />{" "}
                      <span>Earns Rs 7-10 Lakhs annually</span>
                    </div>
                    <div className="Row-3">
                      <img src={RING} />{" "}
                      <span>Star is Rohini, Raasi is Taurus</span>
                      <a href="#">View Horoscope</a>
                    </div>
                  </div>
                </div>

                <div className="Content-box">
                  <div>
                    <p>Like his profile? Connect with him now</p>
                  </div>

                  <div className="Shortlisted">
                    {shortList ? (
                      <>
                        <button
                          onClick={() => {
                            SetshortList(false);
                          }}
                        >
                          <i class="fa-solid fa-check"></i> Shortlisted
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            SetshortList(true);
                          }}
                        >
                          <i className="fa-regular fa-star"></i> Shortlist
                        </button>
                      </>
                    )}
                  </div>

                  <div className="Individual-Page-Show-Interest">
                    <button
                      onClick={() => {
                        setInterestPopUp(true);
                      }}
                    >
                      <i className="fa-solid fa-user-group"></i> Show Interest
                    </button>
                  </div>
                </div>
                <div
                  className="Individual-Page-Block"
                  onClick={() => {
                    setBlockPopUp(true);
                  }}
                >
                  <p>
                    <i class="fa-solid fa-ban"></i> Block Profile
                  </p>
                </div>
              </div>
            </div>

            <div className="MobileView-Individaulpage-Head-Section">
              <div className="individualProfileDetails">
                <div className="IndividualUserDetails">
                  <div className="userProfileImageDivision">
                    <div className="CheckingUserVerification">
                      <i class="fa-solid fa-circle-check"></i>
                      Verified user
                    </div>
                    <div className="userProfileImage">
                      <img
                        src={userProfilePicture}
                        alt="User Profile Picture"
                      />
                    </div>
                    <div className="ProfilePictureSliderDivision">....</div>
                  </div>

                  <div className="userInformationDivision">
                    <div className="userName">
                      {`Thomas Vijayaraj`}
                      <span className="OnlineStatus"> Online</span>
                    </div>
                    <div className="profilePersonalDetails">
                      <div className="info">{`25 Yrs, 171 cms`}</div>
                      <div className="info">{`BE, Software Engineer`}</div>
                      <div className="info">{`Roman catholic`}</div>
                      <div className="info">{`Chennai, Tamilnadu`}</div>
                      <div className="info">{`Earns ₹ 7-10 Lakhs/Annum`}</div>
                      <div className="info">
                        {`Rohini/Taurus`}{" "}
                        <span className="ViewHoroScopeLink">
                          View Horoscope
                        </span>
                      </div>
                    </div>
                    <div className="MatchingScore">
                      <span className="MatchScoreLabel">Match Score</span>
                      <span className="MatchScoreValue">
                        {12}/<span className="FixedValue">{18}</span>
                        <img src={informationIcon} alt="informationIcon" />
                        {/* <i class="fa-solid fa-circle-info"></i> */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="UserIdDetails">
                  <span className="UserProfileID">{`JM123456`}</span>{" "}
                  <span className="dashSymbol">|</span>{" "}
                  <span className="UserProfileCreatedBYLabel">{`Profile created by Friend`}</span>
                </div>
              </div>

              <div className="likeHisProfileSection">
                <div className="likeHisProfileLabel">
                  Like his profile?{" "}
                  <span className="likeHisProfileLabel_Connect">Connect</span>{" "}
                  with him now
                </div>
                <div className="ProfileHisButtons">
                  <button
                    className="chatbtn"
                    onClick={() => {
                      setSubscribePopUp(true);
                    }}
                  >
                    <i class="fa-solid fa-comment-dots"></i>
                    <span>Chat now</span>
                  </button>

                  {shortList ? (
                    <button
                      className="shortListbtn"
                      onClick={() => {
                        SetshortList(false);
                      }}
                    >
                      <i class="fa-solid fa-check"></i>
                      <span>Shortlist</span>
                    </button>
                  ) : (
                    <button
                      className="shortListbtn"
                      onClick={() => {
                        SetshortList(true);
                      }}
                    >
                      <i class="fa-regular fa-star"></i>
                      <span>Shortlist</span>
                    </button>
                  )}

                  <button
                    className="shortListbtn"
                    onClick={() => {
                      setInterestPopUp(true);
                    }}
                  >
                    <i class="fa-regular fa-user"></i>
                    <span>Show Interest</span>
                  </button>
                </div>
              </div>

              <div className="share_block_profile">
                <div className="shareProfile">
                  <i class="fa-solid fa-share-nodes"></i>
                  <span className="ProfileLabel">Share Profile</span>
                </div>
                <div className="blockProfile">
                  <i class="fa-solid fa-ban"></i>
                  <span className="ProfileLabel">Block Profile</span>
                </div>
              </div>
            </div>

            <div className="Individaulpage-Mid-Section">
              <div className="Individaulpage-About">
                <p>About Him</p>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos.
                </span>
              </div>

              <div className="Individaulpage-BasicDetails">
                <p>Basic Details</p>

                <div className="Individaulpage-Table">
                  <div className="Individaulpage-Lable">
                    <span> Profile created for</span>
                    <span>Age</span>
                    <span>Height</span>
                    <span>Weight</span>
                    <span>Marital Status</span>
                    <span>Eating Habits</span>
                    <span>Smoking Habits</span>
                    <span>Drinking Habits</span>
                    <span>Contact Number</span>
                  </div>
                  <div className="Individaulpage-Input">
                    <span>Friend</span>
                    <span>27 Years</span>
                    <span>175 cms</span>
                    <span>70 kgs</span>
                    <span>Never Married</span>
                    <span>Vegetarian</span>
                    <span>Never</span>
                    <span>Never</span>
                    <span className="profileContactNumber">0987654321</span>
                  </div>
                </div>
                <span className="SubcriptionContactNumber">
                  {" "}
                  <Link to="/subscribe" className="SubscriptionLink">
                    <i class="fa-solid fa-lock"></i> Subscribe{" "}
                  </Link>
                  to view the contact details
                </span>
              </div>

              <div className="Individaulpage-Religious-Details">
                <p>Religious Details</p>

                <div className="Individaulpage-Table">
                  <div className="Individaulpage-Lable">
                    <span> Denomination</span>
                    <span>Division</span>
                    <span>Star</span>
                    <span>Raasi</span>
                    <span>Horoscope</span>
                  </div>
                  <div className="Individaulpage-Input">
                    <span>Roman Catholic</span>
                    <span>Chettiyar</span>
                    <span>Rohini</span>
                    <span>Taurus</span>
                    <a href="#">Request Horoscope</a>
                  </div>
                </div>
              </div>

              <div className="Individaulpage-Professional-Details">
                <p>Professional Details</p>

                <div className="Individaulpage-Table">
                  <div className="Individaulpage-Lable">
                    <span> Education</span>
                    <span>Employed in</span>
                    <span>Occupation</span>
                    <span>Occupation in detail</span>
                    <span>Organization name</span>
                    <span className="Annual_IncomeRow">Annual Income</span>
                  </div>
                  <div className="Individaulpage-Input">
                    <span>BE</span>
                    <span>Private</span>
                    <span>Software Engineer</span>
                    <a href="#">Request Detail</a>
                    <span className="Individaulpage_organizationName">
                      Infosys
                    </span>

                    <span className="Annual_IncomeRow">Rs 7-10 lakh</span>
                  </div>
                </div>
                <div className="middleSubscriptionLine">
                  <span className="forSubcriptionTag">
                    {" "}
                    <a
                      href="#"
                      onClick={() => {
                        Navigate("/subscribe");
                      }}
                      className="SubscriptionLink"
                    >
                      {" "}
                      <i class="fa-solid fa-lock"></i> Subscribe{" "}
                    </a>{" "}
                    to view the organization details
                  </span>
                </div>
              </div>

              <div className="Individaulpage-Location-Details">
                <p>Location Details</p>

                <div className="Individaulpage-Table">
                  <div className="Individaulpage-Lable">
                    <span> Country</span>
                    <span>State</span>
                    <span>City</span>
                    <span>Citizenship</span>
                  </div>
                  <div className="Individaulpage-Input">
                    <span>India</span>
                    <span>Tamil Nadu</span>
                    <span>Chennai</span>
                    <span>Indian</span>
                  </div>
                </div>
              </div>

              <div className="Individaulpage-Family-Details">
                <p>Family Details</p>

                <div className="Individaulpage-Table">
                  <div className="Individaulpage-Lable">
                    <span> Family values</span>
                    <span>Family type</span>
                    <span>Family Status</span>
                    <span>Father’s Occupation</span>
                    <span> Mother’s Occupation</span>
                    <span>Brothers</span>
                    <span>Brothers married</span>
                    <span>Sisters</span>
                    <span>Sisters married</span>
                  </div>
                  <div className="Individaulpage-Input">
                    <span>Traditional</span>
                    <span>Nuclear</span>
                    <span>Rich</span>
                    <span>Railways</span>
                    <span>Teacher</span>
                    <span>2 Brothers</span>
                    <span>1 </span>
                    <span>1 Sister</span>
                    <span>1</span>
                  </div>
                </div>
              </div>

              <div className="Individaulpage-Profile-Details">
                <p>Profile Verification</p>

                <div className="Individaulpage-Table ProfileVerificationCard">
                  <span className="IndividualCheckTickIcon">
                    {" "}
                    <i className="fa-solid fa-circle-check"></i>{" "}
                    <span>Name and Age has been verified with ID</span>
                  </span>
                  <span className="IndividualCheckTickIcon">
                    {" "}
                    <i className="fa-solid fa-circle-check"></i>{" "}
                    <span>
                      Education Details has been verififed with degree
                      certificate
                    </span>
                  </span>
                  <span className="IndividualCheckTickIcon">
                    {" "}
                    <i className="fa-solid fa-circle-check"></i>{" "}
                    <span>Mobile number has been verified</span>
                  </span>
                </div>
                <div className="Individaulpage-Profile-Condtion">
                  <span>
                    *We have checked the documents uploaded by the member
                    against the information provided in the profile. You are
                    solely responsible to check the authenticity of the profile
                  </span>
                </div>
              </div>

              <div className="Individaulpage-Profile-Matches">
                <p className="IndividualProfileMatchesMainHeading">
                  See how well your profile matches with your partner
                </p>
                <div className=".Individaulpage-Profile-Matches-Header">
                  <div className="div1">
                    <div className="Individaulpage-Profile-Matches-Image1">
                      <img src={FIMG} />
                    </div>
                    <div className="Individaulpage-Profile-Matches-Context">
                      <p>
                        You match <span>12</span>/18 of his preferences
                      </p>
                    </div>
                    <div className="Individaulpage-Profile-Matches-Image2">
                      <img src={MIMG} />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="Individaulpage-Profile-Matches-Parents">
                  <p>Few lines about my partner </p>
                  <p className="headerParentsParagraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, metus nec fringilla accumsan, risus sem
                    sollicitudin lacus, ut interdum tellus elit sed risus.
                    Maecenas
                  </p>
                </div>

                <div className="Individaulpage-Profile-Matches-Table">
                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Age</p>
                      <span>25-31 Years</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Height</p>
                      <span>5 ft 2'' to 5 ft 8''</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Wrong">
                      <span>
                        <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Marital Status</p>
                      <span>Never married</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Physical Status</p>
                      <span>Normal</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Mother Tongue</p>
                      <span>Tamil</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Food Habits</p>
                      <span>Vegetarian</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Smoking Habits</p>
                      <span>Never</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-NA">
                      {/* <p>Field not selected by the Match !!</p> */}
                      <div>
                        <img src={NA} />
                      </div>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Drinking Habits</p>
                      <span>Never</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Denomination</p>
                      <span>CSI</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-NA">
                      {/* <p>Field not selected by the Match !!</p> */}
                      <div>
                        <img src={NA} />
                      </div>
                    </div>
                    {/* <label>Field not selected by the Match !!</label> */}
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Division</p>
                      <span>Nadar</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Wrong">
                      <span>
                        <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Subcaste</p>
                      <span>lorem ipsum</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Education</p>
                      <span>BA BL</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Wrong">
                      <span>
                        <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Employed in</p>
                      <span>Private</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Occupation</p>
                      <span>Lawyer</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Wrong">
                      <span>
                        <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Annual Income</p>
                      <span>₹4-8 Lks/annum</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Wrong">
                      <span>
                        <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>Country</p>
                      <span>India</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>State</p>
                      <span>Tamil Nadu</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>

                  <div className="Individaulpage-Profile-Matches-Label">
                    <div className="Individaulpage-Profile-Matches-Category">
                      <p>City</p>
                      <span>Coimbatore</span>
                    </div>

                    <div className="Individaulpage-Profile-Matches-Right">
                      <span>
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualPage;

import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PersonalDetails_ProfessionalDetails.css";
const PersonalDetails_ProfessionalDetails = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
}) => {
  const PersonalDetails_ProfessionalDetails = [
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Education",
      value: ProfileDetails.Education,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Graduate", disabled: false },
        { value: "Post Graduate", disabled: false },
        { value: "Under Graduate", disabled: false },
        { value: "School", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsTextBox PersonalDetailsTextBoxHuge",
      inputType: "text",
      name: "College",
      value: ProfileDetails.College,
    },
    {
      inputType: "radio",
      name: "EmployedIn",
      value: ProfileDetails.field,
      field: [
        {
          name: "EmployedIn",
          label: "Government",
          value: "Government",
          id: "Government",
          for: "Government",
        },
        {
          name: "EmployedIn",
          label: "Private",
          value: "Private",
          id: "Private",
          for: "Private",
        },
        {
          name: "EmployedIn",
          label: "Business",
          value: "Business",
          id: "Business",
          for: "Business",
        },
        {
          name: "EmployedIn",
          label: "Defence",
          value: "Defence",
          id: "Defence",
          for: "Defence",
        },
        {
          name: "EmployedIn",
          label: "Self Employed",
          value: "SelfEmployed",
          id: "SelfEmployed",
          for: "SelfEmployed",
        },
        {
          name: "EmployedIn",
          label: "NotWorking",
          value: "NotWorking",
          id: "NotWorking",
          for: "NotWorking",
        },
      ],
    },
    {
      className: "PersonalDetailsMediumOption",
      inputType: "select",
      name: "Occupation",
      value: ProfileDetails.Occupation,
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Employee", disabled: false },
        { value: "Manager", disabled: false },
        { value: "Team Leader", disabled: false },
        { value: "General Manager", disabled: false },
      ],
    },
    {
      className: "PersonalDetailsTextBox PersonalDetailsTextBoxHuge",
      inputType: "text",
      name: "OccupationInDetail",
      value: ProfileDetails.OccupationInDetail,
    },
    {
      className: "PersonalDetailsTextBox PersonalDetailsTextBoxHuge",
      inputType: "text",
      name: "OrganizationName",
      value: ProfileDetails.OrganizationName,
    },
    {
      inputType: "select",
      name: "AnnualIncomeCurrency",
      className: "PersonalDetailsMediumOption",
      // currencyType: {
      // name: "AnnualIncomeCurrencyType",
      //   value: ProfileDetails.AnnualIncomeCurrencyType,
      //   options: [
      //     { value: "Indian Rupees", disabled: true, selected: true },
      //     { value: "Dollar", disabled: false },
      //     { value: "Euro", disabled: false },
      //     { value: "Pound", disabled: false },
      //     { value: "Swiss", disabled: false },
      //     { value: "Philllipine", disabled: false },
      //   ],
      // },
      income: {
        name: "AnnualIncome",
        value: ProfileDetails.AnnualIncome,
        options: [
          { value: "2 - 3 Lakhs", disabled: true, selected: true },
          { value: "4 - 5 Lakhs", disabled: false },
          { value: "5 - 8 Lakhs", disabled: false },
          { value: "8 - 10 Lakhs", disabled: false },
          { value: "above 10L", disabled: false },
          { value: "above 20L", disabled: false },
        ],
      },
    },
  ];

  return (
    <div
      className={`${
        editProcess === "ProfessionalDetails"
          ? "EditTimePersonalDetails_ProfessionalDetails_Card"
          : "PersonalDetails_ProfessionalDetails"
      } `}
    >
      <div className="fewWordBoxHeader">
        <h6>Professional Details</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("ProfessionalDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "ProfessionalDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
      </div>

      <div className="card PersonalDetails_card">
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("ProfessionalDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>
          {editProcess === "ProfessionalDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            "Edit"
          )}
        </button>
        <div
          className={`${
            editProcess === "ProfessionalDetails"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          <div className="Details_section">
            <ul
              className={`Details_section_label ${
                editProcess === "ProfessionalDetails"
                  ? "Details_section_label_EditTime"
                  : ""
              }`}
            >
              <li>Education</li>
              <li>College/Institution</li>
              <li className="EditEmployeedIN">Employed in</li>
              <li>Occupation</li>
              <li className="EditOccupationInD">Occupation in detail</li>
              <li className="EditOccupationInN">Organization Name</li>
              <li className="EditInAnnual">Annual Income</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "ProfessionalDetails"
                  ? "Details_section_colon_EditTime PD_Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li>:</li>
              <li>:</li>
              <li className="EditEmployeedIN">:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
              <li>:</li>
            </ul>

            {editProcess === "ProfessionalDetails" ? (
              <>
                <ul className="Details_section_values PD_Details_section_values">
                  {PersonalDetails_ProfessionalDetails.map((input) =>
                    input.inputType === "radio" ? (
                      <div className="partnerFieldAns EditPartnerPreferenceRadioFields partnerPreferenceRadioFields">
                        <RadioInputField
                          input={input}
                          handleChange={handleChange}
                          inputFields={ProfileDetails}
                        />
                      </div>
                    ) : input.inputType === "select" &&
                      input.name === "AnnualIncomeCurrency" ? (
                      <div className="PersonaolDetails_ProfessionalDetails_Annual EditProfessionalDeatilsAnnual">
                        {/* <div className="partnerFieldAns">
                          <SelectInputField
                            input={input.currencyType}
                            className={input.className}
                            handleChange={handleChange}
                          />
                        </div> */}
                        <div className="partnerFieldAns">
                          <SelectInputField
                            input={input.income}
                            className={input.className}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    ) : input.inputType === "select" ? (
                      <div className="partnerFieldAns">
                        <SelectInputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                      </div>
                    ) : (
                      <div className="partnerFieldAns">
                        <InputField
                          input={input}
                          className={input.className}
                          handleChange={handleChange}
                        />
                        <span className="Details_section_values_rightLabel">
                          {input.FieldLabel}
                        </span>
                      </div>
                    )
                  )}
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails.Education === "" || ProfileDetails.Education === undefined ||
                    ProfileDetails.Education === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add Education`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Education
                    )}
                  </li>
                  <li>
                    {ProfileDetails.College === "" || ProfileDetails.College === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add College`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.College
                    )}
                  </li>
                  <li>
                    {ProfileDetails.EmployedIn === "" || ProfileDetails.EmployedIn === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add Details`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.EmployedIn
                    )}
                  </li>
                  <li>
                    {ProfileDetails.Occupation === "" || ProfileDetails.Occupation === undefined ||
                    ProfileDetails.Occupation === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add Occupation`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.Occupation
                    )}
                  </li>
                  <li>
                    {ProfileDetails.OccupationInDetail === "" || ProfileDetails.OccupationInDetail === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add details`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.OccupationInDetail
                    )}
                  </li>
                  <li>
                    {ProfileDetails.OrganizationName === "" || ProfileDetails.OrganizationName === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add details`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.OrganizationName
                    )}
                  </li>
                  <li>
                    {ProfileDetails.AnnualIncome === "" || ProfileDetails.AnnualIncome === undefined ||
                    // ProfileDetails.AnnualIncomeCurrencyType === "" || ProfileDetails.AnnualIncomeCurrencyType === undefined ||
                    ProfileDetails.AnnualIncome === "Select" ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("ProfessionalDetails");
                        }}
                      >
                        {`Add details`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      `${ProfileDetails.AnnualIncome} `
                      // ${ProfileDetails.AnnualIncomeCurrencyType}
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "ProfessionalDetails" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_ProfessionalDetails;

import React from "react";

import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";
import "./FilterBasicDetails.css";
const FilterBasicDetails = ({ filterId, setFilterId, filterInputs, handleChange }) => {
  // basic input fields
  const basicInputs = [
    {
      className: "partnerPreferenceSmallOption selectFielBox",
      inputType: "range",
      label: "Age",
      field: [
        {
          name: "ageFrom",
          value: filterInputs.ageFrom,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          name: "ageTo",
          value: filterInputs.ageTo,
          options: [
            { value: "31", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
    {
      className: "partnerPreferenceSmallOption selectFielBox",
      inputType: "range",
      label: "Height",
      field: [
        {
          value: filterInputs.heightFrom,
          name: "heightFrom",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          value: filterInputs.heightTo,
          name: "heightTo",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
    {
      label: "Mother Toungue",
      inputType: "checkBox",
      checkInputs: [
        { name: "MotherToungue", value: "tamil", label: "Tamil" },
        { name: "MotherToungue", value: "telugu", label: "Telugu" },
        { name: "MotherToungue", value: "malayalam", label: "Malayalam" },
        { name: "MotherToungue", value: "kannada", label: "kannada" },
      ],
    },
    {
      label: "Marital Status",
      inputType: "checkBox",
      checkInputs: [
        {
          name: "MaritalStatus",
          value: "NeverMarried",
          label: "Never married",
        },
        {
          name: "MaritalStatus",
          value: "Widow/Widower",
          label: "Widow/Widower",
        },
        { name: "MaritalStatus", value: "Divorced", label: "Divorced" },
      ],
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("basicDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Basic Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("basicDetails")
              ? setFilterId(filterId.filter((e) => e !== "basicDetails"))
              : setFilterId([...filterId, "basicDetails"]);
          }}
        >
          {filterId.includes("basicDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("basicDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {basicInputs.map((input) =>
            input.inputType === "range" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className="FilterboxPartnerFieldRange partnerFieldRangeFlex">
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input.field[0]}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                    <div>to</div>
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input.field[1]}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                    {input.label === "Age" && <div>Years</div>}
                    {input.label === "Height" && <div>Cms</div>}
                  </div>
                </div>
              </>
            ) : input.inputType === "checkBox" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className="ProfileTypeCheckBoxsList">
                    {input.checkInputs.map((inp) => (
                      <CheckBoxInputField input={inp} />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          )}
        </div>
      )}
    </div>
  );
};

export default FilterBasicDetails;

import React from "react";

import homeLeftRose from "../../../assets/images/Home/homeRoseLeft.png";
import homeRightRose from "../../../assets/images/Home/homeRoseRight.png";
import "./HomeAdvBox.css";

const HomeAdvBox = ({ homeCouple }) => {
  return (
    <>
      <div className="HomeAdvBox">
        <div className="homeLeftRose">
          <img src={homeLeftRose} />
        </div>
        <div className="homeRightRose">
          <img src={homeRightRose} />
        </div>

        <div className="homeAdvContentContainer">
          {homeCouple ? (
            <>
              <div className="homeAdvContentPic">
                <img src={homeCouple} />
              </div>
              <h5>We help you Find the Perfect partner!!!</h5>
            </>
          ) : (
            <>
              <div className="homeAdvChat">
                <div className="homeAdvContent">
                  <h3>Need help to find a life partner?</h3>
                  <div>Get guidance from our customer support executive</div>
                </div>
                <div className="homeChatButton">
                  <button>Chat with us</button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeAdvBox;

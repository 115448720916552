import React from "react";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../components/Auth/AuthHeader";
import withAuthBackground from "../Auth/withAuthBackground";
import "../Auth/Register/AccountCreationSuccess.css";

const EmailChanged = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      <div className="row">
        <div className="successFormContiner">
          <div className="SuccessHeader">
            Your Email has been changed successfully!
          </div>
          <div className="successButton">
            <button
              onClick={() => {
                navigate("/login");
              }}
            >
              Login to your account
            </button>
          </div>

          <div className="resetFull"></div>
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(EmailChanged, "register");

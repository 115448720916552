import React from "react";

import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import aadharVerifyPic from "../../assets/images/Aadhar/aadharVerifyPic.png";
import aadharPending from "../../assets/images/Aadhar/aadharPending.png";
import AadharCard from "../../components/Aadhar/AadharCard";
import Footer from "../../components/Footer/Footer";
import { useState } from "react";

const AadharPending = () => {
  const [aadhar, setAadhaar] = useState("pending");
  const aadharContent = [
    {
      classname: "aadharUpload",
      content:
        "Upload your Aadhar to verify your identity information. The document uploaded by you will not be shown to other members.",
    },
    {
      classname: "aadharPending",
      content:
        "Your Aadhar has been uploaded successfully and is being verified. This process might take upto two hours.",
      aadharImage: aadharPending,
    },
    {
      classname: "aadharSuccess",
      content:
        "Your Aadhar has been verified successfully.\n The document uploaded by you will not be shown to other members.",
    //   aadharImage: aadharSuccess,
    },
  ];

  return (
    <>
      <div className="container-fluid backGroundAntique">
        <HomeHeader />

        {/* aadhar  */}
        <div className="aadharContainer row">
          <div className="aadharVerificationStatus">
            <div className="aadharVerificationStatusimage">
              <img src={aadharVerifyPic} alt="" />
            </div>
            <div className="aadharVerificationStatusMessage">
              Your Profile has been verified successfully
            </div>
          </div>
          <div className="aadharVerificationTitle">
            Verified profiles are trusted more and likely to get shortlisted
            easily
          </div>
          <AadharCard
            aadharContent={
              aadhar === "upload"
                ? aadharContent[0]
                : aadhar === "pending"
                ? aadharContent[1]
                : aadhar === "success"
                ? aadharContent[2]
                : ""
            }
          />
        </div>
        <div className="row homeFooter">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AadharPending;

import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import './ReligiousSearchHoroscopeDetails.css'

const ReligiousSearchHoroscopeDetails = ({
  RegularSearchField,
  handleChange,
}) => {
  // religious inputs...
  const RegularSearchHoroscopeInputs = [
    {
      inputType: "select",
      name: "star",
      value: RegularSearchField.star,
      //  className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "raasi",
      value: RegularSearchField.raasi,
      //  className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];
  return (
    <div className="regularSearchIndividualContainer">
      <div className="regularSearchHead">Horoscope Details</div>
      <div className="DesktopregularSearchContainerIndividualInputs">
        <div className="regularSearchLabels">
          <div className="MotherTongueEditTime">Star</div>
          <div className="EatingEditTime">Raasi</div>
          {/* <div className="partnerFieldQue">Sub caste</div> */}
        </div>
        <div className="regularSearchInputs">
          {RegularSearchHoroscopeInputs.map((input) =>
            input.inputType === "select" ? (
              <div className="partnerFieldAns">
                <SelectInputField
                  input={input}
                  className={input.className}
                  handleChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ReligiousSearchHoroscopeDetails;

import React from "react";
import { useState } from "react";

import FilterLifeStyle from "../FilterOptions/FilterLifeStyle";
import FilterPersonalDetails from "../FilterOptions/FilterPersonalDetails";
import FilterLocationDetails from "../FilterOptions/FilterLocationDetails";
import FilterProfessionalDetails from "../FilterOptions/FilterProfessionalDetails";
import FilterFamilyDetails from "../FilterOptions/FilterFamilyDetails";
import FilterReligiousDetails from "../FilterOptions/FilterReligiousDetails";
import FilterBasicDetails from "../FilterOptions/FilterBasicDetails";
import FilterProfileType from "../FilterOptions/FilterProfileType";
import "./Filter.css";

const Filter = ({
  handleChange,
  filterInputs,
  setFilterInputs,
  filterId,
  setFilterId,
}) => {
  return (
    <>
      <div className="matchesFilterBoxContainer">
        <div className="matchesFilterTitle">Filter By</div>
        <form action="">
          <div className="filterDropDowns">
            {/* profile type */}
            <FilterProfileType
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
              handleChange={handleChange}
            />

            {/* basic details */}
            <FilterBasicDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
              handleChange={handleChange}
            />

            {/* religious details */}
            <FilterReligiousDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
              handleChange={handleChange}
            />

            {/* PROFESSIONAL DETAILS */}
            <FilterProfessionalDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
              handleChange={handleChange}
            />

            {/* LOCATIONAL DETAILS */}
            <FilterLocationDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
              handleChange={handleChange}
            />

            {/* PERSONAL DETAILS */}
            <FilterPersonalDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
            />

            {/* LIFE STYLE DETAILS */}
            <FilterLifeStyle
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
            />

            {/* FAMILY DETAILS */}
            <FilterFamilyDetails
              filterInputs={filterInputs}
              filterId={filterId}
              setFilterId={setFilterId}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Filter;

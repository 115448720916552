import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import ProfileSettingsTab from "../../components/ProfileSettingsTab/ProfileSettingsTab";
import { TextArea } from "@progress/kendo-react-inputs";
import nayan from "../../assets/images/Home/nayan.jpg";
import "../CustomerSupport/CustomerSupport.css";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

const CustomerSupport = () => {
  const navigate = useNavigate();

  const [help, setHelp] = useState("");

  const [backendRes, setBackendRes] = useState("");

  const schema = z.object({
    name: z.string().min(1, { message: "Please enter your name" }),
    mobile: z
      .string()
      .min(10, { message: "Invalid mobile number" })
      .max(10, { message: "Invalid mobile number" }),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      mobile: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      const user_id = window.sessionStorage.getItem("User_ID");

      const { name, mobile } = values;
    
      const userName = name;
      const howCanWeHelp = help;

      const postData = await axios.post(
        `${BACKEND_PORT}/customSupport/customSupport`,
        {
          user_id,
          userName,
          mobile,
          howCanWeHelp,
        }
      );

      alert(postData.data.message);

      reset();
      setHelp("");
    } catch (err) {
      console.log(err);
      setBackendRes(err.response.data.message);
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    setHelp(value);
  };

  // console.log(help);
  // console.log(watch(), errors);

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Customer Support</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </div>
            </span>

            <div className="profileDetails_pic_ID">
              <div className="profileImg">
                <img src={nayan} />
              </div>
              <div className="profileName">{`Cathy`}</div>
              <span className="profileID">{`JM123456`}</span>
            </div>

            <div className="Contact-Us">
              <div className="Contact-Us-Header MobileViewCustomerSupport">
                Customer Support
              </div>
              <div className="Contact-Us-Form ">
                <label>{backendRes}</label>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="MobileViewCustomerSupportForm"
                >
                  <label>Your Name*</label>
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    {...register("name")}
                  />
                  {errors.name && (
                    <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
                      {errors.name.message}
                    </label>
                  )}

                  <label>Your Mobile Number*</label>
                  <input
                    type="Number"
                    name="mobile"
                    autoComplete="off"
                    {...register("mobile")}
                  />
                  {errors.mobile && (
                    <label className="PR_Page_inputs_validation_Label PR_Page_inputs_validation_Label3">
                      {errors.mobile.message}
                    </label>
                  )}

                  <label>How can we help?</label>
                  <TextArea
                    className="Contact-Us-TextArea"
                    name="help"
                    value={help}
                    onChange={handleChange}
                    autoComplete="off"
                  ></TextArea>

                  <button className="MobileViewCustomerSupportButton">
                    Submit
                  </button>
                </form>
              </div>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSupport;

import { useEffect } from "react";
import { DependencyList } from "react";

export function useDebounceEffect(fn, waitTime, deps, DependencyList) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps ? DependencyList : "");
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EditMobileNumberVerificationPopup.css";

const EditMobileNumberVerificationPopup = ({
  edit,
  setMobileVerified,
  setMobileVerificationPopup,
  setEditMobileNumberPopup,
  handleVerify,
  handleEditButton,
}) => {
  // code state
  const [code, setCode] = useState("");
  let [error, setError] = useState("");

  // counter state
  let [counter, setCounter] = useState(59);

  useEffect(() => {
    // counter
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const navigate = useNavigate();

  const handleChange = ({ target: { value } }) => {
    setError("");

    if (value.length !== 4 || !/^[0-9]*$/.test(value)) {
      setError("Invalid code");
    } else {
      setError("");
    }
    setCode(value);
  };
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if (!error.length && code.length) {
        setMobileVerified(true);
      } else if (!code.length) {
        setError("Enter Code");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          setMobileVerificationPopup(false);
        }}
      >
        X
      </button>
      <div className="editMobileHeader">Verify Your Mobile Number</div>

      {counter === 0 ? (
        <>
          <div className="editMobileText">
            A 4-Digit Confirmation code is sent to{" "}
            <span className="MobileNo">0987654321</span>&nbsp;
            <span className="editButon" onClick={handleEditButton}>
              {edit && "(Edit)"}
            </span>
          </div>
        </>
      ) : (
        <div className="editMobileText">
          A 4-Digit Confirmation code is sent to{" "}
          <span className="MobileNo">0987654321</span>&nbsp;
          <span className="editButon" onClick={handleEditButton}>
            {edit && "(Edit)"}
          </span>
        </div>
      )}
      <div className="mobileInput mobileNumberVerificationInput">
        <form onSubmit={handleSubmit}>
          <input
            className=""
            placeholder="Enter Code"
            value={code}
            onChange={handleChange}
          />

          <button onClick={handleVerify}>Verify</button>
          <span className="mobInpErr">{error}</span>
        </form>
      </div>
      {/* counter finishes resend the code */}
      {counter === 0 ? (
        <div className="counter">
          Didn’t receive the code?{" "}
          <span
            className="counterColor"
            onClick={() => {
              setCounter(59);
            }}
          >
            Resend code
          </span>
        </div>
      ) : (
        <div className="counter">
          Resending the code in{" "}
          <span className="counterColor">
            00 : {counter < 10 ? "0" + counter : counter}
          </span>
        </div>
      )}
    </>
  );
};

export default EditMobileNumberVerificationPopup;

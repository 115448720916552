import React from "react";
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";

const FilterLocationDetails = ({
  filterId,
  setFilterId,
  filterInputs,
  handleChange,
}) => {
  const locationInputs = [
    {
      label: "Select the prefered location",
      inputType: "option",
      name: "location",
      value: filterInputs.location,
      className:
        "partnerPreferenceLargeOption selectFielBox MobilePartnerPreferenceLargeOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("locationDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Location Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("locationDetails")
              ? setFilterId(filterId.filter((e) => e !== "locationDetails"))
              : setFilterId([...filterId, "locationDetails"]);
          }}
        >
          {filterId.includes("locationDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("locationDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {locationInputs.map((input) => (
            <div className="dropDownOption">
              <div className="dropDownComponentTitle">{input.label}</div>
              <div className="partnerFieldRange partnerFieldRangeFlex">
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterLocationDetails;

import React from "react";
import { useNavigate } from "react-router-dom";

import "./HomeMatchesBox.css";

const HomeMatchesBox = ({setHeaderBox}) => {
  const matchesArray = [
    {
      match: "All Matches",
      link: "/matches/totalMatches",
      header1: "2000 Total Matches",
      header2: "Members who match your Partner preferences",
      activity: true,
    },
    {
      match: "New Matches",
      link: "/matches/newMatches",
      header1: "1500 New Matches",
      header2: "Members who have joined in the last 10 Days",
      activity: true,
    },
    {
      match: "Mutual Matches",
      link: "/matches/mutualMatches",
      header1: "250 Mutual Matches",
      header2: "Members whose preferences match your profile and vice-versa",
      activity: true,
    },
    {
      match: "Members looking for you",
      link: "/matches/membersLookingForYou",
      header1: "5000 Members looking for you",
      header2: "Members whose preferences matches your profile",
      activity: true,
    },
    {
      match: "Shortlisted by you",
      link: "/matches/shortlistedByYou",
      header1: "Shortlisted by you",
      header2: "",
      activity: true,
    },
    {
      match: "Who viewed you",
      link: "/matches/viewedYou",
      header1: "Who viewed you",
      header2: "",
      activity: true,
    },
    {
      match: "Who shortlisted you",
      link: "/matches/shortlistedYou",
      header1: "Who shortlisted you",
      header2: "",
      activity: true,
    },
    {
      match: "Blocked Profiles",
      link: "/matches/blockedProfiles",
      header1: "Blocked Profiles",
      header2: "",
      activity: true,
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="homeMatchesHeaderContainer">
      <div className="homeSearches">
        {matchesArray.map((match, idx) => (
          <div
            className="homeSearch"
            onClick={() => {
              navigate(match.link, {
                state: { header1: match.header1, header2: match.header2,activity: match.activity },
              });
              setHeaderBox("")
            }}
            key={idx}
          >
            {match.match}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeMatchesBox;

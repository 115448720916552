import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import LandingPage from "./container/landing/LandingPage";
import Login from "./container/Auth/Login/Login";
import Register from "./container/Auth/Register/Register";
import MobileAuth from "./container/Auth/Mobile Verify/MobileAuth";
import EditMobile from "./container/Auth/Mobile Verify/EditMobile";
import EmailVerify from "./container/Auth/Email verify/EmailVerifiy";
import EditEmail from "./container/Auth/Email verify/EditEmail";
import ForgotPassword from "./container/Auth/Forgot/Forgotpassword.js";
import Resetpassword from "./container/Auth/Reset/Resetpassword";
import SendCode from "./container/Auth/Forgot/SendCode";
import Approval from "./container/Approval/Approval";
import Home from "./container/Home/Home";
import Matches from "./components/Macthes/Matches/Matches";
import Aadhar from "./container/Aadhar/Aadhar";
import PartnerPreference from "./container/PartnerPreferences/PartnerPreference";
import AadharPending from "./components/Aadhar/AadharPending";
import AadharSuccess from "./components/AadharVerified";

import ProfileRegister1 from "./container/ProfileRegister/ProfileRegister1/ProfileRegister1";
import ProfileRegister2 from "./container/ProfileRegister/ProfileRegister2/ProfileRegister2";
import ProfileRegister3 from "./container/ProfileRegister/ProfileRegister3/ProfileRegister3";
import ProfileRegister4 from "./container/ProfileRegister/ProfileRegister4/ProfileRegister4";
import ProfileRegister5 from "./container/ProfileRegister/ProfileRegister5/ProfileRegister5";
import AddProfilePhotos from "./container/AddProfilePhotos/AddProfilePhotos";
import EditProfilePage from "./container/EditProfilePage/EditProfilePage";

import IndividualPage from "./container/Individualpages/IndividualPage";
import KeywordSearch from "./container/Searches/KeywordSearch/KeywordSearch";
import MatrimonyIDSearch from "./container/Searches/MatrimonyIDSearch/MatrimonyIDSearch";
import RegularSearch from "./container/Searches/RegularSearch/RegularSearch";
import SubscribePage from "./container/SubscribePage/SubscribePage";
import Notifications from "./container/NotificationsPage/Notifications";
import ChangeEmailId from "./container/ChangeEmailId/ChangeEmailId";
import CustomerSupport from "./container/CustomerSupport/CustomerSupport";
import PhotoPrivacy from "./container/PhotoPrivacy/PhotoPrivacy";
import Contactus from "./container/Contactus/Contactus";
import History from "./container/History/History";
import ShortlistedProfiles from "./container/ShortlistedProfiles/ShortlistedProfiles";
import Testimonials from "./container/Testimonials/Testimonials";
import PagenotFound from "./container/PagenotFound/PagenotFound";
import PrivacyPolicy from "./container/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./container/TermsAndConditions/TermsAndConditions";
import FAQ from "./container/FAQ/FAQ";
import AccountCreationSuccess from "./container/Auth/Register/AccountCreationSuccess";
import ResetSuccessfully from "./container/Auth/Reset/ResetSuccessfully";
import MobileNoIncorrect from "./container/Auth/Mobile Verify/MobileNoIncorrect";
import MobileSearchBox from "./container/Searches/MobileSearchBox/MobileSearchBox";
import MobileMatchesBox from "./components/Macthes/Matches/MobileMatchesBox/MobileMatchesBox";
import MobileAadhar from "./container/Aadhar/MobileAadhar";
import SettingsBox from "./container/ChangeEmailId/SettingsBox";
import VerifyNewEmailID from "./container/ChangeEmailId/VerifyNewEmailID";
import EmailChanged from "./container/ChangeEmailId/EmailChanged";
import HelpAndSupport from "./container/HelpAndSupport/HelpAndSupport";
import MobileFilterBox from "./components/Macthes/Matches/MobileMatchesBox/MobileFilterBox/MobileFilterBox";
import EmailVerified from "./container/Auth/Email verify/EmailVerified";
import ChangeEmailIdSuccess from "./container/ChangeEmailId/ChangeEmailIdSuccess";

export const BACKEND_PORT = "http://192.168.29.166:8000";

// console.log(process.env.BACKEND_PORTS);

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/accountCreationSuccess"
            element={<AccountCreationSuccess />}
          />
          <Route path="/mobileVerification" element={<MobileAuth />} />
          <Route path="/editMobile" element={<EditMobile />} />
          <Route path="/mobileNoIncorrect" element={<MobileNoIncorrect />} />
          <Route path="/emailVerification" element={<EmailVerify />} />
          <Route path="/emailVerified" element={<EmailVerified />} />
          <Route path="/editEmail" element={<EditEmail />} />
          <Route path="/sendOTP" element={<SendCode />} />
          <Route path="/verify" element={<ForgotPassword />} />
          <Route path="/reset" element={<Resetpassword />} />
          <Route path="/resetSuccessfully" element={<ResetSuccessfully />} />
          <Route
            path="/profileregister/BasicDetails"
            element={<ProfileRegister1 />}
          />
          <Route
            path="/profileregister/FamilyDetails"
            element={<ProfileRegister2 />}
          />
          <Route
            path="/profileregister/PersonalDetails"
            element={<ProfileRegister3 />}
          />
          <Route
            path="/profileregister/EducationDetails"
            element={<ProfileRegister4 />}
          />
          <Route
            path="/profileregister/BasicInformation"
            element={<ProfileRegister5 />}
          />
          <Route path="/addprofilephotos" element={<AddProfilePhotos />} />
          <Route path="/approval" element={<Approval />} />
          <Route path="/home" element={<Home />} />
          <Route path="/matches/:matchType" element={<Matches />} />
          <Route path="/individual" element={<IndividualPage />} />
          <Route path="/mobileSearchBox" element={<MobileSearchBox />} />
          <Route path="/mobileMatchesBox" element={<MobileMatchesBox />} />
          <Route path="/mobileFilterBox" element={<MobileFilterBox />} />
          <Route path="/keywordsearch" element={<KeywordSearch />} />
          <Route path="/matrimonyidsearch" element={<MatrimonyIDSearch />} />
          <Route path="/regularsearch" element={<RegularSearch />} />
          <Route path="/editprofilepage" element={<EditProfilePage />} />
          <Route path="/partnerPreference" element={<PartnerPreference />} />
          <Route path="/aadhar" element={<Aadhar />} />
          <Route path="/mobileaadhar" element={<MobileAadhar />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/aadharPending" element={<AadharPending />} />
          <Route path="/aadharSuccess" element={<AadharSuccess />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/changeEmailId" element={<ChangeEmailId />} />
          <Route path="/emailChangedSuccessfull" element={<ChangeEmailIdSuccess />} />
          <Route path="/VerifyNewEmailID" element={<VerifyNewEmailID />} />
          <Route path="/emailChanged" element={<EmailChanged />} />
          <Route path="/settingsBox" element={<SettingsBox />} />
          <Route path="/history" element={<History />} />
          <Route path="/customersupport" element={<CustomerSupport />} />
          <Route
            path="/ShortlistedProfiles"
            element={<ShortlistedProfiles />}
          />
          <Route path="/helpAndSupport" element={<HelpAndSupport />} />
          <Route path="/photoPrivacy" element={<PhotoPrivacy />} />
          <Route path="/contactUs" element={<Contactus />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="*" element={<PagenotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../Testimonials/Testimonials.css";
import Couple1 from "../../assets/images/Testimonials/Couple1.png";
import Couple2 from "../../assets/images/Testimonials/Couple2.jpg";
import { useNavigate } from "react-router-dom";
import nayan from '../../assets/images/Home/nayan.jpg'

const Testimonials = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const slides = [
    {
      image: Couple1,
      name: " Hannah George and Alex Matthew",
      date: "6/6/2022",
    },
    {
      image: Couple2,
      name: "Harry Matthew and Lucci George",
      date: "25/12/2021",
    },
  ];
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const next = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="searchBoxTapBar">
                <span className="PageHeading">Testimonials</span>
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left "></i>
                </div>
              </div>
            </span>

            <div className="profileDetails_pic_ID">
              <div className="profileImg">
                <img src={nayan} />
              </div>
              <div className="profileName">{`Cathy`}</div>
              <span className="profileID">{`JM123456`}</span>
            </div>

            <div className="Testimonials">
              <span className="desktopHeading">Testimonials</span>
              <div className="Testimonials-Image">
                <i class="fa-solid fa-chevron-left left-arrow" onClick={prev}></i>
                <img src={slides[current].image} />
                <i class="fa-solid fa-chevron-right right-arrow" onClick={next}></i>
              </div>
              <div className="Testimonials-Context">
                {slides[current].name}
                <span>Marriage date : {slides[current].date}</span>
              </div>
            </div>

            <div className="row Testimonials-Footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;

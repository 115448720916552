import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../SubscribePage/SubscribePage.css";
import nayan from "../../assets/images/Home/nayan.jpg";
import { useState } from "react";
import ProfilePicPlaceholder from "../../assets/images/Home/HomeProfilePlaceHolder.png";
import MobileNotificationIcon from "../../assets/images/Sidebar/NotificationBell Icon.png";
import GreenNotificationPointIcon from "../../assets/images/Sidebar/GreenNotificationPointIcon.png";
import red_ribbon from "../../assets/images/Subscribe/red_ribbon.png";
import gold_ribbon from "../../assets/images/Subscribe/gold_ribbon.png";
import silver_ribbon from "../../assets/images/Subscribe/silver_ribbon.png";
import blue_ribbon from "../../assets/images/Subscribe/blue_ribbon.png";
import { useNavigate } from "react-router-dom";
import Tab1Crown from "../../assets/images/Subscribe/Tab1Crown.png";
import Tab2Gold from "../../assets/images/Subscribe/Tab2Gold.png";
import Tab3Silver from "../../assets/images/Subscribe/Tab3Silver.png";
import Tab4Free from "../../assets/images/Subscribe/Tab4Free.png";
const SubscribePage = () => {
  const navigate = useNavigate();

  const token = window.sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const [profilePicture, setProfilePicture] = useState(nayan);

  const [subscriptionTab, setSubscriptionTab] = useState("");

  const SubscriptionArray = [
    {
      classname: "SubscribeCardBlue",
      Header: "FREE",
      Price: "₹0",
      Duration: "",
      Button: "GET STARTED",
      Content1: "Lorem ipsum dolor Lorem ipsum dolor sit amet",
    },
    {
      classname: "SubscribeCardOrange",
      Header: "SILVER",
      Price: "₹2000",
      Duration: "3 Months",
      Button: "BUY NOW",
      Content1: "Lorem ipsum dolor Lorem ipsum dolor sit amet",
    },
    {
      classname: "SubscribeCardGreen",
      Header: "GOLD",
      Price: "₹3000",
      Duration: "6 Months",
      Button: "BUY NOW",
      Content1: "Lorem ipsum dolor Lorem ipsum dolor sit amet",
    },
    {
      classname: "SubscribeCardGold",
      Header: "PLATINUM",
      Price: "₹4000",
      Duration: "1 Year",
      Button: "BUY NOW",
      Content1: "Lorem ipsum dolor Lorem ipsum dolor sit amet",
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="PageNotFoundTapBar">
                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>

                <div className="PageNotFoundProfilePics">
                  {profilePicture ? (
                    <img src={profilePicture} />
                  ) : (
                    <img
                      src={ProfilePicPlaceholder}
                      className="placeHolderImg"
                    />
                  )}
                </div>
                <span className="pageNotFoundProfileDetails">Hey ! Cathy</span>

                <span
                  className="MobileNotificationIcon"
                  onClick={() => {
                    navigate("/notifications");
                  }}
                >
                  <img src={MobileNotificationIcon} alt="NotificationBell" />
                  <img
                    src={GreenNotificationPointIcon}
                    alt="MobileNotificationPoint"
                    className="MobileNotificationPoint"
                  />
                </span>
              </div>
            </span>

            <div className="SubscribePage">
              <div className="SubscribePage-Header">
                Subscribe to enjoy unlimited benefits
              </div>

              <div className="SubscribePage-Cards DesktopSubscriptionCards">
                {SubscriptionArray.map((sub) => (
                  <div className={`SubscribePage-Card`}>
                    <div
                      className={`SubscribePage-Card-Package ${sub.classname}`}
                    >
                      <p> {sub.Header}</p>
                      <b> {sub.Price}</b>
                      {/* <span> 3 Months</span> */}
                    </div>
                    <div className="SubscribePage-Card-1-Context">
                      <div className="SubscribePage-Card-Context-Checkmark">
                        <i class="fa-solid fa-circle-check"></i>
                        <i class="fa-solid fa-circle-check"></i>
                        <i class="fa-solid fa-circle-check"></i>
                      </div>
                      <div className="SubscribePage-Card-Context-Info">
                        <div>{sub.Content1}</div>
                        <div>{sub.Content1}</div>
                        <div>{sub.Content1}</div>
                      </div>
                    </div>
                    <div className="SubscribePage-Card-Context-Button">
                      <button className={` ${sub.classname}`}>
                        {sub.Button}
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="subscriptionPageTabs MobileSubscritionCards">
                <div
                  className={`${
                    subscriptionTab === "platinum"
                      ? "openSubscriptionPageTab"
                      : ""
                  } subscriptionPageTab`}
                  onClick={() => {
                    setSubscriptionTab(
                      subscriptionTab === "platinum" ? "" : "platinum"
                    );
                  }}
                >
                  <div
                    className={`tabHeader ${
                      subscriptionTab === "platinum" ? "OpenTab1Header" : ""
                    } `}
                  >
                    <div className="tabHeaderContent Tab1content">
                      <img
                        src={Tab1Crown}
                        alt="Tab1Crown"
                        className="Tab1Crown"
                      />
                      <h6>Platinum</h6>
                    </div>

                    {subscriptionTab === "platinum" ? (
                      <i class="fa-solid fa-chevron-up"></i>
                    ) : (
                      <i class="fa-solid fa-chevron-down"></i>
                    )}
                  </div>

                  <div
                    className={`${
                      subscriptionTab === "platinum"
                        ? "subscriptionOpenTabBodyCard"
                        : "subscriptionCloseTabBodyCard"
                    }`}
                  >
                    <div className="platinumBadge">
                      <span className="badgeheading">PLATINUM</span>
                      <img src={red_ribbon} alt="badgeRibben" />
                      <span className="priceTag">&#8377;4000</span>
                      <span className="Yeartag">1 Year</span>
                    </div>

                    <div className="subscriptionWishList">
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>

                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    subscriptionTab === "gold" ? "openSubscriptionPageTab2" : ""
                  } subscriptionPageTab`}
                  onClick={() => {
                    setSubscriptionTab(
                      subscriptionTab === "gold" ? "" : "gold"
                    );
                  }}
                >
                  <div
                    className={`tabHeader ${
                      subscriptionTab === "gold" ? "OpenTab1Header" : ""
                    } `}
                  >
                    <div className="tabHeaderContent Tab2content">
                      <img
                        src={Tab2Gold}
                        alt="Tab2Gold"
                        className="Tab1Crown"
                      />
                      <h6>Gold</h6>
                    </div>

                    {subscriptionTab === "gold" ? (
                      <i class="fa-solid fa-chevron-up"></i>
                    ) : (
                      <i class="fa-solid fa-chevron-down"></i>
                    )}
                  </div>

                  <div
                    className={`${
                      subscriptionTab === "gold"
                        ? "subscriptionOpenTabBodyCard"
                        : "subscriptionCloseTabBodyCard"
                    }`}
                  >
                    <div className="goldBadge">
                      <span className="badgeheading">GOLD</span>
                      <img src={gold_ribbon} alt="badgeRibben" />
                      <span className="priceTag">&#8377;3000</span>
                      <span className="Yeartag">6 Months</span>
                    </div>

                    <div className="subscriptionWishList">
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>

                      <button className="subscriptionGoldenButton">
                        BUY NOW
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    subscriptionTab === "silver"
                      ? "openSubscriptionPageTab3"
                      : ""
                  } subscriptionPageTab`}
                  onClick={() => {
                    setSubscriptionTab(
                      subscriptionTab === "silver" ? "" : "silver"
                    );
                  }}
                >
                  <div
                    className={`tabHeader ${
                      subscriptionTab === "silver" ? "OpenTab1Header" : ""
                    } `}
                  >
                    <div className="tabHeaderContent Tab3content">
                      <img
                        src={Tab3Silver}
                        alt="Tab3Silver"
                        className="Tab3Silver"
                      />
                      <h6>Silver</h6>
                    </div>

                    {subscriptionTab === "silver" ? (
                      <i class="fa-solid fa-chevron-up"></i>
                    ) : (
                      <i class="fa-solid fa-chevron-down"></i>
                    )}
                  </div>

                  <div
                    className={`${
                      subscriptionTab === "silver"
                        ? "subscriptionOpenTabBodyCard"
                        : "subscriptionCloseTabBodyCard"
                    }`}
                  >
                    <div className="silverBadge">
                      <span className="badgeheading">SILVER</span>
                      <img src={silver_ribbon} alt="badgeRibben" />
                      <span className="priceTag">&#8377;2000</span>
                      <span className="Yeartag">3 Months</span>
                    </div>

                    <div className="subscriptionWishList silverSubscriptionWishList">
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>

                      <button className="subscriptionSilverButton">
                        BUY NOW
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    subscriptionTab === "free" ? "openSubscriptionPageTab4" : ""
                  } subscriptionPageTab`}
                  onClick={() => {
                    setSubscriptionTab(
                      subscriptionTab === "free" ? "" : "free"
                    );
                  }}
                >
                  <div
                    className={`tabHeader ${
                      subscriptionTab === "free" ? "OpenTab1Header" : ""
                    } `}
                  >
                    <div className="tabHeaderContent Tab4content">
                      <img src={Tab4Free} alt="Tab4Free" className="Tab4Free" />
                      <h6>Free</h6>
                    </div>

                    {subscriptionTab === "free" ? (
                      <i class="fa-solid fa-chevron-up"></i>
                    ) : (
                      <i class="fa-solid fa-chevron-down"></i>
                    )}
                  </div>

                  <div
                    className={`${
                      subscriptionTab === "free"
                        ? "subscriptionOpenTabBodyCard"
                        : "subscriptionCloseTabBodyCard"
                    }`}
                  >
                    <div className="freeBadge">
                      <span className="badgeheading">FREE</span>
                      <img src={blue_ribbon} alt="badgeRibben" />
                      <span className="priceTag">&#8377;0</span>
                      {/* <span className="Yeartag">3 Months</span> */}
                    </div>

                    <div className="subscriptionWishList silverSubscriptionWishList">
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>
                      <div>
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Lorem ipsum dolor Lorem ipsum dolor sit amet</p>
                      </div>

                      <button className="subscriptionFreeButton">
                        GET STARTED
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribePage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./MobileVerification.css";

const MobileVerification = ({ mobile, nextPage, edit, setMobileVerified }) => {
  // code state
  const [code, setCode] = useState("");
  let [error, setError] = useState("");

  // counter state
  let [counter, setCounter] = useState(59);

  useEffect(() => {
    // counter
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const navigate = useNavigate();

  const handleChange = ({ target: { value } }) => {
    setError("");

    if (value.length !== 4 || !/^[0-9]*$/.test(value)) {
      setError("Invalid code");
    } else {
      setError("");
    }
    setCode(value);
  };
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if (!error.length && code.length) {
        setMobileVerified(true);
      } else if (!code.length) {
        setError("Enter Code");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="mobileText">
        A 4-Digit Confirmation code is sent to{" "}
        <span className="mobileTextBold">
          {mobile} <span className="editButon">(Edit)</span>
        </span>
      </div>

      <div className="mobileInput">
        <form onSubmit={handleSubmit}>
          <input
            className=""
            placeholder="Enter Code"
            value={code}
            onChange={handleChange}
          />
          <button>Verify</button>
          <span className="mobInpErr">{error}</span>
        </form>
      </div>

      {/* counter finishes resend the code */}
      {counter === 0 ? (
        <div className="counter">
          Didn’t receive the code?
          <span
            className="counterColor"
            onClick={() => {
              setCounter(59);
            }}
          >
            Resend code
          </span>
        </div>
      ) : (
        <div className="counter">
          Resending the code in{" "}
          <span className="counterColor">
            00 : {counter < 10 ? "0" + counter : counter}
          </span>
        </div>
      )}
      {edit && (
        <div className="VerifySkipdiv">
          <button className="VerifySkipButton" onClick={()=>{navigate("/emailVerification");}}>{`Skip >`}</button>
        </div>
      )}
    </>
  );
};

export default MobileVerification;

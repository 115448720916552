import React from "react";
import InputField from "../../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../../components/PartnerPreference/InputFields/SelectInputField";
import "./RegularSearchProfessional.css";

function RegularSearchProfessional({ RegularSearchField, handleChange }) {

  const RegularSearchBasicInputs = [
    {
      inputType: "select",
      name: "education",
      value: RegularSearchField.education,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "employed",
      value: RegularSearchField.employed,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "occupation",
      value: RegularSearchField.occupation,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      className: "partnerPreferenceSmallOption",
      inputType: "range",
      name: "annualIncome",
      field: [
        {
          name: "annualIncomeFrom",
          value: RegularSearchField.annualIncomeFrom,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          name: "annualIncometo",
          value: RegularSearchField.annualIncometo,
          options: [
            { value: "31", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
  ];

  return (
    <div className="RSbasicPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle">Professional Details</div>
      </div>

      <div className={`RSDetailsSectionRow_EditTime`}>
        <div className="partnerInputs">
          <ul className={`RSDetails_section_label_EditTime`}>
            <li className="MotherTongueEditTime">Education</li>
            <li className="EatingEditTime">Employed</li>
            <li className="SmokingEditTime">Occupation</li>
            <li className="DrinkingEditTime">Annual Income</li>
          </ul>
          <ul className={`PartnerDetails_section_colon_EditTime`}>
            <li className="MotherTongueEditTime">:</li>
            <li className="EatingEditTime">:</li>
            <li className="SmokingEditTime">:</li>
            <li className="DrinkingEditTime">:</li>
          </ul>
          <ul className="Details_section_values partnerDetails_section_values">
            {RegularSearchBasicInputs.map((input) =>
              input.inputType === "radio" ? (
                <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                  <RadioInputField
                    input={input}
                    handleChange={handleChange}
                    inputFields={RegularSearchField}
                    className={`EditTimeRadioflexWrap`}
                  />
                </div>
              ) : input.inputType === "select" ? (
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
              ) : input.inputType === "range" ? (
                <div className="partnerFieldRange ">
                  <SelectInputField
                    input={input.field[0]}
                    className={input.className}
                    handleChange={handleChange}
                  />
                  <div> - </div>
                  <div className="partnerFieldRange">
                    <SelectInputField
                      input={input.field[1]}
                      className={input.className}
                      handleChange={handleChange}
                    />
                  </div>
                  <span className="Details_section_values_rightLabel">
                    {input.name === "age" && <div>Years</div>}
                  </span>
                </div>
              ) : (
                <div className="partnerFieldAns">
                  <InputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                  <span className="Details_section_values_rightLabel">
                    {input.FieldLabel}
                  </span>
                </div>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RegularSearchProfessional;

import React, { useState } from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import "./PartnerPreferenceAbout.css";

const PartnerPreferenceAbout = ({
  partnerPreferenceField,
  handleEdit,
  handleSave,
  partnerEditProcess,
  setPartnerEditProcess,
  handleChange,
}) => {

    // const [about, setAbout] = useState("");
    const max = 500;

  return (
    <div className="aboutPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle AboutPartnerFieldTitle">
          About your partner
        </div>

        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("AboutDetails");
          }}
        >
          {partnerEditProcess === "AboutDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              <i class="fa-solid fa-pen"></i> Close
            </span>
          ) : (
            <>
              <i class="fa-solid fa-pen partnerPreferenceMobileEdit"></i>
            </>
          )}
        </button>

        <button
          className="PartnerEditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("AboutDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {partnerEditProcess === "AboutDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>Edit</>
          )}
        </button>
      </div>

      <div
        className={`${
          partnerEditProcess === "AboutDetails"
            ? "PartnerPreference_FewWords_EditTime"
            : "PartnerPreference_FewWords_PTag"
        }`}
      >
        {partnerEditProcess === "AboutDetails" ? (
          <>
            <div className="partnerFieldAns">
              <TextArea
                maxLength={max}
                name={"about"}
                onChange={handleChange}
                value={partnerPreferenceField.about}
                className="PR_Tab5_form_inputBox FedwWordsInputBox"
              ></TextArea>
            </div>
            <label className="PartnerPreference_input_form_Bottomlabel">
              Characters Remaining :{" "}
              <span className="PRChar_Remaining_count_span">
                {max - String(partnerPreferenceField.about).length}
              </span>
            </label>
          </>
        ) : (
          <div className="PartnerPreferenceAboutDetails">
            <p className="PartnerPreferenceAboutDetailsPara">
              {partnerPreferenceField.about}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerPreferenceAbout;


//  <div className="partnerTextField">
//    <TextArea
//      maxLength={30}
//      value={partnerPreferenceField.about}
//      onChange={handleChange}
//      name="about"
//    ></TextArea>
//    <div>Minimum 30 characters</div>
//  </div>;
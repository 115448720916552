/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import Footer from "../../components/Footer/Footer";
import PhofilePhoto from "../../assets/images/Home/nayan.jpg";
import "./EditProfilePage.css";
import Mobile_green_icon from "../../assets/images/Home/Mobile_green_icon.png";
import PersonalDetails_FewWords from "./PersonalDetails_FewWords/PersonalDetails_FewWords";
import PersonalDetails_BasicDetails from "./PersonalDetails_BasicDetails/PersonalDetails_BasicDetails";
import PersonalDetails_HoroscopeDetails from "./PersonalDetails_HoroscopeDetails/PersonalDetails_HoroscopeDetails";
import PersonalDetails_ReligionInformation from "./PersonalDetails_ReligionInformation/PersonalDetails_ReligionInformation";
import PersonalDetails_BridesLocation from "./PersonalDetails_BridesLocation/PersonalDetails_BridesLocation";
import PersonalDetails_ProfessionalDetails from "./PersonalDetails_ProfessionalDetails/PersonalDetails_ProfessionalDetails";
import PersonalDetails_FamilyDetails from "./PersonalDetails_FamilyDetails.js/PersonalDetails_FamilyDetails";
import PersonalDetails_AadharVerificationDetails from "./PersonalDetails_AadharVerificationDetails/PersonalDetails_AadharVerificationDetails";
import Missing_Details_BlueCard from "./Missing_Deatils_BlueCard/Missing_Details_BlueCard";
import EditMobile from "../Auth/Mobile Verify/EditMobile";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EditMobileNumberVerificationPopup from "./EditMobileNumberPopup/EditMobileNumberVerificationPopup";
import EditMobileNumberVerifiedPopup from "./EditMobileNumberPopup/EditMobileNumberVerifiedPopup";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

const EditProfilePage = () => {
  const token = window.sessionStorage.getItem("token");
  const User_ID = window.sessionStorage.getItem("User_ID");

  const navigate = useNavigate();

  const [MissingCard, setMissingCard] = useState("");

  const [missingCardDet, setMissingCardDet] = useState([]);

  const [profileData, setProfileData] = useState({});

  const [ProfileDetails, setProfileDetails] = useState({
    FewWordsAboutMe: "",
    ProfileCreatedFor: "",
    Name: "",
    Age: "",
    Height: "",
    BodyType: "",
    MotherTongue: "",
    MaritalStatus: "",
    EatingHabits: "",
    SmokingHabits: "",
    DrinkingHabits: "",

    PlaceofBirth: "",
    DateOfBirth: "",
    TimeOfBirth: "",
    Star: "",
    Raasi: "",
    HoroscopeCopy: "",

    Denomination: "",
    Division: "",
    Subcaste: "",

    Country: "",
    State: "",
    City: "",
    Citizenship: "",

    Education: "",
    College: "",
    EmployedIn: "",
    Occupation: "",
    OccupationInDetail: "",
    OrganizationName: "",
    AnnualIncome: "",
    AnnualIncomeCurrencyType: "",

    FamilyValues: "",
    FamilyType: "",
    FamilyStatus: "",
    FathersOccupation: "",
    MothersOccupation: "",
    NoOfBrothers: "",
    BrothersMarried: "",
    NoOfSisters: "",
    SistersMarried: "",
    FamilyLocation: "",

    aadhar_verification_details: "",
  });

  const [save, setSave] = useState(false); //Save button

  const [editProcess, setEditProcess] = useState(""); //Profile Editing button

  const [indDetails, setIndDetails] = useState([]); //To handle cancel button(It recides to its previous values)

  // Edit Mobile Number popup

  const [EditMobileNumberPopup, setEditMobileNumberPopup] = useState(false);
  const [MobileVerificationPopup, setMobileVerificationPopup] = useState(false);
  const [MobileVerifiedPopup, setMobileVerifiedPopup] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      let {
        data: { getUsersbyId },
      } = await axios.get(`${BACKEND_PORT}/profiles/view/${User_ID}`);

      setProfileData(getUsersbyId);

      let missingCards = [
        {
          name: "Education",
          title: "Share Cathy’s Academic details for better match search",
          placeholder: "Enter the maximum Educational Qualification",
          value: getUsersbyId?.professional_details?.education,
        },
        {
          name: "Occupation",
          title: "Share Cathy’s professional details",
          placeholder: "Enter the professional details",
          value: getUsersbyId?.professional_details?.occupation,
        },
        {
          name: "FathersOccupation",
          title: "Share Cathy’s Father’s Occupation",
          placeholder: "Enter here",
          value: getUsersbyId?.family_details?.fathers_occupation,
        },
        {
          name: "MothersOccupation",
          title: "Share Cathy’s Mother’s Occupation",
          placeholder: "Enter here",
          value: getUsersbyId?.family_details?.mothers_occupation,
        },
        {
          title: "Share Cathy’s Sibling Details",
          name: "NoOfBrothers",
          placeholder: "Select",
          subTitle: "No of Brothers",
          value: getUsersbyId?.family_details?.no_of_brothers,
        },
        {
          title: "Share Cathy’s Sibling Details",
          name: "BrothersMarried",
          placeholder: "Select",
          subTitle: "No of Brothers Married",
          value: getUsersbyId?.family_details?.brothers_married,
        },
        {
          title: "Share Cathy’s Sibling Details",
          name: "NoOfSisters",
          placeholder: "Select",
          subTitle: "No of Sisters",
          value: getUsersbyId?.family_details?.no_of_sisters,
        },
        {
          title: "Share Cathy’s Sibling Details",
          name: "SistersMarried",
          placeholder: "Select",
          subTitle: "No of Sisters Married",
          value: getUsersbyId?.family_details?.sisters_married,
        },
      ].filter(({ value }) => value === undefined || !value || value === "");
      setMissingCardDet(missingCards);
      missingCards.length > 0 ? setMissingCard(0) : setMissingCard("");

      setProfileDetails({
        ...ProfileDetails,
        FewWordsAboutMe: getUsersbyId?.basic_information?.about,

        ProfileCreatedFor: getUsersbyId?.basic_details?.profile_created_for,
        Name: getUsersbyId?.basic_details?.name,
        Age: getUsersbyId?.basic_details?.age,
        Height: getUsersbyId?.basic_details?.height,
        BodyType: getUsersbyId?.basic_details?.body_type,
        MotherTongue: getUsersbyId?.basic_details?.mother_tongue,
        MaritalStatus: getUsersbyId?.basic_details?.marital_status,
        EatingHabits: getUsersbyId?.basic_details?.eating_habits,
        SmokingHabits: getUsersbyId?.basic_details?.smoking_habits,
        DrinkingHabits: getUsersbyId?.basic_details?.drinking_habits,

        PlaceofBirth: getUsersbyId?.horoscope_details?.place_of_birth,
        DateOfBirth: getUsersbyId?.horoscope_details?.date_of_birth,
        TimeOfBirth: getUsersbyId?.horoscope_details?.time_of_birth,
        Star: getUsersbyId?.horoscope_details?.star,
        Raasi: getUsersbyId?.horoscope_details?.raasi,
        HoroscopeCopy: getUsersbyId?.horoscope_details?.horoscope_copy,

        Denomination: getUsersbyId?.religion_information?.denomination,
        Division: getUsersbyId?.religion_information?.division,
        Subcaste: getUsersbyId?.religion_information?.sub_caste,

        Country: getUsersbyId?.brides_location?.country,
        State: getUsersbyId?.brides_location?.state,
        City: getUsersbyId?.brides_location?.city,
        Citizenship: getUsersbyId?.brides_location?.citizenship,

        Education: getUsersbyId?.professional_details?.education,
        College: getUsersbyId?.professional_details?.college,
        EmployedIn: getUsersbyId?.professional_details?.employeed_in,
        Occupation: getUsersbyId?.professional_details?.occupation,
        OccupationInDetail:
          getUsersbyId?.professional_details?.occupation_in_details,
        OrganizationName: getUsersbyId?.professional_details?.organization_name,
        AnnualIncome: getUsersbyId?.professional_details?.annual_income,

        FamilyValues: getUsersbyId?.family_details?.family_values,
        FamilyType: getUsersbyId?.family_details?.family_type,
        FamilyStatus: getUsersbyId?.family_details?.family_status,
        FathersOccupation: getUsersbyId?.family_details?.fathers_occupation,
        MothersOccupation: getUsersbyId?.family_details?.mothers_occupation,
        NoOfBrothers: getUsersbyId?.family_details?.no_of_brothers,
        BrothersMarried: getUsersbyId?.family_details?.brothers_married,
        NoOfSisters: getUsersbyId?.family_details?.no_of_sisters,
        SistersMarried: getUsersbyId?.family_details?.sisters_married,
        FamilyLocation: getUsersbyId?.family_details?.family_location,

        aadhar_verification_details:
          getUsersbyId?.aadhar_verification_details?.verification_status,
      });
    };

    getData();
  }, []);

  // console.log(missingCardDet);

  useEffect(() => {
    EditMobileNumberPopup || MobileVerificationPopup || MobileVerifiedPopup
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [EditMobileNumberPopup, MobileVerificationPopup, MobileVerifiedPopup]);

  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");

  const handleChangeEditMobileNumber = ({ target: { value } }) => {
    setError("");
    if (!/^[0-9]*$/.test(value)) {
      setError("Invalid Mobile No.");
    } else if (value.length !== 10) {
      setError("Must include ten numbers");
    }

    setMobile(value);
  };

  const handleSubmitEditMobileNumber = (e) => {
    try {
      e.preventDefault();
      if (!error.length && mobile.length) {
        navigate("/mobileVerification");
      } else if (!mobile.length) {
        setError("Must include ten numbers");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditMobileNumber = () => {
    setEditMobileNumberPopup(true);
    setMobileVerificationPopup(false);
    setMobileVerifiedPopup(false);
  };

  const handleEditButton = () => {
    setEditMobileNumberPopup(true);
    setMobileVerificationPopup(false);
    setMobileVerifiedPopup(false);
  };

  const handleUpdate = () => {
    setEditMobileNumberPopup(false);
    setMobileVerificationPopup(true);
    setMobileVerifiedPopup(false);
  };

  const handleVerify = () => {
    setEditMobileNumberPopup(false);
    setMobileVerificationPopup(false);
    setMobileVerifiedPopup(true);
  };

  // Edit Mobile Number popup End

  const handleEdit = (id) => {
    setEditProcess(id === editProcess ? "" : id);
    setIndDetails({ ...ProfileDetails });
  };

  const handleSave = async (type) => {
    if (type === "save") {
      setEditProcess("");

      // About
      let about = ProfileDetails.FewWordsAboutMe;

      // Basic Details
      let profile_created_for = ProfileDetails.ProfileCreatedFor;
      let name = ProfileDetails.Name;
      let age = ProfileDetails.Age;
      let height = ProfileDetails.Height;
      let body_type = ProfileDetails.BodyType;
      let mother_tongue = ProfileDetails.MotherTongue;
      let marital_status = ProfileDetails.MaritalStatus;
      let eating_habits = ProfileDetails.EatingHabits;
      let smoking_habits = ProfileDetails.SmokingHabits;
      let drinking_habits = ProfileDetails.DrinkingHabits;
      let disability;

      // horoscope_details
      let place_of_birth = ProfileDetails.PlaceofBirth;
      let date_of_birth = ProfileDetails.DateOfBirth;
      let time_of_birth = ProfileDetails.TimeOfBirth.toString();
      let star = ProfileDetails.Star;
      let raasi = ProfileDetails.Raasi;
      let horoscope_copy = ProfileDetails.HoroscopeCopy;

      // religion_information
      let denomination = ProfileDetails.Denomination;
      let division = ProfileDetails.Division;
      let sub_caste = ProfileDetails.Subcaste;
      let religious_values;

      // brides_location
      let country = ProfileDetails.Country;
      let state = ProfileDetails.State;
      let city = ProfileDetails.City;
      let citizenship = ProfileDetails.Citizenship;

      // professional_details
      let education = ProfileDetails.Education;
      let college = ProfileDetails.College;
      let employeed_in = ProfileDetails.EmployedIn;
      let occupation = ProfileDetails.Occupation;
      let occupation_in_details = ProfileDetails.OccupationInDetail;
      let organization_name = ProfileDetails.OrganizationName;
      let work_location;
      let annual_income = ProfileDetails.AnnualIncome;

      // family_details
      let family_values = ProfileDetails.FamilyValues;
      let family_type = ProfileDetails.FamilyType;
      let family_status = ProfileDetails.FamilyStatus;
      let fathers_occupation = ProfileDetails.FathersOccupation;
      let mothers_occupation = ProfileDetails.MothersOccupation;
      let no_of_brothers = ProfileDetails.NoOfBrothers;
      let brothers_married = ProfileDetails.BrothersMarried;
      let no_of_sisters = ProfileDetails.NoOfSisters;
      let sisters_married = ProfileDetails.SistersMarried;
      let family_location = ProfileDetails.FamilyLocation;
      let marry_another_community;
      let no_of_children;

      const aboutData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-about/${User_ID}`,
        {
          about,
        }
      );

      const basicData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-basicdetails/${User_ID}`,
        {
          profile_created_for,
          name,
          age,
          height,
          body_type,
          mother_tongue,
          marital_status,
          eating_habits,
          smoking_habits,
          drinking_habits,
          disability,
        }
      );

      const horoscopeData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-horoscopedetails/${User_ID}`,
        {
          place_of_birth,
          date_of_birth,
          time_of_birth,
          star,
          raasi,
          horoscope_copy,
        }
      );

      const ReligiousData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-religiousdetails/${User_ID}`,
        {
          denomination,
          division,
          sub_caste,
          religious_values,
        }
      );

      const bridesData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-bridesdetails/${User_ID}`,
        {
          country,
          state,
          city,
          citizenship,
        }
      );

      const professionalData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-professionaldetails/${User_ID}`,
        {
          education,
          college,
          employeed_in,
          occupation,
          occupation_in_details,
          organization_name,
          work_location,
          annual_income,
        }
      );

      const familyData = await axios.put(
        `${BACKEND_PORT}/profiles/edit-familydetails/${User_ID}`,
        {
          family_values,
          family_type,
          family_status,
          fathers_occupation,
          mothers_occupation,
          no_of_brothers,
          brothers_married,
          no_of_sisters,
          sisters_married,
          family_location,
          marry_another_community,
          no_of_children,
        }
      );
    } else if (type === "cancel") {
      setProfileDetails({ ...indDetails });
      setEditProcess("");
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    console.log(name, value);
    setProfileDetails({ ...ProfileDetails, [name]: value });
  };

  // converting height in cms to feet and inches
  let heightInFeet = "";
  let heightInInches = "";
  heightInFeet = heightInInches = ProfileDetails.Height * 0.0328;

  return (
    <>
      <div className="container_fluid">
        <div className="col-lg-12">
          <div className="row EditPage_Header">
            <HomeHeader />
          </div>

          <div className="row MobileEditPage_Header">
            <div className="searchBoxTapBar">
              Edit Profile
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </div>

          <div className="EditPageBody">
            <div className="EditPageBody_profileCard">
              <div className="EditPageBody_profilePhotoCard">
                <img
                  src={`https://res.cloudinary.com/tardivalentine/image/upload/v1684327123/x18hjiqroicnta2pbjyz.png`}
                  alt="PhofilePhoto"
                />
                <div className="profilePhotoCard_EditPhotoLink">
                  <a
                    onClick={() => {
                      navigate("/addprofilephotos");
                    }}
                  >
                    Edit photo
                  </a>
                </div>
              </div>
              <div className="EditPageBody_Profile_details">
                <h4 id="Profile_details_Name">{ProfileDetails?.Name}</h4>
                <p id="Profile_details_CreatedFor">
                  Profile created for {`Friend`}
                </p>
                <p>
                  <></>
                  {ProfileDetails?.Age ? ProfileDetails?.Age : "--"} Yrs,
                  {ProfileDetails.Height
                    ? `
                          ${heightInFeet.toString().split(".")[0]} Ft 
                          ${Math.ceil(heightInInches)} In / 
                          ${ProfileDetails.Height} Cms
                        `
                    : "-----"}
                </p>
                <p>
                  {ProfileDetails.Division ? ProfileDetails.Division : "---"},{" "}
                  {ProfileDetails.Denomination
                    ? ProfileDetails.Denomination
                    : "---"}
                </p>
                <p>
                  {ProfileDetails.City ? ProfileDetails.City : "---"},{" "}
                  {ProfileDetails.State ? ProfileDetails.State : "---"},{" "}
                  {ProfileDetails.Country ? ProfileDetails.Country : "---"}
                </p>
                <p>
                  {ProfileDetails.Education ? ProfileDetails.Education : "---"},{" "}
                  {ProfileDetails.Occupation
                    ? ProfileDetails.Occupation
                    : "---"}
                </p>
                <div className="profile_verification_div">
                  <div>
                    <img
                      src={Mobile_green_icon}
                      alt="EditPageBody_Profile_details"
                      className="Mobile_green_icon"
                    />
                    <span>{`+91 ${profileData?.mobile}`}</span>
                  </div>
                  <div className="verifiedInfo">
                    <i className="fa-solid fa-circle-check"></i>
                    <span className="Verified_span">{`Verified`}</span>
                    <a
                      href="#"
                      className="Edit_Link"
                      onClick={handleEditMobileNumber}
                    >
                      [Edit]
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {typeof MissingCard === "number" && (
              <Missing_Details_BlueCard
                missingCardDet={missingCardDet}
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                handleSave={handleSave}
              />
            )}

            <div className="PersonalDetails_Bar">
              <div>
                <span>Personal Details</span>
              </div>
            </div>

            <>
              <PersonalDetails_FewWords
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_BasicDetails
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_HoroscopeDetails
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
                setProfileDetails={setProfileDetails}
              />
            </>

            <>
              <PersonalDetails_ReligionInformation
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_BridesLocation
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_ProfessionalDetails
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_FamilyDetails
                ProfileDetails={ProfileDetails}
                handleChange={handleChange}
                setSave={setSave}
                handleEdit={handleEdit}
                editProcess={editProcess}
                setEditProcess={setEditProcess}
                handleSave={handleSave}
              />
            </>

            <>
              <PersonalDetails_AadharVerificationDetails
                ProfileDetails={ProfileDetails}
              />
            </>
          </div>
        </div>

        {EditMobileNumberPopup && (
          <div
            className={`${
              EditMobileNumberPopup === true ? "EditMobileNumberPopupOn" : ""
            }`}
          >
            <>
              <div className="editMobileContainer">
                <button
                  onClick={() => {
                    setEditMobileNumberPopup(false);
                  }}
                >
                  X
                </button>
                <div className="editMobileHeader">Edit Mobile Number</div>
                <div className="editMobileText">
                  Please change your mobile number and verify it
                </div>
                <div className="editMobileInp">
                  <form onSubmit={handleSubmitEditMobileNumber}>
                    <input
                      className="editDefaultFeild"
                      placeholder="+91"
                      disabled
                    />
                    <input
                      className="editMobileInpFeild"
                      placeholder="Enter Mobile No."
                      value={mobile}
                      onChange={handleChangeEditMobileNumber}
                    />
                    <button onClick={handleUpdate}>Update</button>
                    <span className="EditProfilePageEditMobileErr">
                      {error}
                    </span>
                  </form>
                </div>
              </div>
            </>
          </div>
        )}

        {MobileVerificationPopup && (
          <div
            className={`${
              MobileVerificationPopup === true
                ? "MobileVerificationPopupOn"
                : ""
            }`}
          >
            <div className="editMobileVerificationContainer">
              <EditMobileNumberVerificationPopup
                setMobileVerificationPopup={setMobileVerificationPopup}
                setEditMobileNumberPopup={setEditMobileNumberPopup}
                edit={"edit"}
                handleVerify={handleVerify}
                handleEditButton={handleEditButton}
              />
            </div>
          </div>
        )}

        {MobileVerifiedPopup && (
          <div
            className={`${
              MobileVerifiedPopup === true ? "MobileVerificationPopupOn" : ""
            }`}
          >
            <div className="editMobileVerifiedContainer">
              <EditMobileNumberVerifiedPopup
                setMobileVerifiedPopup={setMobileVerifiedPopup}
              />
            </div>
          </div>
        )}

        <div className="row EditPageFooter">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default EditProfilePage;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withAuthBackground from "../withAuthBackground";
import AuthHeader from "../../../components/Auth/AuthHeader";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";

const SendCode = () => {
  const navigate = useNavigate();

  const {
    state: { sendOtpTo, email },
  } = useLocation();
  console.log(sendOtpTo, email);

  const handleSendLink = async () => {
    let sendLink = await axios.post(`${BACKEND_PORT}/forgotPassword`, {
      email,
    });
    // console.log(email);

    console.log(sendLink);
    navigate("/emailVerification", {
      state: {
        UserID: null,
        email: email,
      },
    });
  };

  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      <div className="row">
        <div className="forgotContainer">
          {sendOtpTo === "mobile" ? (
            <>
              <div className="forgotHeader">
                Please Verifiy Your Mobile Number
              </div>
              <div className="forgotText">
                A 4-Digit Confirmation code will be
                <br /> sent to your mobile number
                <br />
                <span className="forgotTextBold">XXXXXX9871</span>
              </div>
              <div
                className="loginButton sendCodeButton"
                onClick={() => {
                  navigate("/verify", {
                    state: { forgotPassword: true },
                  });
                }}
              >
                <button>Send code</button>
              </div>
            </>
          ) : (
            <>
              <div className="forgotHeader">Please Verifiy Your Email</div>
              <div className="forgotText">
                A Confirmation link will be sent to your email
                <br />
                <span className="forgotTextBold">{email}</span>
              </div>
              <div className="loginButton sendCodeButton">
                <button onClick={handleSendLink}>Send link</button>
              </div>
            </>
          )}
          <div
            className="goBack"
            onClick={() => {
              navigate("/login");
            }}
          >
            <span className="material-symbols-outlined">chevron_left</span>
            Back
          </div>
          <div className="forgotFull" />
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(SendCode, "login");

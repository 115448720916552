import React from "react";
import './EditMobileNumberVerifiedPopup.css'

const EditMobileNumberVerifiedPopup = ({ setMobileVerifiedPopup }) => {
  return (
    <>
      <button
        onClick={() => {
          setMobileVerifiedPopup(false);
        }}
      >
        X
      </button>
      <div className="editMobileHeader">Mobile Number Changed Sucessfully!</div>
      <div className="editMobileText desktopMobileVerifiedText">
        You have successfully verified your mobile number.
      </div>

      <div className="MobileEditMobileText">
        <button
          onClick={() => {
            setMobileVerifiedPopup(false);
          }}
          className="MobileverifiedCloseButton"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default EditMobileNumberVerifiedPopup;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import shareGrey from "../../../assets/images/Matches/shareGrey.png";
import shareBlue from "../../../assets/images/Matches/shareBlue.png";
import homeProfilePic1 from "../../../assets/images/Individual/userProfilePicture.png";
import homeProfilePic2 from "../../../assets/images/Individual/userProfilePicture.png";
import homeProfilePic3 from "../../../assets/images/Individual/userProfilePicture.png";
import chatIcon from "../../../assets/images/Matches/Chat_Icon.png";
import verified from "../../../assets/images/Matches/Verified_User.png";
import pending from "../../../assets/images/Matches/Pending_verification.png";
import yetToVerify from "../../../assets/images/Matches/yetToVerify.png";
import HomeHeader from "../../Headers/Home header/HomeHeader";
import Filter from "../Filter/Filter";
import Footer from "../../Footer/Footer";
import ShareInput from "../ShareInput/ShareInput";
import Activity from "../Activity/Activity";
import SubscribePopUp from "../../Individual/Subscribe/SubscribePopUp";
import InterestPopUp from "../../Individual/ShowInterest/InterestPopUp";
import "./Matches.css";
import RequestPhoto from "../../Individual/RequestPhoto/RequestPhoto";
import nayan from "../../../assets/images/Home/nayan.jpg";
import NoData from "../../../assets/images/Matches/NoData.png";
import ProfilePicPlaceholder from "../../../assets/images/Home/HomeProfilePlaceHolder.png";
import MobileNotificationIcon from "../../../assets/images/Sidebar/NotificationBell Icon.png";
import GreenNotificationPointIcon from "../../../assets/images/Sidebar/GreenNotificationPointIcon.png";

const Matches = () => {
  const [profilePicture, setProfilePicture] = useState(nayan);

  // share profile pop up
  const [shareProfile, setShareprofile] = useState(false);

  // subscribe pop up
  const [subscribe, setSubscribe] = useState(false);

  // interest pop up
  const [interest, setInterest] = useState(false);

  // request photo pop up
  const [requestPhoto, setrequestPhoto] = useState(false);
  // console.log(requestPhoto);

  // when clicking on the matches page the page goes to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // getting headers from the home page
  const {
    state: { header1, header2, header3, activity, profileMembers },
  } = useLocation();

  console.log(profileMembers);

  // navigate
  const navigate = useNavigate();

  // matches details
  const [matchesData, setMatchesData] = useState([
    {
      profilepic: homeProfilePic2,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: "The user has not uploaded the profile photo",
      isverified: "Aadhar verification pending",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: homeProfilePic1,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: homeProfilePic2,
      isverified: "Verified",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: homeProfilePic1,
      isverified: "Aadhar verification pending",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
    {
      profilepic: homeProfilePic3,
      isverified: "Yet to verify",
      name: "Thomas Vijayraj",
      location: "Chennai, Tamil Nadu",
      ageHeight: "25 Years, 171 cms",
      denomination: "Roman catholic",
      division: "lorem ipsum",
      education: "BE",
      Occupation: "Sofware Engineer",
    },
  ]);

  return (
    <>
      <div className={`container-fluid  matchesContainerPage `}>
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="PageNotFoundTapBar">
              <span className="PageHeading">Testimonials</span>

              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/mobileMatchesBox");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>

              <div className="PageNotFoundProfilePics">
                {profilePicture ? (
                  <img src={profilePicture} />
                ) : (
                  <img src={ProfilePicPlaceholder} className="placeHolderImg" />
                )}
              </div>
              <span className="pageNotFoundProfileDetails">Hey ! Cathy</span>

              <span
                className="MobileNotificationIcon"
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                <img src={MobileNotificationIcon} alt="NotificationBell" />
                <img
                  src={GreenNotificationPointIcon}
                  alt="MobileNotificationPoint"
                  className="MobileNotificationPoint"
                />
              </span>
            </div>
          </span>
        </div>

        {/* background grey when popup */}
        <div
          className={`${shareProfile && "matchesBlack"} ${
            subscribe && "matchesBlack"
          } ${interest && "matchesBlack"}
          ${requestPhoto && "matchesBlack"}`}
        />

        {/* share profile popup */}
        {shareProfile && (
          <div className="shareProfileContainer">
            <div>
              <div
                className="matchShareClose"
                onClick={() => {
                  setShareprofile(false);
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div className="matchShareContainerBox">
                <div className="matchShareHeader">
                  <div className="matchShareBlue">
                    <img src={shareBlue} />
                  </div>
                  <div>Share This Profile!</div>
                </div>
                <div className="matchShareContent">
                  <form>
                    <ShareInput
                      buttonName={"Copy ID"}
                      classname="shareInputMedium"
                    />
                    <div>or</div>
                    <ShareInput
                      buttonName={"Copy Link"}
                      classname="shareInputLarge"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* subscribe pop up */}
        {subscribe && (
          <div className="matchesSubscribe">
            <SubscribePopUp
              setSubscribePopUp={setSubscribe}
              classname={"matchesSubscribe"}
              subscribe={subscribe}
            />
          </div>
        )}

        {/* interest popup */}
        {interest && (
          <div className="matchesSubscribe">
            <InterestPopUp setInterestPopUp={setInterest} interest={interest} />
          </div>
        )}

        {/* request photo popup */}
        {requestPhoto && (
          <div className="matchesSubscribe">
            <RequestPhoto
              setrequestPhoto={setrequestPhoto}
              requestPhoto={requestPhoto}
            />
          </div>
        )}

        {/* matches pages */}
        <div className="col-lg-12">
          <div className="matchContainerHeaders ">
            <div className="matchContainerHeader1">
              <p>
                {header1} ({matchesData.length})
              </p>
            </div>
            <div className="matchContainerHeader2forMobile ">{header2}</div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row matchesContainer">
            <div className="col-lg-3 matchesOptions ">
              {activity ? <Activity /> : <Filter />}
            </div>

            {header1 && (
              <button
                onClick={() => {
                  navigate("/mobileFilterBox");
                }}
                className={`${
                  header1 === "Blocked Profile" ||
                  header1 === "Who has shown interest" ||
                  header1 === "Who viewed you" ||
                  header1 === "Shortlisted by you"
                    ? "HideFilterButton"
                    : ""
                } MobileMatchesFilterButton`}
              >
                <i class="fa-solid fa-filter"></i>
                <span>Filter</span>
              </button>
            )}

            <div className="col-lg-8 profileContentsDivision">
              {header1 === "Blocked Profile" && matchesData.length ? (
                <div className="NoDataProfilesContent">
                  <img src={NoData} alt="No data placeholder" />
                  <p>{"There are no blocked profiles"}</p>
                </div>
              ) : (
                matchesData.map((data, idx) => (
                  <div className="matchesCardContainerBox">
                    <div className="HistoryCardIdContainer">
                      <div>
                        <span className="matchId">JM123456</span> | Profile
                        created by Friend
                      </div>
                      <div
                        className="HistoryShareIcon"
                        onClick={() => {
                          setShareprofile(true);
                        }}
                      >
                        <img src={shareGrey} />
                      </div>
                    </div>
                    <div className="matchesCardContainer" key={idx.toString()}>
                      <div className="matchesCardPictureContainer">
                        <div className="UserVerificationStatus">
                          {data?.aadhar_verification_details
                            ?.verification_status === "Verified" ? (
                            <div className="UserVerificationStatus_verified">
                              <i class="fa-solid fa-circle-check"></i>{" "}
                              {`Verified user`}
                            </div>
                          ) : data?.aadhar_verification_details
                              ?.verification_status ===
                            "Aadhar verification pending" ? (
                            <div className="UserVerificationStatus_pending">
                              <i class="fa-solid fa-circle-check"></i>{" "}
                              {`Verified user`}
                            </div>
                          ) : data?.aadhar_verification_details
                              ?.verification_status === "Yet to verify" ? (
                            <div className="UserVerificationStatus_yet">
                              <i class="fa-solid fa-circle-check"></i>{" "}
                              {`Verified user`}
                            </div>
                          ) : (
                            <div>
                              <i class="fa-solid fa-circle-check"></i>{" "}
                              {`Verified user`}
                            </div>
                          )}
                        </div>
                        <div className="HistoryCardPicture">
                          {data.profilepic ===
                            "The user has not uploaded the profile photo" ||
                          data.profilepic === "View photo request sent" ? (
                            <>
                              <div
                                className="macthesNoProfilePic"
                                onClick={() => {
                                  const matches = [...matchesData];
                                  matches[idx] = {
                                    ...matches[idx],
                                    profilepic:
                                      matches[idx].profilepic ===
                                      "View photo request sent"
                                        ? "The user has not uploaded the profile photo"
                                        : "View photo request sent",
                                  };
                                  setMatchesData(matches);
                                }}
                              >
                                <div>{data.profilepic}</div>
                                {data.profilepic ===
                                  "The user has not uploaded the profile photo" && (
                                  <button
                                    className="macthesNoProfilePicRequest"
                                    onClick={() => {
                                      setrequestPhoto(true);
                                    }}
                                  >
                                    Request Now
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <img src={data.profilepic} />
                          )}
                        </div>
                        <div
                          onClick={() => {
                            navigate("/individual", {
                              state: {
                                data: { profilepic: data.profilepic },
                                profilepic: "",
                              },
                            });
                          }}
                        >
                          View profile
                        </div>
                      </div>

                      <div className="matchesCardDetailsContainer">
                        <div className="HistoryCardVerifiedContainer">
                          {data.isverified === "Verified" ? (
                            <span className="HistoryCardIsVerified HistoryCardVerified">
                              <div className="HistoryVerifiedIcon">
                                <img src={verified} />
                              </div>
                              <span>{data.isverified}</span>
                            </span>
                          ) : data.isverified ===
                            "Aadhar verification pending" ? (
                            <span className="HistoryCardIsVerified HistoryCardPending">
                              <div className="HistoryVerifiedIcon">
                                <img src={pending} />
                              </div>
                              <span>{data.isverified}</span>
                            </span>
                          ) : data.isverified === "Yet to verify" ? (
                            <span className="HistoryCardIsVerified HistoryCardYetToVerify">
                              <div className="HistoryVerifiedIcon">
                                <img src={yetToVerify} />
                              </div>
                              <span>{data.isverified}</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="HistoryCardName">{data.name}</div>

                        <div className="HistoryCardLocation HistoryCardGrey">
                          {data.location}
                        </div>

                        <div className=" matchCardDetails">
                          <div className="HistoryCardDetailsInfoQue">
                            <div className="HistoryCardGrey HistoryCardDet">
                              Age, Height
                            </div>
                            <div className="HistoryCardGrey HistoryCardDet">
                              Denomination
                            </div>
                            <div className="HistoryCardGrey HistoryCardDet">
                              Division
                            </div>
                            <div className="HistoryCardGrey HistoryCardDet">
                              Education
                            </div>
                            <div className="HistoryCardGrey HistoryCardDet">
                              Occupation
                            </div>
                          </div>
                          <div className="HistoryCardDetailsInfoAns">
                            <div className="HistoryCardBlack HistoryCardDet">
                              {data.ageHeight}
                            </div>
                            <div className="HistoryCardBlack HistoryCardDet">
                              {data.denomination}
                            </div>
                            <div className="HistoryCardBlack HistoryCardDet">
                              {data.division}
                            </div>
                            <div className="HistoryCardBlack HistoryCardDet">
                              {data.education}
                            </div>
                            <div className="HistoryCardBlack HistoryCardDet">
                              {data.Occupation}
                            </div>
                          </div>
                        </div>

                        <hr className="matchesCardHr" />

                        <div className="matchesCardButtons">
                          <button
                            onClick={() => {
                              setSubscribe(true);
                            }}
                          >
                            <div>
                              <div className="HistoryChatIcon">
                                <img src={chatIcon} />
                              </div>
                              <div>Chat</div>
                            </div>
                          </button>
                          {data.shortListed ? (
                            <button
                              onClick={() => {
                                const History = [...matchesData];
                                History[idx] = {
                                  ...History[idx],
                                  shortListed: false,
                                };
                                setMatchesData(History);
                              }}
                              className="shortListBtn"
                            >
                              <i class="fa-solid fa-check"></i>
                              <span>Shortlisted</span>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                const history = [...matchesData];
                                history[idx] = {
                                  ...history[idx],
                                  shortListed: true,
                                };
                                setMatchesData(history);
                              }}
                              className="shortListBtn"
                            >
                              <i className="fa-regular fa-star"></i>
                              <span>Shortlist</span>
                            </button>
                          )}

                          <button
                            onClick={() => {
                              setInterest(true);
                            }}
                            className="ShowInteretedBTN"
                          >
                            <i className="fa-solid fa-user-group"></i>Show
                            Interest
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="row homeFooter">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Matches;

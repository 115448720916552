import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import withBGAddprofilePhotos from "./withBGAddprofilePhotos/withBGAddprofilePhotos";
import DeleteButton from "../../assets/images/ProfileRegister/DeleteButton.png";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import "./AddProfilePhotos.css";
import axios from "axios";
import { BACKEND_PORT } from "../../App";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AddProfilePhotos = () => {
  const navigate = useNavigate();

  const [popUp, setPopUp] = useState(false);

  const [previewPhoto, setPreviewPhoto] = useState(null);

  const [photoEditPopUp, setPhotoEditPopUp] = useState(false);

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  // const [aspect, setAspect] = (useState < number) | (undefined > 16 / 9);
  const [aspect, setAspect] = useState(1 / 1);
  const [croppedPicture, setCroppedPicture] = useState(["", "", ""]);
  let [pictureCount, setPictureCount] = useState(0);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    setPhotoEditPopUp(true);
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
          setPreviewPhoto
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  const handleDeleteImage = (img, idx) => {
    let croppedPic = croppedPicture.filter((profile) => profile !== img);
    croppedPic[2] = "";
    setCroppedPicture(croppedPic);
    setPictureCount(pictureCount - 1);
  };

  const croppedPictureLength = croppedPicture.filter(
    (profile) => profile === ""
  );

  const user_id = window.sessionStorage.getItem("User_ID");

  const handlePostImage = async () => {

    var image = new Image();

    image = croppedPicture[0];

    console.log(image);

    // try{
    //   const putData = await axios.put(
    //     `${BACKEND_PORT}/userDetails/upload/${user_id}`,
    //     {
    //       croppedPicture,
    //     }
    //   );

    //   console.log(putData);
    // }catch(error){
    //   console.log(error);
    // }
    // console.log(croppedPicture);
    // navigate("/approval");

  }

  return (
    <>
      <div className="AddProfilePhoto_card">
        <p className="AddProfilePhoto_card_Heading">
          Add your photo to get featured on top of the search results
        </p>

        {croppedPictureLength.length === 3 ? (
          <>
            <div className="AddProfilePhoto_Placeholder">
              Upload a Photo to view here
            </div>
          </>
        ) : (
          <div className="AddProfilePhoto_AfterOnePicUpload">
            {croppedPicture.map((profile, idx) => {
              return (
                <>
                  {profile.length ? (
                    <div className="AddProfilePhoto_with_deletebuttonDiv">
                      <img
                        src={profile}
                        height="170"
                        width="170"
                        className="AddProfilePhoto_AfterOnePicUpload_img"
                      />
                      <img
                        src={DeleteButton}
                        height="25"
                        width="25"
                        className="AddProfilePhoto_AfterOnePicUpload_DeleteButton"
                        onClick={() => {
                          handleDeleteImage(profile, idx);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="AddProfilePhoto_Placeholder_AfterOnePicUpload">
                      Upload a Photo to view here
                    </div>
                  )}
                </>
              );
            })}
          </div>
        )}

        {pictureCount === 3 ? (
          <>
            <label
              className="AddProfilePhoto_card_ProceedButton"
              onClick={handlePostImage}
            >
              Proceed
            </label>
          </>
        ) : (
          <>
            <div className="AddProfilePhoto_card_BrowseDiv">
              <p>Drag and Drop files here</p>
              <p>or</p>

              <label className="AddProfilePhoto_card_BrowseButton">
                Browse
                <input
                  type={"file"}
                  accept="image/*"
                  onChange={onSelectFile}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <label className="AddProfilePhoto_card_BrowseButton forMobileViewAddProfilePhoto_card_BrowseButton">
              Add
              <input
                type={"file"}
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: "none" }}
              />
            </label>
          </>
        )}
        {popUp && (
          <>
            <div className="popupContainer">
              <div className="popupBox">
                <button
                  onClick={() => {
                    setPopUp(false);
                  }}
                  className="PopupBox_X_button"
                >
                  X
                </button>
                <h4>Are You Sure?</h4>
                <p>
                  If you skip this step now, potential matches will not be able
                  to find you
                </p>
                <div className="PopupBox_FuncButton_Div">
                  <button
                    onClick={() => {
                      navigate("/approval");
                    }}
                    className="PopupBox_withOutPhoto_button"
                  >
                    <span className="DesktopViewButton">
                      I’m fine without my photo
                    </span>
                    <span className="MobileViewButton">Not now</span>
                  </button>
                  <button
                    onClick={() => {
                      setPopUp(false);
                    }}
                    className="PopupBox_AddPhoto_button"
                  >
                    <span className="DesktopViewButton">
                      I will add my profile photo
                    </span>
                    <span className="MobileViewButton">Add now</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {photoEditPopUp && (
          <>
            <div className="photoEditPopUp_container">
              <div className="photoEditPopUp_Box">
                <label className="photoUploadlabel">Photo Upload</label>
                <div className="profilePhotoCropIMG">
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                      id={"addPR_Cropping_img"}
                      // height="100%"
                      // width={"auto"}
                    />
                  </ReactCrop>
                </div>
                <div className="profilePhotoCrop_Division">
                  <div className="col-lg-12">
                    <div className="row ">
                      <div className="col-lg-5 profilePhotoCropedPreview">
                        {!!completedCrop && (
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              border: "1px solid black",
                              objectFit: "contain",
                              objectPosition: "center",
                              width: "200px",
                              height: "200px",
                            }}
                          />
                        )}
                        <br />
                        <span className="profilePhotoCropedPreview_label">
                          Preview
                        </span>
                      </div>

                      <div className="col-lg-7 profilePhotoCrop_tools">
                        <div>
                          <i class="fa-solid fa-upload"></i>
                          <label>
                            Upload
                            <input
                              type={"file"}
                              accept="image/*"
                              onChange={onSelectFile}
                              style={{ display: "none" }}
                            />
                          </label>
                        </div>
                        <div>
                          <i class="fa-solid fa-rotate-left"></i>
                          <button
                            onClick={(e) =>
                              setRotate(
                                // Math.min(180, Math.max(-180, rotate + 90))
                                rotate === 360 ? 0 : rotate + 90
                              )
                            }
                          >
                            Rotate
                          </button>
                        </div>
                        {/* <div>
                          <i class="fa-solid fa-crop-simple"></i>
                          <button>Crop</button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profilePhotoCrop_PermissionButtons">
                  <button
                    onClick={() => {
                      setPhotoEditPopUp(false);
                      let croppedPic = [...croppedPicture];
                      croppedPic[pictureCount] = previewPhoto;
                      setCroppedPicture(croppedPic);
                      setPictureCount(pictureCount + 1);

                      if (croppedPicture[pictureCount].length) {
                      }
                      // setCroppedPicture([...croppedPicture, previewPhoto]);
                    }}
                  >
                    Crop
                  </button>
                  <button
                    onClick={() => {
                      setPhotoEditPopUp(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        className="AddProfilePhoto_card_Skip_link_Div"
        onClick={() => {
          setPopUp(true);
        }}
      >
        <span className="AddProfilePhoto_card_Skip_link">
          Skip
          <span className="material-symbols-outlined ">chevron_right</span>
        </span>
      </div>
    </>
  );
};

export default withBGAddprofilePhotos(AddProfilePhotos);

import React from "react";
import InputField from "../../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../../components/PartnerPreference/InputFields/SelectInputField";
import "./RegularSearchReligion.css";

function RegularSearchReligion({
  RegularSearchField,
  handleChange,
  partnerEditProcess,
}) {

  const RegularSearchBasicInputs = [
    {
      inputType: "select",
      name: "denomination",
      value: RegularSearchField.denomination,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "division",
      value: RegularSearchField.division,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "subCaste",
      value: RegularSearchField.subCaste,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="RSbasicPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle">Religion Preference</div>
      </div>

      <div className={`RSDetailsSectionRow_EditTime`}>
        <div className="partnerInputs">
          <ul className={`RSDetails_section_label_EditTime`}>
            <li className="DenominationEditTime">Denomination</li>
            <li className="DivisionEditTime">Division</li>
            <li className="subCasteEditTime">Sub caste</li>
          </ul>
          <ul className={`PartnerDetails_section_colon_EditTime`}>
            <li className="DenominationEditTime">:</li>
            <li className="DivisionEditTime">:</li>
            <li className="subCasteEditTime">:</li>
          </ul>
          <ul className="Details_section_values partnerDetails_section_values">
            {RegularSearchBasicInputs.map((input) =>
              input.inputType === "select" ? (
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RegularSearchReligion;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerUser: [],
  selectedItem: {},
};

const registerSlices = createSlice({
  name: "registerSlices",
  initialState,
  reducers: {
    addRegisterDetails: (state, action) => {
      let userData = { ...action.payload };
      state.registerUser.push(userData);
    },
    setSelectedUser: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
});


export const { addRegisterDetails, setSelectedUser} = registerSlices.actions;

export default registerSlices.reducer
import React from "react";
import InputField from "../../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../../components/PartnerPreference/InputFields/SelectInputField";
import "./RegularSearchBasic.css";

function RegularSearchBasic({ RegularSearchField, handleChange }) {
  const RegularSearchBasicInputs = [
    {
      className: "partnerPreferenceSmallOption",
      inputType: "range",
      name: "age",
      field: [
        {
          name: "ageFrom",
          value: RegularSearchField.ageFrom,
          options: [
            { value: "25", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          name: "ageTo",
          value: RegularSearchField.ageTo,
          options: [
            { value: "31", disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
    {
      className: "partnerPreferenceMediumOption",
      inputType: "range",
      name: "height",
      field: [
        {
          value: RegularSearchField.heightFrom,
          name: "heightFrom",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
        {
          value: RegularSearchField.heightTo,
          name: "heightTo",
          options: [
            { value: `5'2"`, disabled: true, selected: true },
            { value: "Tamil", disabled: false },
            { value: "English", disabled: false },
            { value: "Hindi", disabled: false },
          ],
        },
      ],
    },
    {
      inputType: "radio",
      value: RegularSearchField.bodyType,
      field: [
        { name: "bodyType", label: "Slim", value: "Slim", idAndFor: "slim" },
        {
          name: "bodyType",
          label: "Athletic",
          value: "Athletic",
          idAndFor: "athletic",
        },
        {
          name: "bodyType",
          label: "Average",
          value: "Average",
          idAndFor: "average",
        },
        {
          name: "bodyType",
          label: "Heavy",
          value: "Heavy",
          idAndFor: "heavy",
        },
      ],
    },
    {
      inputType: "radio",
      value: RegularSearchField.maritalStatus,
      field: [
        {
          name: "maritalStatus",
          label: "Unmaried",
          value: "Unmaried",
          idAndFor: "unmaried",
        },
        {
          name: "maritalStatus",
          label: "widow/widower",
          value: "widow/widower",
          idAndFor: "widower",
        },
        {
          name: "maritalStatus",
          label: "Divorced",
          value: "Divorced",
          idAndFor: "divorced",
        },
        {
          name: "maritalStatus",
          label: "Seperated",
          value: "Seperated",
          idAndFor: "seperated",
        },
        {
          name: "maritalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "doesn'tmatter",
        },
      ],
    },
    {
      inputType: "radio",
      field: [
        {
          name: "physicalStatus",
          label: "Normal",
          value: "Normal",
          idAndFor: "normal",
        },
        {
          name: "physicalStatus",
          label: "Physically challenged",
          value: "Physically challenged",
          idAndFor: "athletic",
        },
        {
          name: "physicalStatus",
          label: "Doesn't matter",
          value: "Doesn't matter",
          idAndFor: "n'tmatter",
        },
      ],
    },
    {
      inputType: "select",
      name: "motherTounge",
      value: RegularSearchField.motherTounge,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "foodHabits",
      value: RegularSearchField.foodHabits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "smokingHabits",
      value: RegularSearchField.smokingHabits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "drinkingHabbits",
      value: RegularSearchField.drinkingHabbits,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="RSbasicPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="partnerFieldTitle">Basic Preferences</div>
      </div>

      <div className={`RSDetailsSectionRow_EditTime`}>
        <div className="partnerInputs">
          <ul className={`RSDetails_section_label_EditTime`}>
            <li>Age</li>
            <li>Height</li>
            <li className="BodyTypeEditTime">Body Type</li>
            <li className="MaritalStatusEditTime">Marital Status</li>
            <li className="PhysicalStatusEditTime">Physical Status</li>
            <li className="MotherTongueEditTime">Mother Tongue</li>
            <li className="EatingEditTime">Eating Habits</li>
            <li className="SmokingEditTime">Smoking Habits</li>
            <li className="DrinkingEditTime">Drinking Habits</li>
          </ul>
          <ul className={`PartnerDetails_section_colon_EditTime`}>
            <li>:</li>
            <li>:</li>
            <li className="BodyTypeEditTime">:</li>
            <li className="MaritalStatusEditTime">:</li>
            <li className="PhysicalStatusEditTime">:</li>
            <li className="MotherTongueEditTime">:</li>
            <li className="EatingEditTime">:</li>
            <li className="SmokingEditTime">:</li>
            <li className="DrinkingEditTime">:</li>
          </ul>
          <ul className="Details_section_values partnerDetails_section_values">
            {RegularSearchBasicInputs.map((input) =>
              input.inputType === "radio" ? (
                <div className="partnerFieldAns partnerPreferenceRadioFields EditPartnerPreferenceRadioFields">
                  <RadioInputField
                    input={input}
                    handleChange={handleChange}
                    inputFields={RegularSearchField}
                    className={`EditTimeRadioflexWrap`}
                  />
                </div>
              ) : input.inputType === "select" ? (
                <div className="partnerFieldAns">
                  <SelectInputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                </div>
              ) : input.inputType === "range" ? (
                <div className="partnerFieldRange partnerFieldAns">
                  <SelectInputField
                    input={input.field[0]}
                    className={input.className}
                    handleChange={handleChange}
                  />
                  <div> - </div>
                  <div className="partnerFieldAns">
                    <SelectInputField
                      input={input.field[1]}
                      className={input.className}
                      handleChange={handleChange}
                    />
                  </div>
                  <span className="Details_section_values_rightLabel">
                    {input.name === "age" && <div>Years</div>}
                  </span>
                </div>
              ) : (
                <div className="partnerFieldAns">
                  <InputField
                    input={input}
                    className={input.className}
                    handleChange={handleChange}
                  />
                  <span className="Details_section_values_rightLabel">
                    {input.FieldLabel}
                  </span>
                </div>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RegularSearchBasic;

import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../ProfileSettingsTab/ProfileSettingsTab.css";

const ProfileSettingsTab = () => {
  const [settingsID, setsettingsID] = useState("");

  const settingsHeader = [
    { header: "Change Email ID", id: "/changeemailid", link: "/changeEmailId" },
    { header: "History", id: "/history", link: "/history" },
    {
      header: "Customer Support",
      id: "/customersupport",
      link: "/customersupport",
    },
    {
      header: "Shortlisted profiles",
      id: "/ShortlistedProfiles",
      link: "/ShortlistedProfiles",
    },
    { header: "Privacy", id: "/privacyPolicy", link: "/photoPrivacy" },
  ];

  const { pathname } = useLocation();
  // console.log(pathname === settingsID);
  const navigate = useNavigate();
  //   to highlight the select header, get the pathname from the location
  useEffect(() => {
    // window.location.reload();
    setsettingsID(pathname);
  }, [pathname]);
  return (
    <div>
      <div className="ChangeEmailTitle">Profile Settings</div>
      <form action="">
        <div className="changeEmailHeaders">
          {settingsHeader.map(({ header, id, link }) => {
            return (
              <>
                <div
                  className={`changeEmailHeader ${
                    settingsID === link && "changeEmailPink"
                  }`}
                  key={id}
                  onClick={() => {
                    navigate(link, {
                      state: { header1: header, activity: true },
                    });
                  }}
                >
                  {/* {console.log(link)} */}
                  <div>{header}</div>
                </div>
              </>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default ProfileSettingsTab;

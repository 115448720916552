import React from "react";
import './FilterProfessionalDetails.css'
import SelectInputField from "../../PartnerPreference/InputFields/SelectInputField";
import CheckBoxInputField from "../../PartnerPreference/InputFields/CheckBoxInputField";

const FilterProfessionalDetails = ({ filterId, setFilterId, filterInputs, handleChange }) => {
  const professionalInputs = [
    {
      label: "Select the Profession",
      inputType: "option",
      name: "profession",
      value: filterInputs.profession,
      className:
        "partnerPreferenceMediumOption selectFielBox MobilePartnerPreferenceMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
      // error: PrInputs.errors.Denomination,
    },
    {
      label: "Employed in",
      inputType: "checkBox",
      checkInputs: [
        { name: "employed", value: "government", label: "Government/PSU" },
        { name: "employed", value: "private", label: "Private" },
        { name: "employed", value: "buisness", label: "Buisness" },
        { name: "employed", value: "defence", label: "Defence" },
        { name: "employed", value: "selfEmployed ", label: "Self Employed" },
      ],
    },
    {
      className:
        "partnerPreferenceMediumLargeOption selectFielBox MobileProfessionalDetailsMediumLargeOption",
      inputType: "range",
      label: "Annual Income",
      from: {
        name: "AnnualIncomeFrom",
        value: filterInputs.AnnualIncomeFrom,
        options: [
          { value: "Rs 90,00", disabled: true, selected: true },
          { value: "Tamil", disabled: false },
          { value: "English", disabled: false },
          { value: "Hindi", disabled: false },
        ],
        error: "",
      },
      to: {
        name: "AnnualIncomeTo",
        value: filterInputs.AnnualIncomeTo,
        options: [
          { value: "Any", disabled: true, selected: true },
          { value: "Tamil", disabled: false },
          { value: "English", disabled: false },
          { value: "Hindi", disabled: false },
        ],
        error: "",
      },
    },
  ];
  return (
    <div className="filterDropDown">
      <div
        className={`${
          filterId.includes("professionalDetails")
            ? "openfilterDropDownHeader"
            : "filterDropDownHeader"
        }`}
      >
        <div className="filterDropDownTitle">Professional Details</div>
        <div
          className="filterDropDownPlus"
          onClick={() => {
            filterId.includes("professionalDetails")
              ? setFilterId(filterId.filter((e) => e !== "professionalDetails"))
              : setFilterId([...filterId, "professionalDetails"]);
          }}
        >
          {filterId.includes("professionalDetails") ? (
            <i className="fa-solid fa-minus"></i>
          ) : (
            <i className="fa-solid fa-plus"></i>
          )}
        </div>
      </div>
      {filterId.includes("professionalDetails") && (
        <div className="dropDownComponent">
          <div className="dropDownLine"></div>
          {professionalInputs.map((input) =>
            input.inputType === "option" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className="partnerFieldRange partnerFieldRangeFlex">
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : input.inputType === "checkBox" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className={`ProfileTypeCheckBoxsList`}>
                    {input.checkInputs.map((inp) => (
                      <CheckBoxInputField
                        input={inp}
                        handleChange={handleChange}
                      />
                    ))}
                  </div>
                </div>

                {/* {console.log(input.checkInputs)} */}
              </>
            ) : input.inputType === "range" ? (
              <>
                <div className="dropDownOption">
                  <div className="dropDownComponentTitle">{input.label}</div>
                  <div className="partnerFieldRange partnerFieldRangeFlex">
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input.from}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                    <div>to</div>
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input.to}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          )}
        </div>
      )}
    </div>
  );
};

export default FilterProfessionalDetails;

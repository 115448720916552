import React from "react";
import { useNavigate } from "react-router-dom";
import candidate from "../../../../assets/images/Home/nayan.jpg";

function MobileMatchesBox() {
  const navigate = useNavigate();
  return (
    <>
      <div className="searchBoxTapBar">
        Matches
        <div
          className="leftArrowIcon"
          onClick={() => {
            navigate("/home");
          }}
        >
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      </div>

      <div className="SearchBoxContents MatchesBoxContents">
        <div className="MatchesBoxCandidateProfile">
          <div className="MBprofilePhoto">
            <img src={candidate} />
          </div>
          <p className="candidateName">{"Cathy"}</p>
          <p className="candidateId">{"JM123456"}</p>
        </div>
        <label className="MatchesBoxLabel">Matches</label>
        <div className="SearchBoxButtons">
          <button
            onClick={() => {
              navigate("/matches/totalMatches", {
                state: {
                  header1: "Total Matches",
                  header2: "",
                  activity: true,
                },
              });
            }}
          >
            <label>Total Matches</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/newMatches", {
                state: {
                  header1: "New Matches",
                  header2: "Members who have joined in the last 10 Days",
                  activity: true,
                },
              });
            }}
          >
            <label>New Matches</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/mutualMatches", {
                state: {
                  header1: "Mutual Matches",
                  header2: "1000 Members matches based on your preferences",
                  header3:
                    "These are members whose preferences match your profile and vice versa",
                  activity: true,
                },
              });
            }}
          >
            <label>Mutual Matches</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/membersLookingForYou", {
                state: {
                  header1: "Members looking for you",
                  header2: "Members whose preferences matches your profile",
                  activity: true,
                },
              });
            }}
          >
            <label>Members looking for you</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/shortlistedByYou", {
                state: {
                  header1: "Shortlisted by you",
                  header2: "",
                  activity: true,
                },
              });
            }}
          >
            <label>Shortlisted by you</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/viewedYou", {
                state: {
                  header1: "Who viewed you",
                  header2: "",
                  activity: true,
                },
              });
            }}
          >
            <label>Who viewed you</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/shortlistedYou", {
                state: {
                  header1: "Who has shown interest",
                  header2: "",
                  activity: true,
                },
              });
            }}
          >
            <label>Who has shown interest</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>

          <button
            onClick={() => {
              navigate("/matches/blockedProfiles", {
                state: {
                  header1: "Blocked Profile",
                  header2: "",
                  activity: true,
                },
              });
            }}
          >
            <label>Blocked Profile</label>
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default MobileMatchesBox;

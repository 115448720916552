import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import withAuthBackground from "../withAuthBackground";
import AuthHeader from "../../../components/Auth/AuthHeader";
import FormInputs from "../../../components/Auth/Form input/FormInputs";
import googleIcon from "../../../assets/images/Auth/googleIcon.png";
import "./Login.css";
import axios from "axios";
import { BACKEND_PORT } from "../../../App";
// import BACKEND_PORT from '../../../App'

const Login = () => {
  // console.log(BACKEND_PORT);
  // form feild's state
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",

    errors: {
      email: "",
      password: "",
    },

    touched: {
      email: false,
      password: false,
    },
  });

  //  password eye icon
  const [passwordEye, setPasswordEye] = useState(true);

  // common error validation
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // login input
  const loginInputs = [
    {
      name: "email",
      type: "text",
      value: credentials.email,
      placeholder: "Email ID / Mobile No.",
      error: credentials.errors.email,
    },
    {
      name: "password",
      type: passwordEye ? "password" : "text",
      value: credentials.password,
      placeholder: "Password",
      error: credentials.errors.password,
      classname: "loginPasswordEye",
    },
  ];

  // input blur validations
  const handleBlur = ({ target: { name } }) => {
    const touched = { ...credentials.touched, [name]: true };
    setCredentials({ ...credentials, touched });
  };

  // input validations and form input
  const handleChange = ({ target: { name, value } }) => {
    const errors = credentials.errors;
    errors.email = "";
    setError("");

    switch (name) {
      case "email": {
        if (!value) {
          errors.email = !value ? "Enter Email ID / Mobile No." : "";
        }
        break;
      }

      case "password": {
        errors.password = !value ? "Enter password" : "";
        break;
      }
    }

    setCredentials({ ...credentials, [name]: value }, errors);
  };

  // handling eye icon
  const handlePassword = () => {
    setPasswordEye(!passwordEye);
  };

  // handling forgot password
  const handleForgotPassword = () => {
    const error = credentials.errors;
    if (credentials.email.includes("@") && credentials.email.includes(".com")) {
      navigate("/sendOTP", {
        state: { sendOtpTo: "email", email: credentials.email },
      });
    } else if (
      /^[0-9]*$/.test(credentials.email) &&
      credentials.email.length === 10
    ) {
      navigate("/sendOTP", { state: { sendOtpTo: "mobile" } });
    } else if (
      !credentials.email.length &&
      !(credentials.email.includes("@") && credentials.email.includes(".com"))
    ) {
      setError("");
      error.email = "Please fill this field to reset password";
    } else if (
      /^[0-9]*$/.test(credentials.email) &&
      credentials.email.length !== 10
    ) {
      setError("");
      error.email = "Enter valid mobile no. to reset password";
    } else if (
      credentials.email.length &&
      (!credentials.email.includes("@") || !credentials.email.includes(".com"))
    ) {
      setError("");
      error.email = "Enter valid Email ID to reset password";
    }

    setCredentials({ ...credentials, error });
  };

  // backend validations and error handling
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // checking validation (frontend)
      const errors = Object.values(credentials.errors).filter(
        (err) => err !== ""
      );
      const notTouched = Object.values(credentials.touched).filter((e) => !e);

      if (!errors.length && !notTouched.length) {
        const { email, password } = credentials;
        const LoginData = await axios.post(`${BACKEND_PORT}/login`, {
          email,
          password,
        });

        const token = LoginData.data.accessToken;

        window.sessionStorage.setItem("User_ID", LoginData.data.user_Id);
        window.sessionStorage.setItem("token", token);

        console.log(LoginData);

        if (LoginData.data.message === "Login Sucessfully") {
          if (token) {
            navigate("/home");
          } else {
            navigate("/login");
          }
        } else if (LoginData.data.message === "Approval Pending") {
          navigate("/approval");
        } else if (LoginData.data.message === "BasicDetails") {
          navigate("/profileregister/BasicDetails");
        } else if (LoginData.data.message === "FamilyDetails") {
          navigate("/profileregister/FamilyDetails");
        } else if (LoginData.data.message === "PersonalDetails") {
          navigate("/profileregister/PersonalDetails");
        } else if (LoginData.data.message === "EducationDetails") {
          navigate("/profileregister/EducationDetails");
        } else if (LoginData.data.message === "BasicInformation") {
          navigate("/profileregister/BasicInformation");
        }
      } else {
        const error = credentials.errors;
        error.email = "";
        setCredentials({ ...credentials, error });
        setError("All the input fields are mandatory");
      }
    } catch (err) {
      console.log(err.response.data);
      setError(err.response.data.message);
    }
  };

  return (
    <>
      {/* login content container */}
      <AuthHeader form={"login"} />

      {/* login page body */}
      <div className="row">
        <div className="Login">
          <div className="loginFormContainer">
            {error && <div className="loginErr">{error}</div>}

            <form className="loginForm" onSubmit={handleSubmit}>
              {loginInputs.map((inp) => (
                <FormInputs
                  key={inp.label}
                  name={inp.name}
                  type={inp.type}
                  value={inp.value}
                  placeholder={inp.placeholder}
                  handleChange={handleChange}
                  error={inp.error}
                  handleBlur={handleBlur}
                  handlePassword={handlePassword}
                  classname={inp.classname}
                  passwordEye={passwordEye}
                />
              ))}

              {/* forgot password link */}
              <div className="forgotPassLink" onClick={handleForgotPassword}>
                Forgot Password?
              </div>

              {/* forgot password button */}
              <div className="loginButton">
                <button>Login</button>
              </div>
            </form>

            {/*register button link  */}
            <div
              className="authLink"
              onClick={() => {
                navigate("/register");
              }}
            >
              Don't have an account? <span>Register</span>
            </div>
            {/* login hr */}
            <div className="loginHr">
              <div>Or</div>
            </div>

            {/* google login */}
            <div className="googlelogin">
              <div className="googleloginText">Login using</div>
            </div>

            {/* google icon */}
            <div className="googleIcon">
              <img src={googleIcon} />
            </div>

            <div className="loginFull"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthBackground(Login, "login");

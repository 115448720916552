import React from "react";
import "./CheckBoxInputField.css";

const CheckBoxInputField = ({ input, handleChange }) => {
  return (
    <>
      <div className="inputCheckBox">
        <input
          type="checkbox"
          name={input.name}
          value={input.value}
          onChange={handleChange}
        />
        <label>{input.label}</label>
      </div>
    </>
  );
};

export default CheckBoxInputField;

import React from "react";

import SelectInputField from "../InputFields/SelectInputField";

const PartnerPreferenceLocation = ({
  partnerPreferenceField,
  handleEdit,
  handleSave,
  partnerEditProcess,
  setPartnerEditProcess,
  handleChange,
}) => {
  const PartnerPreferenceLocationInputs = [
    {
      inputType: "select",
      name: "country",
      value: partnerPreferenceField.country,
      className: "PersonalDetailsMediumOption",
      options: [
        { value: "Select", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "state",
      className: "PersonalDetailsMediumOption",
      value: partnerPreferenceField.state,
      options: [
        { value: "Preferred State/States", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
    {
      inputType: "select",
      name: "city",
      className: "PersonalDetailsMediumOption",
      value: partnerPreferenceField.city,
      options: [
        { value: "Preferred City/Cities", disabled: true, selected: true },
        { value: "Tamil", disabled: false },
        { value: "English", disabled: false },
        { value: "Hindi", disabled: false },
      ],
    },
  ];

  return (
    <div className="religiousPrefFields">
      <div className="partnerFieldHeaderRow">
        <div className="ReligiousPartnerFieldTitle">Location Preferences</div>
      </div>

      <button
        className="EditPage_EditButton mobileViewEditButton"
        onClick={() => {
          handleEdit("locationDetails");
        }}
      >
        {partnerEditProcess === "locationDetails" ? (
          <span
            onClick={() => {
              handleSave("cancel");
            }}
          >
            <i class="fa-solid fa-pen"></i> Close
          </span>
        ) : (
          <>
            <i class="fa-solid fa-pen partnerPreferenceMobileEdit"></i>
          </>
        )}
      </button>

      <button
        className="PartnerEditPage_EditButton DesktopViewEditButton"
        onClick={() => {
          handleEdit("locationDetails");
        }}
      >
        <i class="fa-solid fa-pen"></i>

        {partnerEditProcess === "locationDetails" ? (
          <span
            onClick={() => {
              handleSave("cancel");
            }}
          >
            Close
          </span>
        ) : (
          <>Edit</>
        )}
      </button>

      <div
        className={`PartnerPreferenceDetailsSectionRow ${
          partnerEditProcess === "locationDetails"
            ? "DetailsSectionRow_EditTime"
            : "DetailsSectionRow"
        }`}
      >
        <div className="partnerInputs">
          {/* basic details labels */}
          <ul
            className={`${
              partnerEditProcess === "locationDetails"
                ? "PartnerDetails_section_label  PartnerDetails_section_label_EditTime"
                : "PartnerDetails_section_label"
            }`}
          >
            <li>Country</li>
            <li>State</li>
            <li>City</li>
          </ul>

          <ul
            className={`PartnerDetails_section_colon ${
              partnerEditProcess === "locationDetails"
                ? "PartnerDetails_section_colon_EditTime"
                : ""
            }`}
          >
            <li>:</li>
            <li>:</li>
            <li>:</li>
          </ul>

          {partnerEditProcess === "locationDetails" ? (
            // basic details inputs
            <>
              <ul className="Details_section_values partnerDetails_section_values">
                {PartnerPreferenceLocationInputs.map((input) =>
                  input.inputType === "select" ? (
                    <div className="partnerFieldAns">
                      <SelectInputField
                        input={input}
                        className={input.className}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </ul>
            </>
          ) : (
            <>
              <ul className="partnerDetails_section_values">
                <li>
                  {partnerPreferenceField.country === "" ||
                  partnerPreferenceField.country === "Select" ||
                  partnerPreferenceField.country === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("locationDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.country
                  )}
                </li>

                <li>
                  {partnerPreferenceField.state === "" ||
                  partnerPreferenceField.state === "Select" ||
                  partnerPreferenceField.state === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("locationDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.state
                  )}
                </li>

                <li>
                  {partnerPreferenceField.city === "" ||
                  partnerPreferenceField.city === "Select" ||
                  partnerPreferenceField.city === undefined ? (
                    <span
                      className="NeedToSelectValuePink"
                      onClick={() => {
                        handleEdit("locationDetails");
                      }}
                    >
                      {`Add Details`} &nbsp;&nbsp;
                      <i class="fa-solid fa-play"></i>
                    </span>
                  ) : (
                    partnerPreferenceField.city
                  )}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerPreferenceLocation;

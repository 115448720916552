import React from "react";

import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import aadharPending from "../../assets/images/Aadhar/aadharPending.png";
import aadharSuccess from "../../assets/images/Aadhar/aadharSuccess.png";
import uploadSearchGlass from "../../assets/images/Aadhar/searchGlasses.png";
import cardFormat from "../../assets/images/Aadhar/cards.png";
import waterShadow from "../../assets/images/Aadhar/waterShadow.png";
import hourglass from "../../assets/images/Aadhar/hourglass.png";
import CheckMark from "../../assets/images/Aadhar/CheckMark.png";
import xmark from "../../assets/images/Aadhar/xmark.png";
import "./MobileAadhar.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MobileAadhar = () => {
  const navigate = useNavigate();

  const [aadhar, setAadhaar] = useState("rejected");

  const aadharContent = [
    {
      classname: "aadharUpload",
      content:
        "Upload your Aadhar to verify your identity information. The document uploaded by you will not be shown to other members.",
    },
    {
      classname: "aadharPending",
      content:
        "Your Aadhar has been uploaded successfully and is being verified. This process might take upto two hours.",
      aadharImage: aadharPending,
    },
    {
      classname: "aadharSuccess",
      content:
        "Your Aadhar has been verified successfully.\n The document uploaded by you will not be shown to other members.",
      aadharImage: aadharSuccess,
    },
  ];

  return (
    <>
      <div className="container-fluid backGroundAntique">
        <div className="row">
          <span className="DesktopContactUsHomeHeader">
            <HomeHeader />
          </span>

          <span className="MobileContactUsHomeHeader">
            <div className="searchBoxTapBar">
              <span className="PageHeading">Profile Verification</span>
              <div
                className="leftArrowIcon"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </div>
          </span>
        </div>

        {/* aadhar  */}
        <div className="MobileAaharContainer">
          {aadhar === "upload" ? (
            <div className="aadharUploadStatus">
              <div className="aadharVerifyIMGContainer">
                <div className="uploadImgBox">
                  <img
                    src={uploadSearchGlass}
                    alt="uploadSearchGlass"
                    className="glass"
                  />
                  <img
                    src={cardFormat}
                    alt="uploadSearchGlass"
                    className="formatCard"
                  />
                  <img
                    src={waterShadow}
                    alt="waterShadow"
                    className="waterShadow"
                  />
                </div>
              </div>
              <p className="adharVerifyTopPara">We need to verify your ID</p>
              <p className="adharVerifyMiddlePara">
                Verified profiles are trusted more and likely to get shortlisted
                easily
              </p>
              <p className="adharVerifyBottomPara">
                Upload your <span className="AadhaarHightLight">Aadhaar</span>{" "}
                to verify your identity information. The document uploaded by
                you will not be shown to other members.
              </p>
              <div className="StatusLabel">Verify</div>
            </div>
          ) : aadhar === "pending" ? (
            <div className="aadharUploadStatus">
              <div className="aadharVerifyIMGContainer">
                <div className="uploadImgBox">
                  <img src={hourglass} alt="hourglass" className="hourglass" />

                  <img
                    src={waterShadow}
                    alt="waterShadow"
                    className="waterShadow"
                  />
                </div>
              </div>
              <p className="adharVerifyTopPara">Verification is pending</p>
              <p className="adharVerifyMiddlePara">
                Verified profiles are trusted more and likely to get shortlisted
                easily
              </p>
              <p className="adharVerifyBottomPara">
                Your Aadhaar has been uploaded successfully and is being
                verified. This process might take upto two hours.
              </p>
              <div className="StatusLabel">Back</div>
            </div>
          ) : aadhar === "success" ? (
            <div className="aadharUploadStatus">
              <div className="aadharVerifyIMGContainer">
                <div className="uploadImgBox">
                  <img src={CheckMark} alt="CheckMark" className="CheckMark" />

                  <img
                    src={waterShadow}
                    alt="waterShadow"
                    className="waterShadow"
                  />
                </div>
              </div>
              <p className="adharVerifyTopPara">Profile Verified</p>
              <p className="adharVerifyMiddlePara">
                Verified profiles are trusted more and likely to get shortlisted
                easily
              </p>
              <p className="adharVerifyBottomPara">
                Your Aadhaar has been verified successfully. The document
                uploaded by you will not be shown to other members.
              </p>
              <div className="StatusLabel">Back</div>
            </div>
          ) : aadhar === "rejected" ? (
            <div className="aadharUploadStatus">
              <div className="aadharVerifyIMGContainer">
                <div className="uploadImgBox">
                  <img src={xmark} alt="xmark" className="xmark" />

                  <img
                    src={waterShadow}
                    alt="waterShadow"
                    className="waterShadow"
                  />
                </div>
              </div>
              <p className="adharVerifyTopPara">Profile Rejected</p>
              <p className="adharVerifyMiddlePara">
                Verified profiles are trusted more and likely to get shortlisted
                easily
              </p>
              <p className="adharVerifyBottomPara">
                Your Aadhaar has been rejected since it is not valid. Please try
                again later.The document uploaded by you will not be shown to
                other members.
              </p>
              <div className="StatusLabel">Back</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default MobileAadhar;

import React from "react";
import InputField from "../../../components/PartnerPreference/InputFields/InputField";
import RadioInputField from "../../../components/PartnerPreference/InputFields/RadioInputField";
import SelectInputField from "../../../components/PartnerPreference/InputFields/SelectInputField";
import "./PersonalDetails_FamilyDetails.css";

const PersonalDetails_FamilyDetails = ({
  ProfileDetails,
  handleChange,
  handleEdit,
  editProcess,
  handleSave,
}) => {
  // family inputs
  const PersonalDetails_ProfessionalDetails = [
    {
      inputType: "radio",
      name: "FamilyValues",
      value: ProfileDetails.field,
      field: [
        {
          name: "FamilyValues",
          label: "Orthodox",
          value: "Orthodox",
          id: "Orthodox",
          for: "Orthodox",
        },
        {
          name: "FamilyValues",
          label: "Traditional",
          value: "Traditional",
          id: "Traditional",
          for: "Traditional",
        },
        {
          name: "FamilyValues",
          label: "Moderate",
          value: "Moderate",
          id: "Moderate",
          for: "Moderate",
        },
        {
          name: "FamilyValues",
          label: "Liberal",
          value: "Liberal",
          id: "Liberal",
          for: "Liberal",
        },
      ],
    },
    {
      inputType: "radio",
      name: "FamilyType",
      value: ProfileDetails.field,
      field: [
        {
          name: "FamilyType",
          label: "Joint",
          value: "Joint",
          id: "Joint",
          for: "Joint",
        },
        {
          name: "FamilyType",
          label: "Nuclear",
          value: "Nuclear",
          id: "Nuclear",
          for: "Nuclear",
        },
        {
          name: "FamilyType",
          label: "Others",
          value: "Others",
          id: "Others",
          for: "Others",
        },
      ],
    },
    {
      inputType: "radio",
      name: "FamilyStatus",
      value: ProfileDetails.field,
      field: [
        {
          name: "FamilyStatus",
          label: "Middle Class",
          value: "Middle Class",
          id: "Middle Class",
          for: "Middle Class",
        },
        {
          name: "FamilyStatus",
          label: "Upper Middle Class",
          value: "Upper Middle Class",
          id: "UpperMiddleClass",
          for: "UpperMiddleClass",
        },
        {
          name: "FamilyStatus",
          label: "Rich ",
          value: "Rich ",
          id: "Rich",
          for: "Rich ",
        },
        {
          name: "FamilyStatus",
          label: "Affluent",
          value: "Affluent",
          id: "Affluent",
          for: "Affluent",
        },
      ],
    },
    {
      inputType: "radio",
      value: ProfileDetails.FathersOccupation,
      field: [
        {
          name: "FathersOccupation",
          label: "Government",
          value: "Government",
          id: "Government",
          for: "Government",
        },
        {
          name: "FathersOccupation",
          label: "Private",
          value: "Private",
          id: "Private",
          for: "Private",
        },
        {
          name: "FathersOccupation",
          label: "Business",
          value: "Business",
          id: "Business",
          for: "Business",
        },
        {
          name: "FathersOccupation",
          label: "Defence",
          value: "Defence",
          id: "Defence",
          for: "Defence",
        },
        {
          name: "FathersOccupation",
          label: "Self Employed",
          value: "Self Employed",
          id: "Self Employed",
          for: "Self Employed",
        },
        {
          name: "FathersOccupation",
          label: "Not Working",
          value: "Not Working",
          id: "Not Working",
          for: "Not Working",
        },
      ],
    },
    {
      inputType: "radio",
      name: "MothersOccupation",
      value: ProfileDetails.MothersOccupation,
      field: [
        {
          name: "MothersOccupation",
          label: "Government",
          value: "Government",
          id: "Government",
          for: "Government",
        },
        {
          name: "MothersOccupation",
          label: "Private",
          value: "Private",
          id: "Private",
          for: "Private",
        },
        {
          name: "MothersOccupation",
          label: "Business",
          value: "Business",
          id: "Business",
          for: "Business",
        },
        {
          name: "MothersOccupation",
          label: "Defence",
          value: "Defence",
          id: "Defence",
          for: "Defence",
        },
        {
          name: "MothersOccupation",
          label: "Self Employed",
          value: "Self Employed",
          id: "Self Employed",
          for: "Self Employed",
        },
        {
          name: "MothersOccupation",
          label: "Not Working",
          value: "Not Working",
          id: "Not Working",
          for: "Not Working",
        },
      ],
    },
  ];

  return (
    <div
      className={`${
        editProcess === "FamilyDetails"
          ? "EditTimePersonalDetails_FamilyDetails_Card"
          : "PersonalDetails_FamilyDetails"
      } `}
    >
      <div className="fewWordBoxHeader">
        <h6>Family Details</h6>
        <button
          className="EditPage_EditButton mobileViewEditButton"
          onClick={() => {
            handleEdit("FamilyDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {/* edit and close button */}
          {editProcess === "FamilyDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>
              {/* <i class="fa-solid fa-pen"></i> */}
              Edit
            </>
          )}
        </button>
      </div>

      <div className="card PersonalDetails_card">
        <button
          className="EditPage_EditButton DesktopViewEditButton"
          onClick={() => {
            handleEdit("FamilyDetails");
          }}
        >
          <i class="fa-solid fa-pen"></i>

          {/* edit and close button */}
          {editProcess === "FamilyDetails" ? (
            <span
              onClick={() => {
                handleSave("cancel");
              }}
            >
              Close
            </span>
          ) : (
            <>
              {/* <i class="fa-solid fa-pen"></i> */}
              Edit
            </>
          )}
        </button>
        <div
          className={`${
            editProcess === "FamilyDetails"
              ? "DetailsSectionRow_EditTime"
              : "DetailsSectionRow"
          }`}
        >
          {/* family inputs label */}
          <div className="Details_section">
            <ul
              className={`Details_section_label ${
                editProcess === "FamilyDetails"
                  ? "Details_section_label_EditTime FamilyDetails_section_colon_EditTime"
                  : ""
              }`}
            >
              <li className="EditFamilyValues">Family Values</li>
              <li className="EditFamilyType">Family type</li>
              <li className="EditFamilyStates">Family Status</li>
              <li className="EditFamilyFathers">Father’s Occupation</li>
              <li className="EditFamilyMothers">Mother’s Occupation</li>
              {editProcess !== "FamilyDetails" && (
                <>
                  <li>No. of Brothers</li>
                  <li>Brothers Married</li>
                  <li>No. of Sisters</li>
                  <li>Sisters Married</li>
                </>
              )}

              {/* family sibbiling input */}
              {editProcess === "FamilyDetails" && (
                <div className="PersonalDetails_familyDetails_Sibilings_Row">
                  <div className="PersonalDetails_familyDetails_sibiling">
                    <label> No. of Brothers</label>
                    <InputField
                      className={"PersonalDetailsTextBox"}
                      handleChange={handleChange}
                      input={{
                        name: "NoOfBrothers",
                        value: ProfileDetails.NoOfBrothers,
                      }}
                    />
                  </div>
                  <div className="PersonalDetails_familyDetails_sibiling">
                    <label>Brothers married</label>
                    <InputField
                      handleChange={handleChange}
                      className={"PersonalDetailsTextBox"}
                      input={{
                        name: "BrothersMarried",
                        value: ProfileDetails.BrothersMarried,
                      }}
                    />
                  </div>
                  <div className="PersonalDetails_familyDetails_sibiling">
                    <label> No. of Sisters</label>
                    <InputField
                      handleChange={handleChange}
                      className={"PersonalDetailsTextBox InputBoxNoOfSisters"}
                      input={{
                        name: "NoOfSisters",
                        value: ProfileDetails.NoOfSisters,
                      }}
                    />
                  </div>
                  <div className="PersonalDetails_familyDetails_sibiling">
                    <label> Sisters married</label>
                    <InputField
                      handleChange={handleChange}
                      className={
                        "PersonalDetailsTextBox InputBoxNoOfSistersMarrid"
                      }
                      input={{
                        name: "SistersMarried",
                        value: ProfileDetails.SistersMarried,
                      }}
                    />
                  </div>
                </div>
              )}

              <li>Family Location</li>
            </ul>
            <ul
              className={`Details_section_colon ${
                editProcess === "FamilyDetails"
                  ? "Details_section_colon_EditTime"
                  : ""
              }`}
            >
              <li className="EditFamilyValues"> : </li>
              <li className="EditFamilyType"> : </li>
              <li className="EditFamilyStates"> : </li>
              <li className="EditFamilyFathers"> : </li>
              <li className="EditFamilyMothers"> : </li>
              <li
                className={`${
                  editProcess === "FamilyDetails"
                    ? "PersonalDetails_familyDetails_withOutColon"
                    : ""
                }`}
              >
                {" "}
                :{" "}
              </li>
              <li
                className={`${
                  editProcess === "FamilyDetails"
                    ? "PersonalDetails_familyDetails_withOutColon"
                    : ""
                }`}
              >
                {" "}
                :{" "}
              </li>
              <li
                className={`${
                  editProcess === "FamilyDetails"
                    ? "PersonalDetails_familyDetails_withOutColon"
                    : ""
                }`}
              >
                {" "}
                :{" "}
              </li>
              <li
                className={`${
                  editProcess === "FamilyDetails"
                    ? "PersonalDetails_familyDetails_withOutColon"
                    : ""
                }`}
              >
                {" "}
                :{" "}
              </li>

              <li
                className={`${
                  editProcess === "FamilyDetails"
                    ? "PersonalDetails_familyDetails_FamilyLocation"
                    : ""
                }`}
              >
                :
              </li>
            </ul>

            {editProcess === "FamilyDetails" ? (
              <>
                <ul className="Details_section_values Details_section_values_Family FD_Details_section_values">
                  {PersonalDetails_ProfessionalDetails.map((input) => (
                    <div className="partnerFieldAns partnerPreferenceRadioFields EditFDRadioFields">
                      <RadioInputField
                        inputFields={ProfileDetails}
                        input={input}
                        handleChange={handleChange}
                      />
                    </div>
                  ))}
                  {/* family location input */}
                  <div className="partnerFieldAns partnerPreferenceRadioFields PersonalDetails_familyDetails_FamilyLocation">
                    <RadioInputField
                      inputFields={ProfileDetails}
                      input={{
                        inputType: "radio",
                        name: "FamilyLocation",
                        value: ProfileDetails.FamilyLocation,
                        field: [
                          {
                            name: "FamilyLocation",
                            label: "Same as mine",
                            value: "Same as mine",
                            id: "Same as mine",
                            for: "Same as mine",
                          },
                          {
                            name: "FamilyLocation",
                            label: "Different location",
                            value: "Different location",
                            id: "Different location",
                            for: "Different location",
                          },
                        ],
                      }}
                      handleChange={handleChange}
                      // className={"PersonalDetails_familyDetails_FamilyLocation"}
                    />
                  </div>
                </ul>
              </>
            ) : (
              <>
                <ul className="Details_section_values">
                  <li>
                    {ProfileDetails.FamilyValues === "" || ProfileDetails.FamilyValues === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add values`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.FamilyValues
                    )}
                  </li>
                  <li>
                    {ProfileDetails.FamilyType === "" || ProfileDetails.FamilyType === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add type`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.FamilyType
                    )}
                  </li>
                  <li>
                    {ProfileDetails.FamilyStatus === "" || ProfileDetails.FamilyStatus === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add status`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.FamilyStatus
                    )}
                  </li>
                  <li>
                    {ProfileDetails.FathersOccupation === "" || ProfileDetails.FathersOccupation === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Occupation`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.FathersOccupation
                    )}
                  </li>
                  <li>
                    {ProfileDetails.MothersOccupation === "" || ProfileDetails.MothersOccupation === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Occupation`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.MothersOccupation
                    )}
                  </li>
                  <li>
                    {ProfileDetails.NoOfBrothers === "" || ProfileDetails.NoOfBrothers === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Brothers`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.NoOfBrothers
                    )}
                  </li>
                  <li>
                    {ProfileDetails.BrothersMarried === "" || ProfileDetails.BrothersMarried === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Brothers`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.BrothersMarried
                    )}
                  </li>
                  <li>
                    {ProfileDetails.NoOfSisters === "" || ProfileDetails.NoOfSisters === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Sisters`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.NoOfSisters
                    )}
                  </li>
                  <li>
                    {ProfileDetails.SistersMarried === "" || ProfileDetails.SistersMarried === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Sisters`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.SistersMarried
                    )}
                  </li>
                  <li>
                    {ProfileDetails.FamilyLocation === "" || ProfileDetails.FamilyLocation === undefined ? (
                      <span
                        className="NeedToSelectValuePink"
                        onClick={() => {
                          handleEdit("FamilyDetails");
                        }}
                      >
                        {`Add Location`} &nbsp;&nbsp;
                        <i class="fa-solid fa-play"></i>
                      </span>
                    ) : (
                      ProfileDetails.FamilyLocation
                    )}
                  </li>
                </ul>
              </>
            )}

            {editProcess === "FamilyDetails" ? (
              <div className="Details_section_update_buttons">
                <button
                  className="save"
                  onClick={() => {
                    handleSave("save");
                  }}
                >
                  Save
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    handleSave("cancel");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails_FamilyDetails;

{
  /* <ul className="Details_section_values">
              <li>{`Traditional`}</li>
              <li>{`Nuclear`}</li>
              <li>{`Upper-middle class`}</li>
              <li>
                <span className="NeedToSelectValuePink">
                  {`Add Father’s Occupation`} &nbsp;&nbsp;
                  <i class="fa-solid fa-play"></i>
                </span>
              </li>
              <li>
                <span className="NeedToSelectValuePink">
                  {`Add Mother’s Occupation`} &nbsp;&nbsp;
                  <i class="fa-solid fa-play"></i>
                </span>
              </li>
              <li>{`Not Specified`}</li>
              <li>{`Not Specified`}</li>
              <li>{`Not Specified`}</li>
            </ul> */
}

import React from 'react';

import  "./ShareInput.css";

const ShareInput = ({classname,buttonName}) => {
  return (
    <div className={`shareInput ${classname}`}>
        <input />
        <button>{buttonName}</button>
    </div>
  )
}

export default ShareInput
import React, { useEffect } from "react";
import "../ShowInterest/InterestPopUp.css";

const InterestPopUp = ({ interest, setInterestPopUp }) => {
  useEffect(() => {
    interest
      ? (document.getElementById("body").style.overflowY = "hidden")
      : (document.getElementById("body").style.overflowY = "auto");
  }, [interest]);
  return (
    <>
      <div className="InterestPopUp">
        <div className="interestPopupContent">
          <div
            className="InterestPopUp-Close"
            onClick={() => {
              document.getElementById("body").style.overflowY = "auto";
              setInterestPopUp(false);
            }}
          >
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div className="InterestPopUp-Box">
            <div className="InterestPopUp-Context">
              <p>Interest has successfully been sent to the match.</p>
            </div>

            <div className="InterestPopUp-Buttons">
              <div className="InterestPopUp-Button-2">
                <button
                  onClick={() => {
                    document.getElementById("body").style.overflowY = "auto";
                    setInterestPopUp(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterestPopUp;

import React from "react";
import { useNavigate } from "react-router-dom";
import withVerificationBackground from "../Auth/withVerificationBackground";
import hoursGlass from '../../assets/images/ProfileRegister/hourglass.png'
import "./Approval.css";

const Approval = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mobileContainer">
        <div className="mobileVerifiedIcon">
          <i
            class="fa-solid fa-xmark"
            onClick={() => {
              navigate("/");
            }}
          ></i>
        </div>
        <div className="MobileViewImg">
          <img src={hoursGlass} alt="HoursGlass" />
        </div>
        <div className="mobileVerifiedTHeader Approvelheader">
          Waiting for Approval
        </div>
        <div className="mobileVerifiedText ApprovelPageText">
          Your account is waiting for our administrator approval.
        </div>
        <div className="mobileVerifiedText ApprovelPageText24">
          Please check back after 24Hrs
        </div>
      </div>
    </>
  );
};

export default withVerificationBackground(Approval);

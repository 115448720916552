import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/Home header/HomeHeader";
import "../PagenotFound/PagenotFound.css";
import IMG from "../../assets/images/PagenotFound/Error404.png";
import { useNavigate } from "react-router-dom";
import nayan from '../../assets/images/Home/nayan.jpg'
import { useState } from "react";
import ProfilePicPlaceholder from "../../assets/images/Home/HomeProfilePlaceHolder.png";
import MobileNotificationIcon from '../../assets/images/Sidebar/NotificationBell Icon.png'
import GreenNotificationPointIcon  from '../../assets/images/Sidebar/GreenNotificationPointIcon.png'

const PagenotFound = () => {
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(nayan)
  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="row">
            <span className="DesktopContactUsHomeHeader">
              <HomeHeader />
            </span>

            <span className="MobileContactUsHomeHeader">
              <div className="PageNotFoundTapBar">
                <span className="PageHeading">Testimonials</span>

                <div
                  className="leftArrowIcon"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </div>

                <div className="PageNotFoundProfilePics">
                  {profilePicture ? (
                    <img src={profilePicture} />
                  ) : (
                    <img
                      src={ProfilePicPlaceholder}
                      className="placeHolderImg"
                    />
                  )}
                </div>
                <span className="pageNotFoundProfileDetails">Hey ! Cathy</span>

                <span
                  className="MobileNotificationIcon"
                  onClick={() => {
                    navigate("/notifications");
                  }}
                >
                  <img src={MobileNotificationIcon} alt="NotificationBell" />
                  <img
                    src={GreenNotificationPointIcon}
                    alt="MobileNotificationPoint"
                    className="MobileNotificationPoint"
                  />
                </span>
              </div>
            </span>

            <div className="PagenotFound">
              <div className="PagenotFound-Context">
                404 - Page not found
                <span>
                  {" "}
                  The page you are looking for might been removed or temporarily
                  unavailable
                </span>
              </div>

              <div className="PagenotFound-Image">
                <img src={IMG} />
              </div>
            </div>
            <div className="row ">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PagenotFound;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import withVerificationBackground from "../withVerificationBackground";
import "./EmailVerify.css";

const EmailVerified = () => {
//   const [emailVerified, setEmailVerified] = useState(true);
//   let [counter, setCounter] = useState(59);

//   useEffect(() => {
//     const timer =
//       counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

//     return () => {
//       clearInterval(timer);
//     };
//   }, [counter]);

  const navigate = useNavigate();

//   const {
//     state: { UserID, email },
//   } = useLocation();-

  return (
    <>
      <div className="emailContainer">
        {/* <div className="registeredheader">
          <p>You have successfully registered with Joy Matrimony !</p>
          <p>
            Your Matrimony ID is <span className="matrimonyID">{UserID}</span>
          </p>
          <hr className="MobileverifyHrTag" />
        </div> */}
        {/* {emailVerified ? ( */}
          <>
            <div
              className="EmailVerifiedIcon"
              onClick={() => {
                navigate("/profileregister/BasicDetails");
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </div>
            <div className="EmailVerifiedTHeader">Email Verified! </div>
            <div className="EmailVerifiedText">
              You have successfully verified your email ID
            </div>
          </>
        {/* ) : (
          <>
            <div className="emailHeader">Verify your Email</div>
            <div className="emailText">
              A verification link has been sent to your email{" "}
              <span>{email}.</span>
              Please click on the link that has been sent to your email account
              (check spam if not received), and verify your email to continue
              the registration process.
            </div>
            {counter === 0 ? (
              <div className="emailTextLink">
                <span
                  onClick={() => {
                    navigate("/editEmail");
                  }}
                >
                  Click here
                </span>{" "}
                if you did not receive an email or would like to change the
                email
              </div>
            ) : (
              <div className="counter">
                Resending the code in
                <span className="counterColor">
                  00 : {counter < 10 ? "0" + counter : counter}
                </span>
              </div>
            )}
          </>
        )} */}
      </div>
    </>
  );
};

export default withVerificationBackground(EmailVerified);

import React from "react";

import { useNavigate } from "react-router-dom";
import "./HomeCardComponent.css";

const HomeCardComponent = ({ profileMembers, header1, header2, link, activity }) => {
  const navigate = useNavigate();

  // const screenWidth = window.screen.width;
  console.log(profileMembers);
  return (
    <>
      <div className="homeMatchesHeader">
        <div>
          <h4 className="homeMatchesHeader1">{header1}</h4>
          <div className="homeMatchesHeader2">{header2}</div>
        </div>
        <div
          className="homeSeeAll"
          onClick={() => {
            navigate(link, {
              state: { header1, header2, activity, profileMembers },
            });
          }}
        >
          See All
        </div>
      </div>
      <div className="homeMatchesProfile">
        <div className="profilePersonsRow DesktopRow">
          {profileMembers.slice(0,3).map((profile, idx) => {
            return (
              <>
                <div className="card homeProfileCard" key={idx} onClick={()=> {navigate("/individual", {
                  state: {
                    user_id: profile.user_id,
                    profilepic: profile.profilePictures[0],
                    data: profile,
                  },
                });}}>
                  <div className="homeCardProfileImage">
                    <img
                      src={profile?.profilePictures[0]}
                      className="card-img-top homeProfileImg"
                      alt="..."
                    />
                  </div>

                  <div className="card-body homeProfileBody">
                    <div className="card-text homeProfiletext">
                      {profile.name}
                    </div>
                    <div className="card-text">{profile.age}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="profilePersonsRow MobileRow">
          {profileMembers.slice(0, 2).map((profile, idx) => {
            return (
              <>
                <div
                  className="card homeProfileCard"
                  key={idx}
                  onClick={() => {
                    navigate("/individual", {
                      state: {
                        user_id: profile.user_id,
                        data: profile,
                      },
                    });
                  }}
                >
                  <img
                    src={profile.profilePic}
                    className="card-img-top homeProfileImg"
                    alt="..."
                  />
                  <div className="card-body homeProfileBody">
                    <div className="card-text homeProfiletext">
                      {profile.name}
                    </div>
                    <div className="card-text">{profile.age}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HomeCardComponent;

// (screenWidth <= 426)
//   ?

// : profileMembers.slice(0, 2).map((profile, idx) => {
//     return (
//       <>
//         <div className="card homeProfileCard" key={idx}>
//           <img
//             src={profile.profilePic}
//             className="card-img-top homeProfileImg"
//             alt="..."
//           />
//           <div className="card-body homeProfileBody">
//             <div className="card-text homeProfiletext">
//               {profile.name}
//             </div>
//             <div className="card-text">{profile.age}</div>
//           </div>
//         </div>
//       </>
//     );
//   })
